// Render Prop
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Card, Field, SecondaryButton } from "../../Styles/styles";
import { useParams } from "react-router-dom";
import { swapSKU } from "../../Services/containers.services";

const ContainerSwapForm = ({
  currentSKU,
  currentContainerID,
  targetOptionID,
}) => {
  const [demand, setDemand] = useState(currentSKU?.demand_qty);
  const [promo, setPromo] = useState(currentSKU?.promo_qty);
  const [backlog, setBacklog] = useState(currentSKU?.frombacklog);
  const [japanQTY, setJapanQTY] = useState(currentSKU?.jpn_qty);
  const { poid, page } = useParams();

  useEffect(() => {
    setDemand(currentSKU?.demand_qty);
    setPromo(currentSKU?.promo_qty);
    setBacklog(currentSKU?.frombacklog);
    setJapanQTY(currentSKU?.jpn_qty);
  }, [currentSKU]);

  const formik = useFormik({
    initialValues: {
      poNum: poid,
      from: currentContainerID,
      to: targetOptionID,
      SKU: currentSKU?.SKU,
      // SKU INFO:
      demand: 0,
      promo: 0,
      backlog: 0,
      japan: 0,
    },

    onSubmit: (values) => {
      const swappingInfo = {
        poNum: poid,
        stage: page,
        from: values?.from,
        to: values?.to,
        SKU: values?.SKU,
        skuObject: {
          demand: values?.demand,
          promo: values?.promo,
          backlog: values?.backlog,
          japan: values?.japan,
        },
      };

      swapSKU({ swappingInfo });
    },
    enableReinitialize: true,
  });

  const handleDemandChange = (e) => {
    const nextOutDemand = e.target.value;
    const currOutDemand = formik.values.demand;
    if (nextOutDemand > currOutDemand) {
      setDemand((prev) => prev - 1);
    }
    if (nextOutDemand < currOutDemand) {
      setDemand((prev) => prev + 1);
    }
    formik.handleChange(e);
  };

  const handlePromoChange = (e) => {
    const nextOutPromo = e.target.value;
    const currOutPromo = formik.values.promo;
    if (nextOutPromo > currOutPromo) {
      setPromo((prev) => prev - 1);
    }
    if (nextOutPromo < currOutPromo) {
      setPromo((prev) => prev + 1);
    }
    formik.handleChange(e);
  };

  const handleBacklogChange = (e) => {
    const nextOutBacklog = e.target.value;
    const currOutBacklog = formik.values.backlog;
    if (nextOutBacklog > currOutBacklog) {
      setBacklog((prev) => prev - 1);
    }
    if (nextOutBacklog < currOutBacklog) {
      setBacklog((prev) => prev + 1);
    }
    formik.handleChange(e);
  };

  const handleJapanChange = (e) => {
    const nextOutJapanQTY = e.target.value;
    const currOutJapanQTY = formik.values.japan;
    if (nextOutJapanQTY > currOutJapanQTY) {
      setJapanQTY((prev) => prev - 1);
    }
    if (nextOutJapanQTY < currOutJapanQTY) {
      setJapanQTY((prev) => prev + 1);
    }
    formik.handleChange(e);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <h4>Singapore Stocks</h4>
        <label htmlFor="demand">{`Demand (Remaining : ${demand})`}</label>
        <Field
          id="demand"
          type="number"
          name="demand"
          min={0}
          max={currentSKU?.demand_qty}
          onChange={handleDemandChange}
          value={formik.values.demand}
        />

        <label htmlFor="promo">{`Promo (Remaining : ${promo})`}</label>
        <Field
          id="promo"
          type="number"
          name="promo"
          min={0}
          max={currentSKU?.promo_qty}
          onChange={handlePromoChange}
          value={formik.values.promo}
        />

        <label htmlFor="backlog">{`Backlog (Remaining : ${backlog})`}</label>
        <Field
          type="number"
          name="backlog"
          min={0}
          max={currentSKU?.frombacklog}
          onChange={handleBacklogChange}
          value={formik.values.backlog}
        />
      </Card>
      <Card>
        <h4>Japan Stocks</h4>
        <label htmlFor="japan">{`Japan (Remaining : ${japanQTY})`}</label>
        <Field
          id="japan"
          type="number"
          name="japan"
          min={0}
          max={currentSKU?.jpn_qty}
          onChange={handleJapanChange}
          value={formik.values.japan}
        />
      </Card>
      <SecondaryButton type="submit">Submit</SecondaryButton>
    </form>
  );
};

export default ContainerSwapForm;
