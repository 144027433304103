import api from "./api";

export const storeForecastOptions = async ({ setOptions }) => {
  const url = "/getforecast";
  const response = await api.get(url);
  const data = response?.data;
  // console.log(data);
  setOptions(data);
};

export const getVendorList = async ({ setVendorList, setAlertInfo }) => {
  try {
    const url = "/getforecastvendorlist";

    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const returnData = response.data["data"];
        setVendorList(returnData);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error.response.data["message"] ?? "Failed to load data.",
    }));
  }
};

export const getSkuForecast = async ({
  selectedSKU,
  setForecastedData,
  setAlertInfo,
}) => {
  if (selectedSKU !== "") {
    try {
      const url = `/getskuforecast/${selectedSKU}`;

      const response = await api.get(url);

      if (response.status === 200) {
        if (
          response.data["status_code"] === 200 &&
          response.data["status"] === "success"
        ) {
          const returnData = response.data["data"];

          setForecastedData((prev) => ({
            ...prev,
            curMonth: returnData.cur_month,
            label: returnData.label,
            forecast: returnData.forecast,
            pastBought: returnData.past_bought,
            reason: returnData.reason,
            ema_6: returnData.ema_6,
            sma_18: returnData.sma_18,
          }));
        } else {
          setAlertInfo((prev) => ({
            ...prev,
            open: true,
            severity: "error",
            msg: response.data["message"] ?? "Failed to load data.",
          }));
        }
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } catch (error) {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: error.response.data["message"] ?? "Failed to load data.",
      }));
    }
  } else {
    // console.log("No SKU is selected.");
  }
};
