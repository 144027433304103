import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
  Button
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

import DatePickerField from "../../../../Components/DatePicker/DatePickerField";
import { Field, useFormikContext } from "formik";
import EditableTable from "../BulkCreateTable";
import RefreshIcon from '@mui/icons-material/Refresh';

const actionButtonStyle = {
  height: "30px",
  borderColor: "#53545C",
  color: "#53545C",
  // margin: "0px 8px",
  marginTop: "24px",
  fontSize: "11px",
  fontWeight: 400,
  borderRadius: "4px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#53545C",
  },
};

const Label = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
`;

const CheckboxLabel = MUIStyled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6E7079;
`;

const PlaceholderText = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #ABAFB1;
`;

const checkboxStyle = {
  color: "#CFD3D4",
  "&.Mui-checked": {
    color: "#bead7c",
  },
};


const fullHeightTextFieldStyle = {
  padding: "0px",
  margin: "0px",
  height: "100%",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const selectStyle = {
  marginBottom: "24px",
  height: "52px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};


const NewEventCard = ({ skuRows, locations = [], handleBulkCreate}) => {
  const { values, setFieldValue, handleChange } = useFormikContext();
  const [sgLocations, setSgLocations] = useState([]);
  const [data, setData] = useState([['', '', '']]);


  const tableRef = useRef();

  useEffect(() => {
    let list = [];
    if (locations.length > 0) {
      list = locations.filter((e) => e.is_sg_store === true);
    }

    setSgLocations(list);
  }, [locations]);


  const handleLocation = (e) => {
    const lvalue = e.target.value;

    if (lvalue[lvalue.length - 1] === "all") {
      // 1: "All"
      setFieldValue(
        `bulk_create_locations`,
        values.bulk_create_locations.length === locations.length
          ? []
          : locations
      );
      return;
    } else if (lvalue[lvalue.length - 2] === "all") {
      //  2: "All"
      setFieldValue(
        `bulk_create_locations`,
        values.bulk_create_locations.length === locations.length
          ? []
          : locations
      );
    } else if (lvalue[lvalue.length - 1] === "all-sg-store") {
      // 1: "All SG"

      setFieldValue(
        `bulk_create_locations`,
        values.bulk_create_locations.length === sgLocations.length
          ? []
          : sgLocations
      );
    } else if (lvalue[lvalue.length - 2] === "all-sg-store") {
      // 2: "All SG"
      setFieldValue(
        `bulk_create_locations`,
        values.bulk_create_locations.length === sgLocations.length
          ? []
          : sgLocations
      );
    } else {
      setFieldValue(`bulk_create_locations`, e.target.value);
      return;
    }
  };

  const handleBulkCreateClick = () => {
    console.log("Bulk Create Promo Group");
    const tableData = {
      "tableData": data,
      "locations": values.bulk_create_locations,
      "description": values.bulk_create_description,
      "isStackable": values.is_stackable,
    };

    const errorRows = handleBulkCreate(tableData);

    // reset the table
    setData(errorRows);
  }

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        padding: "28px 32px",
        border: "1px solid #C1C1C1",
      }}
    >
      <Grid container columnSpacing={2} marginBottom={3}>
        <Grid item xs={6}>
          <Field
            id={`bulk_create_locations`}
            name={`bulk_create_locations`}
            component={({ children }) => (
              <Select
                value={values.bulk_create_locations}
                displayEmpty
                onChange={handleLocation}
                inputProps={{ "aria-label": "Without label" }}
                multiple
                sx={{ width:"100%", ...selectStyle }}
                renderValue={(selected) => {
                  if (
                    selected === undefined ||
                    selected?.length === 0
                  ) {
                    return (
                      <PlaceholderText>Location</PlaceholderText>
                    );
                  } else {
                    return selected
                      ?.map((s) => s.name)
                      .join(", ");
                  }
                }}
              >
                {children}
              </Select>
            )}
          >
            <MenuItem
              value="all"
              classes={{
                root:
                  locations.length > 0 &&
                  values.bulk_create_locations.length ===
                    locations.length
                    ? {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                        },
                      }
                    : "",
              }}
            >
              <Checkbox
                sx={checkboxStyle}
                checked={
                  locations.length > 0 &&
                  values.bulk_create_locations.length ===
                    locations.length
                }
                indeterminate={
                  values.bulk_create_locations.length >
                    0 &&
                  values.bulk_create_locations.length <=
                    locations.length
                }
              />
              <Typography sx={{ fontWeight: "600" }}>
                Select All
              </Typography>
            </MenuItem>
            {sgLocations.length > 0 && (
              <MenuItem
                value="all-sg-store"
                classes={{
                  root:
                    sgLocations.length > 0 &&
                    values.bulk_create_locations.length ===
                      sgLocations.length
                      ? {
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                          "&:hover": {
                            backgroundColor:
                              "rgba(0, 0, 0, 0.08)",
                          },
                        }
                      : "",
                }}
              >
                <Checkbox
                  sx={checkboxStyle}
                  checked={
                    (sgLocations.length > 0 &&
                      values.bulk_create_locations
                        .length === sgLocations.length) ||
                    values.bulk_create_locations.length ===
                      locations.length
                  }
                  indeterminate={
                    values.bulk_create_locations.length >
                      0 &&
                    values.bulk_create_locations.filter(
                      (e) => e.is_sg_store
                    ).length > 0
                  }
                />
                <Typography sx={{ fontWeight: "600" }}>
                  Select All SG Stores
                </Typography>
              </MenuItem>
            )}
            {locations.map((pt) => {
              return (
                <MenuItem key={pt.id} value={pt}>
                  <Checkbox
                    sx={checkboxStyle}
                    checked={values.bulk_create_locations.some((e) => e.id === pt.id)}
                  />
                  {pt.name}
                </MenuItem>
              );
            })}
          </Field>
          <FormControlLabel
            id={`is_stackable`}
            name={`is_stackable`}
            checked={values?.is_stackable}
            onChange={(e) => {
              setFieldValue(
                `is_stackable`,
                !values?.is_stackable
              );
            }}
            control={<Checkbox
              sx={{
                color: "#CFD3D4",
                "&.Mui-checked": {
                  color: "#bead7c",
                },
              }}
            />}
            label={
              <CheckboxLabel>Stackable Promo</CheckboxLabel>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="normal"
            fullWidth
            multiline
            id="bulk_create_description"
            name="bulk_create_description"
            value={values.bulk_create_description}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Short Description"
            InputProps={{}}
            sx={fullHeightTextFieldStyle}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Label>SKU(s) Amount Discount Table</Label>
        <Button variant="None" sx={{
            opacity: (data.length === 1 && data[0].every(cell => cell === '')) ? 0 : 1,
            transition: "opacity 0.3s",
            height: "30px",
            borderColor: "#53545C",
            padding: "0px 8px",
            color: "#53545C",
            margin: "0px 0px 6px 0px",
            fontSize: "10px",
            fontWeight: 400,
            borderRadius: "4px",
            whiteSpace: "nowrap",
            "&:hover": {
              color: "red",
              backgroundColor: "rgba(255, 0, 0, 0.1)",
            },
            }}  
            onClick={() => { setData([['', '', '']]) }}
            disabled={data.length === 1 && data[0].every(cell => cell === '')}
        >
          <RefreshIcon sx={{fontSize:'14px', marginTop:'-2px', marginRight:'3px'}}/>
          Reset Table
        </Button>
      </Box>
      <EditableTable ref={tableRef} data={data} setData={setData} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          height="35px"
          sx={actionButtonStyle}
          onClick={() => handleBulkCreateClick()}
          disabled={skuRows.length === 0}
        >
          Bulk Create
        </Button>
      </Box>
    </Box>
  );
};

export default NewEventCard;
