import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  latestManagementAPI,
  latestProcurementAPI,
  latestStageAPI,
  latestVendorAPI,
  poGenDetailAPI,
  saveManagementAPI,
  saveProcurementAPI,
  saveVendorAPI,
  sendManAPI,
  sendProAPI,
  sendVenAPI,
  toBacklogManAPI,
  toBacklogPROAPI,
} from "../Components/Services/apiURL";

import useAuth from "./useAuth";

import send from "../images/Send_Button.svg";
import styled from "styled-components";
import { orderTableImmutableColumns } from "../Components/Tables/columns";
import { Field } from "../Styles/styles";
import { numToCurrency } from "../Services/util";

const SVG = styled.img`
  height: 18px;
`;

const stageStrToInt = (stage) => {
  switch (stage) {
    case "Procurement":
      return 1;
    case "Management":
      return 2;
    case "Proforma":
      return 3;
    case "POGeneration":
      return 4;
    case "POsToReceive":
      return 4;
  }
};
const SecondaryButton = styled.div`
  background: rgba(251, 249, 243, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
`;
const useOrderTable = ({ stage, option, refresher, handleRefresh }) => {
  const [PO, setPO] = useState();
  const [modalData, setModalData] = useState();
  const [latestVersion, setLatestVersion] = useState();
  const [isEditable, setIsEditable] = useState();

  const vendorID = useRef();

  const updateRow = (rowIndex, columnID, value) => {
    setPO((old) => {
      var oldPOdetail = [...old.POdetail];
      const newPOdetail = oldPOdetail.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...oldPOdetail[rowIndex],
            [columnID]: value,
          };
        }
        return row;
      });
      return {
        ...old,
        POdetail: newPOdetail,
      };
    });
  };

  const EditableCell = ({
    cell: { value },
    row: { index },
    column: { id },
  }) => {
    const onChange = (e) => {
      const currValue = e.target.value;
      updateRow(index, id, currValue);
    };

    return <Field value={value === null ? "" : value} onChange={onChange} />;
  };

  const EditableNumberCell = ({
    cell: { value },
    row: { index },
    column: { id },
  }) => {
    const onChange = (e) => {
      const currValue = e.target.value;
      updateRow(index, id, parseInt(currValue === "" ? 0 : currValue, 10));
    };

    return (
      <Field
        style={{ width: "100px", textAlign: "right" }}
        value={value}
        min="0"
        onChange={onChange}
        type="number"
      />
    );
  };

  const editableColumns = useMemo(
    () => [
      {
        Header: (props) => {
          return `SKU (${props.rows.length})`;
        },
        accessor: "SKU",
        tipText: `SKU IDs`,
      },
      {
        Header: "Description",
        accessor: "description",
        tipText: "Description of SKU",
      },
      {
        Header: "Status",
        accessor: "status",
        tipText: "SKU Status",
      },
      {
        Header: "Total Qty",
        accessor: "total_qty",
        tipText: "Total SKUs already in warehouse",
      },
      {
        Header: "Safety Stock",
        accessor: "safety_qty",
        tipText: "Safety SKUs from previous cycle",
      },
      {
        Header: "JPN Qty",
        accessor: "jpn_qty",
        tipText: "SKUs from Japan",
        Cell: EditableNumberCell,
      },
      {
        Header: "Promo Qty",
        accessor: "promo_qty",
        tipText: "How many SKUs to be ordered for Promo",
        Cell: EditableNumberCell,
      },
      {
        Header: "Replenishment Qty",
        accessor: "demand_qty",
        tipText:
          "Forecasted demand for SKUs next month, indicated for you to amend if needed",
        Cell: EditableNumberCell,
      },
      {
        Header: "Backlog Qty",
        accessor: "frombacklog",
        tipText: "Backlog Quantity from previous cycle(s)",
        Cell: EditableNumberCell,
      },
      {
        Header: "Total Order Qty",
        accessor: (row) =>
          row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog,

        tipText:
          "Total Amount of SKU to order, calculated by summing Japan, Promo, Replenishment, Backlog QTY",
      },
      {
        Header: "Total Value (SGD)",
        // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
        accessor: (row) =>
          numToCurrency(
            (row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog) *
              row.price
          ).toFixed(2),
        tipText: "Total value of this row",
      },
      {
        Header: "Remarks",
        accessor: "remark",
        tipText: "Remarks for this SKU",
        Cell: EditableCell,
      },
      {
        Header: "Order Later",

        Cell: (cell) => (
          <SecondaryButton
            style={{
              background: "none",
              color: "inherit",
              border: "none",
              font: "inherit",
              cursor: "pointer",
            }}
            onClick={() => sendBacklog(cell)}
          >
            <SVG src={send} />
          </SecondaryButton>
        ),
      },
    ],
    []
  );

  const immutableColumns = useMemo(orderTableImmutableColumns, []);

  const auth = useAuth()[0];
  var latestAPI;
  var saveAPI;
  var sendAPI;
  var toBacklogAPI;
  const approveAPI = sendVenAPI;
  switch (stage) {
    case "Procurement":
      latestAPI = latestProcurementAPI;
      saveAPI = saveProcurementAPI;
      sendAPI = sendManAPI;
      toBacklogAPI = toBacklogPROAPI;
      break;
    case "Management":
      latestAPI = latestManagementAPI;
      saveAPI = saveManagementAPI;
      sendAPI = sendProAPI;
      toBacklogAPI = toBacklogManAPI;
      break;
    case "Proforma":
      latestAPI = latestVendorAPI;
      saveAPI = saveVendorAPI;
      break;
    case "POGeneration":
      latestAPI = poGenDetailAPI;
      break;
    case "POsToReceive":
      latestAPI = poGenDetailAPI;
      break;
  }

  const sendBacklog = (cell) => {
    const SKU = cell.row.original.SKU;
    const currentVendorID = vendorID.current;

    fetch(toBacklogAPI + option, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.accessToken,
      },
      body: JSON.stringify({ SKU, VendorID: currentVendorID }),
    }).then((res) => {
      if (res.status === 200) {
        handleRefresh();
      }
    });
  };

  useEffect(() => {
    fetch(latestAPI + option, {
      headers: { Authorization: "Bearer " + auth.accessToken },
    })
      .then((res) => res.json())
      .then((data) => {
        // flag for similar items
        let dataFlagged = data[0];
        for (let i = 0; i < dataFlagged.POdetail.length; i++) {
          if (data[1][i].similaritems.length !== 0) {
            dataFlagged.POdetail[i]["similar_flag"] = true;
            let similar_purchased_flag = false;
            for (let item of data[1][i].similaritems) {
              if (item.qty_ordered > 0) {
                similar_purchased_flag = true;
                break;
              }
            }
            dataFlagged.POdetail[i]["similar_purchased_flag"] =
              similar_purchased_flag;
          }
        }

        setPO(dataFlagged);

        // setPO(data[0]);
        // console.log(data[0])
        setModalData(data[1]);
        vendorID.current = data[0].VendorID;
      });
    fetch(latestStageAPI + option, {
      headers: { Authorization: "Bearer " + auth.accessToken },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.stage !== latestVersion) {
          setLatestVersion(data.stage);
        }
        const currIsLatest = stageStrToInt(stage) === data.stage;
        const isCorrectUser =
          (auth.position === "procurement" &&
            (stage === "Procurement" || stage === "Proforma")) ||
          (auth.position === "management" && stage === "Management");

        if (stage === "Proforma") {
          setIsEditable(false);
        } else {
          setIsEditable(isCorrectUser && currIsLatest);
        }
      });
  }, [stage, option, latestVersion, refresher]);

  const handleSave = () => {
    // Save in an variable
    // Check response, if 200, set to a new one
    // Else error updating
    fetch(saveAPI + option, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + auth.accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(PO),
    }).then((res) => {
      // console.log(saveAPI + option);
      if (res.status === 200) {
        handleRefresh();
      }
    });
  };

  const handleSubmit = () => {
    fetch(sendAPI + option, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + auth.accessToken,
        "Content-Type": "application/json",
      },
    }).then(() => handleRefresh());
  };

  const handleApprove = () => {
    fetch(approveAPI + option, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + auth.accessToken,
        "Content-Type": "application/json",
      },
    }).then(() => handleRefresh());
  };

  return {
    columns: isEditable ? editableColumns : immutableColumns,
    PO,
    modalData,
    isEditable,
    handleSave,
    handleSubmit,
    handleApprove,
  };
};

export default useOrderTable;
