import React, {
  useEffect,
  createContext,
  useMemo,
  useState,
  Fragment,
} from "react";
import { useParams } from "react-router-dom";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { useSticky } from "react-table-sticky";

import { SVG, TertiaryButton } from "../../../Styles/styles";
import up from "../../../images/Ascending.svg";
import down from "../../../images/Descending.svg";
import defaultSort from "../../../images/DefaultSort.svg";

//Modal
import useSKUDetailModal from "../../../Hooks/useSKUDetailModal";
import OrderStatusTypeSKUModal from "./OrderStatusTypeSKUModal";

//API
import { getSKUModalDetail } from "../../../Services/ordertable.service";
import useForecast from "../../../Hooks/useForecast";
import { getSkuForecast } from "../../../Services/forecast.service";

//PO details table (orders > procurement/etc/etc > *order table/backlog/history*)
export const OrderStatusTypeTable = ({
  POInfo,
  tableColumns,
  setPOInfo,
  setMasterPOInfo,
  updateUserEdit,
  cancelEdit,
  updateBacklog,
  deleteBacklog,
  containersInfo,
  isAbleToStage,
  isLoading,
  setIsLoading,
  setAlertInfo,
  viewActive,
}) => {
  //useMemo to prevent rerender
  const { orderStatus, ro_num, orderStatusType } = useParams();
  const data = useMemo(
    () =>
      POInfo.POdetail?.map((e) => {
        // Need to add ro_num to open the detail modal by clicking view button
        return { ...e, ro_num: POInfo.PONUMBER };
      }),
    [POInfo]
  );
  const columns = useMemo(() => tableColumns, []);

  //Modal

  //sku detail
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [modalSKUDetail, setModalSKUDetail] = useState({});
  const { open, handleOpen, handleClose } = useSKUDetailModal({
    setModalSKUDetail, //to reset
  });

  const { forecastedData, setForecastedData } = useForecast();

  const handleDoubleClick = async (e, row) => {
    if (row.values.country === "JP") {
      //not handling this atm
      return;
    }

    handleOpen();

    //call api
    await getSKUModalDetail({
      ro_num,
      sku: row.values.SKU,
      country: row.values.country,
      setModalSKUDetail,
      setIsLoading,
      setAlertInfo,
    });

    await getSkuForecast({
      selectedSKU: row.values.SKU,
      setForecastedData,
    });
  };

  //The instance here == tableInstance

  //Destructuring of table instances to get something to be used when returning the table structure below.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page, //this is basically row with pagination
    nextPage,
    previousPage,
    canPreviousPage, //condition to check whether can go to prev page or not
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    SelectColumnFilter,
    // setGlobalFilter,
    // setFilter,
  } = useTable(
    {
      //Values that can be access by the cell later
      columns: columns,
      data: data,
      initialState: { pageSize: 25 },
      //Custom
      containersInfo: containersInfo,
      POdetail: POInfo.POdetail,
      setPOInfo: setPOInfo,
      setMasterPOInfo: setMasterPOInfo,
      updateUserEdit: updateUserEdit,
      cancelEdit: cancelEdit,
      updateBacklog: updateBacklog,
      deleteBacklog: deleteBacklog,
      setEditableRowIndex: setEditableRowIndex,
      editableRowIndex: editableRowIndex,
      orderStatus: orderStatus,
      orderStatusType: orderStatusType,
      isAbleToStage: isAbleToStage,
      isLoading: isLoading,
      setIsLoading: setIsLoading,
      viewActive: viewActive,
    },
    //Hooks that called to apply these thing
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useSticky
  );

  const viewOnlyTab =
    orderStatus === "pogeneration" || orderStatus === "postoreceive";

  const { pageIndex, pageSize } = state;
  const excludeArrowSortingHeaders = ["Backlog", "Edit", "Delete"];

  return (
    <div>
      {/* Modal open when double click */}
      <OrderStatusTypeSKUModal
        open={open}
        handleClose={handleClose}
        modalSKUDetail={modalSKUDetail}
        isLoading={isLoading.gettingSKUModalDetail}
        forecastedData={forecastedData}
      />

      {/* Table HTML */}
      <div style={{ height: "60vh", overflow: "scroll" }}>
        <table {...getTableProps()} className="orderTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <Fragment key={column.Header}>
                    {(column.Header === "Edit" ||
                      column.Header === "Backlog") &&
                    viewOnlyTab ? null : (
                      <th
                        {...column.getHeaderProps(
                          !excludeArrowSortingHeaders.includes(column.Header)
                            ? column.getSortByToggleProps() // to enable sorting feature
                            : undefined
                        )}
                        {...column.getHeaderProps(
                          !excludeArrowSortingHeaders.includes(column.Header)
                            ? {
                                //curly instead of open bracket, cause u return an object
                                style: {
                                  ...column.style,
                                  cursor: "pointer",
                                },
                              }
                            : {
                                style: {
                                  ...column.style,
                                },
                              }
                        )}
                      >
                        {column.render("Header")}

                        {/* Add a sort direction indicator */}
                        {!excludeArrowSortingHeaders.includes(column.Header) &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <SVG
                                style={{
                                  marginLeft: "10px",
                                }}
                                src={up}
                              />
                            ) : (
                              <SVG
                                style={{
                                  marginLeft: "10px",
                                }}
                                src={down}
                              />
                            )
                          ) : (
                            <SVG
                              style={{
                                marginLeft: "10px",
                              }}
                              src={defaultSort}
                            />
                          ))}
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    )}
                  </Fragment>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onDoubleClick={
                    (e) => handleDoubleClick(e, row) // open Modal
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Fragment key={`${cell.column.Header} - cell`}>
                        {(cell.column.Header === "Edit" ||
                          cell.column.Header === "Backlog") &&
                        viewOnlyTab ? null : cell.column.Header === //if product code then add in the left:200
                          "Product Code" ? (
                          <td
                            {...cell.getCellProps({
                              style: {
                                ...cell.getCellProps().style,
                                textAlign: "center",
                                backgroundColor: "inherit",
                                left: 222,
                                sticky: "left",
                              },
                            })} //the args inside is like can className
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : cell.column.Header === "SKU Desc" ? (
                          <td
                            {...cell.getCellProps({
                              style: {
                                ...cell.getCellProps().style,
                                textAlign: "center",
                                backgroundColor: "inherit",
                                left: 344,
                                sticky: "left",
                              },
                            })} //the args inside is like can className
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : (
                          <td
                            {...cell.getCellProps({
                              style: {
                                ...cell.getCellProps().style,
                                textAlign: "center",
                                backgroundColor: "inherit",
                              },
                            })} //the args inside is like can className
                          >
                            {cell.render("Cell")}
                          </td>
                        )}
                      </Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          marginTop: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </TertiaryButton>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </TertiaryButton>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => nextPage()}
            disabled={false}
          >
            Next
          </TertiaryButton>
          <TertiaryButton
            className="hoverSecWhiteButton"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </TertiaryButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ marginTop: "10px" }}>
            Page
            <strong style={{ marginLeft: "5px", marginRight: "5px" }}>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <div style={{ width: "8px" }} />
          <span style={{ marginTop: "10px" }}>
            Go To Page:
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px", marginLeft: "5px" }}
            />
          </span>
        </div>
        <span style={{ marginTop: "10px" }}>
          Show
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            {[25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          Items
        </span>
      </div>
    </div>
  );
};

export default OrderStatusTypeTable;

/*


SKU/PO/VenContext = createContext()
<TableContext.Provider>
<FilterObject> => useContext(TableContext)
<OrderStatusTypeTable tableSettings={{sortBy}} tableData={{data,col}} tableContext={TableContext}>
<TableContext.Provider>

*/
