import React, { useState } from 'react';
import { Card, Divider } from "@mui/material";
import { DashboardButton } from "../../Styles/styles";
import Popularity from '../../Components/Charts/PopularityChart';

const PopularityDashboard=({     
    year, 
    popYearTopData, popTopLabels,
    popYearBotData, popBotLabels, 
    popPrevYearTopData, popPrevTopLabels, 
    popPrevYearBotData, popPrevBotLabels,
    skuYearData, skuLabels, skuPrevYearData, skuPrevLabels, SKUID
    })=> {
    const [currYearData, setCurrYearData] = useState(popYearTopData);
    const [currLabelData, setCurrLabelData] = useState(popTopLabels);
    const [prevYearData, setPrevYearData] = useState(popPrevYearTopData);
    const [prevLabelData, setPrevLabelData] = useState(popPrevTopLabels);

    const top5CurrYear = () => {
        setCurrYearData(popYearTopData);
        setCurrLabelData(popTopLabels);
        };
    const bot5CurrYear = () => {
        setCurrYearData(popYearBotData);
        setCurrLabelData(popBotLabels);
        };

    const top5PrevYear = () => {
        setPrevYearData(popPrevYearTopData);
        setPrevLabelData(popPrevTopLabels);
        };
    const bot5PrevYear = () => {
        setPrevYearData(popPrevYearBotData);
        setPrevLabelData(popPrevBotLabels);
        };
    return(
        <>
            <div style={{marginTop: '3px', textAlign: 'justify', fontSize: '14px'}}>
                *The chart displays the top and bottom 5 products across all stores, physical, online and events. Click on the 'Top 5' and 'Bottom 5' buttons to toggle information. Click on the charts to view distribution of products across stores.
           </div>
            <div className="d-flex flex-inline justify-content-between"> 
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}>
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5CurrYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5CurrYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Popularity year={year} dataYear={currYearData} labelsYear={currLabelData} type={"All"} 
                        skuData={skuYearData} skuLabel={skuLabels} SKUID={SKUID}/>}
                </Card> 
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}>
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Popularity year={year-1} dataYear={prevYearData} labelsYear={prevLabelData} type={"All"}
                        skuData={skuPrevYearData} skuLabel={skuPrevLabels} SKUID={SKUID}/>}
                </Card> 
            </div>
            <div className="d-flex flex-inline justify-content-between">
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Popularity year={year-1} dataYear={prevYearData} labelsYear={prevLabelData} type={"Physical"}
                        skuData={skuPrevYearData} skuLabel={skuPrevLabels} SKUID={SKUID}/>}
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Popularity year={year-1} dataYear={prevYearData} labelsYear={prevLabelData} type={"Online"}
                        skuData={skuYearData} skuLabel={skuLabels} SKUID={SKUID}/>}
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Popularity year={year-1} dataYear={prevYearData} labelsYear={prevLabelData} type={"Events"}
                        skuData={skuYearData} skuLabel={skuLabels} SKUID={SKUID}/>}
                </Card>
            </div>
        </>
    );
};

export default PopularityDashboard;
