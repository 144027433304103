import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { dashboardOrderNavigationMapper } from "./actiondashaboard.mapper";
import { currencyFormatter, dateFormatter } from "../../Services/util";
import { Check } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
const useActionDashboardColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "view",
        Cell: NavigateOrderButton,
      },
      {
        Header: "Order Code",
        accessor: "ro_num",
        Cell: (row) => (
          <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
        ),
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: (row) => (
          <div style={{ textAlign: "left", minWidth: 350 }}>{row.value}</div>
        ),
      },
      {
        Header: "Version",
        accessor: "version",
        Cell: (row) => (
          <div style={{ textAlign: "left", minWidth: 100 }}>{row.value}</div>
        ),
      },
      {
        Header: "Currency",
        accessor: "currency",
        Cell: (row) => (
          <div style={{ textAlign: "center", minWidth: 150 }}>
            {row.value ?? ""}
          </div>
        ),
      },
      {
        Header: "Order Value",
        accessor: "value",
        Cell: (row) => (
          <div style={{ textAlign: "right", minWidth: 150 }}>
            {currencyFormatter(row.value, "$")}
          </div>
        ),
      },
      {
        Header: "Stage",
        accessor: "stage",
        Cell: (row) => (
          <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",

        Cell: (row) => (
          <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
        ),

        filter: (rows, columnIds, filterValues) => {
          if (!filterValues || filterValues.length === 0) {
            return rows;
          }
          return rows.filter((row) =>
            filterValues.includes(row.values[columnIds[0]])
          );
        },
      },

      {
        Header: "Updated At",
        accessor: "updated_on",
        Cell: (row) => (
          <div style={{ textAlign: "center", minWidth: 150 }}>
            {dateFormatter(row.value)}
          </div>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: (row) => (
          <div style={{ textAlign: "center", minWidth: 150 }}>
            {dateFormatter(row.value)}
          </div>
        ),
      },
    ],
    []
  );
  return { columns };
};

export default useActionDashboardColumns;

const NavigateOrderButton = (props) => {
  const navigate = useNavigate();
  const tags = props?.row?.values?.tags;

  return (
    <div style={{ textAlign: "center", minWidth: 60 }}>
      <IconButton
        onClick={() =>
          navigate(
            `orders/${dashboardOrderNavigationMapper(tags).toLowerCase()}`
          )
        }
      >
        <RemoveRedEyeIcon sx={{ color: "#bead7c", fontSize: "32px" }} />
      </IconButton>
    </div>
  );
};
