import { IconButton } from "@mui/material";
import React, { useState } from "react";

import POModal from "./POModal";
import InfoIcon from "@mui/icons-material/Info";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export const Modals = ({ poInfo }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton onClick={handleOpen}>
        <RemoveRedEyeIcon sx={{ color: "#bead7c", fontSize: "32px" }} />
      </IconButton>
      {poInfo && (
        <POModal open={open} handleClose={handleClose} poInfo={poInfo} />
      )}
    </>
  );
};
