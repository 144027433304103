import { createContext } from "react";
import useContainer from "../Hooks/useContainer";

export const ContainerContext = createContext();

export const ContainerProvider = ({ children }) => {
  const {
    containers,
    columns,
    containerContent,
    handleClick,
    containersArrayIndex,
    setContainersIndexClicked,
    containersIndexClicked,
    handleSave,
    modalFunctions,
    page,
    setRefresher,
  } = useContainer();

  return (
    <ContainerContext.Provider
      value={{
        containers,
        containerContent,
        handleClick,
        containersArrayIndex,
        setContainersIndexClicked,
        containersIndexClicked,
        columns,
        handleSave,
        modalFunctions,
        page,
        setRefresher,
      }}
    >
      {children}
    </ContainerContext.Provider>
  );
};
