import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { Form, Formik } from "formik";
import EmployeeDetailsSection from "../AddIncentivePlan/EmployeeDetailsSection";
import {
  getIncentiveDetailById,
  updateIncentivePlan,
} from "../../../../Services/incentiveplantable.service";
import { AlertContext } from "../../../../App";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#fff",
  boxShadow: "0px 4px 32px rgba(61, 70, 112, 0.08)",
  display: "flex",
  borderRadius: "12px",
};

const Title = MUIStyled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const SubTitle = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #8B8D97;
`;

const titleBoxStyle = {
  width: "100%",
  height: "90px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "29px 24px",
};

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

const footerBoxStyle = {
  width: "100%",
  height: "110px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 24px",
};

const cancelButtonStyle = {
  width: "180px",
  borderColor: "#53545C",
  color: "#53545C",
  fontSize: "14px",
  fontWeight: 400,
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#bead7c",
  },
};

const submitButtonStyle = {
  width: "180px",
  color: "#000",
  fontSize: "14px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const EditIncentivePlanModal = ({ id, handleClose, skuRows = [] }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const [incentiveDetail, setIncentiveDetail] = useState();

  const handleFormSubmit = async (values) => {
    // console.log("handleFormSubmit", values);

    await updateIncentivePlan(id, values, setAlertInfo, handleClose);
  };

  useEffect(() => {
    getIncentiveDetailById(id, setIncentiveDetail);
  }, []);
  if (incentiveDetail) {
    return (
      <Formik
        initialValues={{
          description: incentiveDetail?.description ?? "",
          employment: incentiveDetail?.employment ?? "",
          gross_price: incentiveDetail?.gross_price ?? "",
          incentive_type: incentiveDetail?.incentive_type ?? "",
          incentive: incentiveDetail?.incentive ?? "",
          start_date: incentiveDetail?.start_date ?? "",
          end_date: incentiveDetail?.end_date ?? "",
          employee: incentiveDetail?.employee ?? "",
          employee_type: incentiveDetail?.employee_type ?? "",
          product_sku: incentiveDetail?.product_sku ?? [],
        }}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={modalContainerStyle}>
              <Box sx={{ width: "100%" }}>
                <Box sx={titleBoxStyle}>
                  <Title>Edit Incentive</Title>
                  <OutlinedBox onClick={handleClose}>
                    <ClearIcon sx={closeIconStyle} />
                  </OutlinedBox>
                </Box>

                <Box sx={{ width: "100%", height: "75%" }}>
                  <Box sx={{ padding: "13px 30px" }}>
                    <SubTitle>Employee Details</SubTitle>
                    <Box height="24px" />
                    <EmployeeDetailsSection skuRows={skuRows} isEdit={true} />
                  </Box>
                </Box>

                <Box sx={footerBoxStyle}>
                  <Button
                    variant="outlined"
                    sx={cancelButtonStyle}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Box width="48px" />
                  <Button
                    variant="contained"
                    sx={submitButtonStyle}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  }
};

export default EditIncentivePlanModal;
