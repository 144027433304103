import React, { useState } from "react";
import { Card } from "@mui/material";
import Performance from "../../Components/Charts/PerformanceChart";

const PerformanceDashboard = ({
  year,
  perPrevYearData,
  perYearData,
  percentArray,
  currentYear,
  previousYear,
  label,
  sma_24,
}) => {
  return (
    <>
      <div style={{ padding: "3px", textAlign: "justify", fontSize: "14px" }}>
        *The chart depicts the Sales Value (SGD) for the previous year and the
        current year, accompanied by a Moving Average indicator of the past 24
        months. For additional information, please hover over the chart.
      </div>
      <div className="d-flex justify-content-center p-4">
        {perPrevYearData && percentArray && (
          <Performance
            year={year}
            perPrevYearData={perPrevYearData}
            perYearData={perYearData}
            percentArray={percentArray}
            currentYear={currentYear}
            previousYear={previousYear}
            label={label}
            sma_24={sma_24}
          />
        )}
      </div>
    </>
  );
};

export default PerformanceDashboard;
