import React, { useState } from "react";

import { styled as MUIStyled } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const OutlinedBorder = MUIStyled(Box)`
  width: 20px;
  height: 20px;
  border: 1px solid #bead7c;
  border-radius: 6px;
  dislpay: flex;
  position: relative;
`;

const Square = MUIStyled(Box)`
  width: 16px;
  height: 16px;
  background: #bead7c;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
`;

const ListText = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #6E7079;
`;
const SelectedSKUExpandTile = ({ sku }) => {
  const [expand, setExpand] = useState(false);

  const handleExpandClick = () => {
    setExpand(!expand);
  };

  return (
    <>
      <ListItemButton
        onClick={handleExpandClick}
        alignItems="center"
        sx={{ padding: "8px 0px" }}
      >
        <Stack direction="row" alignItems="center" sx={{ mr: "12px" }}>
          <OutlinedBorder>
            <Square />
          </OutlinedBorder>
          <Box sx={{ ml: "14px" }} />
          <ListText>{sku?.skuID}</ListText>
        </Stack>

        {expand ? (
          <ExpandLess sx={{ color: "#5E6366" }} />
        ) : (
          <ExpandMore sx={{ color: "#5E6366" }} />
        )}
      </ListItemButton>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component="div" sx={{ marginLeft: "34px", padding: "0px" }}>
          <ListItemText
            secondary={sku?.description}
            sx={{ padding: "4px 0px" }}
          />
          <ListItemText secondary={sku?.category} sx={{ padding: "4px 0px" }} />
          <ListItemText secondary={sku?.vendorID} sx={{ padding: "4px 0px" }} />
        </List>
      </Collapse>
    </>
  );
};

export default SelectedSKUExpandTile;
