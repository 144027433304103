import api from "./api";

export const generateInvoice = async ({ invoiceInfo, poNum }) => {
  const invoiceURL = `invoice/generate_invoice/${poNum}`;

  try {
    const response = await api.post(invoiceURL, invoiceInfo);
    const responseData = await response?.data?.invoice_link;
    const downloadLink = process.env.REACT_APP_API_URL + responseData;
    window.open(downloadLink);
  } catch (error) {
    alert(error);
  }
};

export const generatePO = async ({ containerID, ponum, comments }) => {
  const generatePOURL = `/generatepo/${containerID}`;
  try {
    api.post(generatePOURL, { ponum, comments });
  } catch (error) {
    alert(error);
  }
};
