import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Productivity = ({year, dataYear, labelsYear}) => {
  const options = {
    responsive: true,
    indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Productivity Of Stores",
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Stores",
        },
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Productivity Of Stores For " + year ,
      },
      tooltip: {
        callbacks: {
            label: function(context) {
                //console.log(context)
                    return `Productivity: ${context.formattedValue}`;
            },
          },
        },
    },
  };
  
  
  const data = {
    labels: labelsYear,
    datasets: [
      {
        label: "Revenue / Employee",
        data: dataYear, /* dataYear.x.map(x => x.data) */
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",,
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <Bar data={data} options={options}/>
    </>
  );
};

export default Productivity;
