import React, { useContext } from "react";
import styled from "styled-components";
import CreateContainerModal from "../../../Components/Modals/Container.create.modal";
import { ContainerContext } from "../../../Contexts/container.context";
import useModal from "../../../Hooks/useModal";
export const CircleButton = styled.button`
  background: #ffffff;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  align-items: "center";
  justify-content: "center";
  width: 60px;
  height: 60px;
  border: 0px;
  border-radius: 60px;
`;

const AddContainer = () => {
  const modalFunctions = useModal();
  const handleAddContainer = () => {
    modalFunctions?.handleOpen();
  };

  return (
    <>
      <CreateContainerModal modalFunctions={modalFunctions} />
      <div
        style={{
          height: "25vh",
          width: "25vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircleButton onClick={handleAddContainer}>+</CircleButton>
      </div>
    </>
  );
};

export default AddContainer;
