import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

import DatePickerField from "../../../../Components/DatePicker/DatePickerField";
import useModal from "../../../../Hooks/useModal";
import AddSKUModalWrapper from "../../Marketing Campaign/AddEvent/AddSKUModalWrapper";
import { Field, useFormikContext } from "formik";

const Label = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
`;

const Placeholder = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #ABAFB1;
`;

const textFieldStyle = {
  padding: "0px",
  margin: "0px",
  marginBottom: "24px",
  height: "52px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const selectStyle = {
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const formControlStyle = {
  padding: "0px",
  margin: "0px",
  marginBottom: "24px",
  height: "52px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
};

const incentiveTypes = ["Value"];
const employeeTypes = ["ALL", "SALES"];

const EmployeeDetailsSection = ({ skuRows = [], isEdit = false }) => {
  const { values, setFieldValue, handleChange, touched, errors } =
    useFormikContext();

  const {
    open: openSKUs,
    handleOpen: handleOpenSKUs,
    handleClose: handleCloseSKUs,
  } = useModal();

  return (
    <>
      <AddSKUModalWrapper
        open={openSKUs}
        handleClose={handleCloseSKUs}
        name="product_sku"
        skuRows={skuRows}
      />
      <FormControl fullWidth sx={formControlStyle}>
        <Field
          id="employee_type"
          name="employee_type"
          component={({ children }) => (
            <Select
              value={values.employee_type}
              displayEmpty
              onChange={(e) => setFieldValue("employee_type", e.target.value)}
              inputProps={{ "aria-label": "Without label" }}
              sx={selectStyle}
            >
              {children}
            </Select>
          )}
        >
          <MenuItem disabled value="">
            <Placeholder>Employee Type</Placeholder>
          </MenuItem>
          {employeeTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      <Grid container columnSpacing={2} sx={{}}>
        <Grid item xs={6}>
          <FormControl fullWidth sx={formControlStyle}>
            <Field
              id="incentive_type"
              name="incentive_type"
              component={({ children }) => (
                <Select
                  value={values.incentive_type}
                  displayEmpty
                  onChange={(e) =>
                    setFieldValue("incentive_type", e.target.value)
                  }
                  inputProps={{ "aria-label": "Without label" }}
                  sx={selectStyle}
                >
                  {children}
                </Select>
              )}
            >
              <MenuItem disabled value="">
                <Placeholder>Incentive Type</Placeholder>
              </MenuItem>
              {incentiveTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Field id="product_sku" name="product_sku" validate={(value) => {}}>
            {({ field, form, meta }) => (
              <TextField
                margin="normal"
                fullWidth
                id="product_sku"
                name="product_sku"
                value={values?.product_sku}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Product SKU(s)"
                InputProps={{
                  readOnly: true,
                }}
                sx={textFieldStyle}
                onClick={() => {
                  if (!isEdit) {
                    handleOpenSKUs();
                  }
                }}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} sx={{}}>
        <Grid item xs={6}>
          <Label>Gross Price</Label>
          <Field id="gross_price" name="gross_price" validate={(value) => {}}>
            {({ field, form, meta }) => (
              <TextField
                margin="normal"
                fullWidth
                id="gross_price"
                name="gross_price"
                value={values?.gross_price}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Gross Price"
                sx={textFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Label>Incentive</Label>
          <Field id="incentive" name="incentive" validate={(value) => {}}>
            {({ field, form, meta }) => (
              <TextField
                margin="normal"
                fullWidth
                id="incentive"
                name="incentive"
                value={values?.incentive}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Incentive"
                sx={textFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Label>Start Date</Label>
          <DatePickerField
            name="start_date"
            value={values.start_date}
            onChange={setFieldValue}
            placeholder="Start Date"
          />
        </Grid>
        <Grid item xs={6}>
          <Label>End Date</Label>
          <DatePickerField
            name="end_date"
            value={values.end_date}
            onChange={setFieldValue}
            placeholder="End Date"
          />
        </Grid>
      </Grid>
      <Field id="description" name="description" validate={(value) => {}}>
        {({ field, form, meta }) => (
          <TextField
            margin="normal"
            fullWidth
            multiline={true}
            rows={3}
            id="description"
            name="description"
            value={values?.description}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Description"
            sx={{
              ...textFieldStyle,
              margin: "36px 0px",
              height: "100%",
            }}
          />
        )}
      </Field>
    </>
  );
};

export default EmployeeDetailsSection;
