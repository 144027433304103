import React, { useState } from "react";

import RowExpandTable from "../../Components/Tables/RowExpandTable";
import useRejectedTable from "../../Hooks/useRejectedTable";

import ChangelogModal from "../../Components/Modals/RejectedTable.modal";
import useModal from "../../Hooks/useModal";
import { useEffect } from "react";

const RejectedTable = ({ stage, option, refresher, intra }) => {
  const {
    columns,
    POdetails,
    historyValue,
    historyOptions,
    handleHistoryChange,
  } = useRejectedTable({
    stage,
    option,
    refresher,
    intra,
  });
  // _______________________________________________________
  // Code for handling expand table
  const { open, handleOpen, handleClose } = useModal();
  const [rowInfo, setRowInfo] = useState();
  const expandTable = (row) => {
    setRowInfo([row.original]);
  };

  // Callback for expandTable()
  useEffect(() => {
    handleOpen();
  }, [rowInfo]);

  // _______________________________________________________

  return (
    <>
      {rowInfo && (
        <ChangelogModal
          open={open}
          handleClose={handleClose}
          rowInfo={rowInfo}
        />
      )}
      <div>
        {POdetails ? (
          <div>
            <p>
              The table below shows the snapshot of the SKUs before your team
              sent it to another stage.
            </p>
            <p>
              The changes highlighted in green have been modified. Double click
              on the row to view the changelog in detail.
            </p>
            <select
              style={{
                color: "inherit",
                border: "none",
                font: "inherit",
              }}
              value={historyValue}
              onChange={handleHistoryChange}
            >
              {historyOptions &&
                historyOptions.map((log) => (
                  <option
                    style={{
                      font: "inherit",
                      color: "black",
                    }}
                    key={log}
                  >
                    {log}
                  </option>
                ))}
            </select>
            <RowExpandTable
              columns={columns}
              data={POdetails}
              initialState={{ hiddenColumns: ["flag"] }}
              rowFunc={expandTable}
            />
          </div>
        ) : (
          <div>There are no rejected POs/ROs, hurray!</div>
        )}
      </div>
    </>
  );
};

export default RejectedTable;
