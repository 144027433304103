import React, { useState, useEffect, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { useFormControl } from "@mui/material/FormControl";

import LoadingButton from "@mui/lab/LoadingButton";
import PublishIcon from "@mui/icons-material/Publish";

//MUI custome theme
import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";

//API
import {
    getPODetails,
    compareHistory,
} from "../../../Services/ordertable.service";
import { AlertContext } from "../../../App";

export const OrderStatusTypeHistorySelectPO = ({
    POToCompareUserChoice,
    setPOToCompareUserChoice,
    setComparePOInfo,
    POToCompareList,
    isLoading,
    setIsLoading,
}) => {
    const { alertInfo, setAlertInfo } = useContext(AlertContext);
    const { orderStatus, ro_num, orderStatusType } = useParams();

    const [indexChosen, setIndexChosen] = useState(0);

    const handleFirstPOChange = (e) => {
        // console.log("here", e.target);
        setPOToCompareUserChoice((prev) => ({
            ...prev,
            firstPO: e.target.value,
        }));

        // console.log("List: ", POToCompareList);
        // console.log(e.target.value);
    };

    const handleMenuItemClick = (e) => {
        // console.log("Menu click e", e.target.dataset.index);
        setIndexChosen(e.target.dataset.index);
    };

    const handleSecondPOChange = (e) => {
        setPOToCompareUserChoice((prev) => ({
            ...prev,
            secondPO: e.target.value,
        }));
    };

    const handleSubmit = async () => {
        // console.log("LATEST PO: ", POToCompareUserChoice.firstPO);
        // console.log("Old PO: ", POToCompareUserChoice.secondPO);

        await compareHistory(
            POToCompareUserChoice.firstPO,
            POToCompareUserChoice.secondPO,
            ro_num,
            setComparePOInfo,
            setIsLoading,
            setAlertInfo
        );
    };
    //to refresh second when the first is chosen
    useEffect(() => {
        setPOToCompareUserChoice((prev) => ({
            ...prev,
            secondPO: "",
        }));
    }, [POToCompareUserChoice.firstPO]);

    const POToCompareListLength = POToCompareList.length;
    const isOldPoDisabled = POToCompareUserChoice.firstPO === "";

    // console.log("POToCompareUserChoice: ", POToCompareUserChoice);

    const selectorStyle = {
        minWidth: 200,
        height: "100%",
        display: "inline-flex",
        ml: 3,
    };

    return (
        <>
            <Box sx={selectorStyle}>
                <FormControl fullWidth>
                    <InputLabel
                        htmlFor="latest-po-input"
                        id="latest-po-select-label"
                    >
                        Latest PO
                    </InputLabel>
                    <Select
                        defaultValue=""
                        labelId="latest-po-select-label"
                        id="latest-po-input"
                        value={POToCompareUserChoice.firstPO}
                        data-test="testing"
                        label="Latest PO"
                        onChange={handleFirstPOChange}
                    >
                        {POToCompareList.map((row, index) => {
                            return (
                                <MenuItem
                                    key={row.value}
                                    value={row.value}
                                    data-index={index}
                                    disabled={
                                        index === POToCompareListLength - 1
                                    }
                                    onClick={handleMenuItemClick}
                                >
                                    {`${row.label} - ${
                                        row.edited_by
                                    } - ${new Date(
                                        row.last_edited_date
                                    ).toDateString()}`}
                                </MenuItem>
                            );
                        })}
                        {/* <MenuItem value={[1, 1, 1]}>S1V1R1</MenuItem>
                        <MenuItem value={[1, 1, 2]}>S1V1R2</MenuItem>
                        <MenuItem value={[1, 1, 3]}>S1V1R3</MenuItem> */}
                        {/* <MenuItem value={"1, 1, 1"}>S1V1R1</MenuItem>
                        <MenuItem value={"1, 1, 2"}>S1V1R2</MenuItem>
                        <MenuItem value={"1, 1, 3"}>S1V1R3</MenuItem> */}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={selectorStyle}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="old-po-input" id="old-po-select-label">
                        Old PO
                    </InputLabel>
                    <Select
                        defaultValue=""
                        labelId="old-po-select-label"
                        id="old-po-input"
                        value={POToCompareUserChoice.secondPO}
                        label="Old PO"
                        onChange={handleSecondPOChange}
                        disabled={isOldPoDisabled}
                    >
                        {POToCompareList.map((row, index) => {
                            if (index <= indexChosen) {
                                return;
                            } else {
                                return (
                                    <MenuItem key={row.value} value={row.value}>
                                        {`${row.label} - ${
                                            row.edited_by
                                        } - ${new Date(
                                            row.last_edited_date
                                        ).toDateString()}`}
                                    </MenuItem>
                                );
                            }
                        })}

                        {/* <MenuItem value={[1, 1, 1]}>S1V1R1</MenuItem>
                        <MenuItem value={[1, 1, 2]}>S1V1R2</MenuItem>
                        <MenuItem value={[1, 1, 3]}>S1V1R3</MenuItem> */}
                        {/* <MenuItem value={"1, 1, 1"}>S1V1R1</MenuItem>
                        <MenuItem value={"1, 1, 2"}>S1V1R2</MenuItem>
                        <MenuItem value={"1, 1, 3"}>S1V1R3</MenuItem> */}
                    </Select>
                </FormControl>
            </Box>
            <ThemeProvider theme={customMUITheme}>
                <LoadingButton
                    color="generate"
                    variant="contained"
                    onClick={() => {
                        handleSubmit();
                    }}
                    loading={isLoading.comparingHistory}
                    loadingPosition="start"
                    startIcon={<PublishIcon />}
                    sx={{ height: 55, ml: 3 }}
                >
                    Submit
                </LoadingButton>
            </ThemeProvider>
        </>
    );
};

export default OrderStatusTypeHistorySelectPO;
