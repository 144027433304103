import React, { useState } from 'react';
import { Card } from "@mui/material";
import { DashboardButton } from "../../Styles/styles";
import Busy from '../../Components/Charts/BusiestChart';

const BusynessDashboard=({ 
    year, 
    busYearTopData, busTopLabels,
    busYearBotData, busBotLabels, 
    busPrevYearTopData, busPrevTopLabels, 
    busPrevYearBotData, busPrevBotLabels })=> {
    const [currYearData, setCurrYearData] = useState(busYearTopData);
    const [currLabelData, setCurrLabelData] = useState(busTopLabels);
    const [prevYearData, setPrevYearData] = useState(busPrevYearTopData);
    const [prevLabelData, setPrevLabelData] = useState(busPrevTopLabels);

    const top5CurrYear = () => {
        setCurrYearData(busYearTopData);
        setCurrLabelData(busTopLabels);
        };
    const bot5CurrYear = () => {
        setCurrYearData(busYearBotData);
        setCurrLabelData(busBotLabels);
        };

    const top5PrevYear = () => {
        setPrevYearData(busPrevYearTopData);
        setPrevLabelData(busPrevTopLabels);
        };
    const bot5PrevYear = () => {
        setPrevYearData(busPrevYearBotData);
        setPrevLabelData(busPrevBotLabels);
        };

    return(
        <>
            <div style={{padding: '3px', textAlign: 'justify', fontSize: '14px'}}>
                *The chart displays the top and bottom 5 products across all stores, physical, online and events by number of orders. Click on the 'Top 5' and 'Bottom 5' buttons to toggle information.
           </div>
            <div className="d-flex flex-inline justify-content-between">
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}>
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5CurrYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5CurrYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Busy year={year} dataYear={currYearData} labelsYear={currLabelData} type={"All"}/>}
                </Card> 
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}>
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Busy year={year-1} dataYear={prevYearData} labelsYear={prevLabelData} type={"All"}/>}
                </Card> 
                </div> 
            <div className="d-flex flex-inline justify-content-between">
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Busy year={year} dataYear={prevYearData} labelsYear={prevLabelData} type={"Physical"}/>}
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Busy year={year} dataYear={prevYearData} labelsYear={prevLabelData} type={"Online"}/>}
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Busy year={year} dataYear={prevYearData} labelsYear={prevLabelData} type={"Events"}/>}
                </Card>
            </div>
        </>
    );
};

export default BusynessDashboard;
