import api from "./api";

/*
General naming convention:

get<Noun> => returns <Noun>
store<Noun> = (set<Noun>) => uses set to store <Noun> and returns void

*/
export const storeContainers = async ({ setContainers, poNum, stage }) => {
  var getContainerURL = "";

  switch (stage) {
    case "Procurement":
      getContainerURL = `getcontainerpro/${poNum}`;
      break;
    case "Management":
      getContainerURL = `getcontainerman/${poNum}`;
      break;
    case "Proforma":
      getContainerURL = `getcontainerven/${poNum}`;
      break;
    case "POGeneration":
      getContainerURL = `getcontainergen/${poNum}`;
      break;
    case "POsToReceive":
      getContainerURL = `getcontainergen/${poNum}`;
      break;
  }
  try {
    // console.log({ getContainerURL, poNum, stage });
    const response = await api.get(getContainerURL);
    const data = response?.data;

    setContainers(data);
    // setContainers(containers);
  } catch (err) {
    // console.log(err);
  }
};

export const saveContainers = async ({ container, poNum, stage }) => {
  var saveContainerURL = "";
  switch (stage) {
    case "Procurement":
      saveContainerURL = `updateconpro/${poNum}`;
      break;
    case "Management":
      saveContainerURL = `updateconman/${poNum}`;
      break;
    case "Proforma":
      saveContainerURL = `updateconven/${poNum}`;
      break;
  }
  // const saveContainerURL = `updateconpro/${poNum}`;
  try {
    await api.put(saveContainerURL, container);
  } catch (error) {
    alert(error);
  }
};

// export const swapSKU = async ({ poNum, stage, from, to, SKU, skuObject }) => {
export const swapSKU = async ({ swappingInfo }) => {
  const { poNum, stage, from, to, SKU, skuObject } = swappingInfo;
  var swapContainerURL = "";

  switch (stage) {
    case "Procurement":
      swapContainerURL = `conswappro/${poNum}`;
      break;
    case "Management":
      swapContainerURL = `conswapman/${poNum}`;
      break;
    case "Proforma":
      swapContainerURL = `conswapven/${poNum}`;
      break;
  }

  await api.put(swapContainerURL, {
    conid1: from,
    conid2: to,
    SKU,
    qty: skuObject?.demand,
    from: "demand",
  });
  await api.put(swapContainerURL, {
    conid1: from,
    conid2: to,
    SKU,
    qty: skuObject?.promo,
    from: "promo",
  });
  await api.put(swapContainerURL, {
    conid1: from,
    conid2: to,
    SKU,
    qty: skuObject?.backlog,
    from: "backlog",
  });
  await api.put(swapContainerURL, {
    conid1: from,
    conid2: to,
    SKU,
    qty: skuObject?.japan,
    from: "japan",
  });
};

export const swapConSize = async ({ conid, to }) => {
  var swapConSize = `swapcontainersize/${conid}`;

  try {
    await api.put(swapConSize, {
      size: to,
    });
  } catch (error) {
    alert(error);
  }
};
export const createContainer = async ({ containerSize, poNum, stage }) => {
  var url = "";
  switch (stage) {
    case "Procurement":
      url = `createcontainerpro/${poNum}`;
      break;
    case "Management":
      url = `createcontainerman/${poNum}`;
      break;
    case "Proforma":
      url = `createcontainerven/${poNum}`;
      break;
  }
  if (
    containerSize === "" ||
    containerSize === null ||
    containerSize === undefined
  ) {
    alert("Container size invalid");
  } else {
    api.post(url, { size: containerSize });
  }
};

export const deleteContainer = async ({ containerID }) => {
  const url = `deletecontainer/${containerID}`;
  await api.post(url, {});
};
