import React from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import GeneratePOForm from "../Forms/GeneratePO.form";
import { modalStyle, SubHeader } from "../../Styles/styles";

const GeneratePOModal = ({
  containers,
  containersArrayIndex,
  modalFunctions,
}) => {
  const containerID = containers[containersArrayIndex]?.con_id;
  const { open, handleClose } = modalFunctions;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <SubHeader>Generate PO</SubHeader>
        <GeneratePOForm containerID={containerID} />
      </Box>
    </Modal>
  );
};

export default GeneratePOModal;
