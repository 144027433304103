import React, { useState, useEffect, useMemo, useContext } from "react";
import { styled as MUIStyled } from "@mui/material/styles";
import HomeIcon from "../../../images/HomeIcon.svg";
import { IconSVG, Title } from "../../../Styles/styles";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { INCENTIVE_TABLE_COLUMNS } from "./incentive.table.columns";

import searchIcon from "../../../images/Search.svg";
import filterIcon from "../../../images/Filter.svg";
import { DataGrid } from "@mui/x-data-grid";
import useModal from "../../../Hooks/useModal";
import AddIncentivePlanModal from "./AddIncentivePlan/AddIncentivePlanModal";
import FilterIncentiveModal from "./FilterIncentiveModal";
import { getIncentivePlanList } from "../../../Services/incentiveplantable.service";
import { getSKUList } from "../../../Services/eventstable.service";
import { AlertContext } from "../../../App";

const Text = MUIStyled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
`;

const searchTextFieldStyle = {
  width: "300px",
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "& label.Mui-focused": {
    color: "#CFD3D5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CFD3D5",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CFD3D5",
    },
    "&:hover fieldset": {
      borderColor: "#CFD3D5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#bead7c",
    },
  },
};

const filterButtonStyle = {
  borderColor: "#53545C",
  color: "#53545C",
  margin: "0px 8px",
  fontSize: "11px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#53545C",
  },
};

const addPlanButtonStyle = {
  color: "#000",
  fontSize: "11px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const datagridBoxStyle = {
  width: "100%",
  marginTop: "22px",

  "& .MuiDataGrid-root": {
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6E7079",
  },
  "& .name-column--cell": {},
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#2C2D33",
    align: "center",
    justifyContent: "center",
  },

  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
  "& .MuiCheckbox-root": {},
  "& .MuiDataGrid-row": {},
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiCheckbox-root svg": {
    width: 24,
    height: 24,
    backgroundColor: "transparent",
    border: "1px solid #CFD3D5",
    borderRadius: "8px",
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#bead7c",
    borderColor: "#bead7c",
  },
};

const itemsPerPageList = [25, 50, 100];

const IncentivePlan = () => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  // Memoize the columns so that React doesn't keep rendering
  const columns = useMemo(() => INCENTIVE_TABLE_COLUMNS, []);

  const { open, handleOpen, handleClose } = useModal();
  const {
    open: openFilter,
    handleOpen: handleOpenFilter,
    handleClose: handleCloseFilter,
  } = useModal();

  const [skuRows, setSKURows] = useState([]);
  const [skuLoaded, setSkuLoaded] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  const [incentiveCount, setIncentiveCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(itemsPerPageList[0]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [productCode, setProductCode] = useState();
  const [employeeID, setEmployeeID] = useState();

  const [searchKeyword, setSearchKeyword] = useState();

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSKUList(setSKURows, setSkuLoaded);
  }, []);

  useEffect(() => {
    getIncentivePlanList(
      setRows,
      setLoading,
      setIncentiveCount,
      page + 1,
      pageSize,
      startDate === undefined ? "" : startDate,
      endDate === undefined ? "" : endDate,
      searchKeyword === undefined ? "" : searchKeyword,
      productCode === undefined ? "" : productCode,
      employeeID === undefined ? "" : employeeID
    );
  }, [
    page,
    pageSize,
    searchKeyword,
    startDate,
    endDate,
    productCode,
    employeeID,
    alertInfo,
  ]);

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };

  return (
    <div style={{ background: "#F4F5FA" }}>
      <AddIncentivePlanModal
        open={open}
        handleClose={handleClose}
        skuRows={skuRows}
      />
      <FilterIncentiveModal
        open={openFilter}
        handleClose={handleCloseFilter}
        skuRows={skuRows}
        employeeList={employeeList}
        setFilterStartDate={setStartDate}
        setFilterEndDate={setEndDate}
        setFilterEmployeeID={setEmployeeID}
        setFilterProductCode={setProductCode}
      />
      <div
        style={{
          display: "flex",
          padding: "3px",
          alignItems: "center",
          background: "#FFF",
        }}
      >
        <IconSVG
          src={HomeIcon}
          style={{
            alignSelf: "self-start",
            marginTop: "4px",
          }}
        />
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>Incentives</Title>
      </div>

      <div
        style={{
          background: "#ffffff",
          borderRadius: "12px",
          margin: "32px 20px",
          padding: "21px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text>Incentive Plan</Text>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              sx={searchTextFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSVG src={searchIcon} />
                  </InputAdornment>
                ),
                style: { height: "35px", fontWeight: 400, fontSize: "12px" },
              }}
              placeholder="Search Plan"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
            />

            <Button
              variant="outlined"
              height="35px"
              startIcon={<IconSVG src={filterIcon} />}
              sx={filterButtonStyle}
              onClick={() => {
                handleOpenFilter();
              }}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              height="35px"
              endIcon={<AddSharpIcon sx={{ fontSize: "8px" }} />}
              sx={addPlanButtonStyle}
              onClick={() => {
                handleOpen();
              }}
            >
              Add Plan
            </Button>
          </div>
        </div>

        <Box sx={datagridBoxStyle}>
          <DataGrid
            autoHeight
            paginationMode="server"
            checkboxSelection={false}
            rows={rows}
            columns={columns}
            rowsPerPageOptions={itemsPerPageList}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  "margin-top": "1em",
                  "margin-bottom": "1em",
                },
            }}
            onCellClick={handleCellClick}
            onRowClick={handleRowClick}
            loading={loading}
            rowCount={incentiveCount}
          />
        </Box>
      </div>
    </div>
  );
};

export default IncentivePlan;
