import { IconButton } from "@mui/material";
import useModal from "../../../Hooks/useModal";
import OrderStatusTypeSKUModal from "../2.CommonChildren/OrderStatusTypeSKUModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useContext, useState } from "react";
import useForecast from "../../../Hooks/useForecast";
import useSKUDetailModal from "../../../Hooks/useSKUDetailModal";
import { getSKUModalDetail } from "../../../Services/ordertable.service";
import { getSkuForecast } from "../../../Services/forecast.service";
import { AlertContext } from "../../../App";

const OrderStatusTypeTableDetailButton = ({
  ro_num,
  row,
  isLoading,
  setIsLoading,
}) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [modalSKUDetail, setModalSKUDetail] = useState({});
  const { open, handleOpen, handleClose } = useSKUDetailModal({
    setModalSKUDetail, //to reset
  });

  const { forecastedData, setForecastedData } = useForecast();

  const handleClick = async (row) => {
    if (row.original.country === "JP") {
      //not handling this atm
      return;
    }

    handleOpen();
    let ro_num = row.original.ro_num;

    //call api
    await getSKUModalDetail({
      ro_num,
      sku: row.original.SKU,
      country: row.original.country,
      setModalSKUDetail,
      setIsLoading,
      setAlertInfo,
    });

    await getSkuForecast({
      selectedSKU: row.original.SKU,
      setForecastedData,
    });
  };

  return (
    <>
      <OrderStatusTypeSKUModal
        open={open}
        handleClose={handleClose}
        modalSKUDetail={modalSKUDetail}
        isLoading={false}
        forecastedData={forecastedData}
      />
      <div style={{ textAlign: "center", minWidth: 60 }}>
        <IconButton onClick={() => handleClick(row)}>
          <RemoveRedEyeIcon sx={{ color: "#bead7c", fontSize: "32px" }} />
        </IconButton>
      </div>
    </>
  );
};

export default OrderStatusTypeTableDetailButton;
