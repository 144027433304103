import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Title,
  Tooltip,
  Legend
);



const SKUDesc = ({year, dataYear, labelsYear, SKUID}) => {
  const options = {
    responsive: true,
    plugins: {
        title: {
          display: true,
          text: year +" Distribution For " + SKUID,
        },
      tooltip: {
        callbacks: {
          title: function(context) {
            //console.log(context)
            return `${context[0].label}`;
        },
            label: function(context) {
                //console.log(context)
                    return `Order Value: ${context.formattedValue}`;
            },
            /*afterLabel: function() {
              //console.log(context)
                  return `SKU desc`;
                }*/
              }
          },
        },
    };
  
  const data = {
      labels: labelsYear,
      datasets: [
        {
          label: "Popularity Of Products",
          data: dataYear,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 159, 64, 1)",,
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
          ],
          borderWidth: 1,
        },
        // {
        //   label: "2021 Revenue",
        //   data: [100, 90, 87, 50, 60, 101],
        //   backgroundColor: [
        //     "rgba(54, 162, 235, 0.2)",
        //   ],
        //   borderColor: [
        //     "rgba(54, 162, 235, 1)",
        //   ],
        //   borderWidth: 1,
        // },
      ],
    };
  
  return (
    <>
      <Pie data={data} options={options} />
    </>
  );
};

export default SKUDesc;
