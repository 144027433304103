import React, { useState, useEffect, useRef } from "react";
import { Modal } from "@mui/material";
import EditIncentivePlanModal from "./EditIncentivePlanModal";
import { getSKUList } from "../../../../Services/eventstable.service";

const EditIncentivePlanModalWrapper = ({ open, handleClose, id }) => {
  const modalRef = useRef();
  const [skuRows, setSKURows] = useState([]);
  const [skuLoaded, setSkuLoaded] = useState(false);

  useEffect(() => {
    getSKUList(setSKURows, setSkuLoaded);
  }, []);

  return (
    <Modal open={open} closeOnOverlayClick={false}>
      <div>
        {skuRows.length > 0 && (
          <EditIncentivePlanModal
            id={id}
            handleClose={handleClose}
            skuRows={skuRows}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditIncentivePlanModalWrapper;
