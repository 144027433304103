import api from "./api"; //api here is the axios instance.
import { getUser } from "./auth.service";

// Helper
const getOrderStatusRep = (orderStatus) => {
  switch (orderStatus) {
    case "procurement":
      return "pro";
    case "rereview":
      return "re";
    case "management":
      return "man";
    case "proforma":
      return "ven";
    case "pogeneration":
      return "pogen";
    case "postoreceive":
      return "potore";
    default:
      return null;
  }
};

const alertError = (err, setAlertInfo) => {
  // console.log("error: ", err);
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err?.response?.data["message"] ?? err.toString(),
  }));
};

// ---------------------- ORDER TABLE **PROCUREMENT > POS TO RECEIVE** START ------------------------------------------------------------------------------------------------------------------------------------
//Get PO details by passing PO
export const getPODetails = async ({
  orderStatus, //procurement, rereview, management, proforma, pogeneration, postoreceive
  orderStatusType,
  ro_num,
  setPOInfo,
  setMasterPOInfo,
  setBacklogPOInfo,
  setIsLoading,
  setAlertInfo,
}) => {
  const orderStatusRep = getOrderStatusRep(orderStatus);
  const url = () => {
    switch (orderStatusType) {
      case "order_table":
        return `updatepodetail${orderStatusRep}/${ro_num}`; //updatepodetailpro/AT-7-2022
      case "backlog":
        return `getbacklog/${ro_num}`; //getbacklog/AT-7-2022
      default:
        return `updatepodetail${orderStatusRep}/${ro_num}`; //updatepodetailpro/AT-7-2022
    }
  };

  try {
    setIsLoading((prevState) => ({
      // '{' will return function, '(' will return object, that's why we cover using '(' here.
      ...prevState,
      tableLoading: true,
    }));

    const response = await api.get(url());
    // console.log("getPODetails API called successfully!");
    // console.log("Response from GET updatedetail: ", response);
    // console.log("Response Data: ", response);
    const returnData = response.data;

    if (orderStatusType === "backlog") {
      await setBacklogPOInfo({ ...returnData });
    } else {
      await setPOInfo({ ...returnData });
      await setMasterPOInfo({ ...returnData });
    }
  } catch (err) {
    alertError(err, setAlertInfo);
    // await setMasterPOInfo({}); //if po not in this stage, then reset
    // await setPOInfo({}); //if po not in this stage, then reset
  }

  setIsLoading((prevState) => ({
    ...prevState,
    tableLoading: false,
  }));
};

// Update the review status
export const updatePoReviewStatus = async ({
  is_reviewed,
  orderStatus, //procurement, rereview, management, proforma, pogeneration, postoreceive
  ro_num,
  setPOInfo,
  setMasterPOInfo,
  setContainersInfo,
  setIsLoading,
  setAlertInfo,
}) => {
  const url = `updatepodetailreview/`;

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      poUpdating: true,
    }));

    const response = await api.post(url, {
      is_reviewed: is_reviewed,
      po_number: ro_num,
    });

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        await setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "success",
          msg: response.data["message"] ?? "Successfully updated.",
        }));
        //Refresh
        getPODetails({
          orderStatus,
          ro_num,
          setPOInfo,
          setMasterPOInfo,
          setIsLoading,
          setAlertInfo,
        });
        getContainerDetails(
          orderStatus,
          ro_num,
          setContainersInfo,
          setIsLoading,
          setAlertInfo
        );
      } else {
        // alertError(
        //   response.data["message"] ?? "Failed to update.",
        //   setAlertInfo
        // );
      }
    } else {
      // alertError(response.data["message"] ?? "Failed to update.", setAlertInfo);
    }
  } catch (err) {
    alertError(err.response.data["message"], setAlertInfo);
  }
  await setIsLoading((prevState) => ({
    ...prevState,
    poUpdating: false,
  }));
};

//PUT PO Number by passing entire PO details with that PO Number
export const putPOInfo = async ({
  orderStatus,
  ro_num,
  masterPOInfo,
  setPOInfo,
  setMasterPOInfo,
  setContainersInfo,
  setIsLoading,
  setAlertInfo,
}) => {
  const orderStatusRep = getOrderStatusRep(orderStatus);

  const url = `updatepodetail${orderStatusRep}/${ro_num}`; //updatepodetailpro/AT-7-2022

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      poUpdating: true,
    }));

    const response = await api.put(url, masterPOInfo);

    setPOInfo(masterPOInfo);
    setMasterPOInfo(masterPOInfo);
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "The PO has been saved successfully!",
    }));

    //Refresh
    getPODetails({
      orderStatus,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
    });
    getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
  } catch (err) {
    alertError(err?.response?.data["message"] ?? err?.toString(), setAlertInfo);
  }
  await setIsLoading((prevState) => ({
    ...prevState,
    poUpdating: false,
  }));
};

//Get Container details by passing PO
export const getContainerDetails = async (
  orderStatus,
  ro_num,
  setContainersInfo,
  setIsLoading,
  setAlertInfo
) => {
  let orderStatusRep = getOrderStatusRep(
    orderStatus === "rereview" ? "procurement" : orderStatus //procurement and rereview is the same in here - then pogen + potore == gen
  );

  if (orderStatusRep === "pogen" || orderStatusRep === "potore") {
    orderStatusRep = "gen";
  }

  const url = `getcontainer${orderStatusRep}/${ro_num}`; //getcontainerpro/AT-7-2022
  // console.log("URL of get container: ", url);
  try {
    setIsLoading((prevState) => ({
      ...prevState,
      cardLoading: true,
    }));
    const response = await api.get(url);
    // console.log("getContainerDetails API called successfully!");
    // console.log("getContainerDetails response.data: ", response.data);
    setContainersInfo(response.data);
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    cardLoading: false,
  }));
};

//Get Container add choices options - only 3 now
export const getContainerOptions = async (
  setContainerOptions,
  setIsLoading,
  setAlertInfo
) => {
  const url = `getcontaineroptions`; //getcontaineroptions

  try {
    setIsLoading((prevState) => ({
      ...prevState,
      containerOptionsLoading: true,
    }));
    const response = await api.get(url);
    // console.log("getContainerDetails API called successfully!");
    // console.log("getContainerOptions response.data: ", response.data);
    setContainerOptions(response.data);
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    containerOptionsLoading: false,
  }));
};

//Create new Container
export const createContainer = async (
  orderStatus,
  ro_num,
  size,
  setContainersInfo,
  setIsLoading,
  setAlertInfo
) => {
  const orderStatusRep = getOrderStatusRep(
    orderStatus === "rereview" ? "procurement" : orderStatus //procurement and rereview is the same in here.
  );
  const url = `createcontainer${orderStatusRep}/${ro_num}`; //getcontaineroptions

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      creatingContainer: true,
    }));

    const body = {
      size,
    };

    const response = await api.post(url, body);
    // console.log("Post create container API called successfully!");

    // Refresh information - only options
    getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "A new container has been created successfully!",
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  await setIsLoading((prevState) => ({
    ...prevState,
    creatingContainer: false,
  }));
};

//Delete Container
export const deleteContainer = async (
  orderStatus,
  containerId,
  ro_num,
  setContainersInfo,
  setPOInfo,
  setMasterPOInfo,
  setIsLoading,
  setAlertInfo
) => {
  const url = `deletecontainer/${containerId}`; //getcontaineroptions

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      deletingContainer: true,
    }));

    const response = await api.post(url);
    // console.log("Post create container API called successfully!");
    // console.log("Delete container response.data: ", response.data);
    // Refresh information - seems like need to refresh everything
    getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    getPODetails({
      orderStatus,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
    });
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `The container - ${containerId} has been deleted successfully!`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  await setIsLoading((prevState) => ({
    ...prevState,
    deletingContainer: false,
  }));
};

//Optimize ontainer
export const optimizeContainer = async (
  orderStatus,
  ro_num,
  setContainersInfo,
  setPOInfo,
  setMasterPOInfo,
  setIsLoading,
  setAlertInfo
) => {
  const url = `optimizecon/${ro_num}`; //getcontaineroptions
  // console.log("Optimize URL: ", url);
  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      optimizingContainer: true,
    }));
    const response = await api.post(url);
    // console.log("Post optimize container API called successfully!");

    // Refresh information - seems like need to refresh everything
    await getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    await getPODetails({
      orderStatus,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
    });
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "The container has been optimized successfully!",
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }
  await setIsLoading((prevState) => ({
    ...prevState,
    optimizingContainer: false,
  }));
};

//Optimize ontainer
export const splitContainer = async ({
  orderStatus,
  ro_num,
  setContainersInfo,
  setPOInfo,
  setMasterPOInfo,
  setIsLoading,
  setAlertInfo,
  body,
}) => {
  const url = `splitcontainer/${ro_num}`; //split container

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      isSplittingContainer: true,
    }));

    const response = await api.post(url, body);
    // console.log("Post optimize container API called successfully!");
    // Refresh information - seems like need to refresh everything
    await getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    await getPODetails({
      orderStatus,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
    });
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "The container has been splitted successfully!",
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }
  await setIsLoading((prevState) => ({
    ...prevState,
    isSplittingContainer: false,
  }));
};

//Send to Management
export const sendToManagement = async (
  orderStatus,
  ro_num,
  setContainersInfo,
  setPOInfo,
  setMasterPOInfo,
  setIsLoading,
  setAlertInfo
) => {
  const url = `sendtomanage/${ro_num}`; //getcontaineroptions

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      sendingToManagement: true,
    }));

    const response = await api.post(url);
    // console.log("Post send to management API called successfully!");

    // Refresh information - seems like need to refresh everything
    // getContainerDetails(
    //     orderStatus,
    //     ro_num,
    //     setContainersInfo,
    //     setIsLoading,
    //     setAlertInfo
    // );
    // getPODetails({
    //     orderStatus,
    //     ro_num,
    //     setPOInfo,
    //     setMasterPOInfo,
    //     setIsLoading,
    //     setAlertInfo,
    // });
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `${ro_num} has been sent to management successfully!`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    sendingToManagement: false,
  }));
};

//Approve PO, then redirect to previous
//Add refresh
export const approvePO = async (ro_num, setIsLoading, setAlertInfo) => {
  const url = `sendtovendor/${ro_num}`; //getcontaineroptions

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      approvingPO: true,
    }));

    const response = await api.post(url);

    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `${ro_num} has been approved successfully!`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  await setIsLoading((prevState) => ({
    ...prevState,
    approvingPO: false,
  }));
};

//Reject PO, then redirect to previous
//Add refresh
export const rejectPO = async (ro_num, setIsLoading, setAlertInfo, values) => {
  const url = `sendtoprocurement/${ro_num}`; //getcontaineroptions

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      rejectingPO: true,
    }));

    const response = await api.post(url, values);

    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `${ro_num} has been rejected!`,
      }));
    }
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  await setIsLoading((prevState) => ({
    ...prevState,
    rejectingPO: false,
  }));
};

//Generate PO/RO
export const generatePO = async (
  orderStatus,
  ro_num,
  POComment,
  selectedContainer,
  setPOInfo,
  setMasterPOInfo,
  setContainersInfo,
  setIsLoading,
  setAlertInfo
) => {
  let isAllGenerated = false;
  const url = `generatepo/${selectedContainer}`; //getcontaineroptions

  const body = {
    comments: POComment,
    ponum: ro_num,
  };

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      generatingPO: true,
    }));

    const response = await api.post(url, body);
    // console.log("Post Generate PO API called successfully!");

    if (response.data.isAllGenerated === true) {
      setPOInfo({});
      setMasterPOInfo({});
      setContainersInfo([]);
      isAllGenerated = true;
    } else {
      getContainerDetails(
        orderStatus,
        ro_num,
        setContainersInfo,
        setIsLoading,
        setAlertInfo
      );
      getPODetails({
        orderStatus,
        ro_num,
        setPOInfo,
        setMasterPOInfo,
        setIsLoading,
        setAlertInfo,
      });
    }
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `${ro_num} - ${response.data.success}`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  await setIsLoading((prevState) => ({
    ...prevState,
    generatingPO: false,
  }));

  return isAllGenerated;
};

//Download RO
export const downloadRO = async (
  selectedContainer,
  downloadROParams,
  setIsLoading,
  setAlertInfo
) => {
  const { arrDate, dateRequired, ROComment } = downloadROParams;

  const url = `invoice/generate_invoice/pdf`; //getcontaineroptions

  // console.log("Download RO URL: ", url);

  const body = {
    arr_date: arrDate.format("YYYY-MM-DD"),
    date_required: dateRequired.format("YYYY-MM-DD"),
    comments: ROComment,
    container_id: selectedContainer,
  };

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      downloadingRO: true,
    }));

    const response = await api.post(url, body, { responseType: "blob" });
    // console.log(response);
    window.open(URL.createObjectURL(response.data));
    // console.log("Get Download RO API called successfully!");
    // console.log("Download RO response.data: ", response.data);
    // Refresh information - seems like need to refresh everything
    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "The RO has been generated successfully!",
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  await setIsLoading((prevState) => ({
    ...prevState,
    downloadingRO: false,
  }));
};

//Push/pull to backlog
export const backlogPushPullDelete = async ({
  sku,
  country,
  actionText,
  orderStatus,
  orderStatusType,
  ro_num,
  setContainersInfo,
  setPOInfo,
  setMasterPOInfo,
  setBacklogPOInfo,
  setIsLoading,
  setAlertInfo,
}) => {
  const url = () => {
    switch (actionText) {
      case "Push": //push
        return `sendtobacklog/${ro_num}`; //push
      case "Pull": //pull
        return `sendfrombacklog/${ro_num}`; //pull
      case "Delete": //delete
        return `deletebacklog/${ro_num}`; //Delete
      default:
        return `sendtobacklog/${ro_num}`; //push
    }
  };

  const body = {
    sku,
    country,
  };

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      backlogging: true,
    }));

    const response = await api.post(url(), body);

    // Refresh information - seems like need to refresh everything
    getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    getPODetails({
      orderStatus,
      orderStatusType,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setBacklogPOInfo,
      setIsLoading,
      setAlertInfo,
    });
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `${sku} - ${country} has been ${actionText}ed successfully!`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    backlogging: false,
  }));
};

//Save in backlog
export const backlogUpdate = async ({
  orderStatus,
  orderStatusType,
  ro_num,
  backlogPOInfo,
  setBacklogPOInfo,
  setIsLoading,
  setAlertInfo,
}) => {
  const url = `updatebacklog/${ro_num}`; //updatebacklog/AT-7-2022

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      backlogUpdating: true,
    }));

    const response = await api.post(url, backlogPOInfo);

    setBacklogPOInfo(backlogPOInfo);

    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "The Backlog has been saved successfully!",
    }));

    //Refresh
    getPODetails({
      orderStatus, //procurement, rereview, management, proforma, pogeneration, postoreceive
      orderStatusType,
      ro_num,
      setBacklogPOInfo,
      setIsLoading,
      setAlertInfo,
    });
  } catch (err) {
    alertError(err, setAlertInfo);
  }
  await setIsLoading((prevState) => ({
    ...prevState,
    backlogUpdating: true,
  }));
};

//Get Compare History PO List
export const getCompareHistoryList = async (
  orderStatus,
  orderStatusType,
  ro_num,
  setPOToCompareList,
  setIsLoading,
  setAlertInfo
) => {
  const url = `comparehistory/${ro_num}`;
  // console.log("Compare History URL: ", url);

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      gettingPOToCompare: true,
    }));

    const response = await api.get(url);
    // console.log("Get Compare History List response.data: ", response);
    // Refresh information - seems like need to refresh everything

    const returnData = response.data;
    // console.log("return data: ", returnData);
    await setPOToCompareList(returnData);
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    gettingPOToCompare: false,
  }));
};

//Compare History PO
export const compareHistory = async (
  firstPO,
  secondPO,
  ro_num,
  setComparePOInfo,
  setIsLoading,
  setAlertInfo
) => {
  const url = `comparehistory/${ro_num}`;

  const body = {
    new: firstPO,
    old: secondPO,
  };

  // const url = `comparehistory/AT-7-2022`;
  // console.log("Compare History URL: ", url);
  // const body = {
  //     new: [1, 1, 28],
  //     old: [2, 5, 2],
  // };

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      comparingHistory: true,
    }));

    const response = await api.post(url, body);

    // Refresh information - seems like need to refresh everything

    const returnData = response.data;

    await setComparePOInfo({
      POdetail: returnData[2].comparison,
    });

    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `PO History has been compared successfully!`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    comparingHistory: false,
  }));
};

// ---------------------- ORDER TABLE **PROCUREMENT > POS TO RECEIVE** END --------------------------------------------------------------------------------------------------------------

export const getSKUModalDetail = async ({
  ro_num,
  sku,
  country,
  setModalSKUDetail,
  setIsLoading,
  setAlertInfo,
}) => {
  const url = `getskudetail/${ro_num}/${sku}/${country}`;

  try {
    await setIsLoading((prevState) => ({
      ...prevState,
      gettingSKUModalDetail: true,
    }));

    const response = await api.get(url);

    // Refresh information - seems like need to refresh everything
    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const returnData = response.data["data"];

        await setModalSKUDetail(returnData);
      }
    }
  } catch (err) {
    alertError(err, setAlertInfo);
  }

  setIsLoading((prevState) => ({
    ...prevState,
    gettingSKUModalDetail: false,
  }));
};

export const getInactiveSKU = async ({
  ro_num,
  setInactiveSKUList,
  setAlertInfo,
}) => {
  const url = `insertskulist/${ro_num}`;

  try {
    const response = await api.get(url);
    if (response.status === 200) {
      // console.log("Get Compare History List response.data: ", response);
      // Refresh information - seems like need to refresh everything

      const returnData = response.data;
      // console.log("return data: ", returnData);

      setInactiveSKUList(returnData);
    } else {
      setInactiveSKUList([]);
    }
  } catch (err) {
    alertError(err.response.data["message"], setAlertInfo);
  }
};

export const insertInactiveSKU = async ({
  orderStatus,
  orderStatusType,
  ro_num,
  sku,
  country,
  setIsInserting,
  setPOInfo,
  setMasterPOInfo,
  setIsLoading,
  setAlertInfo,
}) => {
  const url = `insertsku/${ro_num}`;
  const body = {
    sku,
    country,
  };

  try {
    await setIsInserting(true);
    const response = await api.post(url, body);
    // console.log("Get Compare History List response.data: ", response);
    // Refresh information - seems like need to refresh everything
    const returnData = response.data;

    //refresh
    getPODetails({
      orderStatus, //procurement, rereview, management, proforma, pogeneration, postoreceive
      orderStatusType,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
    });
  } catch (err) {
    alertError(err, setAlertInfo);
  }
  await setIsInserting(false);
};
