import React, { useState, useEffect } from "react";
import { SelectColumnFilter } from "../../../Components/Tables/SortFilterTable/Filters";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Box, Select, MenuItem, Button } from "@mui/material";
import {
  SecondaryButton,
  DeleteButton,
  PrimaryPill,
  SecondaryLoadingButton,
} from "../../../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@material-ui/core/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

//modal
// import useSKUContainerModal from "../../../Hooks/useSKUContainerModal";
import OrderStatusTypeSKUContainerModal from "../2.CommonChildren/OrderStatusTypeSKUContainerModal";
import TurnoverChart from "../../ManagementDashboard/TurnoverChart/TurnoverChart";
import { currencyFormatter } from "../../../Services/util";
import OrderStatusTypeSKUModal from "../2.CommonChildren/OrderStatusTypeSKUModal";
import useModal from "../../../Hooks/useModal";
import OrderStatusTypeTableDetailButton from "./order_status_type_table_detail_button";

const SKUCell = ({ data, row, value }) => {
  //data is the entire PO, row is the current row

  // const [pillStatus, setPillStatus] = useState({
  //     isSimilar: false,
  //     isNew: false,
  //     isPromo: true,
  // });

  let isSimilar = false;
  let isNew = false;
  let isPromo = false;

  let arrayData;
  for (const array in data) {
    arrayData = data[array];
    // console.log("ARRAY DATA: ", arrayData);
    // console.log("ROW: ", row);
    if (
      arrayData.SKU === row.values.SKU &&
      arrayData.country === row.values.country
    ) {
      //check the is_new, similar_items.length and promo
      // console.log("MATCH FOUND");
      // console.log("DATA HERE: ", arrayData);

      isSimilar = arrayData.has_similar_items;
      isNew = arrayData.is_new;
      //isPromo = ?

      // setPillStatus((prev) => ({
      //     ...prev,
      //     isSimilar: arrayData.similar_items.length > 0,
      //     isNew: arrayData.is_new,
      //     // isPromo: ?
      // }));

      break;
    }
  }
  return (
    <>
      <Box style={{ textAlign: "left" }}>{value}</Box>
      <Box sx={{ display: "flex", m: 0.2 }}>
        <ThemeProvider theme={customMUITheme}>
          {isNew && <PrimaryPill theme={{ main: "#82A15E" }}>New</PrimaryPill>}
          {isPromo && (
            <PrimaryPill theme={{ main: "#5E82A1" }}>Promo</PrimaryPill>
          )}

          {isSimilar && (
            <PrimaryPill theme={{ main: "#A15E82" }}>Similar</PrimaryPill>
          )}
        </ThemeProvider>
      </Box>
    </>
  );
};

const ContainerModalCell = ({
  value,
  cell,
  isAbleToStage,
  row: { index, values },
  column: { id },
  updateUserEdit,
  containersInfo,
  POdetail,
  setPOInfo,
  setMasterPOInfo,
  orderStatus,
  orderStatusType,
  isLoading,
  setIsLoading,
  viewActive,
}) => {
  const [totalQty, setTotalQty] = useState(0);

  useEffect(() => {
    let qtyTemp = 0;
    for (const { qty } of values.containers) {
      qtyTemp = qtyTemp + qty;
    }
    setTotalQty(qtyTemp);
  }, [values.containers]);

  const [modalSKUContainerDetail, setModalSKUContainerDetail] = useState({});
  const [modalSKUContainerDetailInitial, setModalSKUContainerDetailInitial] =
    useState({});

  const [openSKUContainerModal, setOpenSKUContainerModal] = useState(false);

  const handleOpenSKUContainerModal = () => {
    // console.log("values.containers: ", values.containers);
    const containerList = containersInfo.map((row) => {
      for (const { container_id, qty } of values.containers) {
        if (container_id === row.con_id) {
          return {
            container_id: row.con_id,
            qty: qty,
          };
        }
      }
      return {
        container_id: row.con_id,
        qty: 0,
      };
    });
    setModalSKUContainerDetail(containerList);
    setModalSKUContainerDetailInitial(containerList);
    setOpenSKUContainerModal(true);
  };
  const handleCloseSKUContainerModal = () => {
    setOpenSKUContainerModal(false);
  };

  const isDisabledSelect = () => {
    for (const containerInfo of containersInfo) {
      // console.log("containerInfo: ", containerInfo);
      if (containerInfo.generated === true) {
        // disable of value equal
        if (values.container === containerInfo.con_id) {
          return true;
        }
      }
    }
    return false;
  };

  const containerListToDisplay = () => {
    return Object.values(values.containers)
      .map((row) => {
        return row.container_id;
      })
      .sort()
      .join(" ");
  };
  // Implement logic here: need to implement other logic regardless of the amount, since every row can have different container distri.
  // value is now list> container_id + qty
  // How to tackle when user want to change or add container or delete? - Display all containers available and let the user edit.
  // console.log("HERE: ", containerListToDisplay() === "");
  // console.log("HERE: ", containerListToDisplay());
  return (
    <div style={{ textAlign: "center" }}>
      <OrderStatusTypeSKUContainerModal
        open={openSKUContainerModal}
        handleClose={handleCloseSKUContainerModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setPOInfo={setPOInfo}
        setMasterPOInfo={setMasterPOInfo}
        modalSKUContainerDetail={modalSKUContainerDetail}
        setModalSKUContainerDetail={setModalSKUContainerDetail}
        modalSKUContainerDetailInitial={modalSKUContainerDetailInitial}
        totalQty={totalQty}
        values={values}
      />
      {containerListToDisplay() === "" ? (
        "No Order"
      ) : (
        <SecondaryButton
          variant="contained"
          className="action-button"
          disabled={isDisabledSelect() || !isAbleToStage}
          onClick={() => {
            handleOpenSKUContainerModal();
          }}
        >
          {containerListToDisplay()}
        </SecondaryButton>
      )}
    </div>
  );
};

const EditableCell = ({
  value,
  cell,
  row: { index, values },
  column: { id },
  updateUserEdit,
  editableRowIndex,
  orderStatusType,
}) => {
  const [tempValue, setTempValue] = useState(value);

  const onChange = (e) => {
    if (id === "demand_qty" || id === "promo_qty") {
      let input = e.target.value;

      if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
        setTempValue(input);
      }
      // if (e.target.value === "") {
      //   setTempValue(e.target.value);
      // } else if (/^\d+$/.test(e.target.value)) {
      //   const valueCheck = Number(e.target.value);
      //   if (valueCheck > 1000) {
      //     console.log("Must be in between 0 and 1000");
      //   } else {
      //     setTempValue(valueCheck);
      //   }
      // }

      // if (e.target.value === "") {
      //   setTempValue(0);
      // }
    } else {
      setTempValue(e.target.value);
    }
  };

  const onBlur = (e) => {
    updateUserEdit(
      values.SKU,
      values.country,
      id,
      id === "demand_qty" || id === "promo_qty"
        ? parseInt(e.target.value) || ""
        : e.target.value,
      orderStatusType
    );
  };

  useEffect(() => {
    //track cell value change, if change via save button, here can detect and update accordingly.
    setTempValue(value);
  }, [value]);

  if (index === editableRowIndex) {
    if (id === "remark") {
      return (
        <>
          <TextareaAutosize
            maxRows={4}
            value={tempValue === null ? "" : tempValue}
            style={{ width: 500 }}
            onChange={onChange}
            onBlur={onBlur}
          />
        </>
      );
    }

    if (id === "demand_qty") {
      return (
        <>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="number"
              min="0"
              max="999"
              value={tempValue}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div style={{ textAlign: "center" }}>
          <input
            type="number"
            min="0"
            max="999"
            value={tempValue}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        {id === "remark" ? (
          <p style={{ textAlign: "left", minWidth: 300 }}>
            {value === null ? "" : value}
          </p>
        ) : id === "demand_qty" ? (
          <div style={{ textAlign: "center", minWidth: 200 }}>
            {cell?.row?.original?.is_forecasted === false
              ? `${value} (No Forecast)`
              : value}
          </div>
        ) : (
          <span style={{ minWidth: 125 }}>{value}</span>
        )}
      </>
    );
  }
};

const EditButtonCell = ({
  row: { index, values },
  setEditableRowIndex,
  editableRowIndex,
  cancelEdit,
  orderStatus,
  orderStatusType,
  containersInfo,
}) => {
  const [initialRow, setInitialRow] = useState(values);

  const isDisabledEdit = () => {
    for (const containerInfo of containersInfo) {
      // console.log("containerInfo: ", containerInfo, "containerInfo.generated: ", containerInfo.generated, "value: ", value, "containerInfo.con_id: ", containerInfo.con_id)
      if (containerInfo.generated === true) {
        // disable of value equal
        if (values.container === containerInfo.con_id) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div style={{ textAlign: "center", display: "inline-flex" }}>
      <SecondaryButton
        variant="contained"
        className="action-button"
        disabled={
          orderStatus === "pogeneration" ||
          orderStatus === "postoreceive" ||
          isDisabledEdit()
        }
        onClick={() => {
          const currentIndex = index;
          if (editableRowIndex !== currentIndex) {
            setEditableRowIndex(currentIndex);
          } else {
            // request for saving the updated row

            setEditableRowIndex(null);

            // console.log("updated row values:", values);
            // call your updateRow API - no need call here, later can use save button
          }
        }}
      >
        {/* single action button supporting 2 modes */}
        {editableRowIndex !== index ? "EDIT" : <DoneIcon color="success" />}
      </SecondaryButton>

      {editableRowIndex === index && (
        <SecondaryButton
          variant="contained"
          className="cancel-button"
          disabled={
            orderStatus === "pogeneration" ||
            orderStatus === "postoreceive" ||
            isDisabledEdit()
          }
          onClick={() => {
            setEditableRowIndex(null);
            cancelEdit(initialRow, orderStatusType);
            // console.log("Cancel the changes made to initial: ", initialRow);
            // call your updateRow API - no need call here, later can use save button
          }}
        >
          {/* single action button supporting 2 modes */}
          <CloseIcon color="error" />
        </SecondaryButton>
      )}
    </div>
  );
};

const BacklogCell = ({
  row: { values },
  updateBacklog,
  orderStatus,
  orderStatusType,
  isAbleToStage,
  isLoading,
  containersInfo,
}) => {
  const isDisabledBacklog = () => {
    // to disable if generated
    for (const containerInfo of containersInfo) {
      // console.log("containerInfo: ", containerInfo, "containerInfo.generated: ", containerInfo.generated, "value: ", value, "containerInfo.con_id: ", containerInfo.con_id)
      if (containerInfo.generated === true) {
        // disable of value equal
        if (values.container === containerInfo.con_id) {
          return true;
        }
      }
    }

    // to disable if total quantity === 0
    if (values.demand_qty === 0 && values.promo_qty === 0) {
      return true;
    }

    return false;
  };

  const actionText = () => {
    return orderStatusType === "order_table" ? "Push" : "Pull";
  };

  return (
    <div style={{ textAlign: "center" }}>
      {/* <LoadingButton
                sx={{
                    background: "#ffffff",
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                    borderRadius: 2,
                    height: "40px",
                    border: "0px",
                    padding: "0px 12px",
                    margin: "0px 6px",
                    color: "#485303",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                }}
                loading={isLoading.backlogging}
                variant="outlined"
                onClick={() => {
                    updateBacklog(values.SKU, values.country, actionText());
                }}
                disabled={
                    orderStatus === "pogeneration" ||
                    orderStatus === "postoreceive" ||
                    !isAbleToStage ||
                    isDisabledBacklog()
                }
            >
                {actionText()}
            </LoadingButton> */}
      <StylesProvider injectFirst>
        {/* Push and Pull */}
        <SecondaryLoadingButton
          variant="contained"
          className="action-button"
          loading={isLoading.backlogging}
          onClick={() => {
            updateBacklog(values.SKU, values.country, actionText());
          }}
          disabled={
            orderStatus === "pogeneration" ||
            orderStatus === "postoreceive" ||
            !isAbleToStage ||
            isDisabledBacklog()
          }
        >
          {actionText()}
        </SecondaryLoadingButton>
      </StylesProvider>
    </div>
  );
};

const DeleteCell = ({
  row: { values },
  orderStatus,
  orderStatusType,
  containersInfo,
  updateBacklog,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <StylesProvider injectFirst>
        {/* Delete */}
        <SecondaryLoadingButton
          variant="contained"
          className="delete-button"
          sx={{ color: "red" }}
          onClick={() => {
            updateBacklog(values.SKU, values.country, "Delete");
          }}
          // disabled={
          //     orderStatus === "pogeneration" ||
          //     orderStatus === "postoreceive" ||
          //     !isAbleToStage ||
          //     isDisabledBacklog()
          // }
        >
          Delete
        </SecondaryLoadingButton>
      </StylesProvider>
    </div>
  );
};

export const SKUHeader = {
  Header: "SKU",
  // accessor: "sku",
  accessor: "SKU",
  sticky: "left",
  Filter: SelectColumnFilter,
  Cell: SKUCell,
  disableFilters: true,
  style: {
    position: "sticky",
    zIndex: "5",
    left: 0,
    cursor: "pointer",
    minWidth: 222,
  },
  // zIndex: 5,
};

export const procurementOrdertableColumns = [
  // fields can be accessed via headergroups>headers> inside here
  // SKU, Product Code,SKU Desc columns are sticky to left. Need to check working fine or not after making changes to those columns
  SKUHeader,
  {
    Header: "Product Code",
    accessor: "shortcode",
    sticky: "left",
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: {
      position: "sticky",
      zIndex: "5",
      left: 222,
      cursor: "pointer",
      minWidth: 122,
    },
  },
  {
    Header: "SKU Desc",
    accessor: "description",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
    ),
    sticky: "left",
    style: {
      position: "sticky",
      zIndex: "5",
      left: 344,
      cursor: "pointer",
    },
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Country",
    accessor: "country",
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    Header: "Qty On Hand",
    accessor: "on_hand_qty",
    // Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: { textAlign: "center" },
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 200 }}>{row.value}</div>
    ),
  },
  {
    Header: "Safety Stock",
    accessor: "safety_stock",
    // Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Forecast Qty",
    accessor: "aggregated_forecast",
    Filter: SelectColumnFilter,
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
    ),
    disableFilters: true,
  },
  {
    Header: "Replenish",
    accessor: "demand_qty",
    Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Promotion",
    // accessor: "promotion",
    accessor: "promo_qty",
    Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Total Quantity",
    // accessor: "promotion",
    accessor: "total_qty_ordered",
    // Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Currency",
    accessor: "currency",
    Filter: SelectColumnFilter,
    disableFilters: true,
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {row.value ?? ""}
      </div>
    ),
  },
  {
    Header: "Price",
    // accessor: "promotion",
    accessor: "price",
    // Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>
        {currencyFormatter(row.value, "$")}
      </div>
    ),
  },
  {
    Header: "Remarks",
    // accessor: "remarks",
    accessor: "remark",
    Cell: EditableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Container",
    accessor: "containers",
    Cell: ContainerModalCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: {
      zIndex: "1",
    },
  },

  {
    Header: "EDIT",
    accessor: "edit",
    Cell: EditButtonCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: {
      zIndex: "1",
    },
  },
  {
    Header: "Backlog",
    accessor: "backlog",
    Cell: BacklogCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: {
      zIndex: "1",
    },
  },
  {
    Header: "Delete",
    accessor: "delete",
    Cell: DeleteCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
    style: {
      zIndex: "1",
    },
  },
  {
    Header: "",
    accessor: "POdetail",
    Cell: OrderStatusTypeTableDetailButton,
    disableFilters: true,
    // style: {
    //   zIndex: "1",
    // },
  },
];

//_______________________________________________________________________________________________
