import { useState, createContext } from "react";

export const PODBContext = createContext();

export const PODBProvider = ({ children }) => {
  const [refresher, setRefresher] = useState(0);
  const [instance, setInstance] = useState();

  return (
    <PODBContext.Provider
      value={(instance, setInstance, refresher, setRefresher)}
    >
      {children}
    </PODBContext.Provider>
  );
};
