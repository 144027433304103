import { Modal } from "@mui/material";
import DeleteEventModal from "./DeleteEventModal";

const DeleteEventModalWrapper = ({ open, handleClose, id }) => {
  return (
    <Modal open={open} closeOnOverlayClick={false}>
      <DeleteEventModal id={id} handleClose={handleClose} />
    </Modal>
  );
};

export default DeleteEventModalWrapper;
