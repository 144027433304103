import React, { useMemo } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import styled from "styled-components";
import { SVG } from "../../Styles/styles";
import { useParams } from "react-router-dom";
import { undoBacklog } from "../../Services/services";
import Table from "../Tables/Table";
import undo from "../../images/Undo.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SecondaryButton = styled.div`
  background: rgba(251, 249, 243, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
`;

const BacklogModal = ({ open, handleClose, poInfo, handleRefresh }) => {
  const COLUMNS = [
    { Header: "SKU", accessor: "SKU" },
    { Header: "Total Qty", accessor: "total_qty" },
    { Header: "Safety Qty", accessor: "safety_qty" },
    { Header: "Price", accessor: "price" },
    { Header: "Qty Backlogged", accessor: "qty_backlogged" },
    {
      Header: "Undo Backlog",
      Cell: (cell) => (
        <SecondaryButton
          style={{
            background: "none",
            color: "inherit",
            border: "none",
            font: "inherit",
          }}
          onClick={() => handleUndo(cell)}
        >
          <SVG src={undo}/>
        </SecondaryButton>
      ),
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => poInfo.POdetail, []);

  const param = useParams();

  const handleUndo = (cell) => {
    const SKU = cell.row.original.SKU;
    const VendorID = poInfo.VendorID;
    undoBacklog(SKU, VendorID, param.poid).then(() => handleRefresh());
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h1>Vendor</h1>
        <p>Last Edited : {poInfo.editDate}</p>
        <Table columns={columns} data={data} />
      </Box>
    </Modal>
  );
};

export default BacklogModal;
