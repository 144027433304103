const checkIfSKUValid = (sku, skuRows) => {
    // let attempt = 1;
    // const MAX_ATTEMPT = 5;
    // while (skuRows.length === 0) {
    //   if (attempt === MAX_ATTEMPT) {
    //     break;
    //   }
    //   // wait for 5s skuRows to be loaded
    //   setTimeout(() => {}, 5000);
    // }
    let skuRow = skuRows.find((row) => {
      return row.skuID === sku;
    });
    console.log(skuRow);
    return skuRow !== undefined;
  }

export const processTableData = (data, skuRows) => {

    let tableData = data.tableData
    let errorData = []
    tableData = tableData.map(row => {
      // 1) Trim each cell
      let rowData = row.map(cell => typeof cell === 'string' ? cell.trim() : cell);
      console.log(rowData);
      // 2) Remove empty rows
      if ( rowData[0] == "" && rowData[1] == "" && rowData[2] == "") {
        rowData[3] = "Empty row";
        errorData.push(rowData);
        return null;
      }
      // 3) Check if SKU is valid
      if (!checkIfSKUValid(rowData[0], skuRows)) {
        rowData[3] = "Invalid SKU, not found in list.";
        errorData.push(rowData);
        return null;
      }
      // 4) Get amount discount
      const originalPrice = parseFloat(rowData[1]);
      if (isNaN(rowData[1]) || rowData[1] == '') {
        rowData[3] = "Cannot convert original price to number";
        errorData.push(rowData);
        return null;
      }
      rowData[1] = originalPrice;
      const sellingPrice = parseFloat(rowData[2]);
      if (isNaN(rowData[2]) || rowData[2] == '') {
        rowData[3] = "Cannot convert selling price to number";
        errorData.push(rowData);
        return null;
      }
      rowData[2] = sellingPrice;
      rowData[3] = rowData[1] - rowData[2];
      if (rowData[3] <= 0) {
        rowData[3] = "Original Price must be greater than Selling Price";
        errorData.push(rowData);
        return null;
      }
      return rowData;
    });

    // remove null rows
    tableData = tableData.filter(row => row != null);

    // check if there are duplicated rows
    let uniqueRows = new Set();
    tableData = tableData.filter(row => {
      let key = row[0] + row[1] + row[2];
      if (uniqueRows.has(key)) {
        row[3] = "Duplicated row";
        errorData.push(row);
        return false;
      }
      uniqueRows.add(key);
      return true;
    });

    // group same amount discount
    let groupedSKU = {}
    tableData.map(row => {
      if (groupedSKU[row[3]] == undefined) {
        groupedSKU[row[3]] = []
      }
      groupedSKU[row[3]].push(row[0]);
    });

    return {
      skuList: tableData,
      locationIdList: data.locations,
      desc: data.description.trim() == "" ? null : data.description,
      isStackable: data.isStackable,
      errorRows: errorData,
      groupedSKU: groupedSKU,
    }
  }