import { useNavigate } from "react-router-dom";
import { currencyFormatter, dateFormatter } from "../../../Services/util";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";

const OrderTableDetailCell = ({ cell }) => {
  const navigate = useNavigate();
  const targetRO = cell?.row?.original?.ro_num;
  return (
    <div style={{ textAlign: "center", minWidth: 60 }}>
      <IconButton onClick={() => navigate(`${targetRO}/order_table`)}>
        <RemoveRedEyeIcon sx={{ color: "#bead7c", fontSize: "32px" }} />
      </IconButton>
    </div>
  );
};
//_______________________________________________________________________________________________

const orderTableColumns = [
  {
    Header: "",
    accessor: "POdetail",
    Cell: OrderTableDetailCell,
  },
  {
    Header: "Order Code",
    accessor: "ro_num",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Vendor",
    accessor: "vendor",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 350 }}>{row.value}</div>
    ),
  },
  {
    Header: "Version",
    accessor: "version",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 100 }}>{row.value}</div>
    ),
  },
  {
    Header: "Currency",
    accessor: "currency",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {row.value ?? ""}
      </div>
    ),
  },
  {
    Header: "Order Value",
    accessor: "value",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>
        {currencyFormatter(row.value, "$")}
      </div>
    ),
  },

  {
    Header: "Reason",
    accessor: "reason",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Updated At",
    accessor: "updated_on",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
  {
    Header: "Generated By",
    accessor: "generated_by",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Generated At",
    accessor: "generated_on",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
  {
    Header: "Approved By",
    accessor: "approved_by",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Approved At",
    accessor: "approved_on",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
  {
    Header: "Submitted By",
    accessor: "submitted_by",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Submitted At",
    accessor: "submitted_on",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
  {
    Header: "Reviewed By",
    accessor: "reviewed_by",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Reviewed At",
    accessor: "reviewed_on",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
  {
    Header: "Rejected By",
    accessor: "rejected_by",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Rejected At",
    accessor: "rejected_on",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
  {
    Header: "Created At",
    accessor: "created_at",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
];

export const getOrderTableColumns = ({ orderStatus }) => {
  let cols = [];

  switch (orderStatus) {
    case "procurement":
      cols = orderTableColumns.filter((row) => {
        return (
          row.Header !== "Rejected By" &&
          row.Header !== "Reason" &&
          row.Header !== "Submitted By" &&
          row.Header !== "Generated By" &&
          row.Header !== "Approved By" &&
          row.Header !== "Rejected At" &&
          row.Header !== "Submitted At" &&
          row.Header !== "Generated At" &&
          row.Header !== "Approved At"
        );
      });

      break;
    case "rereview":
      cols = orderTableColumns.filter((row) => {
        return (
          row.Header !== "Updated By" &&
          row.Header !== "Reviewed By" &&
          row.Header !== "Submitted By" &&
          row.Header !== "Generated By" &&
          row.Header !== "Approved By" &&
          row.Header !== "Updated At" &&
          row.Header !== "Reviewed At" &&
          row.Header !== "Submitted At" &&
          row.Header !== "Generated At" &&
          row.Header !== "Approved At"
        );
      });

      break;
    case "management":
      cols = orderTableColumns.filter((row) => {
        return (
          row.Header !== "Reason" &&
          row.Header !== "Updated By" &&
          row.Header !== "Rejected By" &&
          row.Header !== "Generated By" &&
          row.Header !== "Approved By" &&
          row.Header !== "Updated At" &&
          row.Header !== "Rejected At" &&
          row.Header !== "Generated At" &&
          row.Header !== "Approved At"
        );
      });

      break;
    case "proforma":
      cols = orderTableColumns.filter((row) => {
        return (
          row.Header !== "Updated By" &&
          row.Header !== "Reviewed By" &&
          row.Header !== "Submitted By" &&
          row.Header !== "Generated By" &&
          row.Header !== "Rejected By" &&
          row.Header !== "Reason" &&
          row.Header !== "Updated At" &&
          row.Header !== "Reviewed At" &&
          row.Header !== "Submitted At" &&
          row.Header !== "Generated At" &&
          row.Header !== "Rejected At"
        );
      });

      break;
    case "pogeneration":
      cols = orderTableColumns.filter((row) => {
        return (
          row.Header !== "Updated By" &&
          row.Header !== "Reviewed By" &&
          row.Header !== "Submitted By" &&
          row.Header !== "Reason" &&
          row.Header !== "Rejected By" &&
          row.Header !== "Updated At" &&
          row.Header !== "Reviewed At" &&
          row.Header !== "Submitted At" &&
          row.Header !== "Rejected At"
        );
      });

      break;
    case "postoreceive":
      cols = orderTableColumns.filter((row) => {
        return (
          row.Header !== "Updated By" &&
          row.Header !== "Reviewed By" &&
          row.Header !== "Submitted By" &&
          row.Header !== "Reason" &&
          row.Header !== "Rejected By" &&
          row.Header !== "Updated At" &&
          row.Header !== "Reviewed At" &&
          row.Header !== "Submitted At" &&
          row.Header !== "Rejected At"
        );
      });

      break;
    default:
      cols = orderTableColumns;
      break;
  }

  return cols;
};
