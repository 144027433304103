import React, { useMemo, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { InputAdornment, Button, TextField, Box } from "@mui/material";
import searchIcon from "../../../images/Search.svg";
import filterIcon from "../../../images/Filter.svg";
import { IconSVG } from "../../../Styles/styles";
import AddSharpIcon from "@mui/icons-material/AddSharp";

import { DataGrid } from "@mui/x-data-grid";

import { EVENT_TABLE_COLUMNS } from "./marketing.table.columns";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import useModal from "../../../Hooks/useModal";
import FilterEventModal from "./FilterEvent/FilterEventModal";

import { getEventsList } from "../../../Services/eventstable.service";
import { AlertContext } from "../../../App";

const DIV = styled.div`
  background: #ffffff;
  border-radius: 12px;
  margin: 20px;
  padding: 21px;
`;

const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
`;

const searchTextFieldStyle = {
  width: "300px",
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "& label.Mui-focused": {
    color: "#CFD3D5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CFD3D5",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CFD3D5",
    },
    "&:hover fieldset": {
      borderColor: "#CFD3D5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#bead7c",
    },
  },
};

const filterButtonStyle = {
  borderColor: "#53545C",
  color: "#53545C",
  margin: "0px 8px",
  fontSize: "11px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#53545C",
  },
};

const addButtonStyle = {
  color: "#000",
  fontSize: "11px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const datagridStyle = {
  width: "100%",
  marginTop: "22px",
  "& .MuiDataGrid-root": {
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6E7079",
  },
  "& .name-column--cell": {},
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#2C2D33",
    align: "center",
    justifyContent: "center",
  },

  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
  "& .MuiCheckbox-root": {},
  "& .MuiDataGrid-row": {},
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiCheckbox-root svg": {
    width: 24,
    height: 24,
    backgroundColor: "transparent",
    border: "1px solid #CFD3D5",
    borderRadius: "8px",
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#bead7c",
    borderColor: "#bead7c",
  },
};

const itemsPerPageList = [25, 50, 100];

const CampaignTable = ({ props }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const { campaignStatus } = useOutletContext();

  // Memoize the columns so that React doesn't keep rendering
  const columns = useMemo(() => EVENT_TABLE_COLUMNS, []);

  const { open, handleOpen, handleClose } = useModal();

  const navigate = useNavigate();
  const rLocation = useLocation();

  const [eventCount, setEventCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(itemsPerPageList[0]);

  const [searchKeyword, setSearchKeyword] = useState();

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);

  const [eventStatus, setEventStatus] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [location, setLocation] = useState();
  const [eventID, setEventId] = useState();

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setPage(0);
  }, [campaignStatus]);

  useEffect(() => {
    switch (campaignStatus) {
      case "active-events":
        handleOnEventTabChange("ACTIVE");
        setEventStatus("Active");
        break;
      case "upcoming-events":
        handleOnEventTabChange("UPCOMING");
        setEventStatus("Upcoming");
        break;
      case "completed-events":
        handleOnEventTabChange("COMPLETED");
        setEventStatus("Completed");
        break;
      default:
        setRows([]);
        setEventStatus("");
        break;
    }
  }, [
    campaignStatus,
    pageSize,
    page,
    searchKeyword,
    startDate,
    endDate,
    alertInfo,
    eventID,
    location,
  ]);

  const handleOnEventTabChange = (status) => {
    getEventsList(
      status,
      setRows,
      setLoading,
      setEventCount,
      page + 1,
      pageSize,
      location === undefined ? "" : location,
      startDate === undefined ? "" : startDate,
      endDate === undefined ? "" : endDate,
      searchKeyword === undefined ? "" : searchKeyword,
      eventID === undefined ? "" : eventID
    );
  };

  return (
    <DIV>
      <FilterEventModal
        open={open}
        handleClose={handleClose}
        setFilterStartDate={setStartDate}
        setFilterEndDate={setEndDate}
        setFilterLocation={setLocation}
        setFilterEventId={setEventId}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text>
          {eventCount} {eventStatus} Events
        </Text>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            sx={searchTextFieldStyle}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSVG src={searchIcon} />
                </InputAdornment>
              ),
              style: { height: "35px", fontWeight: 400, fontSize: "12px" },
            }}
            placeholder="Search Event"
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          />

          <Button
            variant="outlined"
            height="35px"
            startIcon={<IconSVG src={filterIcon} />}
            sx={filterButtonStyle}
            onClick={() => {
              handleOpen();
            }}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            height="35px"
            endIcon={<AddSharpIcon sx={{ fontSize: "8px" }} />}
            sx={addButtonStyle}
            onClick={() =>
              navigate(`/new-event`, { state: { from: rLocation } })
            }
          >
            Add Event
          </Button>
        </div>
      </div>

      <Box sx={datagridStyle}>
        <DataGrid
          autoHeight
          paginationMode="server"
          checkboxSelection={false}
          rows={rows}
          columns={columns}
          rowCount={eventCount}
          rowsPerPageOptions={itemsPerPageList}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          sx={{
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                "margin-top": "1em",
                "margin-bottom": "1em",
              },
          }}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          loading={loading}
        />
      </Box>
    </DIV>
  );
};

export default CampaignTable;
