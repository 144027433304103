import { useState, createContext } from "react";

export const SKUDBContext = createContext();

export const SKUDBProvider = ({ children }) => {
  const [refresher, setRefresher] = useState(0);
  const [instance, setInstance] = useState();

  return (
    <SKUDBContext.Provider
      value={(instance, setInstance, refresher, setRefresher)}
    >
      {children}
    </SKUDBContext.Provider>
  );
};
