import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { AlertContext } from "../../App";
import ReactSelect from "react-select";
import { getDashboardFiltersFieldsData } from "../../Services/services";
import {
  dashboardFilterStageToTagMapper,
  dashboardFilterTagToStageMapper,
} from "../../Pages/Dashboard/actiondashaboard.mapper";

const ActionDashboardFilterModal = ({ open, handleClose, setFilter }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const [stageList, setStageList] = useState([]);
  const [selectedStage, setSelectedStage] = useState();

  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);

  useEffect(() => {
    getDashboardFiltersFieldsData({
      setVendorList,
      setStageList,
      setTagList,
      setAlertInfo,
    });
  }, []);
  const vendorOptions = () => {
    return vendorList
      .map((vendor) => {
        return {
          value: vendor,
          label: vendor,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };

  const stageOptions = () => {
    return stageList
      .map((stage) => {
        return {
          value: stage,
          label: stage,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectStage = (e) => {
    setSelectedStage(e?.value);
    let sTags = dashboardFilterStageToTagMapper({
      stage: e?.value,
      options: tagList,
    });

    setSelectedTag(sTags);
  };

  const tagOptions = () => {
    return tagList
      .map((tag) => {
        return {
          value: tag,
          label: tag,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectTag = (e) => {
    if (e?.length === 0) {
      setSelectedTag([]);
    } else {
      let sTags;
      e?.map((s) => {
        setSelectedTag([s?.value]);
        sTags = s?.value;
      });
      let sStage = dashboardFilterTagToStageMapper({
        tag: sTags,
        options: stageList,
      });

      setSelectedStage(sStage);
    }
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label: selectedVendor,
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Stage
            </Typography>
            <ReactSelect
              value={
                selectedStage && {
                  value: selectedStage,
                  label: selectedStage,
                }
              }
              options={stageOptions()}
              onChange={handleSelectStage}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Tags
            </Typography>
            <ReactSelect
              value={selectedTag?.map(
                (e) =>
                  e && {
                    value: e,
                    label: e,
                  }
              )}
              isMulti
              options={tagOptions()}
              onChange={handleSelectTag}
              isClearable
            />
          </Box>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setSelectedVendor("");
              setSelectedStage("");
              setSelectedTag([]);
              setFilter("vendor", "");
              setFilter("stage", "");
              setFilter("tags", "");
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilter("vendor", selectedVendor);
              setFilter("stage", selectedStage);
              setFilter("tags", selectedTag);
              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default ActionDashboardFilterModal;
