import React, { useEffect, useState, useMemo, useContext } from "react";

import { storeLeadTimeData } from "../../../Services/UploadDataServices/leadtime.services";
import { LEADTIME_TABLE_COLUMNS } from "./leadtime.table.columns";

import { Box } from "@mui/material";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { AlertContext } from "../../../App";
import SFPTable from "../../../Components/Tables/SortFilterTable/SFPTable";
import { VendorLeadtimeFilterRow } from "../../../Components/Tables/SortFilterTable/FilterRows";

const LeadtimeTable = () => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [tableData, setTableData] = useState();
  const columns = useMemo(() => LEADTIME_TABLE_COLUMNS, []);

  useEffect(() => {
    storeLeadTimeData({ setTableData, setAlertInfo });
  }, []);

  return (
    <>
      {/* <pre>{JSON.stringify(tableData)}</pre> */}
      {tableData ? (
        <SFPTable
          tableInfo={{ data: tableData, columns }}
          filterObject={(instance) => (
            <VendorLeadtimeFilterRow instance={instance} />
          )}
          rowFunc={console.log}
        />
      ) : (
        <Box sx={{ height: 400 }}>
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        </Box>
      )}
    </>
  );
};

export default LeadtimeTable;
