import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  currentCyclePOAPI,
  optionManAPI,
  optionProAPI,
  optionVenAPI,
  prevCyclePOAPI,
} from "../Components/Services/apiURL";
import useAuth from "./useAuth";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const useCycle = () => {
  const params = useParams();

  const navigate = useNavigate();

  const auth = useAuth()[0];

  const [option, setOption] = useState(params.poid);
  const [options, setOptions] = useState();
  const [stage, setStage] = useState(capitalizeFirstLetter(params.page));
  const [stages, setStages] = useState([]);

  const handleStageChange = (e) => {
    setStage(e.target.value);
    navigate(e.target.value + "/" + option);
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
    navigate(stage + "/" + e.target.value);
  };

  useEffect(() => {
    switch (auth?.position) {
      case "procurement":
        setStages(["Procurement", "Management", "Proforma", "POGeneration", "POsToReceive"]);
        break;
      case "management":
        setStages(["Procurement", "Management", "Proforma", "POGeneration", "POsToReceive"]);
        break;
    }
    const optionAPI =
      stage === "Procurement"
        ? optionProAPI
        : stage === "Management"
        ? optionManAPI
        : stage === "Proforma"
        ? optionVenAPI
        : stage === "POGeneration"
        ? currentCyclePOAPI
        : prevCyclePOAPI;

    fetch(optionAPI, {
      headers: { Authorization: "Bearer " + auth.accessToken },
    })
      .then((res) => res.json())
      .then((data) => {
        const opts = data.map((PO) => PO.PONUMBER);
        setOptions(opts);
        // Checks if the next stage contains your current PONumber,
        // if it doesn't, it should redirect you to the first available option of the next stage
        if (!opts.includes(option)) {
          navigate(stage + "/" + opts[0]);
        }
        // Otherwise, it will redirect you to the current PONumber of the next stage
      });
  }, [stage, option]);

  useEffect(() => {
    setOption(params.poid);
  }, [params.poid]);

  return {
    option,
    options,
    stage,
    stages,
    setOptions,
    setStage,
    handleOptionChange,
    handleStageChange,
  };
};

export default useCycle;
