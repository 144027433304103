import { styled as MUIStyled } from "@mui/material/styles";
import { Switch } from "@mui/material";
// For Order Table
// _______________________________________________________

import useModal from "../../Hooks/useModal";
import SKUDBStatusToggleAlertModal from "../Modals/SKUDB.status.toggle.alert.modal";
import VendorsStatusToggleAlertModal from "../Modals/Vendors.status.toggle.alert.modal";
import { dateFormatter } from "../../Services/util";

export const RightAlignedCell = (props) => {
  return <div style={{ textAlign: "right" }}>{props.value}</div>;
};
// _______________________________________________________

// For history
// _______________________________________________________
export const colorableCell = (cell) => {
  const cellData = cell.value;
  const toColor = cellData.flag;

  return toColor === "true" ? (
    <div
      style={{
        margin: "0px",
        display: "flex",
        justifyContent: "space-around",
        textAlign: "right",
      }}
    >
      <p
        style={{
          display: "inline",
          textDecoration: "line-through",
          paddingBottom: 0,
          marginBottom: 0,
          textDecorationThickness: "1px",
          marginRight: "5px",
        }}
      >
        {cellData.value}
      </p>
      <p style={{ display: "inline", paddingBottom: 0, marginBottom: 0 }}>
        {cellData.new_value}
      </p>
    </div>
  ) : (
    <div style={{ textAlign: "right" }}>{cellData.value}</div>
  );
};
// _______________________________________________________

// For PODB
// _______________________________________________________
export const dateCell = (props) => {
  return (
    <div style={{ textAlign: "center", minWidth: 150 }}>
      {dateFormatter(props.value)}
    </div>
  );
};
// _______________________________________________________

// For SKUDB
// _______________________________________________________
const IOSSwitch = MUIStyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#bead7c" : "#bead7c",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export const ToggleSKUActiveCell = (props, setRefresher) => {
  const { open, handleOpen, handleClose } = useModal();
  const status = props.row.original.status;

  return (
    <>
      <SKUDBStatusToggleAlertModal
        open={open}
        handleClose={handleClose}
        props={props}
        setRefresher={setRefresher}
      />
      <IOSSwitch
        sx={{ m: 1, cursor: "pointer" }}
        checked={status === "active"}
        onChange={async (e) => {
          handleOpen();
        }}
      />
    </>
  );
};

// For VendorDB
// _______________________________________________________
export const ToggleVenActiveCell = (props, setRefresher) => {
  const { open, handleOpen, handleClose } = useModal();
  const isActive = props.row.original.active;

  return (
    <>
      <VendorsStatusToggleAlertModal
        open={open}
        handleClose={handleClose}
        props={props}
        setRefresher={setRefresher}
      />
      <IOSSwitch
        sx={{ cursor: "pointer" }}
        checked={isActive}
        onChange={async (e) => {
          handleOpen();
        }}
      />
    </>
    // <WhiteButton
    //   style={{ color: isActive ? "#9B9D3F" : "grey" }}
    //   onClick={async () => {
    //     await toggleVenActiveFunc(props);
    //     setRefresher((prev) => prev + 1);
    //   }}
    // >
    //   {isActive ? "Active" : "Inactive"}
    // </WhiteButton>
  );
};
