import React, {
  useEffect,
  createContext,
  useMemo,
  useState,
  Fragment,
  useRef,
  useContext,
} from "react";
import {
  UploadExcelToForecast,
  getForecastExcelTable,
  deleteForecastExcel,
  downloadForecastExcel,
} from "../../../Services/UploadDataServices/forecastExcel.services";

import { Header, PrimaryButton, PrimaryButton2 } from "../../../Styles/styles";
import { Box } from "@mui/material";

import ForecastExcelTable from "./ForecastExcelTable";
import { forecastExcelColumns } from "./forecastexcel.table.columns";

import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import LoadingButton from "@mui/lab/LoadingButton";

//MUI custome theme
import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";

import { AlertContext } from "../../../App";

const ForecastExcel = () => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [refreshIndicator, setRefreshIndicator] = useState(false);

  const [forecastExcelTableData, setForecastExcelTableData] = useState();
  const forecastExcelTableColumns = useMemo(() => forecastExcelColumns, []);

  //
  const hiddenInputRef = useRef(null);
  const handleUploadClick = (e) => {
    hiddenInputRef.current.click();
  };

  const handleOnFileUploaded = (e) => {
    UploadExcelToForecast({
      e,
      setIsUploading,
      setAlertInfo,
      setRefreshIndicator,
    });
  };

  const handleDownloadExcel = (name) => {
    // console.log("Download Button Clicked!", name);
    downloadForecastExcel({
      filename: name,
      setAlertInfo,
      setIsDownloading,
    });
  };

  const handleDeleteExcel = (name) => {
    // console.log("Delete Button Clicked!", name);
    deleteForecastExcel({
      filename: name,
      setAlertInfo,
      setIsDeleting,
      setRefreshIndicator,
    });
  };

  useEffect(() => {
    getForecastExcelTable({ setForecastExcelTableData });
    // setForecastExcelTableData([
    //     {
    //         month: "Sep 2022",
    //         name: "Aug22_till_Mar_manipulatedOrder.xlsx",
    //         upload_time: "2022-09-15T17:07:30.869447",
    //     },
    // ]);
  }, [refreshIndicator]);

  const buttonDefaultStyling = {
    sx: {
      ml: 1,
    },
    size: "large",
    variant: "contained",
  };
  // console.log("forecastExcelTableData: ", forecastExcelTableData);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header>Forecast Excel</Header>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            ref={hiddenInputRef}
            onChange={handleOnFileUploaded}
            hidden
          />
          {/* <PrimaryButton2 onClick={handleUploadClick}>
                        Upload
                    </PrimaryButton2> */}
          <ThemeProvider theme={customMUITheme}>
            <LoadingButton
              {...buttonDefaultStyling}
              color="generate"
              onClick={handleUploadClick}
              loading={isUploading}
            >
              Upload
            </LoadingButton>
          </ThemeProvider>
        </Box>
      </div>
      <hr />
      {/* <pre>{JSON.stringify(tableData)}</pre> */}
      {forecastExcelTableData ? (
        <ForecastExcelTable
          forecastExcelTableColumns={forecastExcelTableColumns}
          forecastExcelTableData={forecastExcelTableData}
          handleDownloadExcel={handleDownloadExcel}
          handleDeleteExcel={handleDeleteExcel}
        />
      ) : (
        <Box sx={{ height: 400 }}>
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        </Box>
      )}
    </>
  );
};

export default ForecastExcel;
