import React, { useState } from "react";
import { Field } from "../../../Styles/styles";

// HOF that takes in a custom function - updateRow that dictates how the row should be updated
const EditableCell = ({ updateRow, editableKeyToFocus }) => {
  return ({ cell: { value }, row: { index }, column: { id } }) => {
    const key = index + id;

    const onChange = (e) => {
      editableKeyToFocus.current = key;
      const currValue = e.target.value;
      updateRow(index, id, currValue);
    };

    return (
      <Field
        key={key}
        value={value}
        onChange={onChange}
        autoFocus={key === editableKeyToFocus.current}
      />
    );
  };
};

export default EditableCell;
