import React from "react";
import { IconButton } from "@mui/material";
import editIcon from "../../../images/Edit.svg";
import { SVG } from "../../../Styles/styles";
import EditIncentivePlanModalWrapper from "./EditIncentivePlan/EditIncentivePlanModalWrapper";
import useModal from "../../../Hooks/useModal";

const EditButton = ({ id }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <EditIncentivePlanModalWrapper
        open={open}
        handleClose={handleClose}
        id={id}
      />
      <IconButton onClick={handleOpen}>
        <SVG src={editIcon} />
      </IconButton>
    </>
  );
};

export default EditButton;
