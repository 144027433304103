import React, { useContext } from "react";

import RowExpandTable from "../../../Components/Tables/RowExpandTable";
import { ButtonRow, SecondaryButton, SubHeader } from "../../../Styles/styles";
import { ContainerContext } from "../../../Contexts/container.context";

import SwapModal from "../../../Components/Modals/Container.swap.modal";
import useModal from "../../../Hooks/useModal";
// Invoice Related
import InvoiceGeneration from "../../../Components/Modals/Invoice.modal";
import { generatePO } from "../../../Services/invoice.services";
// Invoice Related

import Alerts from "../../../Components/Modals/Alert";
import GeneratePOModal from "../../../Components/Modals/GeneratePO.modal";
import SwitchConSizeModal from "../../../Components/Modals/SwitchConSizeModal";

const ContainerTable = () => {
  const {
    containers,
    containersArrayIndex,
    containerContent,
    columns,
    handleSave,
    modalFunctions,
    page,
  } = useContext(ContainerContext);
  const data = containerContent;

  const invoiceModalFunctions = useModal();
  const handleInvoice = () => {
    const { handleOpen } = invoiceModalFunctions;
    handleOpen();
  };

  const generatePOModalFunctions = useModal();
  const handleGenerate = () => {
    const { handleOpen } = generatePOModalFunctions;
    handleOpen();
  };

  const switchConSizeModalFunctions = useModal();
  const handleSwitchSize = () => {
    const { handleOpen } = switchConSizeModalFunctions;
    handleOpen();
  }

  return (
    <>
      {containers && (
        <>
          <SwapModal
            modalFunctions={modalFunctions}
            containers={containers}
            containersArrayIndex={containersArrayIndex}
          />
          <InvoiceGeneration
            modalFunctions={invoiceModalFunctions}
            containers={containers}
            containersArrayIndex={containersArrayIndex}
          />
          <GeneratePOModal
            modalFunctions={generatePOModalFunctions}
            containers={containers}
            containersArrayIndex={containersArrayIndex}
          />
          <SwitchConSizeModal 
            modalFunctions={switchConSizeModalFunctions}
            containers={containers}
            containersArrayIndex={containersArrayIndex}            
          />
        </>
      )}
      <div style={{ maxWidth: "200vh", overflowX: "scroll" }}>
        {data && columns && (
          <>
            <div style={{display: "flex", justifyContent:"space-between"}}> 
            <SubHeader>
              Viewing Container:
              <span style={{ textDecoration: "underline" }}>
                {containers[containersArrayIndex]?.con_id}
              </span>
            </SubHeader>

              <SecondaryButton onClick={handleSwitchSize} style={{marginBottom: '5px'}} className="hoverSecWhiteButton"> 
                Swap Container Size 
              </SecondaryButton>
            </div>
            <RowExpandTable
              data={data}
              columns={columns}
              rowFunc={console.log}
            />
          </>
        )}
      </div>
      <ButtonRow>
        {page === "Procurement" || page === "Management" ? (
          <Alerts
            buttonText={"Save"}
            alertMessage={"Draft Saved Successfully"}
            styling={"secondary"}
            addFunc={handleSave}
          />
        ) : page === "Proforma" ? (
          <>
            <Alerts
              buttonText={"Save"}
              alertMessage={"Draft Saved Successfully"}
              styling={"secondary"}
              addFunc={handleSave}
            />

            <SecondaryButton
              style={{ marginRight: "10px" }}
              onClick={handleInvoice}
            >
              Download Table
            </SecondaryButton>

            <SecondaryButton
              style={{ marginRight: "10px" }}
              onClick={handleGenerate}
            >
              Generate PO
            </SecondaryButton>
          </>
        ) : (
          ""
        )}
      </ButtonRow>
    </>
  );
};

export default ContainerTable;
