import React, { useState } from 'react';
import { Card } from "@mui/material";
import { DashboardButton } from "../../Styles/styles";
import Revenue from '../../Components/Charts/RevenueChart';

const RevenueDashboard=({ 
    year, 
    revYearTopData, revTopLabels,
    revYearBotData, revBotLabels, 
    revPrevYearTopData, revPrevTopLabels, 
    revPrevYearBotData, revPrevBotLabels })=> {
    const [currYearData, setCurrYearData] = useState(revYearTopData);
    const [currLabelData, setCurrLabelData] = useState(revTopLabels);
    const [prevYearData, setPrevYearData] = useState(revPrevYearTopData);
    const [prevLabelData, setPrevLabelData] = useState(revPrevTopLabels);

    const top5CurrYear = () => {
        setCurrYearData(revYearTopData);
        setCurrLabelData(revTopLabels);
        };
    const bot5CurrYear = () => {
        setCurrYearData(revYearBotData);
        setCurrLabelData(revBotLabels);
        };

    const top5PrevYear = () => {
        setPrevYearData(revPrevYearTopData);
        setPrevLabelData(revPrevTopLabels);
        };
    const bot5PrevYear = () => {
        setPrevYearData(revPrevYearBotData);
        setPrevLabelData(revPrevBotLabels);
        };

    return(
        <>  
            <div style={{padding: '3px', textAlign: 'justify', fontSize: '14px'}}>
               *The chart displays the top and bottom 5 products across all stores, physical, online and events by total sales value. Click on the 'Top 5' and 'Bottom 5' buttons to toggle information. Hover over the chart for more information.
           </div>
            <div className="d-flex flex-inline justify-content-between">
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5CurrYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5CurrYear}>
                        Bottom 5
                    </DashboardButton>
                    <Revenue year={year} dataYear={currYearData} labelsYear={currLabelData} type={"All"}/>
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Revenue year={year-1} dataYear={prevYearData} labelsYear={prevLabelData} type={"All"}/>}
                </Card>
            </div>
            <div className="d-flex flex-inline justify-content-between">
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5 
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Revenue year={year} dataYear={prevYearData} labelsYear={prevLabelData} type={"Physical"}/>}
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Revenue year={year} dataYear={prevYearData} labelsYear={prevLabelData} type={"Online"}/>}
                </Card>
                <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}> 
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Revenue year={year} dataYear={prevYearData} labelsYear={prevLabelData} type={"Events"}/>}
                </Card>
            </div>
        </>
    );
};

export default RevenueDashboard;
