import { Routes, Route } from "react-router-dom";
import React, { useState, createContext } from "react";

import Login from "./Pages/Login/Login";
import RequireAuth from "./Components/Services/RequireAuth";
import Layout from "./Layout";

import Campaign from "./Pages/UsefulLinks/Marketing Campaign/Campaign";

// ___________________________________________________________________________
import Cycle from "./Pages/Process/Cycle";
import CommonPage from "./Pages/Process/CommonPage";
import Containers from "./Pages/Process/Containers/Containers";
// ___________________________________________________________________________

import MachineLearningForecast from "./Pages/UsefulLinks/ML/MachineLearningForecast";
import ForecastExcel from "./Pages/UsefulLinks/ML/ForecastExcel";

// ___________________________________________________________________________
import PODB from "./Pages/UsefulLinks/Database/PODB";
import SKUDB from "./Pages/UsefulLinks/Database/SKUDB";
import VendorDB from "./Pages/UsefulLinks/Database/VendorDB";

// ___________________________________________________________________________
import ManagementDashboard from "./Pages/ManagementDashboard/ManagementDashboard";
import {
  PerformanceData,
  RevenueData,
  ProductivityData,
  BusynessData,
  PopularityData,
} from "./Pages/ManagementDashboard/DataDashboard";
import TurnoverChart from "./Pages/ManagementDashboard/TurnoverChart/TurnoverChart";
import ProcurementChart from "./Pages/ManagementDashboard/ProcurementChart/ProcurementChart";
import VendorLeadtime from "./Pages/UsefulLinks/Vendor Leadtime/vendor.leadtime";
import OutstandingROChart from "./Pages/ManagementDashboard/OutstandingROChart/OutstandingROChart";
import ActionDashboardTest from "./Pages/Dashboard/new.actiondashboard";

// ___________________________________________________________________________
import Orders from "./Pages/Orders/Orders";

import OrderTable from "./Pages/Orders/2.CommonChildren/1.OrderTable";
import TestContainers from "./Pages/Orders/2.CommonChildren/2.Containers";
import History from "./Pages/Orders/2.CommonChildren/4.History";
import OrderLater from "./Pages/Orders/2.CommonChildren/3.OrderLater";
import OrderStatus from "./Pages/Orders/1.OrderStatus/0.OrderStatus";
import OrderStatusType from "./Pages/Orders/1.OrderStatus/OrderStatusType";
import OrderStatusTypeRender from "./Pages/Orders/2.CommonChildren/OrderStatusTypeRender";
import OrderTableDetail from "./Pages/Orders/2.CommonChildren/1.OrderTableDetail";
// ___________________________________________________________________________

//Alert
import CustomizedSnackbars from "./Components/Snackbar";
import CampaignStatus from "./Pages/UsefulLinks/Marketing Campaign/CampaignStatus";
import CampaignTable from "./Pages/UsefulLinks/Marketing Campaign/Table";
import AddEvent from "./Pages/UsefulLinks/Marketing Campaign/AddEvent/AddEvent";
import ReviewEvent from "./Pages/UsefulLinks/Marketing Campaign/ReviewEvent/ReviewEvent";
import IncentivePlan from "./Pages/UsefulLinks/Incentive Plan/IncentivePlan";
import EditEvent from "./Pages/UsefulLinks/Marketing Campaign/EditEvent/EditEvent";
import SafetyStock from "./Pages/UsefulLinks/Safety Stock/SafetyStock";
import SafetyStockSkuStatus from "./Pages/UsefulLinks/Safety Stock/SafetyStockSkuStatus";
import SafetyStockTable from "./Pages/UsefulLinks/Safety Stock/SafetyStockTable";
import { dateFormatter } from "./Services/util";

export const ActionDashboardContext = createContext({}); //ALERT
export const AlertContext = createContext({});

function App() {
  const [actionDashboardData, setActionDashboardData] = useState(); // ACTION BOARD DATA
  const [actionDashboardFilteredData, setActionDashboardFilteredData] =
    useState();

  //Context - values to be pass
  const setActionBoardContextValue = {
    actionDashboardData,
    setActionDashboardData,
    actionDashboardFilteredData,
    setActionDashboardFilteredData,
  };

  //ALERT
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    severity: "",
    msg: "",
  });
  //Context - values to be pass
  const AlertProviderValue = {
    alertInfo,
    setAlertInfo,
  };

  return (
    <ActionDashboardContext.Provider value={setActionBoardContextValue}>
      <AlertContext.Provider value={AlertProviderValue}>
        <CustomizedSnackbars
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              element={
                <RequireAuth
                  allowedPosition={["procurement", "management", "admin"]}
                />
              }
            >
              <Route element={<Layout />}>
                <Route path="/" element={<ActionDashboardTest />} />

                {/* Orders start */}
                <Route
                  path="/orders"
                  element={
                    <Orders
                      lastForecasted={
                        actionDashboardData?.last_forecasted
                          ? dateFormatter(actionDashboardData?.last_forecasted)
                          : ""
                      }
                    />
                  }
                >
                  {/* Procurement, Re-view, Management, Proforma, PO Generation, POs to Receive */}
                  <Route path=":orderStatus" element={<OrderStatus />}>
                    {/* This is the table to have a general view */}
                    <Route
                      path=""
                      element={
                        <>
                          <OrderTable
                            actionDashboardData={actionDashboardData}
                          />
                        </>
                      }
                    />

                    {/* Example: AT-7-2022 */}
                    <Route path=":ro_num" element={<OrderTableDetail />}>
                      {/* Order Table, Backlog, History */}
                      <Route
                        path=":orderStatusType"
                        element={<OrderStatusType />}
                      >
                        <Route path="" element={<OrderStatusTypeRender />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                {/* Orders end */}

                <Route path="/cycle" element={<Cycle />}>
                  <Route path=":page/:poid" element={<CommonPage />}></Route>
                </Route>
                <Route path="/poDB" element={<PODB />} />
                <Route path="/skuDB" element={<SKUDB />} />
                <Route path="/vendorDB" element={<VendorDB />} />

                {/* Marketing Campaign = Events Start */}
                <Route path="/marketingCampaign" element={<Campaign />}>
                  <Route path=":campaignStatus" element={<CampaignStatus />}>
                    <Route
                      path=""
                      element={
                        <>
                          <CampaignTable />
                        </>
                      }
                    />
                  </Route>
                </Route>
                <Route path="/new-event" element={<AddEvent />} />
                <Route path="/edit-event/:eventId" element={<EditEvent />} />
                <Route path="/reviewEvent" element={<ReviewEvent />} />
                <Route path="/vendor-leadtime" element={<VendorLeadtime />} />
                <Route path="/forecast_excel" element={<ForecastExcel />} />
                <Route path="/incentivePlan" element={<IncentivePlan />} />
                {/* Safety Stock Start */}
                <Route path="/safety-stock" element={<SafetyStock />}>
                  <Route
                    path=":safetyStockStatus"
                    element={<SafetyStockSkuStatus />}
                  >
                    <Route
                      path=""
                      element={
                        <>
                          <SafetyStockTable />
                        </>
                      }
                    />
                  </Route>
                </Route>
                <Route path="/mlforecast" element={<MachineLearningForecast />}>
                  <Route path=":skuID/:vendorID" />
                </Route>
                <Route
                  path="/ManagementDashboard"
                  element={<ManagementDashboard />}
                >
                  <Route
                    path="Performance"
                    element={<PerformanceData />}
                  ></Route>
                  <Route path="Revenue" element={<RevenueData />}></Route>
                  <Route
                    path="Productivity"
                    element={<ProductivityData />}
                  ></Route>
                  <Route path="Busyness" element={<BusynessData />}></Route>
                  <Route path="Popularity" element={<PopularityData />}></Route>
                  <Route path="Turnover" element={<TurnoverChart />} />
                  <Route path="Procurement" element={<ProcurementChart />} />
                  <Route path="Outstanding" element={<OutstandingROChart />} />
                </Route>
                <Route path="/containers/:poid" element={<Containers />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </AlertContext.Provider>
    </ActionDashboardContext.Provider>
  );
}

export default App;
