import React, { useState, useEffect } from "react";
import Select from "react-select";

import { newModalStyle, SecondaryButton } from "../../Styles/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { swapConSize } from "../../Services/containers.services";

const SwitchConSizeModal = ({
  modalFunctions,
  containers,
  containersArrayIndex,
}) => {
  const currentContainer = containers[containersArrayIndex];
  const currentContainerSize = currentContainer?.size;

  // For handling Modal Open/Close
  const { open, handleClose } = modalFunctions;

  const sizes = ["20", "40", "40hc"];

  const targetOptionsList = sizes
    // All containers in this PO except currently selected container
    .filter((size) => size !== currentContainerSize)
    .map((size) => ({
      label: size,
      value: size,
    }));

  const [targetOption, setTargetOption] = useState(undefined);
  useEffect(() => {
    setTargetOption(undefined);
  }, [containersArrayIndex]);
  const handleTargetChange = (selectedOption) => {
    setTargetOption(selectedOption);
  };

  // For target container

  useEffect(() => {}, [currentContainer, targetOption]);

  const handleSubmit = () => {
    const info = {
      conid: currentContainer?.con_id,
      to: targetOption?.value,
    };

    swapConSize(info).then(() => window.location.reload());
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={newModalStyle}>
        <h1>Swap Container Size</h1>

        <h3>{`Current Container ID: ${currentContainer?.con_id}`}</h3>
        <Select
          defaultValue={targetOption}
          onChange={handleTargetChange}
          options={targetOptionsList}
        />
        {targetOption && (
          <SecondaryButton onClick={handleSubmit}>Submit</SecondaryButton>
        )}
      </Box>
    </Modal>
  );
};

export default SwitchConSizeModal;
