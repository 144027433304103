import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import useModal from "../../Hooks/useModal";
import SKUDescriptionModal from "../Modals/SKUDescriptionModal";

ChartJS.register(
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Popularity = ({year, dataYear, labelsYear, skuData, skuLabel, SKUID}) => {
  const { open, handleOpen, handleClose } = useModal();
  const options = {
    responsive: true,
    indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Popularity Of Products",
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "SKU ID",
        },
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Popularity of Products " + year ,
      },
      tooltip: {
        callbacks: {
            label: function(context) {
                //console.log(context)
                    return `Order Value: ${context.formattedValue}`;
            },
          },
        },
    },
    /*{onClick: function (evt, activeEls) {
      console.log(evt)
      if (activeEls.length === 0) { 
        return(
        <Modal>
          <Box sx={style}>
            <h1>SKU Description</h1>
                <SKUDesc/>
          </Box>
        </Modal>
        );
      }
    }*/
  };
  
  const data = {
      labels: labelsYear,
      datasets: [
        {
          label: "Popularity Of Products", /* how to disable hide button? */
          data: dataYear,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 159, 64, 1)",,
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
          ],
          borderWidth: 1,
        },
        // {
        //   label: "2021 Revenue",
        //   data: [100, 90, 87, 50, 60, 101],
        //   backgroundColor: [
        //     "rgba(54, 162, 235, 0.2)",
        //   ],
        //   borderColor: [
        //     "rgba(54, 162, 235, 1)",
        //   ],
        //   borderWidth: 1,
        // },
      ],
    };
  return (
    <>
      <Bar style={{ cursor: "pointer" }} onClick={handleOpen} data={data} options={options}
       />
      <SKUDescriptionModal year={year} open={open}  dataYear={skuData} labelsYear={skuLabel} SKUID={SKUID} handleClose={handleClose}/>
    </>
  );
};

export default Popularity;
