import { Modal } from "@mui/material";
import DeletePlanModal from "./DeletePlanModal";

const DeletePlanModalWrapper = ({ open, handleClose, id }) => {
  return (
    <Modal open={open} closeOnOverlayClick={false}>
      <DeletePlanModal id={id} handleClose={handleClose} />
    </Modal>
  );
};

export default DeletePlanModalWrapper;
