import React, { useContext } from "react";
import { SetContainerNPOContext } from "./OrderStatusTypeRender"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING
import { useParams } from "react-router-dom";

import { Card, CardContent, CardActions, Button } from "@mui/material";

import { Paragraph } from "../../../Styles/styles";
import { deleteContainer } from "../../../Services/ordertable.service";

import { AlertContext } from "../../../App";

export const OrderStatusTypeContainerCard = ({
  containerInfo,
  containersInfo, //to add or delete the master containers state
  viewActive,
  setViewActive,
  setIsLoading,
}) => {
  const { orderStatus, ro_num, orderStatusType } = useParams();
  const viewOnlyTab =
    orderStatus === "pogeneration" || orderStatus === "postoreceive";
  const { setPOInfo, setContainersInfo, setMasterPOInfo } = useContext(
    SetContainerNPOContext
  );
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  //individual container
  const {
    Containernumber,
    con_id,
    capacity,
    size,
    volume,
    volume_left,
    generated,
  } = containerInfo;

  const handleDelete = () => {
    deleteContainer(
      orderStatus,
      con_id,
      ro_num,
      setContainersInfo,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo
    );
  };

  const handleView = () => {
    if (viewActive.containerUniqueInfo == con_id) {
      setViewActive({
        containerUniqueInfo: "",
        active: !viewActive.active,
      });
    } else {
      setViewActive({
        containerUniqueInfo: con_id,
        active: true,
      });
    }
  };

  const opacity = () => {
    return generated ? 0.7 : 1;
  };

  const isOccupy = capacity !== 0;

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#e6d5a3",
          opacity: opacity(),
          display: "block",
          transitionDuration: "0.3s",
          height: "150px",
          width: "inherit",
        }}
      >
        <CardContent sx={{ padding: 1, paddingBottom: 0 }}>
          <Paragraph>{`${Containernumber} - ${con_id}`} </Paragraph>
          <h5>
            <b>{size}</b>
          </h5>
          {/* <Paragraph>{Math.round(capacity * 100, 2)}%</Paragraph> */}
          {/* <Paragraph>{volume} mᶟ</Paragraph> */}
          <Paragraph>
            {(volume - volume_left).toFixed(2)} / {volume.toFixed(2)} m
            <sup>3</sup> ({Math.round(capacity * 100, 2)}%)
          </Paragraph>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "space-around",
            p: 0,
            m: 0,
            pt: -1,
            pl: 1,
            pr: 1,
          }}
        >
          {/* view button style changing based on selected */}
          {viewActive.active && viewActive.containerUniqueInfo === con_id ? (
            <Button
              color="info"
              variant="contained"
              size="medium"
              sx={{
                background: "#485202",
                ":hover": { background: "#485202" },
              }}
              onClick={() => {
                handleView();
              }}
            >
              Selected
            </Button>
          ) : (
            <Button
              color="info"
              variant="outlined"
              size="medium"
              sx={{
                color: "#485202",
                borderColor: "#485202",
                ":hover": { color: "#485202", borderColor: "#485202" },
              }}
              onClick={() => {
                handleView();
              }}
            >
              View
            </Button>
          )}
          <Button
            color="error"
            variant="outlined"
            size="medium"
            onClick={() => {
              handleDelete();
            }}
            disabled={viewOnlyTab || generated || isOccupy}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default OrderStatusTypeContainerCard;
