import React from "react";

// Styles and Icon
import { ContainerCard, SVG } from "../../../Styles/styles";
import trashcan from "../../../images/trashcan.svg";
import { useParams } from "react-router-dom";
import { deleteContainer } from "../../../Services/containers.services";
import { useContext } from "react";
import { ContainerContext } from "../../../Contexts/container.context";
// Styles and Icon

const Container = ({ container, handleClick }) => {
  const capacity = Math.round(container?.capacity * 100);
  const isGenerated = container?.generated;
  const isReceived = container?.received;

  const { setRefresher, containersIndexClicked } = useContext(ContainerContext);

  const handleDelete = async () => {
    // console.log({ container });
    await deleteContainer({ containerID: container?.con_id });
    setRefresher((prev) => prev + 1);
  };

  const { page } = useParams();
  return (
    <>
      {page === "Proforma" && isGenerated ? (
        <ContainerCard
          style={{
            cursor: "pointer",
            backgroundColor: "#B4D3B2",
          }}
          onClick={handleClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ display: "inline" }}>
              Container ID: {container.con_id}
            </h4>
            <SVG src={trashcan} onClick={handleDelete} />
          </div>
          <h4>Container Type: {container.size} ft</h4>
          <p>Percent Filled: {capacity}%</p>
          <p>Container Volume Left (m³): {container.volume_left}</p>
        </ContainerCard>
      ) : (page === "POGeneration" || page === "POsToReceive") && isReceived ? (
        <ContainerCard
          style={{
            cursor: "pointer",
            backgroundColor: "#B4D3B2",
          }}
          onClick={handleClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ display: "inline" }}>
              Container ID: {container.con_id}
            </h4>
            <SVG src={trashcan} />
          </div>
          <h4>Container Type: {container.size} ft</h4>
          <p>Percent Filled: {capacity}%</p>
          <p>Container Volume Left (m³): {container.volume_left}</p>
        </ContainerCard>
      ) : (
        <ContainerCard
          style={{
            cursor: "pointer",
            backgroundColor:
              containersIndexClicked === container?.con_id ? "#fff7e4" : "",
          }}
          onClick={handleClick}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ display: "inline" }}>
              Container ID: {container.con_id}
            </h4>
            <SVG src={trashcan} onClick={handleDelete} />
          </div>
          <h4>Container Type: {container.size} ft</h4>
          <p>Percent Filled: {capacity}%</p>
          <p>Container Volume Left (m³): {container.volume_left}</p>
        </ContainerCard>
      )}
    </>
  );
};

export default Container;
