import React, { useState, useEffect, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Icon,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Popper,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

import OrderStatusTypeTable from "./OrderStatusTypeTable";
import OrderTableContainers from "./OrderTableContainers";
import OrderStatusTypeButton from "./OrderStatusTypeButton";
import OrderStatusTypeHistorySelectPO from "./OrderStatusTypeHistorySelectPO";
import OrderStatusTypeTableDataVerify from "./OrderStatusTypeTableDataVerify";
import OrderStatusTypeIconButton from "./OrderStatusTypeIconButton";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { useMockDataTable } from "../2.CommonChildrenHook/useMockDataTable";
import { procurementOrdertableColumns } from "../4.TableColumns/procurement_ordertable_columns";
import { orderStatusHistoryColumns } from "../4.TableColumns/order_status_history_columns";

//Modal
import useModal from "../../../Hooks/useModal";
import OrderStatusTypeInsertInactiveSKUModal from "./OrderStatusTypeInsertInactiveSKUModal";

//ALERT
import { AlertContext } from "../../../App";

//API
import {
  getPODetails,
  getContainerDetails,
  getContainerOptions,
  backlogPushPullDelete,
  getCompareHistoryList,
  updatePoReviewStatus,
} from "../../../Services/ordertable.service";

//Auth
import { getUser } from "../../../Services/auth.service";
import { Check } from "@mui/icons-material";
import { dateFormatter } from "../../../Services/util";
import { orderTableMoreInfo } from "../order.utils";

//create context for
export const SetContainerNPOContext = createContext({});
export const SelectedContainerContext = createContext({});

const OrderStatusTypeRender = (props) => {
  //ALERT
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  //Position
  const position = getUser()?.position; //procurement && management

  // const { orderStatusType } = useOutletContext(); //Order Table, Backlog, History
  const { orderStatus, ro_num, orderStatusType } = useParams();
  const viewOnlyTab =
    orderStatus === "pogeneration" || orderStatus === "postoreceive";

  // --- TABLE RELATED ---

  const [POInfo, setPOInfo] = useState({}); // all changes happen here - For Order Table
  const [masterPOInfo, setMasterPOInfo] = useState({}); // when wanna put request, compare both two. - For Order Table
  const [backlogPOInfo, setBacklogPOInfo] = useState({}); // For Backlog table
  const [comparePOInfo, setComparePOInfo] = useState({}); // For History table

  const [isLoading, setIsLoading] = useState({
    tableLoading: false,
    cardLoading: false,
    poUpdating: false,
    containerOptionsLoading: false,
    creatingContainer: false,
    deletingContainer: false,
    optimizingContainer: false,
    sendingToManagement: false,
    approvingPO: false,
    rejectingPO: false,
    generatingPO: false,
    downloadingRO: false,
    backlogging: false,
    backlogUpdating: false,
    comparingHistory: false,
    gettingPOToCompare: false,
    gettingSKUModalDetail: false,
    isSplittingContainer: false,
  });
  const [isAbleToStage, setIsAbleToStage] = useState(true); //Is able to send,approve, reject

  const [POToCompareUserChoice, setPOToCompareUserChoice] = useState({
    //keep track of selected PO to compare
    ro_num: ro_num,
    firstPO: "", //status, version, record
    secondPO: "",
  });

  //keep track of PO available to compare.
  const [POToCompareList, setPOToCompareList] = useState([]);

  useEffect(() => {
    getCompareHistoryList(
      orderStatus,
      orderStatusType,
      ro_num,
      setPOToCompareList,
      setIsLoading,
      setAlertInfo
    );
  }, [orderStatusType]);

  const orderTableColumns = procurementOrdertableColumns.filter((row) => {
    return !(row.Header === "Delete");
  }); // ordertable
  const backlogTableColumns = procurementOrdertableColumns.filter((row) => {
    return !(row.Header === "Container");
  });

  const historyTableColumns = orderStatusHistoryColumns;

  let tableColumns = () => {
    switch (orderStatusType) {
      case "order_table":
        return orderTableColumns;
      case "backlog":
        return backlogTableColumns;
      case "history":
        return historyTableColumns;
      default:
        return backlogTableColumns;
    }
  };

  let finalPOInfo = () => {
    switch (orderStatusType) {
      case "order_table":
        return POInfo;
      case "backlog":
        return backlogPOInfo;
      case "history":
        return comparePOInfo;
      default:
        return POInfo;
    }
  };

  //card view filter - getcontainerpro api - Containernumber
  const [viewActive, setViewActive] = useState({
    containerUniqueInfo: "",
    active: false,
  });

  //Edit button - 3 stuffs - promo, reple, remark
  const updateUserEdit = (sku, country, columnId, value, orderStatusType) => {
    const updatedUserEditData = (prevData) => {
      return prevData.POdetail.map((row, index) => {
        //row is data itself, index is index

        if (row.SKU === sku && row.country === country) {
          //promo or demand
          if (columnId === "promo_qty") {
            value = Number(value);
            const newTotalQty = () => {
              if (Number(row.demand_qty) === -1) {
                //no forecast from demand_qty, don't affect it.
                return Number(value);
              } else {
                return Number(row.demand_qty) + value;
              }
            };
            return {
              ...row,
              [columnId]: Number(value),
              total_qty_ordered: newTotalQty(),
            };
          }

          if (columnId === "demand_qty") {
            value = Number(value);
            const newTotalQty = () => {
              if (value === -1) {
                //no forecast from demand_qty, don't affect it.
                return Number(row.promo_qty);
              } else {
                return Number(row.promo_qty) + value;
              }
            };

            return {
              ...row,
              [columnId]: Number(value),
              total_qty_ordered: newTotalQty(),
            };
          }

          //handle other then reple + promo
          return {
            ...row,
            [columnId]: value,
          };

          // return {
          //     ...prevData[rowIndex],
          //     [container]: {
          //         ...prevData[rowIndex].container,
          //         [poNumber]: value,
          //     },
          // };
        }
        return row;
      });
    };

    // setPOInfo((prev) => ({
    //     ...prev,
    //     POdetail: updatedUserEditData(prev),
    // }));
    if (orderStatusType === "order_table") {
      setMasterPOInfo((prev) => ({
        ...prev,
        POdetail: updatedUserEditData(prev),
      }));
    }

    if (orderStatusType === "backlog") {
      setBacklogPOInfo((prev) => ({
        ...prev,
        POdetail: updatedUserEditData(prev),
      }));
    }

    setIsAbleToStage(false);
  };

  const cancelEdit = (initialTable, orderStatusType) => {
    //Continue here.

    const resetData = (prevData) => {
      return prevData.POdetail.map((row, index) => {
        if (
          row.SKU === initialTable.SKU &&
          row.country === initialTable.country
        ) {
          const returnValue = {
            ...row,
            demand_qty: initialTable.demand_qty,
            promo_qty: initialTable.promo_qty,
            total_qty_ordered: initialTable.total_qty_ordered,
            remark: initialTable.remark,
            container: initialTable.container,
          };

          return {
            ...row,
            demand_qty: initialTable.demand_qty,
            promo_qty: initialTable.promo_qty,
            total_qty_ordered: initialTable.total_qty_ordered,
            remark: initialTable.remark,
            container: initialTable.container,
          };
        }
        return row;
      });
    };

    // setMasterPOInfo((prev) => {
    //     console.log("this is what set return: ", resetData(prev));
    //     return {
    //         ...prev,
    //         POdetail: resetData(prev),
    //     };
    // });
    if (orderStatusType === "order_table") {
      setMasterPOInfo((prev) => ({
        ...prev,
        POdetail: resetData(prev),
      }));
    }

    if (orderStatusType === "backlog") {
      setBacklogPOInfo((prev) => ({
        ...prev,
        POdetail: resetData(prev),
      }));
    }

    // setPOInfo((prev) => ({
    //     ...prev,
    //     POdetail: resetData(prev),
    // }));
    // setIsAbleToStage(true); // Handle is able to push, now disable to avoid prob
  };

  //Backlog buttons
  const updateBacklog = (sku, country, actionText) => {
    backlogPushPullDelete({
      sku,
      country,
      actionText,
      orderStatus,
      orderStatusType,
      ro_num,
      setContainersInfo,
      setPOInfo,
      setMasterPOInfo,
      setBacklogPOInfo,
      setIsLoading,
      setAlertInfo,
    });
  };

  // --- CARD RELATED ---
  const [containersInfo, setContainersInfo] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(-1); //handle radio

  //Container options
  const [containerOptions, setContainerOptions] = useState([]);
  useEffect(() => {
    //ge tcontainerrunning
    getContainerOptions(setContainerOptions, setIsLoading, setAlertInfo);
  }, []);

  // Real to fetch PODetail
  useEffect(() => {
    getPODetails({
      orderStatus,
      orderStatusType,
      ro_num,
      setPOInfo,
      setMasterPOInfo,
      setBacklogPOInfo,
      setIsLoading,
      setAlertInfo,
    });
    getContainerDetails(
      orderStatus,
      ro_num,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    setComparePOInfo({}); //reset
    setPOToCompareUserChoice({
      ro_num: ro_num,
      firstPO: "", //status, version, record
      secondPO: "",
    });
  }, [ro_num, orderStatusType]);

  //update upon container card view change
  useEffect(() => {
    const filteredData = masterPOInfo.POdetail?.filter((row) => {
      if (viewActive.containerUniqueInfo !== "") {
        for (const container of row.containers) {
          if (container.container_id === viewActive.containerUniqueInfo) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    });

    setPOInfo((prev) => ({ ...prev, POdetail: filteredData }));
  }, [viewActive, masterPOInfo]);

  //Modal
  const { open, handleOpen, handleClose } = useModal();

  const setContainerNPOProviderValue = {
    setPOInfo,
    setContainersInfo,
    setMasterPOInfo,
    isAbleToStage,
    setIsAbleToStage,
  };

  const selectedContainerProviderValue = {
    selectedContainer,
    setSelectedContainer,
  };

  const {
    tableLoading,
    cardLoading,
    poUpdating,
    containerOptionsLoading,
    creatingContainer,
    deletingContainer,
    optimizingContainer,
    sendingToManagement,
    approvingPO,
    rejectingPO,
    generatingPO,
    downloadingRO,
  } = isLoading;

  const isTableLoading = tableLoading || poUpdating;
  const isContainerLoading =
    cardLoading ||
    creatingContainer ||
    deletingContainer ||
    optimizingContainer;
  const isContainerOptionsLoading = containerOptionsLoading;

  const gridTemplateAreas = () => {
    if (orderStatusType === "order_table") {
      return `'header1 header1 header1 reviewbutton insertbutton header2'
            'skutable skutable skutable skutable skutable containers'
            'button button button button button button'
            `;
    } else {
      return `'header1 header1 header1 header1 header1'
            'skutable skutable skutable skutable skutable'
            'button button button button button'
            `;
    }
  };

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridTemplateRows: "10% auto 7%",
    rowGap: 0.5,
    columnGap: 2,
    gridTemplateAreas: gridTemplateAreas(),
    width: "100%",
    height: "100%",
    minHeight: 540,
  };

  /// Popper for vendor info
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;
  ///
  return (
    <>
      {/* Order Table */}
      {/* {masterPOInfo?.POdetail?.length > 0 &&*/}
      {/* {orderStatusType === "order_table" && ( */}
      <>
        {/* Procurement + Proforma render */}
        {/* save send approve reject download generate */}

        <OrderStatusTypeInsertInactiveSKUModal
          open={open}
          handleClose={handleClose}
          setPOInfo={setPOInfo}
          masterPOInfo={masterPOInfo}
          setMasterPOInfo={setMasterPOInfo}
          setIsLoading={setIsLoading}
          position={position}
        />

        <SelectedContainerContext.Provider
          value={selectedContainerProviderValue}
        >
          <SetContainerNPOContext.Provider value={setContainerNPOProviderValue}>
            {/* <CustomizedSnackbars
                            alertInfo={alertInfo}
                            setAlertInfo={setAlertInfo}
                        /> */}
            <Box sx={{ width: 1, pt: 1.5 }}>
              <Box sx={gridContainerStyle}>
                <Box
                  gridArea="header1"
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignSelf: "center",
                    maxHeight: 540,
                  }}
                >
                  <Typography
                    variant="h4"
                    color="#485202"
                    sx={{
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    {POInfo?.PONUMBER ? `${POInfo.PONUMBER} ` : ""}
                    SKU List
                  </Typography>
                  <Tooltip title="Click here to see info">
                    <IconButton
                      aria-describedby={id}
                      type="button"
                      onClick={handlePopoverClick}
                    >
                      <InfoIcon sx={{ color: "#485202" }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box sx={{ p: 1, bgcolor: "background.paper" }}>
                      {orderTableMoreInfo({ POInfo, status: orderStatus }).map(
                        (infoMap) => (
                          <Typography>
                            {infoMap?.label} : {infoMap?.value ?? ""}
                          </Typography>
                        )
                      )}
                    </Box>
                  </Popover>
                  {orderStatusType === "history" && (
                    <OrderStatusTypeHistorySelectPO
                      POToCompareUserChoice={POToCompareUserChoice}
                      setPOToCompareUserChoice={setPOToCompareUserChoice}
                      setComparePOInfo={
                        // to set the table to be loaded through api
                        setComparePOInfo
                      }
                      POToCompareList={POToCompareList} //list to be selected
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  )}
                </Box>
                {(orderStatus === "procurement" ||
                  orderStatus === "rereview") &&
                  orderStatusType === "order_table" && (
                    <Box
                      gridArea="reviewbutton"
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        alignSelf: "center",
                      }}
                    >
                      {POInfo.is_reviewed === true ? (
                        <Button
                          variant="outlined"
                          sx={{
                            height: "40px",
                            background: "#fff",
                            borderRadius: "10px",
                            color: "#485202",
                            border: "1px solid #485202",
                            padding: "0px 16px",
                            fontSize: "16px",
                            ":hover": {
                              color: "#485202",
                              background: "#fff",
                              border: "1px solid #485202",
                            },
                            textTransform: "none",
                          }}
                          onClick={async () => {
                            await updatePoReviewStatus({
                              is_reviewed: false,
                              orderStatus,
                              ro_num,
                              setPOInfo,
                              setMasterPOInfo,
                              setContainersInfo,
                              setIsLoading,
                              setAlertInfo,
                            });
                          }}
                        >
                          Mark as unseen
                          <Check
                            sx={{
                              color: "#485202",
                              fontSize: "18px",
                              marginLeft: "4px",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            height: "40px",
                            background: "#485202",
                            borderRadius: "10px",
                            color: "#fff",
                            border: "0px",
                            padding: "0px 36px",
                            ":hover": {
                              color: "#fff",
                              background: "#485202",
                              border: "0px",
                            },
                            textTransform: "none",
                            fontSize: "16px",
                          }}
                          onClick={async () => {
                            await updatePoReviewStatus({
                              is_reviewed: true,
                              orderStatus,
                              ro_num,
                              setPOInfo,
                              setMasterPOInfo,
                              setContainersInfo,
                              setIsLoading,
                              setAlertInfo,
                            });
                          }}
                        >
                          Mark as seen
                          <Check
                            sx={{
                              color: "#fff",
                              fontSize: "18px",
                              marginLeft: "4px",
                            }}
                          />
                        </Button>
                      )}
                    </Box>
                  )}
                {(orderStatus === "procurement" ||
                  orderStatus === "rereview" ||
                  orderStatus === "proforma") &&
                  orderStatusType === "order_table" && (
                    <Box
                      gridArea="insertbutton"
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        alignSelf: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          height: "40px",
                          background: "#485202",
                          borderRadius: "10px",
                          color: "#fff",
                          border: "0px",
                          padding: "0px 16px",
                          ":hover": {
                            color: "#fff",
                            background: "#485202",
                            border: "0px",
                          },
                          textTransform: "none",
                          fontSize: "16px",
                          marginRight: "20px",
                        }}
                        onClick={() => {
                          handleOpen();
                        }}
                        disabled={
                          position === "management" &&
                          orderStatus === "procurement"
                        }
                      >
                        <AddIcon
                          sx={{
                            color: "#ff",
                            fontSize: "18px",
                            marginRight: "4px",
                          }}
                        />
                        Insert SKU
                      </Button>
                    </Box>
                  )}

                <Box gridArea="skutable" sx={{ minHeight: "63vh" }}>
                  {/* table */}

                  {isTableLoading ? (
                    <Box sx={{ height: 400 }}>
                      <SpinnerContainer>
                        <LoadingSpinner />
                      </SpinnerContainer>
                    </Box>
                  ) : finalPOInfo()?.POdetail?.length > 0 ? (
                    <OrderStatusTypeTable
                      POInfo={finalPOInfo()} //load the table
                      tableColumns={tableColumns()}
                      setPOInfo={setPOInfo}
                      setMasterPOInfo={setMasterPOInfo}
                      updateUserEdit={updateUserEdit}
                      cancelEdit={cancelEdit}
                      updateBacklog={updateBacklog}
                      containersInfo={containersInfo.sort((a, b) =>
                        a?.capacity < b?.capacity ? 1 : -1
                      )}
                      isAbleToStage={isAbleToStage}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      setAlertInfo={setAlertInfo}
                      viewActive={viewActive}
                    />
                  ) : orderStatusType === "history" ? (
                    <p>Please Select Pos to be compared.</p>
                  ) : (
                    <p>No Orders Available.</p>
                  )}
                </Box>

                {/* Containers */}
                {orderStatusType === "order_table" && (
                  <>
                    <Box
                      gridArea="header2"
                      sx={{
                        textAlign: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h4" color="#485202">
                          Containers
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <>
                            {/* Add or optimize container */}
                            {(orderStatus === "procurement" ||
                              orderStatus === "rereview" ||
                              orderStatus === "proforma") &&
                            !viewOnlyTab ? (
                              !isContainerOptionsLoading ? (
                                <OrderStatusTypeIconButton
                                  setContainersInfo={setContainersInfo}
                                  containerOptions={containerOptions}
                                  setIsLoading={setIsLoading}
                                />
                              ) : (
                                <SpinnerContainer>
                                  <LoadingSpinner />
                                </SpinnerContainer>
                              )
                            ) : null}
                          </>
                        </Box>
                      </Box>
                    </Box>
                    <Box gridArea="containers" textAlign="center">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* container cards */}
                        {cardLoading ? (
                          <Box sx={{ height: 400 }}>
                            <SpinnerContainer>
                              <LoadingSpinner />
                            </SpinnerContainer>
                          </Box>
                        ) : (
                          <OrderTableContainers
                            containersInfo={containersInfo}
                            setContainersInfo={setContainersInfo}
                            containerOptions={containerOptions}
                            viewActive={viewActive}
                            setViewActive={setViewActive}
                            setIsLoading={setIsLoading}
                          />
                        )}
                      </Box>
                    </Box>
                  </>
                )}

                {orderStatusType !== "history" && (
                  <Box
                    gridArea="button"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!isTableLoading && (
                      <OrderStatusTypeButton
                        POInfo={POInfo}
                        setPOInfo={setPOInfo}
                        masterPOInfo={masterPOInfo}
                        setMasterPOInfo={setMasterPOInfo}
                        setSelectedContainer={setSelectedContainer}
                        backlogPOInfo={backlogPOInfo}
                        setBacklogPOInfo={setBacklogPOInfo}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </SetContainerNPOContext.Provider>
        </SelectedContainerContext.Provider>
      </>
      {/* )} */}

      {/* )} */}
      {/* Backlog */}
      {/* !loading() && masterPOInfo?.POdetail?.length > 0 && */}
      {/* {orderStatusType === "backlog" && (
                <>
                    <SetContainerNPOContext.Provider
                        value={setContainerNPOProviderValue}
                    >
                        <Box sx={{ width: 1, mt: 1 }}>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={2}
                            >
                                <Box gridColumn="span 12" sx={{ ml: 2 }}>
                                    <Typography variant="h4" color="#485202">
                                        Vendor AT SKU List
                                        {POInfo.PONUMBER
                                            ? ` -> ${POInfo.PONUMBER}`
                                            : ""}
                                    </Typography>

                                    {isTableLoading ? (
                                        <SpinnerContainer>
                                            <LoadingSpinner />
                                        </SpinnerContainer>
                                    ) : (
                                        <OrderStatusTypeTable
                                            POInfo={POInfo}
                                            tableColumns={tableColumns}
                                            updateUserEdit={updateUserEdit}
                                            backlogButton={backlogButtonPush}
                                            containersInfo={containersInfo}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </SetContainerNPOContext.Provider>
                </>
            )} */}
      {/* History */}
      {/* {!loading() &&
                masterPOInfo?.POdetail?.length > 0 &&
                orderStatusType === "history" && (
                    <SelectedContainerContext.Provider
                        value={selectedContainerProviderValue}
                    >
                        <SetContainerNPOContext.Provider
                            value={setContainerNPOProviderValue}
                        >
                            <OrderStatusTypeHistory loading={loading} />
                        </SetContainerNPOContext.Provider>
                    </SelectedContainerContext.Provider>
                )}
            {/* <div> Current Table Data: </div> */}
      {/* <OrderStatusTypeTableDataVerify masterPOInfo={masterPOInfo} /> */}
    </>
  );
};

export default OrderStatusTypeRender;
