import React, { useContext, useEffect } from "react";
import SFPTable from "../../Components/Tables/SortFilterTable/SFPTable";
import { Header, LoadingSpinner, SpinnerContainer } from "../../Styles/styles";
import { ActionDashboardProvider } from "./actiondashboard.context";
import useActionDashboard from "./useActionDashboard";

import { storeAllROs } from "./actiondashboard.services";

//context
//IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING
import { ActionDashboardContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { dashboardOrderNavigationMapper } from "./actiondashaboard.mapper";

const ActionDashboardTest = () => {
  const navigate = useNavigate();

  const { actionDashboardFilteredData, setActionDashboardData } = useContext(
    ActionDashboardContext
  );
  const { columns, tableContext, filterObject } = useActionDashboard();
  const tableInfo = { data: actionDashboardFilteredData, columns };

  //Refresh Action dashboard.

  useEffect(() => {
    storeAllROs({ setter: setActionDashboardData });
  }, []);

  return (
    <ActionDashboardProvider>
      <Header>PO Actions Dashboard</Header>
      {actionDashboardFilteredData == null && (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      )}
      <hr />
      {actionDashboardFilteredData?.length > 0 && (
        <SFPTable
          tableInfo={tableInfo}
          tableContext={tableContext}
          filterObject={filterObject}
          rowFunc={(row) => {
            const tags = row?.values?.tags;
            navigate(
              `orders/${dashboardOrderNavigationMapper(tags).toLowerCase()}`
            );
          }}
        />
      )}
      {actionDashboardFilteredData?.length == 0 && (
        <div>No Actions Needed.</div>
      )}
    </ActionDashboardProvider>
  );
};

export default ActionDashboardTest;
