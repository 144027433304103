import React from "react";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";

// import Forecast from "../Charts/ForecastChart";
import PODetailModalDetail from "./OrderTable.modalDetails";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "75vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PODetailModal = ({ open, handleClose, rowInfo, similarFlag, data }) => {
  // const SKUInfo = rowInfo[0];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {!similarFlag && (
          <div>
            <h1 style={{ textDecoration: "underline" }}>SKU Details</h1>
            <div
              style={{
                minWidth: "150vh",
                maxHeight: "85vh",
                overflowY: "scroll",
              }}
            >
              {data &&
                data.map((SKU, idx) => (
                  <PODetailModalDetail key={idx} data={SKU} />
                ))}
            </div>
            {/* {JSON.stringify(SKUInfo)} */}
          </div>
        )}
        {similarFlag && (
          <div>
            <h1 style={{ textDecoration: "underline" }}>Similar Items</h1>
            <div
              style={{
                minWidth: "125vh",
                maxHeight: "75vh",
                overflowY: "scroll",
              }}
            >
              {data &&
                data.map((dataItem, idx) => (
                  <table>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Vendor</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataItem.similaritems.map((item) => (
                        <tr>
                          <td>{item.skuID}</td>
                          <td>{item.vendorID}</td>
                          <td>{item.qty_ordered}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default PODetailModal;
