import React, { useState, useEffect } from "react";
import { SelectColumnFilter } from "../../../Components/Tables/SortFilterTable/Filters";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Box, Select, MenuItem, Button } from "@mui/material";
import {
    SecondaryButton,
    DeleteButton,
    PrimaryPill,
    SecondaryLoadingButton,
} from "../../../Styles/styles";

import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@material-ui/core/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const isBoldHelper = (row) => {
    // console.log("Row: ", row);
    // console.log(
    //     "row.cells[row.cells.length - 1] ",
    //     row.cells[row.cells.length - 1]
    // );
    // console.log(
    //     "row.cells[row.cells.length - 1].value",
    //     row.cells[row.cells.length - 1].value
    // );
    // return row.cells[row.cells.length - 1].value !== null;
    return false;
};

const textCell = ({ row, value }) => {
    const isBold = isBoldHelper(row);

    return (
        <div
            style={{
                textAlign: "left",
                maxWidth: 300,
                wordWrap: "break-word",
            }}
        >
            {isBold ? (
                <span style={{ fontWeight: "bold" }}>{value}</span>
            ) : (
                <span>{value}</span>
            )}
        </div>
    );
};

const downloadCell = ({ row: { values }, handleDownloadExcel }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <StylesProvider injectFirst>
                <SecondaryLoadingButton
                    variant="contained"
                    className="action-button"
                    // loading={isLoading.backlogging}
                    onClick={() => {
                        handleDownloadExcel(values.name);
                    }}
                    disabled={false}
                >
                    Download
                </SecondaryLoadingButton>
            </StylesProvider>
        </div>
    );
};

const deleteCell = ({ row: { values }, handleDeleteExcel }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <StylesProvider injectFirst>
                <SecondaryLoadingButton
                    variant="contained"
                    sx={{ color: "red" }}
                    // loading={isLoading.backlogging}
                    onClick={() => {
                        handleDeleteExcel(values.name);
                    }}
                    disabled={false}
                >
                    Delete
                </SecondaryLoadingButton>
            </StylesProvider>
        </div>
    );
};

export const forecastExcelColumns = [
    { Header: "Date", accessor: "month", Cell: textCell },
    { Header: "File Name", accessor: "name", Cell: textCell },
    {
        Header: "Upload Time",
        accessor: "upload_time",
        Cell: textCell,
    },
    {
        Header: "Download",
        accessor: "",
        Cell: downloadCell,
        style: {
            zIndex: "5",
        },
    },
    {
        Header: "Delete",
        accessor: "",
        Cell: deleteCell,
        style: {
            zIndex: "5",
        },
    },
];
