import React, { useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useFlexLayout,
  useAbsoluteLayout,
} from "react-table";
import { useSticky } from "react-table-sticky";

import { SVG, TertiaryButton } from "../../Styles/styles";
import up from "../../images/Ascending.svg";
import down from "../../images/Descending.svg";
import defaultSort from "../../images/DefaultSort.svg";

import "./table.css";

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page, //this is basically row with pagination
    nextPage,
    previousPage,
    canPreviousPage, //condition to check whether can go to prev page or not
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    SelectColumnFilter,
  } =
    // setGlobalFilter,
    // setFilter, } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      usePagination,
      useSticky
    );

  useEffect(() => {
    setPageSize(25);
  }, []);

  const { pageIndex, pageSize } = state;

  return (
    <>
      {/* table HTML */}
      <div
        style={{
          height: "60vh",
          overflow: "auto",
        }}
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps(), // to enable sorting feature
                        {
                          //curly instead of open bracket, cause u return an object
                          style: {
                            ...column.style,
                            cursor: "pointer",
                          },
                        }
                      )}
                    >
                      {column.render("Header")}

                      {/* Add a sort direction indicator */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SVG
                            style={{
                              marginLeft: "10px",
                            }}
                            src={up}
                          />
                        ) : (
                          <SVG
                            style={{
                              marginLeft: "10px",
                            }}
                            src={down}
                          />
                        )
                      ) : (
                        <SVG
                          style={{
                            marginLeft: "10px",
                          }}
                          src={defaultSort}
                        />
                      )}
                      {/* <div>
                                                {column.canFilter
                                                    ? column.render("Filter")
                                                    : null}
                                            </div> */}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ cursor: "pointer" }}>
                  {row.cells.map((cell) => {
                    // return flag ? (
                    //     <td
                    //         {...cell.getCellProps()}
                    //         style={{
                    //             // backgroundColor: "#E6FFEC",
                    //             fontWeight: "bold",
                    //         }}
                    //     >
                    //         {cell.render("Cell")}
                    //     </td>
                    // ) : (
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );

                    // );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          marginTop: "5px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </TertiaryButton>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </TertiaryButton>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </TertiaryButton>
          <TertiaryButton
            className="hoverSecWhiteButton"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </TertiaryButton>
        </div>
        <span style={{ marginTop: "10px" }}>
          Page
          <strong style={{ marginLeft: "5px", marginRight: "5px" }}>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span style={{ marginTop: "10px" }}>
          Go To Page:
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px", marginLeft: "5px" }}
          />
        </span>
        <span style={{ marginTop: "10px" }}>
          Show
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          Items
        </span>
      </div>
    </>
  );
};

export default Table;
