import React from "react";
import useModal from "../Hooks/useModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { PrimaryButton, TextButton } from "../Styles/styles";

const SubmitDialog = ({ header, buttonText, message, outcome }) => {
  const { open, handleOpen, handleClose } = useModal();

  function SubmitPO({ buttonText }) {
    if (buttonText === "Reject RO") {
      return (
        <PrimaryButton
          className="hoverButton"
          style={{ backgroundColor: "#800000" }}
          onClick={handleOpen}
        >
          {buttonText}
        </PrimaryButton>
      );
    } else {
      return (
        <PrimaryButton className="hoverButton" onClick={handleOpen}>
          {buttonText}
        </PrimaryButton>
      );
    }
  }



  return (
    <>
      <SubmitPO buttonText={buttonText} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ padding: "20px" }}>
          <h3>{header}</h3>
          <p id="alert-dialog-slide-description">
            Are you sure you want to {message}?
          </p>

          <p id="alert-dialog-slide-description">
            Any unsaved changes will be lost. Please ensure you have saved your
            changes.
          </p>
        </div>
        <DialogActions>
          <TextButton className="hoverSecWhiteButton" onClick={handleClose}>
            Cancel
          </TextButton>
          <TextButton className="hoverSecWhiteButton" onClick={outcome}>
            Proceed
          </TextButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SubmitDialog;
