import { AlertContext } from "../../../../App";
import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Typography, TextField, Stack, Grid } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { Field, Form, Formik } from "formik";
import { updateSafetyStock } from "../../../../Services/safetystock.service";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 32px rgba(61, 70, 112, 0.08)",
  display: "flex",
  borderRadius: "12px",
  padding: "24px 24px",
};

const Title = MUIStyled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

const cancelButtonStyle = {
  width: "180px",
  borderColor: "#53545C",
  color: "#53545C",
  fontSize: "16px",
  fontWeight: 400,
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#bead7c",
  },
};

const submitButtonStyle = {
  width: "180px",
  color: "#000",
  fontSize: "14px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const textFieldStyle = {
  padding: "0px",
  margin: "0px",
  marginBottom: "24px",
  height: "52px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const Label = MUIStyled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #5e6366;
  margin-bottom: 6px;
  margin-left: 0px;
`;

const EditSafetyStockModal = ({ id, detail, handleClose }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [stockDetail, setStockDetail] = useState();

  const handleFormSubmit = async (values) => {
    // console.log("handleFormSubmit", { values });
    const map = {
      sku_id: values.sku_id,
      additional_safety_stock_month: values.additional_safety_stock_month,
    };
    await updateSafetyStock({
      payload: map,
      setAlertInfo,
      onSuccess: handleClose,
    });
  };

  useEffect(() => {
    if (detail) {
      setStockDetail(detail);
    }
  }, []);

  return (
    <Box>
      {stockDetail && (
        <Formik
          initialValues={{
            sku_id: stockDetail?.skuID ?? "",
            description: stockDetail?.description ?? "",
            vendor_name: stockDetail?.vendor_name ?? "",
            additional_safety_stock_month:
              stockDetail?.additional_safety_stock_month ?? null,
          }}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box sx={modalContainerStyle}>
                <Box sx={{ width: "100%" }}>
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing="16px"
                  >
                    <Title>Edit Safety Stock</Title>
                    <OutlinedBox onClick={handleClose}>
                      <ClearIcon sx={closeIconStyle} />
                    </OutlinedBox>
                  </Stack>
                  <Box height="24px" />

                  <Grid container spacing="8px">
                    <Grid item xs={3}>
                      <Typography sx={{ color: "#5e6366", fontSize: "14px" }}>
                        SKU :
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {" "}
                      <Typography sx={{ fontSize: "14px" }}>
                        {values.sku_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ color: "#5e6366", fontSize: "14px" }}>
                        Description :
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {" "}
                      <Typography sx={{ fontSize: "14px" }}>
                        {values.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ color: "#5e6366", fontSize: "14px" }}>
                        Vendor Name :
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {" "}
                      <Typography sx={{ fontSize: "14px" }}>
                        {values.vendor_name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box height="20px" />
                  <Label>Additional Safety Stock (in Months)</Label>
                  <Field
                    id="additional_safety_stock_month"
                    name="additional_safety_stock_month"
                    validate={(value) => {}}
                  >
                    {({ field, form, meta }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="additional_safety_stock_month"
                        name="additional_safety_stock_month"
                        value={values?.additional_safety_stock_month}
                        onChange={handleChange}
                        placeholder="Additional Safety Stock (in Months)"
                        inputRef={(input) => input && input.focus()}
                        sx={{
                          ...textFieldStyle,
                          marginBottom: "0px",
                        }}
                        type="number"
                      />
                    )}
                  </Field>

                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    spacing="16px"
                    marginTop="36px"
                  >
                    <Button
                      variant="outlined"
                      sx={cancelButtonStyle}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={submitButtonStyle}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default EditSafetyStockModal;
