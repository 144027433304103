export const CATEGORY_OPTIONS = [
  { label: "Living Room", value: "living room" },
  { label: "Dining", value: "dining" },
  { label: "Bedroom", value: "bedroom" },
  { label: "Nothing", value: "nothing" },
  { label: "Others", value: "others" },
];

export const YEAR_OPTIONS = ({ startYear, endYear }) => {
  const arr = [];
  for (let i = startYear; i <= endYear; i++) {
    arr.push({ label: i, value: i });
  }
  return arr.reverse();
};

export const MONTH_OPTIONS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((mth, index) => ({ label: mth, value: index + 1 }));
