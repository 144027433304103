import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import Modal from "@mui/material/Modal";

//API
import {
  getInactiveSKU,
  insertInactiveSKU,
} from "../../../Services/ordertable.service";

//ALERT
import { AlertContext } from "../../../App";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "300px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "20% auto auto 20%",
  gap: 1,
  gridTemplateAreas: `'header1 header1'
    'sku sku'
    'country country'
    'button button'`,
};

const buttonDefaultStyling = {
  sx: {
    ml: 1,
  },
  size: "large",
  variant: "contained",
};

const OrderStatusTypeInsertInactiveSKUModal = ({
  open,
  handleClose,
  setPOInfo,
  masterPOInfo,
  setMasterPOInfo,
  setIsLoading,
  position,
}) => {
  //ALERT
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const { orderStatus, ro_num, orderStatusType } = useParams();
  const [inactiveSKUList, setInactiveSKUList] = useState();
  const [inactiveSKUSelected, setInactiveSKUSelected] = useState({
    sku: "",
    country: "",
  });

  const [isInserting, setIsInserting] = useState(false);

  const handleInactiveSKUChange = (e) => {
    if (String(e.target.value).length === 2) {
      setInactiveSKUSelected((prev) => ({
        ...prev,
        country: e.target.value,
      }));
    } else {
      setInactiveSKUSelected((prev) => ({
        ...prev,
        sku: e.target.value,
      }));
    }
  };

  const handleInsert = async () => {
    await insertInactiveSKU({
      orderStatus,
      orderStatusType,
      ro_num,
      sku: inactiveSKUSelected.sku,
      country: inactiveSKUSelected.country,
      setIsInserting,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
    });

    handleClose();
  };

  useEffect(() => {
    if (position === "management" && orderStatus === "procurement") {
      // console.log(
      //     "Management in Procurement. Don't load inactive sku list."
      // );
    } else {
      getInactiveSKU({
        ro_num,
        setInactiveSKUList,
        setAlertInfo,
      });
    }
  }, [masterPOInfo]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalContainerStyle}>
        <Box
          sx={{
            gridArea: "header1",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #bead7c",
            }}
          >
            <h3 style={{ marginBottom: 0 }}>
              Pick Inactive SKU to be Inserted.
            </h3>
            <Button variant="filled" onClick={handleClose}>
              <CloseIcon sx={{ color: "black" }} />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: "sku",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="inactive-sku-input" id="inactive-sku-label">
              Inactive SKU
            </InputLabel>
            <Select
              defaultValue=""
              labelId="inactive-sku-label"
              id="inactive-sku-input"
              value={inactiveSKUSelected.sku}
              // data-test="testing"
              label="Inactive SKU"
              onChange={handleInactiveSKUChange}
            >
              {inactiveSKUList?.map((row, index) => {
                // console.log("HERE: ", row, index);
                return (
                  <MenuItem
                    key={row.sku}
                    value={row.sku}
                    data-index={index}
                    onClick={handleInactiveSKUChange}
                  >
                    {row.sku} - {row.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            gridArea: "country",
          }}
        >
          <FormControl fullWidth>
            <InputLabel
              htmlFor="inactive-sku-country-input"
              id="inactive-sku-country-label"
            >
              Country
            </InputLabel>
            <Select
              defaultValue=""
              labelId="inactive-sku-country-label"
              id="inactive-sku-country-input"
              value={inactiveSKUSelected.country}
              // data-test="testing"
              label="Inactive SKU"
              onChange={handleInactiveSKUChange}
            >
              <MenuItem value={"SG"}>SG</MenuItem>
              <MenuItem value={"JP"}>JP</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box gridArea="button" sx={{ display: "flex-end", justifySelf: "end" }}>
          <LoadingButton
            {...buttonDefaultStyling}
            color="success"
            onClick={() => {
              handleInsert();
            }}
            loading={isInserting}
            disabled={
              inactiveSKUSelected.sku === "" ||
              inactiveSKUSelected.country === ""
            }
          >
            <CheckIcon />
          </LoadingButton>
          <Button
            {...buttonDefaultStyling}
            color="error"
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderStatusTypeInsertInactiveSKUModal;
