import { useMemo, useRef } from "react";
import EditableCell from "../Components/Tables/Cells/EditableCell";
import EditableNumberCell from "../Components/Tables/Cells/EditableNumberCell";
import { SecondaryButton, SVG } from "../Styles/styles";
import swap from "../images/Swap.svg";
import { numToCurrency } from "../Services/util";
const useColumns = ({ updateRow, cellFunction }) => {
  let editableKeyToFocus = useRef(null);
  const containerEditableCol = useMemo(
    () => [
      {
        Header: (props) => {
          return `SKU (${props.rows.length})`;
        },
        accessor: "SKU",
        tipText: `SKU IDs`,
      },
      {
        Header: "Description",
        accessor: "description",
        tipText: "Description of SKU",
      },
      {
        Header: "Status",
        accessor: "status",
        tipText: "SKU Status",
      },
      {
        Header: "Total Qty",
        accessor: "total_qty",
        tipText: "Total SKUs already in warehouse",
      },
      {
        Header: "Safety Stock",
        accessor: "safety_qty",
        tipText: "Safety SKUs from previous cycle",
      },
      {
        Header: "JPN Qty",
        accessor: "jpn_qty",
        tipText: "SKUs from Japan",
        Cell: EditableNumberCell({ updateRow, editableKeyToFocus }),
      },
      {
        Header: "Promo Qty",
        accessor: "promo_qty",
        tipText: "How many SKUs to be ordered for Promo",
        Cell: EditableNumberCell({ updateRow, editableKeyToFocus }),
      },
      {
        Header: "Replenishment Qty",
        accessor: "demand_qty",
        tipText:
          "Forecasted demand for SKUs next month, indicated for you to amend if needed",
        Cell: EditableNumberCell({ updateRow, editableKeyToFocus }),
      },
      {
        Header: "Backlog Qty",
        accessor: "frombacklog",
        tipText: "Backlog Quantity from previous cycle(s)",
        Cell: EditableNumberCell({ updateRow, editableKeyToFocus }),
      },
      {
        Header: "Total Order Qty",
        accessor: (row) =>
          row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog,
        tipText:
          "Total Amount of SKU to order, calculated by summing Japan, Promo, Replenishment, Backlog QTY",
      },
      {
        Header: "Total Value (SGD)",
        // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
        accessor: (row) =>
          numToCurrency(
            (row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog) *
              row.price
          ).toFixed(2),
        tipText: "Total value of this row",
      },
      {
        Header: "Remarks",
        accessor: "remark",
        tipText: "Remarks for this SKU",
        Cell: EditableCell({ updateRow, editableKeyToFocus }),
      },
      {
        Header: "Swap Container",
        // tipText: "Lorem ipsum 11",
        Cell: (cell) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SecondaryButton
              style={{
                cursor: "pointer",
              }}
              onClick={cellFunction}
            >
              <SVG src={swap} />
            </SecondaryButton>
          </div>
        ),
      },
    ],
    [updateRow]
  );

  const containerImmuCol = useMemo(
    () => [
      {
        Header: (props) => {
          return `SKU (${props.rows.length})`;
        },
        accessor: "SKU",
        tipText: `SKU IDs`,
      },
      {
        Header: "Description",
        accessor: "description",
        tipText: "Description of SKU",
      },
      {
        Header: "Status",
        accessor: "status",
        tipText: "SKU Status",
      },
      {
        Header: "Total Qty",
        accessor: "total_qty",
        tipText: "Total SKUs already in warehouse",
      },
      {
        Header: "Safety Stock",
        accessor: "safety_qty",
        tipText: "Safety SKUs from previous cycle",
      },
      {
        Header: "JPN Qty",
        accessor: "jpn_qty",
        tipText: "SKUs from Japan",
      },
      {
        Header: "Promo Qty",
        accessor: "promo_qty",
        tipText: "How many SKUs to be ordered for Promo",
      },
      {
        Header: "Replenishment Qty",
        accessor: "demand_qty",
        tipText:
          "Forecasted demand for SKUs next month, indicated for you to amend if needed",
      },
      {
        Header: "Backlog Qty",
        accessor: "frombacklog",
        tipText: "Backlog Quantity from previous cycle(s)",
      },
      {
        Header: "Total Order Qty",
        accessor: (row) =>
          row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog,
        tipText:
          "Total Amount of SKU to order, calculated by summing Japan, Promo, Replenishment, Backlog QTY",
      },
      {
        Header: "Total Value (SGD)",
        // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
        accessor: (row) =>
          numToCurrency(
            (row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog) *
              row.price
          ).toFixed(2),
        tipText: "Total value of this row",
      },
      {
        Header: "Remarks",
        accessor: "remark",
        tipText: "Remarks for this SKU",
      },
    ],
    []
  );

  return { containerCols: { containerEditableCol, containerImmuCol } };
};

export default useColumns;
