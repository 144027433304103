import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Card, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
//MUI custome theme
import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";

//API
import { splitContainer } from "../../../Services/ordertable.service";

//context
import { SetContainerNPOContext } from "./OrderStatusTypeRender"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING
import { AlertContext } from "../../../App";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "60vh",
  maxHeight: "900px",
  maxWidth: "1500px",
  bgcolor: "rgba(226, 226, 226, 1)",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "10% auto 10%",
  gap: 1,
  gridTemplateAreas: `'header1 header1'
    'modal modal'
    'button button'`,
};

const headerGridStyle = {
  bgcolor: "#bead7c",
  textAlign: "left",
  p: 1,
};

const buttonDefaultStyling = {
  sx: {
    ml: 1,
  },
  size: "large",
  variant: "contained",
};

const OrderStatusTypeSKUContainerModal = ({
  open,
  handleClose,
  setPOInfo,
  setMasterPOInfo,
  isLoading,
  setIsLoading,
  modalSKUContainerDetail,
  setModalSKUContainerDetail,
  modalSKUContainerDetailInitial,
  totalQty,
  values,
}) => {
  const { orderStatus, ro_num } = useParams();
  const { setContainersInfo } = useContext(SetContainerNPOContext);
  const { setAlertInfo } = useContext(AlertContext);

  const sku = values.SKU;
  const country = values.country;

  // console.log("modalSKUContainerDetail: ", modalSKUContainerDetail);

  //Make initial state, then if not fulfill the number or anything, reset,  throw error.
  const handleOnChange = (e) => {
    if (/^\d+$/.test(e.target.value) || e.target.value === "") {
      const inputQty = Number(e.target.value);
      // console.log("inputQty: ", inputQty);
      setModalSKUContainerDetail(
        [...modalSKUContainerDetail].map((object) => {
          if (object.container_id === Number(e.target.id)) {
            if (inputQty >= 0) {
              return {
                ...object,
                qty: inputQty,
              };
            } else {
              return {
                ...object,
                qty: 0,
              };
            }
          } else {
            return object;
          }
        })
      );
      // console.log("modalSKUContainerDetail: ", modalSKUContainerDetail);
    }
  };

  const handleSave = async () => {
    //validation
    // console.log(totalQty);
    // console.log("modalSKUContainerDetail", modalSKUContainerDetail);

    let qtyUpdated = 0;
    for (const { qty } of modalSKUContainerDetail) {
      qtyUpdated = qtyUpdated + qty;
    }

    if (totalQty !== qtyUpdated) {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: "The Total Quantity doesn't match! Please re-assign the quantity.",
      }));
      return "Breaking";
    }

    const body = {
      sku: sku,
      country: country,
      containers: modalSKUContainerDetail,
    };

    // console.log("initial:", modalSKUContainerDetailInitial);
    // console.log("After change: ", modalSKUContainerDetail);
    //debug this tmr
    await splitContainer({
      orderStatus,
      ro_num,
      setContainersInfo,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo,
      body,
    });
  };

  const handleCancel = () => {
    setModalSKUContainerDetail(modalSKUContainerDetailInitial); //reset
    handleClose(); //close
  };

  // console.log("modalSKUContainerDetail: ", modalSKUContainerDetail);
  // console.log(
  //     "typeof modalSKUContainerDetail:",
  //     typeof modalSKUContainerDetail
  // );
  // console.log("containersQty: ", containersQty);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalContainerStyle}>
        <Box
          sx={{
            gridArea: "header1",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #bead7c",
            }}
          >
            <h3 style={{ marginBottom: 0 }}>
              {sku} - {country} - {totalQty}
            </h3>
            <Button variant="filled" onClick={handleCancel}>
              <CloseIcon sx={{ color: "black" }} />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: "modal",
            overflow: "auto",
          }}
        >
          <table size="small">
            <thead>
              <tr>
                <th>Container ID</th>
                <th style={{ zIndex: 5 }}>Order Quantity</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(modalSKUContainerDetail).length !== 0 &&
                modalSKUContainerDetail.map((row) => {
                  return (
                    <tr key={`${sku}-${country}-${row.container_id}`}>
                      <td
                        variant="head"
                        style={{
                          width: "35%",
                          textAlign: "center",
                        }}
                      >
                        {row.container_id}
                      </td>
                      <td>
                        <TextField
                          sx={{ width: "100%" }}
                          id={String(row.container_id)}
                          // label="Order Quantity"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={row.qty}
                          onChange={handleOnChange}
                          variant="standard"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Box>

        <Box gridArea="button" sx={{ display: "flex-end", justifySelf: "end" }}>
          <ThemeProvider theme={customMUITheme}>
            <LoadingButton
              {...buttonDefaultStyling}
              color="save"
              onClick={() => {
                handleSave();
              }}
              loading={isLoading.isSplittingContainer}
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </ThemeProvider>

          <Button
            {...buttonDefaultStyling}
            color="error"
            onClick={() => {
              handleCancel();
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderStatusTypeSKUContainerModal;
