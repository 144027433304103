import React from "react";

import {
    PrimaryButton,
    SecondaryButton,
    DisabledButton,
} from "../Styles/styles";

export const NavLinkChild = ({ isActive, text, disabled }) => {
    if (disabled) {
        // console.log("Disable!");
        return <DisabledButton disabled={true}>{text}</DisabledButton>;
    }

    return isActive ? (
        <PrimaryButton disabled={disabled}>{text}</PrimaryButton>
    ) : (
        <SecondaryButton disabled={disabled}>{text}</SecondaryButton>
    );
};
