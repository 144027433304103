import React, { useState, useEffect, useMemo } from "react";

import useModal from "../../../Hooks/useModal";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import {
  VendorDBContext,
  VendorDBProvider,
} from "../../../Contexts/VendorDB.context";

import { Box } from "@mui/material";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { Header } from "../../../Styles/styles";
import SFPTable from "../../../Components/Tables/SortFilterTable/SFPTable";
import { VendorDBFilterRow } from "../../../Components/Tables/SortFilterTable/FilterRows";
import { storeVendorSet } from "../../../Services/services";
import { VendorDBLargeTableColumn } from "../../../Components/Tables/columns";
import VendorTableModalWrapper from "../../../Components/Modals/VendorTableModalWrapper";

const VendorDB = () => {
  // Tabs
  const [key, setKey] = useState("active");

  const [rows, setRows] = useState([]);

  const [refresher, setRefresher] = useState(0);

  const columns = useMemo(VendorDBLargeTableColumn(setRefresher), []);

  const activeData = rows?.filter((detail) => detail.active === true);

  const inactiveData = rows?.filter((detail) => detail.active !== true);

  const activeTableInfo = { data: activeData, columns };
  const inactiveTableInfo = { data: inactiveData, columns };

  // _______________________________________________________
  // Code for handling expand table
  const { open, handleOpen, handleClose } = useModal();
  const [rowInfo, setRowInfo] = useState();
  const expandTable = (row) => {
    setRowInfo([row.original]);
  };

  // Callback for expandTable()
  useEffect(() => {
    handleOpen();
  }, [rowInfo]);

  useEffect(() => {
    storeVendorSet(setRows);
  }, [refresher]);

  // _______________________________________________________
  return (
    <VendorDBProvider>
      <Header> Vendor Database </Header>
      <hr />
      {rowInfo && (
        <VendorTableModalWrapper
          open={open}
          handleClose={handleClose}
          rowInfo={rowInfo}
        />
      )}

      <Tabs
        id="vendorsDb-tab"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
      >
        <Tab eventKey="active" title="Active">
          <div>
            {rows[0] === "Not Loaded Yet" ? (
              <Box sx={{ height: 400 }}>
                <SpinnerContainer>
                  <LoadingSpinner />
                </SpinnerContainer>
              </Box>
            ) : (
              <SFPTable
                tableInfo={activeTableInfo}
                tableContext={VendorDBContext}
                filterObject={(instance) => (
                  <VendorDBFilterRow instance={instance} status={key} />
                )}
                rowFunc={expandTable}
              />
            )}
          </div>
        </Tab>
        <Tab eventKey="inactive" title="Inactive">
          <div>
            {rows[0] === "Not Loaded Yet" ? (
              <Box sx={{ height: 400 }}>
                <SpinnerContainer>
                  <LoadingSpinner />
                </SpinnerContainer>
              </Box>
            ) : (
              <SFPTable
                tableInfo={inactiveTableInfo}
                tableContext={VendorDBContext}
                filterObject={(instance) => (
                  <VendorDBFilterRow instance={instance} status={key} />
                )}
                rowFunc={expandTable}
              />
            )}
          </div>
        </Tab>
      </Tabs>
    </VendorDBProvider>
  );
};

export default VendorDB;
