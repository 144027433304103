import React, { useEffect, useState, useMemo } from "react";

import { getPODB } from "../../../Services/services";

import { PODBColumns } from "../../../Components/Tables/columns";
import SFPTable from "../../../Components/Tables/SortFilterTable/SFPTable";

import { PODBContext, PODBProvider } from "../../../Contexts/PODB.context";
import { PODBFilterRow } from "../../../Components/Tables/SortFilterTable/FilterRows";

import POModal from "../../../Components/Modals/POModal";
import useModal from "../../../Hooks/useModal";

import { Header } from "../../../Styles/styles";

const PODB = () => {
  // for tableInfo
  const [poData, setPOData] = useState([]);
  useEffect(() => {
    getPODB(setPOData);
  }, []);

  const columns = useMemo(PODBColumns, []);
  const data = poData;
  const tableInfo = { data, columns };

  // _______________________________________________________
  // Code for handling expand table
  const { open, handleOpen, handleClose } = useModal();
  const [rowInfo, setRowInfo] = useState();
  const expandTable = (row) => {
    setRowInfo([row.original]);
    // row.original is an obj {}, wrap it in [] so that when handleClose() triggers.
    // rowInfo is considered as a different object
  };

  // Callback for expandTable()
  useEffect(() => {
    handleOpen();
  }, [rowInfo]);

  // _______________________________________________________

  return (
    <PODBProvider>
      {rowInfo && (
        <POModal open={open} handleClose={handleClose} poInfo={rowInfo} />
      )}
      <Header>PO Database</Header>
      <hr />
      <SFPTable
        tableInfo={tableInfo}
        tableContext={PODBContext}
        filterObject={(instance) => <PODBFilterRow instance={instance} />} // Has to be in this form
        rowFunc={expandTable}
      />
    </PODBProvider>
  );
};

export default PODB;
