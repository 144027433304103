import api from "./api";

const alertError = (err, setAlertInfo) => {
  // console.log("error: ", err);
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err?.response?.data["message"] ?? err?.toString(),
  }));
};

export const getEventsList = async (
  eventStatus,
  setRows,
  setLoading,
  setEventCount,
  page,
  pageSize,
  location,
  startDate,
  endDate,
  search,
  event
) => {
  try {
    setLoading(true);

    const url = `events/?status=${eventStatus}&page=${page}&page_size=${pageSize}&start_date=${startDate}&end_date=${endDate}&search=${search}&event=${event}`;

    const response = await api.get(url);

    if (response.status === 200) {
      setRows(response.data["results"]);
      setEventCount(response.data["count"]);

      setLoading(false);
    } else {
      setRows([]);
      setLoading(false);
    }
  } catch (error) {
    // console.log(error);
    setRows([]);
    setLoading(false);
  }
};

export const getEventDetails = async (eventID, setDetail) => {
  try {
    const url = `events/${eventID}`;

    const response = await api.get(url);

    if (response.status === 200) {
      setDetail(response.data);
    }
  } catch (error) {
    // console.log(error);
  }
};

export const getPromoGroupList = async (setData) => {
  try {
    const url = `promo-groups/?template_only=true&page=no_page`;

    const response = await api.get(url);

    if (response.status === 200) {
      setData(response.data);
    } else {
      setData([]);
    }
  } catch (error) {
    // console.log(error);
    setData([]);
  }
};

export const createEventWithPromoGroups = async (
  payload,
  setAlertInfo,
  onSuccess
) => {
  try {
    const url = `events/event-promo-groups/`;
    const response = await api.post(url, payload);

    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The new event has been created successfully!`,
      }));
      onSuccess();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const updateEvent = async (id, payload, setAlertInfo, onSuccess) => {
  try {
    const url = `events/${id}/event-promo-groups/`;
    const response = await api.put(url, payload);

    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The event - ${id} - has been updated successfully!`,
      }));
      onSuccess();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const deleteEventWithPromoGroups = async (
  id,
  setAlertInfo,
  onSuccess
) => {
  try {
    const url = `events/${id}`;
    const response = await api.delete(url);

    if (response.status === 204) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The event - ${id} - has been deleted successfully!`,
      }));
      onSuccess();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const savePromoGroupTemplate = async (payload, setAlertInfo) => {
  try {
    const url = `promo-groups/template/`;
    const response = await api.post(url, payload);

    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The new promo group has been created successfully!`,
      }));
      // window.location.reload();
      return response.data;
    } else {
      alertError(response, setAlertInfo);
      return null;
    }
  } catch (error) {
    alertError(error, setAlertInfo);
    return null;
  }
};

export const updatePromoGroupTemplate = async (id, payload, setAlertInfo) => {
  try {
    const url = `promo-groups/${id}/`;
    const response = await api.put(url, payload);

    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The promo group - ${id} - has been updated successfully!`,
      }));
      // window.location.reload();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const getSKUList = async (setData, setLoaded) => {
  try {
    const url = `getsku`;

    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        let list = response.data["data"];

        setData(list);
        setLoaded(true);
      } else {
        setData([]);
        setLoaded(true);
      }
    } else {
      setData([]);
      setLoaded(true);
    }
  } catch (error) {
    setData([]);
    setLoaded(true);
  }
};

export const getPromoTypeList = async (setData) => {
  try {
    const url = `promo-groups/promo-types/`;

    const response = await api.get(url);

    if (response.status === 200) {
      setData(response.data);
    } else {
      setData([]);
    }
  } catch (error) {
    // console.log(error);

    setData([]);
  }
};

export const getLocationList = async (setData) => {
  try {
    const url = `events/locations/`;

    const response = await api.get(url);

    if (response.status === 200) {
      setData(response.data);
    } else {
      setData([]);
    }
  } catch (error) {
    // console.log(error);

    setData([]);
  }
};

export const getEventIdList = async (setData) => {
  try {
    const url = `events/?page=no_page`;

    const response = await api.get(url);

    if (response.status === 200) {
      const list = response.data.map((a) => {
        return { id: a.id, name: a.name };
      });
      setData(list);
    } else {
      setData([]);
    }
  } catch (error) {
    // console.log(error);

    setData([]);
  }
};
