import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Stack,
} from "@mui/material";
import styled from "styled-components";

import Select from "@mui/material/Select";
import ReactDatePicker from "react-datepicker";

import moment from "moment";

import {
  getLocationList,
  getEventIdList,
} from "../../../../Services/eventstable.service";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: "0px 4px 32px rgba(61, 70, 112, 0.08)",
  padding: "24px 21px",
  display: "flex",
  borderRadius: "12px",
};

const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #1c1d22;
`;

const SubText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: #1c1d22;
`;

const OutlinedBorder = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #bead7c;
  border-radius: 6px;
  dislpay: flex;
  position: relative;
`;

const Square = styled.div`
  width: 16px;
  height: 16px;
  background: #bead7c;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
`;

const inputLabelStyle = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "15px",
  color: "#53545c",
};

const selectFormControlStyle = {
  width: "360px",
  height: "38px",
  mb: "20px",
  mt: "8px",
  borderRadius: "8px",
};

const selectStyle = {
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "& label.Mui-focused": {
    color: "#bead7c",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bead7c",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#bead7c",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bead7c",
  },

  borderRadius: "8px",
  height: "38px",
};

const filterButtonStyle = {
  width: "180px",
  color: "#000",
  fontSize: "14px",
  fontWeight: 400,
  borderRadius: "12px",
  backgroundColor: "#bead7c",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const dateBoxStyle = {
  height: "330px",
  py: 1,
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

const resetButtonStyle = {
  width: "180px",
  borderColor: "#53545C",
  color: "#53545C",
  fontSize: "14px",
  fontWeight: 400,
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#bead7c",
  },
};

export function formatFilterDate(date) {
  try {
    if (date) {
      return moment(date).format("yyyy-MM-DD");
    } else {
      return "";
    }
  } catch (error) {
    // console.log(error);
    return "";
  }
}

const FilterEventModal = ({
  open,
  handleClose,
  setFilterStartDate,
  setFilterEndDate,
  setFilterLocation,
  setFilterEventId,
}) => {
  const [eventIDList, setEventIDList] = useState([]);
  const [locationList, setLocationIDList] = useState([]);

  const [eventID, setEventID] = useState();
  const [locationID, setLocationID] = useState();

  const handleEventIDChange = (event) => {
    setEventID(event.target.value);
  };
  const handleLocationIDChange = (event) => {
    setLocationID(event.target.value);
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    getEventIdList(setEventIDList);

    // getLocationList(setLocationIDList);
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalContainerStyle}>
        <Box>
          <Title>By Event</Title>

          <Box>
            <InputLabel sx={inputLabelStyle}>Event ID</InputLabel>
            <FormControl sx={selectFormControlStyle}>
              <Select
                defaultValue=""
                value={eventID}
                onChange={handleEventIDChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={selectStyle}
              >
                {eventIDList.map((e) => (
                  <MenuItem key={e} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* <Box>
            <InputLabel sx={inputLabelStyle}>Location ID</InputLabel>
            <FormControl sx={selectFormControlStyle}>
              <Select
                defaultValue=""
                value={locationID}
                onChange={handleLocationIDChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={selectStyle}
              >
                {locationList.map((e) => (
                  <MenuItem key={e} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
          <Divider sx={{ color: "#F0F1F5" }} />
          <Stack direction="row" sx={{ mt: "12px" }}>
            <OutlinedBorder>
              <Square />
            </OutlinedBorder>
            <Box sx={{ ml: "14px" }} />
            <SubText>Date Range</SubText>
          </Stack>
          <Box sx={dateBoxStyle}>
            <ReactDatePicker
              selectsRange
              selected={startDate}
              onChange={onDateRangeChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-DD"
              inline
              maxDate={new Date()}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="16px"
          >
            <Button
              variant="outlined"
              sx={resetButtonStyle}
              onClick={() => {
                setEventID("");
                setLocationID("");
                setStartDate("");
                setEndDate("");
                setFilterEventId("");
                setFilterLocation("");
                setFilterStartDate("");
                setFilterEndDate("");
                handleClose();
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              height="35px"
              sx={filterButtonStyle}
              onClick={() => {
                if (startDate) {
                  let date = formatFilterDate(startDate);
                  setFilterStartDate(date);
                }

                if (endDate) {
                  let date = formatFilterDate(endDate);
                  setFilterEndDate(date);
                }

                if (eventID) {
                  setFilterEventId(eventID);
                }

                if (locationID) {
                  setFilterLocation(locationID);
                }

                handleClose();
              }}
            >
              Filter
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterEventModal;
