// To move DB services here in the next factoring
import api from "./api";
export const getVendorList = async () => {
  const url = "getvendor";
  try {
    const response = await api.get(url);
    return response?.data["data"];
  } catch (err) {
    // console.log(err);
  }
};
