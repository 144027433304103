import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Card, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";

import Forecast from "../../../Components/Charts/ForecastChart";
import useForecast from "../../../Hooks/useForecast";
import { Header } from "../../../Styles/styles";

import OrderStatusTypeSKUTable from "./OrderStatusTypeSKUTable";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "80vh",
  maxHeight: "95vh",
  maxWidth: "95vw",
  bgcolor: "rgba(226, 226, 226, 1)",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "7% minmax(15%,60%) minmax(15%,31%) minmax(15%,31%)",
  gap: 1,
  gridTemplateAreas: `'header1 header1 header1'
    'sku-info graph graph'
    'sku-sales graph graph '
    'stock-info graph graph'`,
};

const nestedGridContainerStyle = {
  display: "grid",
  maxHeight: "100%",
  // gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "0.1fr auto",
  gridTemplateAreas: `'header'
    'table'
    `,
};

const headerGridStyle = {
  bgcolor: "#bead7c",
  textAlign: "left",
  p: 1,
};

const tableGridStyle = {
  overflow: "auto",
  // textAlign: "right",
};

const forecastStyle = {
  bgcolor: "rgba(245, 241, 220, 1)",
};

const OrderStatusTypeSKUModal = ({
  open,
  handleClose,
  modalSKUDetail,
  isLoading,
  forecastedData,
}) => {
  const { width, height } = useWindowSize();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalContainerStyle}>
        <Box
          sx={{
            gridArea: "header1",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #bead7c",
            }}
          >
            <h3 style={{ marginBottom: 0 }}>SKU Details</h3>
            <Button variant="filled" onClick={handleClose}>
              <CloseIcon sx={{ color: "black" }} />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: "67vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              ...headerGridStyle,
              gridArea: "header",
            }}
          >
            <h6 style={{ marginBottom: 0 }}>SKU Information</h6>
          </Box>
          <Box
            sx={{
              ...tableGridStyle,
              gridArea: "table",
            }}
          >
            <OrderStatusTypeSKUTable
              tableType="sku_info"
              modalSKUDetail={modalSKUDetail}
              isLoading={isLoading}
            />
          </Box>

          <Box
            sx={{
              ...headerGridStyle,
              gridArea: "header",
            }}
          >
            <h6 style={{ marginBottom: 0 }}>SKU Sales</h6>
          </Box>
          <Box
            sx={{
              ...tableGridStyle,
              gridArea: "table",
            }}
          >
            <OrderStatusTypeSKUTable
              tableType="sku_sales"
              modalSKUDetail={modalSKUDetail}
              isLoading={isLoading}
            />
          </Box>

          <Box
            sx={{
              ...headerGridStyle,
              gridArea: "header",
            }}
          >
            <h6 style={{ marginBottom: 0 }}>Stock Information</h6>
          </Box>
          <Box
            sx={{
              ...tableGridStyle,
              gridArea: "table",
            }}
          >
            <OrderStatusTypeSKUTable
              tableType="stock_info"
              modalSKUDetail={modalSKUDetail}
              isLoading={isLoading}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: "graph",
            overflow: "auto",
            // alignSelf: "center",
            // textAlign: "center",
            ml: 2.5,
          }}
        >
          <Box
            sx={{
              ...forecastStyle,
            }}
          >
            <Forecast {...forecastedData} />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="36px"
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                color: "#53545C",
              }}
            >
              {forecastedData.reason ?? ""}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderStatusTypeSKUModal;
