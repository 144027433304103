import api from "../../../Services/api";

const alertError = (err, setAlertInfo) => {
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err?.response?.data["message"] ?? err?.toString(),
  }));
};

export const storeOrderTable = async ({ setter, orderStatus }) => {
  var URL = "";
  switch (orderStatus) {
    case "procurement":
      URL = "polatestpro/";
      break;
    case "management":
      URL = "polatestman/";
      break;
    case "proforma":
      URL = "polatestven";
      break;
    case "proforma":
      URL = "polatestven";
      break;
    case "proforma":
      URL = "polatestven";
      break;
  }

  const response = await api.get(URL);
  const data = response?.data;
  setter(data);
};

export const storeContainerTable = async ({ setter, orderStatus }) => {
  // const containersURL = "";
  // const response = await api.get(containersURL);
  // const data = response?.data;

  const data = [
    {
      ro_num: "test-5",
      vendor: "AT",
      container_id: 73,
      container_size: "20",
      volume_left: 3.9,
      percent_filled: 86,
      is_generated: false,
      is_received: false,
      contents: [
        {
          SKU: "PS0300003030307SQ",
          description: "5FT BEDSLAT (20PCS/BUNDLE) (152.3*7*1.5 CM)",
          status: "Active",
          total_size: 9.8,
          total_qty: 0,
          safety_qty: 0,
          price: 29.38,
          jpn_qty: 1,
          promo_qty: 3,
          demand_qty: 2,
          frombacklog: 1,
          total_qty_ordered: 7,
          remark: "new",
        },
        {
          SKU: "PS0300003030210SQ",
          description: "3.5FT BEDSLAT (20PCS/BUNDLE) (106.5*7*1.5 CM)",
          status: "Active",
          total_size: 11.9,
          total_qty: 0,
          safety_qty: 0,
          price: 20.64,
          jpn_qty: 1,
          promo_qty: 3,
          demand_qty: 2,
          frombacklog: 1,
          total_qty_ordered: 7,
          remark: "new",
        },
      ],
    },
    {
      ro_num: "test-4",
      vendor: "MJ",
      container_id: 107,
      container_size: "20",
      volume_left: 5.0,
      percent_filled: 0.81,
      is_generated: false,
      received: false,
      contents: [
        {
          SKU: "FS0208330020000CM",
          description: "LOIS DINING TABLE 150 X 90 X 74CM",
          status: "Active",
          total_size: 12.1,
          total_qty: 0,
          safety_qty: 0,
          price: 179.5,
          jpn_qty: 0,
          promo_qty: 6,
          demand_qty: 5,
          frombacklog: 0,
          total_qty_ordered: 11,
          remark: "",
        },
        {
          SKU: "FS0208112020000CM",
          description: "LOIS DINING TABLE 135 X 80 X 74CM",
          status: "Active",
          total_size: 9.9,
          total_qty: 0,
          safety_qty: 0,
          price: 159.0,
          jpn_qty: 0,
          promo_qty: 9,
          demand_qty: 0,
          frombacklog: 0,
          total_qty_ordered: 9,
          remark: "",
        },
      ],
    },
    {
      ro_num: "test-4",
      vendor: "MJ",
      container_id: 137,
      container_size: "20",
      volume_left: 27,
      percent_filled: 0.0,
      is_generated: false,
      is_received: false,
      contents: [],
    },
    {
      ro_num: "test-4",
      container_id: 139,
      vendor: "MJ",
      container_size: "20",
      volume_left: 27,
      percent_filled: 0.0,
      is_generated: false,
      is_received: false,
      contents: [],
    },
    {
      ro_num: "test-4",
      container_id: 140,
      vendor: "MJ",
      container_size: "20",
      volume_left: 27,
      percent_filled: 0.0,
      is_generated: false,
      is_received: false,
      contents: [],
    },
  ];

  setter(data);
};

export const storeOrderLater = async ({ setter, orderStatus }) => {
  const allBacklogs = "getbacklog/";
  const response = await api.get(allBacklogs);
  const data = response?.data;
  setter(data);
};

export const storeHistory = async () => {};

export const triggerForecast = async ({ setAlertInfo }) => {
  try {
    const forecastURL = "run_forecast/";
    const response = await api.post(forecastURL);
    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const data = response.data["data"];

        await setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "success",
          msg: data["message"],
        }));
      } else {
        alertError(
          response?.data["message"] ??
            "Something went wrong. Please try again.s",
          setAlertInfo
        );
      }
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error.toString(), setAlertInfo);
  }
};

export const exportOrders = async ({ setAlertInfo }) => {
  try {
    const exportURL = process.env.REACT_APP_API_URL + "exportprocurement/";
    window.open(exportURL);
  } catch (error) {
    alertError(error.toString(), setAlertInfo);
  }
};
