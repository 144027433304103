import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOrderTableDetail } from "../2.CommonChildrenHook/1.OrderTableHooks";
import OrderTypeNav from "../1.OrderStatus/OrderTypeNav";
import { CircleButton } from "../../../Styles/styles";

const OrderTableDetail = () => {
    const navigate = useNavigate();
    const params = useParams();

    const { orderStatus, ro_num: roNum } = params;
    const { tableProps } = useOrderTableDetail({ orderStatus, roNum });

    const { tableInfo, tableContext, filterObject } = tableProps;
    // console.log("OrderTableDetails console log");
    return (
        <div>
            <CircleButton
                onClick={() => navigate(`/orders/${orderStatus}`)}
            >{`<-`}</CircleButton>

            <OrderTypeNav />
        </div>
    );
};

export default OrderTableDetail;
