import React, { useContext } from "react";

import useModal from "../../../Hooks/useModal";
import POFilterModal from "../../Modals/PODB.filter.modal";

import { SVG, SecondaryButton, TertiaryButton } from "../../../Styles/styles";
import filter from "../../../images/Filter.svg";

import { GlobalFilter } from "./Filters";
import SKUFilterModal from "../../Modals/SKUDB.filter.modal";
import VendorFilterModal from "../../Modals/VendorDB.filter.modal";
import ActionDashboardFilterModal from "../../Modals/ActionDashboard.filter.modal";
import OrderFilterModal from "../../Modals/Order.filter.modal";
import ContainerFilterModal from "../../Modals/Container.filter.modal copy";
import { Box, Slider } from "@mui/material";
import { VendorLeadtimeFilterModal } from "../../Modals/VendorLeadtime.filter.modal";

import DownloadIcon from "@mui/icons-material/Download";
import {
  exportOrders,
  triggerForecast,
} from "../../../Pages/Orders/3.OrderServices/order.services";
import { AlertContext } from "../../../App";
import { useOutletContext } from "react-router-dom";

const FilterComponent = ({ handleOpen, filter, instance }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      {/* <SecondaryButton style={{ marginLeft: "10px" }} onClick={handleOpen}>
        <SVG src={filter} />
        Filter
      </SecondaryButton> */}

      <GlobalFilter
        filter={instance?.state.globalFilter}
        setFilter={instance?.setGlobalFilter}
      />
    </div>
  );
};

const ActionDashboardFilterComponent = ({ handleOpen, filter, instance }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <SecondaryButton style={{ marginLeft: "10px" }} onClick={handleOpen}>
        <SVG src={filter} />
        Filter
      </SecondaryButton>

      <GlobalFilter
        filter={instance?.state.globalFilter}
        setFilter={instance?.setGlobalFilter}
      />
    </div>
  );
};

const OrderTableFilterComponent = ({
  handleOpen,
  filter,
  instance,
  setAlertInfo,
  showForecastAndExport,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {showForecastAndExport && (
          <SecondaryButton
            style={{ marginLeft: "10px" }}
            onClick={async () => {
              await triggerForecast({ setAlertInfo });
            }}
          >
            Trigger Forecast
          </SecondaryButton>
        )}
        {showForecastAndExport && (
          <SecondaryButton
            style={{ marginLeft: "10px" }}
            onClick={async () => {
              await exportOrders({ setAlertInfo });
            }}
          >
            Export
            <DownloadIcon />
          </SecondaryButton>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <SecondaryButton style={{ marginLeft: "10px" }} onClick={handleOpen}>
          <SVG src={filter} />
          Filter
        </SecondaryButton>

        <GlobalFilter
          filter={instance?.state.globalFilter}
          setFilter={instance?.setGlobalFilter}
        />
      </div>
    </div>
  );
};

const POFilterComponent = ({ handleOpen, filter, instance }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <SecondaryButton style={{ marginLeft: "16px" }} onClick={handleOpen}>
        <SVG src={filter} />
        Filter
      </SecondaryButton>

      <GlobalFilter
        filter={instance?.state.globalFilter}
        setFilter={instance?.setGlobalFilter}
        label="Search"
      />
    </div>
  );
};

const VendorLeadTimeFilterComponent = ({ handleOpen, filter, instance }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <SecondaryButton style={{ marginLeft: "16px" }} onClick={handleOpen}>
        <SVG src={filter} />
        Filter
      </SecondaryButton>

      <GlobalFilter
        filter={instance?.state.globalFilter}
        setFilter={instance?.setGlobalFilter}
        label="Search"
      />
    </div>
  );
};

const SKUFilterComponent = ({ handleOpen, filter, instance }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <SecondaryButton style={{ marginLeft: "16px" }} onClick={handleOpen}>
        <SVG src={filter} />
        Filter
      </SecondaryButton>

      <GlobalFilter
        filter={instance?.state.globalFilter}
        setFilter={instance?.setGlobalFilter}
        label="Product Search"
      />
    </div>
  );
};

const VendorsFilterComponent = ({ handleOpen, filter, instance }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <SecondaryButton style={{ marginLeft: "16px" }} onClick={handleOpen}>
        <SVG src={filter} />
        Filter
      </SecondaryButton>

      <GlobalFilter
        filter={instance?.state.globalFilter}
        setFilter={instance?.setGlobalFilter}
        label="Search"
      />
    </div>
  );
};

export const PODBFilterRow = ({ instance }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <div>
      <POFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
      />
      {instance && (
        <POFilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
        />
      )}
    </div>
  );
};

export const VendorLeadtimeFilterRow = ({ instance }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <div>
      <VendorLeadtimeFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
      />

      {instance && (
        <VendorLeadTimeFilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
        />
      )}
    </div>
  );
};

export const SKUDBFilterRow = ({ instance, status }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <div>
      <SKUFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
        status={status}
      />

      {instance && (
        <SKUFilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
        />
      )}
    </div>
  );
};

export const VendorDBFilterRow = ({ instance, status }) => {
  const { open, handleOpen, handleClose } = useModal();
  return (
    <div>
      <VendorFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
        status={status}
      />

      {instance && (
        <VendorsFilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
        />
      )}
    </div>
  );
};

export const ActionDashboardFilterRow = ({ instance }) => {
  const { open, handleOpen, handleClose } = useModal();
  return (
    <div>
      <ActionDashboardFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
      />

      {instance && (
        <ActionDashboardFilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
        />
      )}
    </div>
  );
};

export const OrderTableFilterRow = ({ instance, status }) => {
  const { open, handleOpen, handleClose } = useModal();
  const { setAlertInfo } = useContext(AlertContext);
  const { orderStatus } = useOutletContext();
  return (
    <div>
      <OrderFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
        status={status}
      />

      {instance && (
        <OrderTableFilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
          setAlertInfo={setAlertInfo}
          showForecastAndExport={
            orderStatus === "procurement" || orderStatus === "rereview"
          }
        />
      )}
    </div>
  );
};

export const ContainerFilterRow = ({ instance }) => {
  const { open, handleOpen, handleClose } = useModal();
  return (
    <div>
      <ContainerFilterModal
        open={open}
        handleClose={handleClose}
        setFilter={instance?.setFilter}
      />

      {instance && (
        <FilterComponent
          handleOpen={handleOpen}
          filter={filter}
          instance={instance}
        />
      )}
    </div>
  );
};
