import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { AlertContext } from "../../../../App";
import { deleteIncentivePlan } from "../../../../Services/incentiveplantable.service";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#fff",
  boxShadow: "0px 4px 32px rgba(61, 70, 112, 0.08)",
  display: "flex",
  borderRadius: "12px",
};

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const Title = MUIStyled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;

const SubTitle = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 19px;
color: #000;
text-align:center;
`;

const titleBoxStyle = {
  width: "100%",
  height: "90px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "29px 24px",
};

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

const footerBoxStyle = {
  width: "100%",
  height: "110px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 24px",
};

const cancelButtonStyle = {
  width: "180px",
  borderColor: "#53545C",
  color: "#53545C",
  fontSize: "14px",
  fontWeight: 400,
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#bead7c",
  },
};

const submitButtonStyle = {
  width: "180px",
  color: "#000",
  fontSize: "14px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const DeletePlanModal = ({ id, handleClose }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const handleDeletePlan = async (id) => {
    // console.log("handleDeletePlan", id);
    await deleteIncentivePlan(id, setAlertInfo, handleClose);
  };

  return (
    <Box sx={modalContainerStyle}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box sx={titleBoxStyle}>
          <Title>Confirm Delete Incentive Plan</Title>
          <OutlinedBox onClick={handleClose}>
            <ClearIcon sx={closeIconStyle} />
          </OutlinedBox>
        </Box>
        <Box height="24px" />
        <Box sx={{ height: "30%", padding: "24px" }}>
          <SubTitle>
            Are you sure that you want to delete this incentive plan with id -{" "}
            {id}?
          </SubTitle>
        </Box>
        <Box height="24px" />
        <Box sx={footerBoxStyle}>
          <Button
            variant="outlined"
            sx={cancelButtonStyle}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Box width="48px" />
          <Button
            variant="contained"
            sx={submitButtonStyle}
            onClick={() => handleDeletePlan(id)}
          >
            Delete
          </Button>
        </Box>
        <Box height="24px" />
      </Box>
    </Box>
  );
};
export default DeletePlanModal;
