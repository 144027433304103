import api from "../api";

const alertError = (err, setAlertInfo) => {
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err,
  }));
};

export const handleUpload = async (e, setAlertInfo) => {
  const fileUploaded = await e.target.files[0];

  const url = `takevendorexcel/${fileUploaded?.name}`;

  try {
    const response = await api.post(url, fileUploaded);
    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: response.data["message"] ?? "Successfully uploaded the file.",
      }));
    } else {
      alertError(
        response.data["message"] ?? "Failed to upload file.",
        setAlertInfo
      );
    }
  } catch (error) {
    alertError(
      error.response.data["message"] ?? "Failed to upload file.",
      setAlertInfo
    );
  }
};

export const storeLeadTimeData = async ({ setTableData, setAlertInfo }) => {
  const url = "viewvendorleads";
  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const data = response?.data["data"];

        setTableData(data);
      } else {
        alertError(
          response.data["message"] ?? "Failed to load data.",
          setAlertInfo
        );
      }
    } else {
      alertError(
        response.data["message"] ?? "Failed to load data.",
        setAlertInfo
      );
    }
  } catch (error) {
    alertError(
      error.response.data["message"] ?? "Failed to upload file.",
      setAlertInfo
    );
  }
};

export const getVendorLeadtimeFilterData = async ({
  setVendorList,
  setDepartureList,
  setArrivalList,
  setAlertInfo,
}) => {
  const url = "viewvendorleadsfilterconditions";

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const vendorList = response?.data["data"]["vendor_name"];
        const departureList = response?.data["data"]["departure"];
        const arrivalList = response?.data["data"]["arrival"];

        setVendorList(vendorList);
        setDepartureList(departureList);
        setArrivalList(arrivalList);
      } else {
        alertError(
          response.data["message"] ?? "Failed to load data.",
          setAlertInfo
        );
      }
    } else {
      alertError(
        response.data["message"] ?? "Failed to load data.",
        setAlertInfo
      );
    }
  } catch (error) {
    alertError(
      error.response.data["message"] ?? "Failed to load data.",
      setAlertInfo
    );
  }
};

export const handleDownload = () => {
  const downloadLink = process.env.REACT_APP_API_URL + "vendorexcel/";
  window.open(downloadLink);
};
