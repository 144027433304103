const useAuth = () => {
  return [
    JSON.parse(localStorage.getItem("user")),
    (name, email, position, accessToken, refreshToken) => {
      const userInfo = { name, email, position, accessToken, refreshToken };
      localStorage.setItem("user", JSON.stringify(userInfo));
    },
  ];
};

export default useAuth;
