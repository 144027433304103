import React from "react";

import useCommon from "../../Hooks/useCommon";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import OrderTable from "./OrderTable";

import Backlogs from "./Backlogs";
import RejectedTable from "./RejectedTable";
import useRefresh from "../../Hooks/useRefresh";
import Containers from "./Containers/Containers";

const CommonPage = () => {
  const { stage, option, auth } = useCommon();

  // Refresher probably needs Context, to be refactored
  const { refresher, handleRefresh } = useRefresh();

  const procurementTabs = () => {
    return (
      <Tabs defaultActiveKey="Order Table" className="my-4">
        <Tab eventKey="Order Table" title="Order Table">
          <OrderTable
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh} // Refresher probably needs Context, to be refactored
          />
          <hr />
        </Tab>
        <Tab eventKey="Container/PO" title="Container/PO">
          <Containers />
          <hr />
        </Tab>

        <Tab eventKey="Order Later" title="Order Later">
          <Backlogs
            stage={stage}
            option={option}
            refresher={refresher} // Refresher probably needs Context, to be refactored
            handleRefresh={handleRefresh}
          />
        </Tab>

        {/* <Tab eventKey="Intra History" title="Intra History">
          <RejectedTable
            stage={stage}
            option={option}
            refresher={refresher}
            intra={true}
          />
        </Tab>
        <Tab eventKey="PO History" title="PO History">
          <RejectedTable
            stage={stage}
            option={option}
            refresher={refresher}
            intra={false}
          />
        </Tab> */}
        <Tab eventKey="History" title="History">
          <RejectedTable
            option={option}
            refresher={refresher}
          />
        </Tab>
      </Tabs>
    );
  };
  const managementTabs = () => {
    return (
      <Tabs defaultActiveKey="Order Table" className="my-4">
        <Tab eventKey="Order Table" title="Order Table">
          <OrderTable
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh} // Refresher probably needs Context, to be refactored
          />
          <hr />
        </Tab>
        <Tab eventKey="Container/PO" title="Container/PO">
          <Containers />
          <hr />
        </Tab>
        <Tab eventKey="Order Later" title="Order Later">
          <Backlogs
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh}
          />
        </Tab>

        {/* <Tab eventKey="Intra History" title="Intra History">
          <RejectedTable
            stage={stage}
            option={option}
            refresher={refresher}
            intra={true}
          />
        </Tab>
        <Tab eventKey="Previous POs" title="Previous POs">
          <RejectedTable stage={stage} option={option} refresher={refresher} intra={false}/>
        </Tab> */}
        <Tab eventKey="History" title="History">
          <RejectedTable
            option={option}
            refresher={refresher}
          />
        </Tab>
      </Tabs>
    );
  };
  const proformaTabs = () => {
    return (
      <Tabs defaultActiveKey="Order Table" className="my-4">
        <Tab eventKey="Order Table" title="Order Table">
          <OrderTable
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh} // Refresher probably needs Context, to be refactored
          />
          <hr />
        </Tab>
        <Tab eventKey="Container/PO" title="Container/PO">
          <Containers />
          <hr />
        </Tab>
        <Tab eventKey="Order Later" title="Order Later">
          <Backlogs
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh}
          />
        </Tab>

        {/* <Tab eventKey="Intra History" title="Intra History">
          <RejectedTable
            stage={stage}
            option={option}
            refresher={refresher}
            intra={true}
          />
        </Tab> */}
        <Tab eventKey="History" title="History">
          <RejectedTable
            stage={stage}
            option={option}
            refresher={refresher}
          />
        </Tab>
      </Tabs>
    );
  };
  const poGenTabs = () => {
    return (
      <Tabs defaultActiveKey="Order Table" className="my-4">
        <Tab eventKey="Order Table" title="Order Table">
          <OrderTable
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh} // Refresher probably needs Context, to be refactored
          />
          <hr />
        </Tab>
        <Tab eventKey="Container/PO" title="Container/PO">
          <Containers
            stage={stage}
            option={option}
            refresher={refresher}
            handleRefresh={handleRefresh}
          />
          <hr />
        </Tab>

        {/* <Tab eventKey="Intra History" title="Intra History">
          <RejectedTable
            stage={stage}
            option={option}
            refresher={refresher}
            intra={true}
          />
        </Tab> */}
        <Tab eventKey="History" title="History">
          <RejectedTable
            option={option}
            refresher={refresher}
          />
        </Tab>
      </Tabs>
    );
  };

  const userTabsDisplay = (userPosition) => {
    switch (userPosition) {
      case "procurement":
        return stage === "Procurement"
          ? procurementTabs()
          : stage === "Proforma"
          ? proformaTabs()
          : poGenTabs();
      case "management":
        return stage === "Management" ? managementTabs() : poGenTabs();
    }
  };
  return <div>{userTabsDisplay(auth.position)}</div>;
};

export default CommonPage;
