import React, { useState } from "react";
import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { MONTH_ARRAY } from "../../../Services/util";
import ChartFilter from "./turnover.filter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TurnoverChart = () => {
  const [chartData, setChartData] = useState();
  const [selectedSKU, setSelectedSKU] = useState();
  const data = {
    labels: MONTH_ARRAY,
    datasets: [
      {
        label: "Turnovers",
        data: selectedSKU?.value?.turnover,
        yAxisID: "y",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Quantity",
        data: selectedSKU?.value?.quantity,
        yAxisID: "y1",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <>
      <ChartFilter dataSetter={setChartData} />

      {chartData && (
        <>
          <label>SKU</label>
          <Select
            options={chartData}
            value={selectedSKU}
            onChange={setSelectedSKU}
          />
          {selectedSKU && <Bar options={chartOptions} data={data} />}
        </>
      )}
    </>
  );
};

export default TurnoverChart;

export const chartOptions = {
  plugins: {
    title: {
      display: true,
      text: "SKU Turnover and Quantity",
    },
  },
  responsive: true,
  scales: {
    y: {
      position: "left",
      beginAtZero: true,
      title: {
        display: true,
        text: "Turnover",
      },
      ticks: {
        count: 10,
      },
    },
    y1: {
      position: "right",
      beginAtZero: true,
      title: {
        display: true,
        text: "Quantity",
      },
      ticks: {
        count: 10,
      },
    },
  },
};
