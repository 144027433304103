import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import context from "react-bootstrap/esm/AccordionContext";

ChartJS.register(...registerablesJS);

ChartJS.register(
  //annotationPlugin,
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Tooltip,
  Legend
);

const Performance = ({
  year,
  perPrevYearData,
  perYearData,
  percentArray,
  currentYear,
  previousYear,
  label,
  sma_24,
}) => {
  var years1 = year - 1;
  var years3 = year - 3;
  // var percentArray = [11.34, 6.59, 11.05, 58.28, 34.2, -20.09];

  const options = {
    responsive: true,
    aspectRatio: 2.5,
    elements: {
      line: {
        tension: 0.35, // smooth lines
      },
    },
    scales: {
      y: {
        position: "left",
        beginAtZero: true,
        title: {
          display: true,
          text: "Sales Value (SGD)",
        },
      },

      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Sales Value (SGD) in ${years1} and ${year}`,
      },
      autocolors: false,

      tooltip: {
        callbacks: {
          title: function (context) {
            //console.log(context)
            return `${context[0].label}`;
          },
          label: function (context) {
            //console.log(context)
            return `Sales Value (SGD): ${context.formattedValue}`;
          },
          // afterLabel: function (context) {
          //   console.log(context);
          //   if (context.datasetIndex === 0) {
          //     return `% Difference: ${percentArray[context.dataIndex]}`;
          //   }
          // },
        },
      },
    },
  };

  const data = {
    label,
    datasets: [
      {
        type: "bar",
        label: "Sales Value in " + years1, //"3 Year Avg (" + years3 + "-" + years1 + ")",
        data: previousYear,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        type: "bar",
        label: "Sales Value in " + year, //"YTD " + year,
        data: currentYear,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },

      // {
      //   type: "line",
      //   label: "SMA-24",
      //   data: sma_24,
      //   borderColor: "rgba(75, 192, 192, 1)",
      //   backgroundColor: "rgba(75, 192, 192,  0.5) ",
      // },
    ],
  };
  return (
    <>
      <Chart type="bar" options={options} data={data} />
    </>
  );
};

export default Performance;
