import { useEffect, useState } from "react";
import { OrderTableFilterRow } from "../../../Components/Tables/SortFilterTable/FilterRows";
import useOrderTable from "../../../Hooks/useOrderTable";

export const useNewOrderTable = ({ orderStatus, data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [instance, setInstance] = useState();

  const tableProps = {
    tableInfo: { data: filteredData },
    tableContext: { instance, setInstance },
    filterObject: (instance) => (
      <OrderTableFilterRow instance={instance} status={orderStatus} />
    ),
  };

  const filterDataByStage = (data) => {
    let filteredList,
      filteredData = [];

    switch (orderStatus) {
      case "procurement":
        filteredList = ["Order Now"];
        break;
      case "rereview":
        filteredList = ["Re-review"];
        break;
      case "management":
        filteredList = ["Management"];
        break;
      case "proforma":
        filteredList = ["Proforma Current", "Proforma Previous"];
        break;
      case "pogeneration":
        filteredList = ["PO Generation"];
        break;
      case "postoreceive":
        filteredList = ["POs to Receive"];
        break;
    }
    for (let item of data) {
      if (filteredList.includes(item.tags)) {
        filteredData.push(item);
      }
    }
    setFilteredData(filteredData);
  };

  function getOrderByStatus({ data, status }) {
    switch (status) {
      case "procurement":
        return data.filter((e) => e.tags === "Order Now");
      case "rereview":
        return data.filter((e) => e.tags === "Re-review");
      case "management":
        return data.filter((e) => e.tags === "Management");
      case "proforma":
        return data.filter(
          (e) => e.tags === "Proforma Current" || e.tags === "Proforma Previous"
        );
      case "pogeneration":
        return data.filter((e) => e.tags === "PO Generation");
      case "postoreceive":
        return data.filter((e) => e.tags === "POs to Receive");
      default:
        return [];
    }
  }

  useEffect(() => {
    if (data) {
      filterDataByStage(data);
    }
  }, [orderStatus]);

  return { tableProps };
};

export const useOrderTableDetail = ({ orderStatus, roNum }) => {
  const props = useOrderTable({ stage: orderStatus, option: roNum });

  const data = props?.PO?.POdetail;
  const columns = props?.columns;
  const [instance, setInstance] = useState();
  const tableProps = {
    tableInfo: { data, columns },
    tableContext: { instance, setInstance },
    // filterObject: (instance) => <OrderTableFilterRow instance={instance} />,
  };
  return { tableProps };
};
