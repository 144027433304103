import api from "../../../Services/api";
// __________________________________________________________________________________________
export const storeTurnOver = async ({ filterFormData, setter }) => {
  const url = "turnover/";
  const response = await api.post(url, filterFormData);
  const data = response?.data;

  setter(data);
};

export const handleCategoryChange = (selectedCategoryList, setFieldValue) => {
  setFieldValue("categories", selectedCategoryList);
};
export const handleYearChange = (selectedYear, setFieldValue) => {
  setFieldValue("year", selectedYear);
};

export const handleMonthChange = (selectedMonth, setFieldValue) => {
  setFieldValue("month", selectedMonth);
};
// __________________________________________________________________________________________
