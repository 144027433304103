import { Box, Modal, Stack, Button, Typography } from "@mui/material";
import { toggleVenActiveFunc } from "../../Services/services";
import ClearIcon from "@mui/icons-material/Clear";
import { styled as MUIStyled } from "@mui/material/styles";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 42px",
  borderRadius: "12px",
};

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  align-items:flex-end;
  cursor: pointer;
`;

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

const VendorsStatusToggleAlertModal = ({
  open,
  handleClose,
  props,
  setRefresher,
}) => {
  const info = props?.row?.original;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          padding="16px 0px"
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <OutlinedBox onClick={handleClose}>
              <ClearIcon sx={closeIconStyle} />
            </OutlinedBox>
          </Box>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "center",
              paddingBottom: 4,
            }}
          >
            Confirm Update Vendor Status
          </Typography>
          {/* <Box paddingBottom="24px"> */}
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            Vendor : {info.vendorID}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              paddingBottom: 2,
            }}
          >
            Name : {info.name}
          </Typography>
          {/* </Box> */}
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              textAlign: "center",
              paddingBottom: 6,
            }}
          >
            Do you want to update this vendor to{" "}
            {info.active ? "inactive" : "active"}?
          </Typography>

          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="24px"
          >
            <Button
              variant="outlined"
              sx={{
                width: "180px",
                borderColor: "#53545C",
                color: "#53545C",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "12px",
                whiteSpace: "nowrap",
                "&:hover": {
                  borderColor: "#bead7c",
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "180px",
                color: "#000",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: "#bead7c",
                borderRadius: "12px",
                whiteSpace: "nowrap",
                "&:hover": {
                  backgroundColor: "#bead7c",
                },
              }}
              onClick={async () => {
                //async here to make it asynchronous function that always return a promise
                await toggleVenActiveFunc(props);
                setRefresher((prev) => prev + 1);
                handleClose();
              }}
            >
              Update
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default VendorsStatusToggleAlertModal;
