import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import newlogo from "../images/NewLogo.png";
import profile from "../images/ProfilePic.png";

import { Logo, ProfilePic } from "../Styles/styles";

import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Icon,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import useAuth from "../Hooks/useAuth";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { removeUser } from "../Services/auth.service";

const Navbars = () => {
    const auth = useAuth()[0];
    const navigate = useNavigate();
    const handleLogout = () => {
        removeUser();
        navigate("/login");
    };

    const navDropdownTitle = (
        <div style={{ display: "flex" }}>
            <ProfilePic src={profile} />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginLeft: "8px",
                }}
            >
                <p style={{ margin: "0", padding: "0", fontWeight: "bold" }}>
                    {auth.name}
                </p>
                <p
                    style={{
                        margin: "0",
                        padding: "0",
                        fontWeight: "normal",
                        fontSize: "12px",
                    }}
                >
                    {auth.email}
                </p>
            </div>
        </div>
    );

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                // backgroundColor: "#BAB69D",
                backgroundColor: "#bead7c",
                paddingBottom: 15,
                paddingTop: 15,
                paddingRight: "3rem",
            }}
        >
            <NavLink to="/" style={{ paddingTop: "10px" }}>
                <Logo src={newlogo} />
            </NavLink>

            <Navbar>
                <Nav>
                    {navDropdownTitle}
                    <NavDropdown
                        align="end"
                        // title={navDropdownTitle}
                        style={{ marginTop: "8px" }}
                    >
                        <NavDropdown.Item
                            onClick={handleLogout}
                            style={{ color: "black" }}
                        >
                            <Box sx={{ display: "flex" }}>
                                <LogoutIcon />
                                <Box sx={{ pl: 2 }}>Logout</Box>
                            </Box>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar>
        </div>
    );
};

export default Navbars;
