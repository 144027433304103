import api from "../api";

const alertError = (err, setAlertInfo) => {
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err.response.data["message"] ?? err.toString(),
  }));
};

export const getForecastExcelTable = async ({ setForecastExcelTableData }) => {
  //http://127.0.0.1:8000/forecastfiles/
  const url = "forecastfiles";
  const response = await api.get(url);

  setForecastExcelTableData(response.data);
};

export const UploadExcelToForecast = async ({
  e,
  setIsUploading,
  setAlertInfo,
  setRefreshIndicator,
}) => {
  // console.log(e);
  // console.log(setIsUploading);
  const fileUploaded = await e.target.files[0];
  // console.log("here: ", fileUploaded);
  // console.log({ fileUploaded, name: fileUploaded?.name });

  const url = `forecastupload/`;
  const formData = new FormData();
  formData.append("file", fileUploaded);
  try {
    await setIsUploading(true);
    const response = await api.post(url, formData);

    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: "The excel is uploaded and processed successfully!",
    }));
    await setRefreshIndicator((prev) => {
      return !prev;
    });
  } catch (err) {
    alertError(err, setAlertInfo);
  } finally {
    setIsUploading(false);
  }
};

export const downloadForecastExcel = async ({
  filename,
  setAlertInfo,
  setIsDownloading,
}) => {
  const url = `forecastdownload/${filename}`;
  // console.log("downloadExcel URL: ", url);
  try {
    await setIsDownloading(true);
    const response = await api.get(url, { responseType: "blob" });
    // console.log(response);

    const url_2 = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url_2;
    link.setAttribute("download", `${filename}`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);

    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `${filename} will be downloaded now.`,
    }));
  } catch (err) {
    alertError(err, setAlertInfo);
  } finally {
    setIsDownloading(false);
  }
};

export const deleteForecastExcel = async ({
  filename,
  setAlertInfo,
  setIsDeleting,
  setRefreshIndicator,
}) => {
  const url = `forecastdelete/${filename}`;

  try {
    await setIsDeleting(true);
    const response = await api.post(url);

    await setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: `${filename} is deleted!`,
    }));
    await setRefreshIndicator((prev) => {
      return !prev;
    });
  } catch (err) {
    alertError(err, setAlertInfo);
  } finally {
    setIsDeleting(false);
  }
};
