import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SFPTable from "../../../Components/Tables/SortFilterTable/SFPTable";

const OrderTableWrapper = ({ tableProps, orderTableColumns }) => {
  const navigate = useNavigate();
  const columns = useMemo(() => orderTableColumns, [orderTableColumns]);
  const tableInfo = { data: tableProps?.tableInfo?.data, columns };

  return (
    <SFPTable
      tableInfo={tableInfo}
      tableContext={tableProps?.tableContext}
      filterObject={tableProps?.filterObject}
      rowFunc={(row) => {
        const targetRO = row?.original?.ro_num;
        navigate(`${targetRO}/order_table`);
      }}
    />
  );
};

export default OrderTableWrapper;
