import React from "react";
import { Header } from "../../Styles/styles";
import BasicSelect from "./Dropdown";

const ManagementDashboard = () => {
    const YTD2022 = "No information yet";
    const YTD2021 = "No information yet";
    const Difference = YTD2022 - YTD2021;

    //const [yearData, setYearData] = useState([]);
    return (
        <>
            <Header> Management Dashboard </Header>
            {/* enable back once got data */}
            {/* <hr />
            <div>
                {Difference > 0 ? (
                    <h3>
                        YTD Sales 2022 ($): {YTD2022}
                        <span style={{ backgroundColor: "lightGreen" }}>
                            (+{Difference})
                        </span>
                    </h3>
                ) : (
                    <h3>
                        YTD Sales 2022 ($): {YTD2022}
                        <span style={{ backgroundColor: "#F08080" }}>
                            {" "}
                            ({Difference})
                        </span>
                    </h3>
                )}
                <h3> YTD Sales 2021 ($): {YTD2021}</h3>
            </div>
            <hr /> */}
            <BasicSelect />
        </>
    );
};

export default ManagementDashboard;
