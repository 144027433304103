import React, { useContext, useRef, useState } from "react";
import {
  handleDownload,
  handleUpload,
} from "../../../Services/UploadDataServices/leadtime.services";
import { Header, PrimaryButton, PrimaryButton2 } from "../../../Styles/styles";
import LeadtimeTable from "./leadtime.table";
import { Box } from "@mui/material";
import { AlertContext } from "../../../App";

const VendorLeadtime = () => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const hiddenFileInput = React.createRef(null);
  const handleUploadClick = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header>Vendor Leadtime</Header>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => {
              handleUpload(e, setAlertInfo);
            }}
            hidden
          />
          <PrimaryButton2 onClick={handleUploadClick}>Upload</PrimaryButton2>
          <PrimaryButton onClick={handleDownload}>Download</PrimaryButton>
        </Box>
      </div>
      <hr />
      <LeadtimeTable />
    </>
  );
};

export default VendorLeadtime;
