import React, { useState } from "react";
import produce from "immer";

import { getRandomColor } from "../ProcurementChart/procurement.chart.services";
import { Bar } from "react-chartjs-2";
import ChartFilter from "./outstanding.filter";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OutstandingROChart = () => {
  const [chartData, setChartData] = useState();

  const datasets = produce(chartData, (draft) => {
    draft?.datasets?.forEach((item, index) => {
      if (index === 0) {
        item.backgroundColor = "#8ea99d";
      } else {
        item.backgroundColor = "#89837a";
      }
    });
  })?.datasets;

  const data = {
    labels: chartData?.labels,
    datasets,
  };
  return (
    <>
      <ChartFilter dataSetter={setChartData} />
      {/* <pre>{JSON.stringify(chartData)}</pre> */}
      {chartData && <Bar options={chartOptions} data={data} />}
    </>
  );
};

export const chartOptions = {
  indexAxis: "y",
  plugins: {
    title: {
      display: true,
      text: "Outstanding ROs",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,

      title: {
        display: true,
        text: "Value (SGD)",
      },
    },
    y: {
      position: "left",
      beginAtZero: true,
      stacked: true,
      title: {
        display: true,
        text: "Vendors",
      },
    },
  },
};

export default OutstandingROChart;
