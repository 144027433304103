import React, { useEffect, useState } from "react";

import { getBacklog } from "../../Services/services";
import Backlog from "./Backlog";

const Backlogs = ({ stage, option, refresher, handleRefresh }) => {
  const [backlogData, setBacklogData] = useState([]);

  useEffect(() => {
    getBacklog(option, setBacklogData);
  }, [stage, option, refresher]);

  return (
    <div>
      {backlogData.length !== 0 ? (
        <div>
          <p>
            The cards below show the pending backlogs for the particular RO.
          </p>
          <p>Click on the cards to view content.</p>
        </div>
      ) : (
        <div>There are no backlogs, hurray!</div>
      )}
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
        }}
      >
        {backlogData.map((backlog) => (
          <Backlog
            key={backlog.PONUMBER}
            backlogInfo={backlog}
            handleRefresh={handleRefresh}
          />
        ))}
      </div>
    </div>
  );
};

export default Backlogs;
