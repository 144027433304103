import React from "react";
import { Field, SecondaryButton } from "../../Styles/styles";
import { Formik, Form } from "formik";

import { generatePO } from "../../Services/invoice.services";

const GeneratePOForm = ({ containerID }) => {
  return (
    <div>
      <Formik
        initialValues={{
          comments: "",
        }}
        onSubmit={(values) => {
          // console.log({
          //   containerID,
          //   ponum: values?.ponum,
          //   comments: values?.comments,
          // });
          generatePO({
            containerID,
            ponum: values?.ponum,
            comments: values?.comments,
          });
        }}
      >
        {({ handleChange }) => (
          <Form>
            <label>PO Number</label>
            <Field type="text" name="ponum" onChange={handleChange} />

            <label>Comments</label>
            <Field type="text" name="comments" onChange={handleChange} />
            <div style={{ marginTop: "10px" }}>
              <SecondaryButton type="submit">Generate PO</SecondaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneratePOForm;
