import { useContext, useState } from "react";
import { AlertContext } from "../../../App";
import HomeIcon from "../../../images/HomeIcon.svg";
import {
  EventActiveTab,
  EventInactiveTab,
  IconSVG,
  Title,
} from "../../../Styles/styles";

import { NavLink, Outlet } from "react-router-dom";

const SafetyStock = () => {
  return (
    <div style={{ background: "#F4F5FA" }}>
      <div
        style={{
          display: "flex",
          padding: "3px",
          alignItems: "center",
          background: "#FFF",
        }}
      >
        <IconSVG
          src={HomeIcon}
          style={{
            alignSelf: "self-start",
            marginTop: "4px",
          }}
        />
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>Safety Stock</Title>
      </div>

      <div
        style={{
          marginTop: "3px",
          padding: "20px",
        }}
      >
        <NavLink
          to="active"
          children={({ isActive }) =>
            SafetyStockButton({ isActive, text: "Active" })
          }
        />

        <NavLink
          to="inactive"
          children={({ isActive }) =>
            SafetyStockButton({ isActive, text: "Inactive" })
          }
        />
      </div>
      <Outlet />
    </div>
  );
};

const SafetyStockButton = ({ isActive, text }) => {
  return isActive ? (
    <EventActiveTab>{text}</EventActiveTab>
  ) : (
    <EventInactiveTab>{text}</EventInactiveTab>
  );
};

export default SafetyStock;
