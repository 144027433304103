import React, { useContext, useLayoutEffect, useRef } from "react";

import Container from "./Container";
import AddContainer from "./AddContainer";

import { ContainerContext } from "../../../Contexts/container.context";

const ContainerRow = () => {
  const { containers, handleClick, page } = useContext(ContainerContext);

  return (
    <>
      {page === "Proforma" ? (
        <div>
          The containers highlighted in green represent containers in which PO
          has been generated.
          To add containers, click on the plus icon. To delete, click on the delete icon on the cards.
        </div>
      ) : page === "POGeneration" || page === "POsToReceive" ? (
        <div>
          The containers highlighted in green represent containers received from
          vendor. 
          To add containers, click on the plus icon. To delete, click on the delete icon on the cards.
        </div>
      ) : (
        <div>
          To add containers, click on the plus icon. To delete, click on the delete icon on the cards.
        </div>
      )}
      <AddContainer />
      <div style={{ display: "flex", overflowX: "scroll" }}> 
        {containers?.map((container, index) => (
          <Container
            key={index}
            container={container}
            handleClick={() => {
              handleClick({ index });
            }}
          />
        ))}
      </div>
    </>
  );
};

export default ContainerRow;
