import api from "../../../Services/api";
import { getVendorList } from "../../../Services/db.services";

export const storeVendorOptions = async ({ setVendorOptions }) => {
  const vendorList = await getVendorList();
  const results = vendorList?.map((item) => ({
    label: `${item?.vendorID} - ${item?.name}`,
    value: item?.vendorID,
  }));

  setVendorOptions(results);
};

export const handleVendorChange = (selectedVendorList, setFieldValue) => {
  setFieldValue("vendors", selectedVendorList);
};

export const getRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return "#" + randomColor;
};

export const storeProcurementChart = async ({ filterFormData, setter }) => {
  const url = "procurement/";
  const response = await api.post(url, filterFormData);

  const data = response?.data;

  setter(data);
};
