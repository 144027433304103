import React from "react";
import { useTable } from "react-table";
import "./table.css";
import exclamation from "../../images/Exclamation.svg";
import styled from "styled-components";

const SVG = styled.img`
  height: 18px;
`;

const RowExpandTable = ({ columns, data, initialState, rowFunc }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      initialState,
    });

  return (
    <div style={{ maxHeight: "60vh", overflow: "auto" }}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <th
                    className="table-tooltip"
                    style={{ zIndex: 100 - index }} // Picked a sufficiently large number, 100 is just randomly picked
                    {...column.getHeaderProps()}
                  >
                    {headerGroup.headers[index].tipText && (
                      <span>{headerGroup.headers[index].tipText}</span>
                    )}
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onDoubleClick={() => rowFunc(row)}
                style={{ cursor: "pointer" }}
              >
                {row.cells.map((cell) => {
                  const flagIndex = row.allCells.length - 1;
                  const flag = row.allCells[flagIndex].value; // Row flag(to highlight rows that have changed)
                  return flag === "true" ? (
                    cell.value.flag === "true" ? ( // Cell flag (to highlight cells of said rows that have changed)
                      <td
                        {...cell.getCellProps()}
                        style={{ backgroundColor: "lightGreen" }}
                      >
                       {" "}{cell.render("Cell")}
                      </td>
                    ) : (
                      <td
                        {...cell.getCellProps()}
                        style={{ backgroundColor: "#E6FFEC" }} // Row of this cell has changed, but not this cell(i.e, other cells of this row has changed)
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  ) : cell.column.tipText === "SKU IDs" &&
                    data[index].similar_purchased_flag ? ( // flag if there is purchase of similar items
                    <td {...cell.getCellProps()}>
                      <span
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        {cell.render("Cell")}
                        <SVG
                          style={{ marginTop: "2px", marginLeft: "5px" }}
                          src={exclamation}
                          onClick={() => rowFunc(row, true)}
                        />
                      </span>
                    </td>
                  ) : (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RowExpandTable;
