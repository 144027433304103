import React, { useContext, useEffect } from "react";

import { Box, Button, Drawer, Slider, Stack, Typography } from "@mui/material";
import { currencyFormatter } from "../../Services/util";
import { useState } from "react";
import ReactSelect from "react-select";
import { AlertContext } from "../../App";

import { getSKUFiltersFieldsData } from "../../Services/services";

export const SKUFilterModal = ({ open, handleClose, setFilter, status }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [price, setPrice] = useState([0, 10]);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [priceRange, setPriceRange] = useState({
    price_start: 0,
    price_end: 10,
  });

  function valuetext(value) {
    return `${currencyFormatter(value, "$")}`;
  }

  const handleChange = (event, newValue) => {
    setPrice(newValue);
  };

  useEffect(() => {
    getSKUFiltersFieldsData({
      setVendorList,
      setCategoryList,
      setPriceRange,
      setAlertInfo,
      status,
    });
  }, [status]);

  useEffect(() => {
    if (priceRange) {
      setPrice([priceRange.price_start ?? 0, priceRange.price_end ?? 10]);
    }
  }, [priceRange]);

  const vendorOptions = () => {
    return vendorList
      .map((vendor) => {
        return {
          value: vendor,
          label: vendor.vendorID + " - " + (vendor?.name ?? ""),
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };

  const categoryOptions = () => {
    return categoryList.map((category) => {
      return {
        value: category,
        label: category,
      };
    });
  };

  const handleSelectCategory = (e) => {
    setSelectedCategory(e?.value);
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label:
                    selectedVendor.vendorID +
                    " - " +
                    (selectedVendor?.name ?? ""),
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Category
            </Typography>
            <ReactSelect
              value={
                selectedCategory && {
                  value: selectedCategory,
                  label: selectedCategory,
                }
              }
              options={categoryOptions()}
              onChange={handleSelectCategory}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="4px"
            >
              Price
            </Typography>
            <Box paddingLeft="8px" paddingRight="8px">
              <Slider
                getAriaLabel={() => "Price range"}
                value={price}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={priceRange.price_start}
                max={priceRange.price_end}
                marks={[
                  {
                    value: priceRange.price_start,
                    label: currencyFormatter(priceRange.price_start, "$"),
                  },
                  {
                    value: priceRange.price_end,
                    label: currencyFormatter(priceRange.price_end, "$"),
                  },
                ]}
                sx={{
                  color: "#bead7c",
                  "&  [data-index='1'].MuiSlider-markLabel": {
                    marginLeft: "-25px",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setPrice([priceRange.price_start, priceRange.price_end]);
              setSelectedCategory("");
              setSelectedVendor("");
              setFilter("vendor_id", "");
              setFilter("vendor_name", "");
              setFilter("category", "");
              setFilter("price", (old = []) => [undefined, undefined]);
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilter("vendor_id", selectedVendor?.vendorID);
              setFilter("vendor_name", selectedVendor?.name);
              setFilter("category", selectedCategory);
              setFilter("price", (old = []) => [price[0], price[1]]);
              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default SKUFilterModal;
