import React, { useEffect, useState, useMemo } from "react";

import SFPTable from "../../../Components/Tables/SortFilterTable/SFPTable";
import { storeSKUSet } from "../../../Services/services";

import { skuColumns } from "../../../Components/Tables/columns";
import { SKUDBContext, SKUDBProvider } from "../../../Contexts/SKUDB.context";
import { SKUDBFilterRow } from "../../../Components/Tables/SortFilterTable/FilterRows";

import { Box } from "@mui/material";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { Header } from "../../../Styles/styles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const SKUDB = () => {
  // for tableInfo
  const [SKUSet, setSKUSet] = useState(["Not Loaded Yet"]);
  const [refresher, setRefresher] = useState(0);
  const columns = useMemo(skuColumns(setRefresher), []);

  // Tabs
  const [key, setKey] = useState("active");

  useEffect(() => {
    setSKUSet(["Not Loaded Yet"]);
    storeSKUSet(setSKUSet, key);
  }, [refresher, key]);

  return (
    <SKUDBProvider>
      <Header>SKUDB Database</Header>
      <hr />
      <Tabs
        id="skuDb-tab"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
      >
        <Tab eventKey="active" title="Active">
          {SKUSet[0] === "Not Loaded Yet" ? (
            <Box sx={{ height: 400 }}>
              <SpinnerContainer>
                <LoadingSpinner />
              </SpinnerContainer>
            </Box>
          ) : (
            <SFPTable
              tableInfo={{ data: SKUSet, columns }}
              tableContext={SKUDBContext}
              filterObject={(instance) => (
                <SKUDBFilterRow instance={instance} status={key} />
              )}
              rowFunc={console.log}
            />
          )}
        </Tab>
        <Tab eventKey="inactive" title="Inactive">
          {SKUSet[0] === "Not Loaded Yet" ? (
            <Box sx={{ height: 400 }}>
              <SpinnerContainer>
                <LoadingSpinner />
              </SpinnerContainer>
            </Box>
          ) : (
            <SFPTable
              tableInfo={{ data: SKUSet, columns }}
              tableContext={SKUDBContext}
              filterObject={(instance) => (
                <SKUDBFilterRow instance={instance} status={key} />
              )}
              rowFunc={console.log}
            />
          )}
        </Tab>
      </Tabs>
    </SKUDBProvider>
  );
};

export default SKUDB;
