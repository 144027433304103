import api from "./api";
import axios from "axios";
export function getLocalRefreshToken() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refreshToken;
}

export function getAccessToken() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.accessToken;
}

export function updateLocalAccessToken(token) {
  let user = JSON.parse(localStorage.getItem("user"));
  user.accessToken = token;
  setUser(user);
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export const getUserInfo = async (accessToken, refreshToken, setAuth) => {
  const response = await api.get("getuserinfo", {
    headers: { Authorization: "Bearer " + accessToken },
  });
  const name = response?.data?.name;
  const email = response?.data?.email;
  const position = response?.data?.position;
  setAuth(name, email, position, accessToken, refreshToken);
};

export function setUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function removeUser() {
  localStorage.clear();
}

export const login = async (email, password, setAuth) => {
  const url = "/auth/jwt/create/";
  const response = await api
    .post(url, {
      email,
      password,
    })
    .then(async (response) => {
      const accessToken = response?.data?.access;
      const refreshToken = response?.data?.refresh;
      getUserInfo(accessToken, refreshToken, setAuth);
    });
};

export const apiLogout = () => {
  removeUser();
  window.location.replace("/login");
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
