import numeral from "numeral";

export function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export const numToCurrency = (num) => {
  return Math.round(num * 100) / 100;
};

export const currencyFormatter = (price, currency) => {
  return numeral(price).format(
    price ? `${currency ?? ""} 0,0.00` : `${currency} 0,0.00`
  );
};

export const dateFormatter = (value) => {
  if (value) {
    let date = new Date(value);

    if (!isNaN(date.getDate())) {
      const day = date.toLocaleString("default", { day: "2-digit" });
      const month = date.toLocaleString("default", { month: "2-digit" });
      const year = date.toLocaleString("default", { year: "numeric" });
      const hour = date.toLocaleString("default", {
        hour: "2-digit",
        hour12: false,
      });
      const minute = date.toLocaleString("default", { minute: "2-digit" });
      return (
        year + "-" + month + "-" + day + ` ${hour}:${minute.padStart(2, "0")}`
      );
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export function getPOStatusLabel(status) {
  switch (status) {
    case 1:
      return "Procurement Stage";
    case 2:
      return "Management Stage";
    case 3:
      return "Vendor";
    case 4:
      return "Shipped But Not Yet Received";
    case 5:
      return "Received";
    case 6:
      return "Backlog";
    default:
      return "Error";
  }
}

export const monthToNum = (month) => {
  const months = {
    January: 1,
    Febuary: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  return months[month];
};

export const MONTH_ARRAY = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June", // 5
  "July", // 6
  "August", // 7
  "September", // 8
  "October", // 9
  "November", // 10
  "December", // 11
];

export const forecastArrayShifter = ({ currMonth }) => {
  const result = [];
  for (let i = 0; i < MONTH_ARRAY.length; i++) {
    const newPos = (i + (8 - monthToNum(currMonth) + 1) + 12) % 12;
    result[newPos] = MONTH_ARRAY[i];
  }
  return result;
};

// (1 + (8 - 9) + 12) % 12;
