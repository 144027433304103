import React from "react";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { currencyFormatter } from "../../../Services/util";

const OrderStatusTypeSKUTable = ({ tableType, modalSKUDetail, isLoading }) => {
  let tableData = [];
  switch (tableType) {
    case "sku_info":
      tableData = [
        ["SKU ID: ", modalSKUDetail.sku],
        ["Product Code: ", modalSKUDetail.shortcode ?? ""],
        ["SKU Name: ", modalSKUDetail.description ?? ""],
        ["Vendor Cost/Qty: ", currencyFormatter(modalSKUDetail.priceqty, "$")],
        ["Currency: ", modalSKUDetail.currency ?? ""],
        ["Category: ", modalSKUDetail.Category],
        ["Similar Items: ", modalSKUDetail.similar_items],
      ];
      break;
    case "sku_sales":
      tableData = [
        [
          "Gross Sales / Month (6 Months): ",
          currencyFormatter(modalSKUDetail.grosssales, "$"),
        ],
        [
          "Discount / Month (6 Months): ",
          currencyFormatter(modalSKUDetail.discount, "$"),
        ],
        [
          "Net Sales / Month (6 Months): ",
          currencyFormatter(modalSKUDetail.netsales, "$"),
        ],
        [
          "Total Sales(YTD): ",
          currencyFormatter(modalSKUDetail.totalsales, "$"),
        ],
        ["Avg Qty Sold / Month (YTD): ", modalSKUDetail.avgqtysoldytd],
        ["Avg Qty Sold / Month (3 Months): ", modalSKUDetail.avgqtysold3months],
        ["Avg Qty on Promo: ", modalSKUDetail.promo],
      ];
      break;
    case "stock_info":
      tableData = [
        ["Total Stock: ", modalSKUDetail.totalstock],
        ["No. of JPN Qty: ", modalSKUDetail.jpnqty],
        ["No. of SG Qty: ", modalSKUDetail.sgqty],
      ];
      break;
  }

  return (
    <>
      {isLoading ? (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      ) : (
        <table size="small">
          <tbody>
            {tableData.map((array, index) => {
              return (
                <tr key={`${tableType}-${index}`}>
                  <td variant="head" style={{ width: "35%" }}>
                    {array[0]}
                  </td>
                  {typeof array[1] === "object" ? (
                    <td>
                      {array[1]?.map(
                        ({ ponumber, sku_id, vendor, qty_ordered }) => {
                          return (
                            <li key={`${ponumber} - ${sku_id}`}>
                              {ponumber} - {sku_id} ({qty_ordered})
                            </li>
                          );
                        }
                      )}
                    </td>
                  ) : (
                    <td style={{ textAlign: "right" }}>{array[1]}</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default OrderStatusTypeSKUTable;
