import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Outlet, useNavigate } from "react-router-dom";

export default function BasicSelect() {
  const [category, setCategory] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCategory(e.target.value);
    navigate(e.target.value);
  };

  return (
    <>
      <Box sx={{ minWidth: 120, pt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="dropwdown">Category...</InputLabel>
          <Select
            labelId="dropwdown"
            id="dropdown-select"
            value={category}
            label="Category"
            onChange={handleChange}
          >
            {/* <MenuItem value={"Performance"}>Performance</MenuItem>
            <MenuItem value={"Revenue"}>Revenue</MenuItem>
            <MenuItem value={"Productivity"}>Productivity</MenuItem>
            <MenuItem value={"Busyness"}>Busyness</MenuItem>
            <MenuItem value={"Popularity"}>Popularity</MenuItem> */}
            <MenuItem value={"Turnover"}>Turnover</MenuItem>
            <MenuItem value={"Procurement"}>Procurement</MenuItem>
            <MenuItem value={"Outstanding"}>Outstanding ROs</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Outlet />
    </>
  );
}
