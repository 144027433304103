import React, { useState } from "react";
import axios from "axios";
import { getUserAPI, logInAPI } from "../../Components/Services/apiURL";

import useAuth from "../../Hooks/useAuth";

import { useNavigate, useLocation } from "react-router-dom";

import styled from "styled-components";
import {
  PrimaryButton,
  SecondaryButton,
  ClickableText,
} from "../../Styles/styles";

//Scanteak Logo

//Icons for Circle Button
import newlogo from "../../images/NewLogo.png";

const Background = styled.div`
  min-height: 100vh;
  background-color: #fbf9f3;
`;

//Scanteak Logo
const Logo = styled.img`
  height: 17.5vh;
  /* width: 50vh; */
  /* margin-bottom: 5vh; */
`;

const Input = styled.input`
  border-radius: 10px;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0px;
`;

const Button = styled.button`
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 20px;
  height: 38px;
  width: 100%;
  background: #ffffff;
  color: #485303;
  size: "18px";
  border: 0px;
`;

export const LoginLogo = styled.img`
  width: 25vh;
  height: 25vh;
  border-radius: 15vh;
`;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  // For logging in
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const setAuth = useAuth()[1];

  const getUser = async (accessToken, refreshToken) => {
    const response = await axios.get(getUserAPI, {
      headers: { Authorization: "Bearer " + accessToken },
    });
    const name = response?.data?.name;
    const email = response?.data?.email;
    const position = response?.data?.position;
    setAuth(name, email, position, accessToken, refreshToken);
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(logInAPI, JSON.stringify({ email, password }), {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (response) => {
          const accessToken = response?.data?.access;
          const refreshToken = response?.data?.refresh;
          getUser(accessToken, refreshToken);
        });
      // navigate(from, { replace: true });
    } catch (err) {
      setEmail("");
      setPassword("");
      if (!err?.response) {
        // console.log("No Server Response");
      } else if (err.response?.status === 400) {
        // console.log("Missing Username or Password");
      } else if (err.response?.status === 401) {
        // console.log("Unauthorized");
      } else {
        // console.log("Login Failed");
      }
    }
  };

  return (
    <Background>
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "50px",
          }}
        >
          <Logo src={newlogo}></Logo>
          <form
            style={{
              width: "30%",
            }}
          >
            <div className="form-group" style={{ marginBottom: "10px" }}>
              <label>Email address</label>
              <Input
                type="email"
                className="form-control"
                id="email"
                placeholder="Key in email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ display: "flex" }}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <Input
                type="password"
                className="form-control"
                id="password"
                placeholder="Key in password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {/* <ClickableText href="/">Forgotten Password?</ClickableText> */}
            <div style={{ height: "16px" }}></div>
            <div
              className="my-2"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center ",
              }}
            >
              <PrimaryButton
                onClick={handleSubmit}
                style={{
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                Sign in
              </PrimaryButton>
            </div>
          </form>
          {/* <div style={{ display: "flex" }}>
            <CircleButton>
              <SVG src={gear} />
            </CircleButton>
            <CircleButton>
              <SVG src={question} />
            </CircleButton>
          </div> */}
        </div>
      </div>
    </Background>
  );
};

export default Login;
