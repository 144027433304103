import React, { useState } from 'react';
import { Card } from "@mui/material";
import { DashboardButton } from "../../Styles/styles";
import Productivity from '../../Components/Charts/ProductivityChart';

const ProductivityDashboard=({
    year, 
    proYearTopData, proTopLabels,
    proYearBotData, proBotLabels, 
    proPrevYearTopData, proPrevTopLabels, 
    proPrevYearBotData, proPrevBotLabels })=> {
    const [currYearData, setCurrYearData] = useState(proYearTopData);
    const [currLabelData, setCurrLabelData] = useState(proTopLabels);
    const [prevYearData, setPrevYearData] = useState(proPrevYearTopData);
    const [prevLabelData, setPrevLabelData] = useState(proPrevTopLabels);

    const top5CurrYear = () => {
        setCurrYearData(proYearTopData);
        setCurrLabelData(proTopLabels);
        };
    const bot5CurrYear = () => {
        setCurrYearData(proYearBotData);
        setCurrLabelData(proBotLabels);
        };

    const top5PrevYear = () => {
        setPrevYearData(proPrevYearTopData);
        setPrevLabelData(proPrevTopLabels);
        };
    const bot5PrevYear = () => {
        setPrevYearData(proPrevYearBotData);
        setPrevLabelData(proPrevBotLabels);
        };

    return(
        <>
            <div style={{marginTop: '3px', textAlign: 'justify', fontSize: '14px'}}>
                *The chart displays the top and bottom 5 products across all stores. Click on the 'Top 5' and 'Bottom 5' buttons to toggle information. 
           </div>
           <div style={{textAlign: 'justify', fontSize: '14px'}}> Note: Productivity is measured by total revenue/ no. of employees. </div>
            <div className="d-flex flex-inline justify-content-between">
            <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}>
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5CurrYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5CurrYear}>
                        Bottom 5
                    </DashboardButton>
                    {currYearData && currLabelData &&
                    <Productivity year={year} dataYear={currYearData} labelsYear={currLabelData}/>}
                </Card> 
            <Card sx={{
                    width: 1000,
                    height: 325,
                    my: 3
                    }}>
                    <DashboardButton className="hoverSecWhiteButton" onClick={top5PrevYear}>
                        Top 5
                    </DashboardButton>
                    <DashboardButton className="hoverSecWhiteButton" onClick={bot5PrevYear}>
                        Bottom 5
                    </DashboardButton>
                    {prevYearData && prevLabelData &&
                    <Productivity year={year-1}  dataYear={prevYearData} labelsYear={prevLabelData}/>}
                </Card> 
            </div>
        </>
    );
};

export default ProductivityDashboard;
