import React, { useState, useEffect } from "react";
import { IconSVG, Title } from "../../../../Styles/styles";
import HomeIcon from "../../../../images/HomeIcon.svg";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";

import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useModal from "../../../../Hooks/useModal";
import ReviewPromoGroupCard from "./ReviewPromoGroupCard";

const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
`;

const Label = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8b8d97;
`;

const Value = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #45464e;
`;

const ExpandListtileTitle = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #45464E;
`;

const ReviewEvent = () => {
  const { open, handleOpen, handleClose } = useModal();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expand, setExpand] = useState(true);

  const handleExpandClick = () => {
    setExpand(!expand);
  };

  return (
    <div style={{ background: "#F4F5FA" }}>
      <div
        style={{
          display: "flex",
          padding: "3px",
          alignItems: "center",
          background: "#FFF",
        }}
      >
        <IconSVG src={HomeIcon} />
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>Events</Title>
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>New Event</Title>
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>Review</Title>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            endIcon={<ExpandMore />}
            sx={{
              width: "170px",
              height: "35px",
              borderColor: "#bead7c",
              color: "#000",
              margin: "0px 8px",
              fontSize: "14px",
              fontWeight: 400,
              borderRadius: "12px",
              "&:hover": {
                borderColor: "#bead7c",
              },
            }}
          >
            Edit Event
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "160px",
              height: "35px",
              color: "#000",
              fontSize: "14px",
              fontWeight: 400,
              backgroundColor: "#bead7c",
              borderRadius: "12px",
              "&:hover": {
                backgroundColor: "#bead7c",
              },
            }}
          >
            Submit
          </Button>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "880px",
            alignItems: "center",
            justifyContent: "center",
            padding: "24px 0px",
          }}
        >
          <Text>Review</Text>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              padding: "28px 32px",
            }}
          >
            <Grid container columnSpacing={3} rowSpacing={6}>
              <Grid item xs={4}>
                <Box>
                  <Label>Event Name</Label>
                  <Value>Christmas Special 2022</Value>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <Label>Location</Label>
                  <Value>Tanglin Mall</Value>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Label>Additional Note</Label>
                  <Value>-</Value>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Label>Start Date</Label>
                  <Value>12 Nov 2022</Value>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <Label>End Date</Label>
                  <Value>12 Dec 2022</Value>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Label>Event Type</Label>
                  <Value>External</Value>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "36px",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ExpandListtileTitle>Promo Groups</ExpandListtileTitle>
              <IconButton onClick={handleExpandClick}>
                {expand ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                height="35px"
                startIcon={<AddSharpIcon sx={{ color: "#000" }} />}
                sx={{
                  width: "170px",
                  height: "35px",
                  borderColor: "#bead7c",
                  color: "#000",
                  margin: "0px 8px",
                  fontSize: "11px",
                  fontWeight: 400,
                  borderRadius: "12px",
                  "&:hover": {
                    borderColor: "#bead7c",
                  },
                }}
              >
                Save Template
              </Button>
              <Button
                variant="contained"
                height="35px"
                startIcon={<AddSharpIcon sx={{ color: "#000" }} />}
                sx={{
                  color: "#000",
                  fontSize: "11px",
                  fontWeight: 400,
                  backgroundColor: "#bead7c",
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#bead7c",
                  },
                }}
              >
                Add Promo Group
              </Button>
            </div>
          </div>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ReviewPromoGroupCard />
              <ReviewPromoGroupCard />
            </List>
          </Collapse>
        </Box>
      </Box>
    </div>
  );
};

export default ReviewEvent;
