import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(...registerablesJS);

ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Tooltip,
  Legend
);

var today = new Date();
var year = () => {
  if (today.getMonth() > 7) {
    return today.getFullYear() + "/" + (today.getFullYear() + 1);
  }
  return today.getFullYear();
};

const Forecast = ({ curMonth, forecast, pastBought, label, ema_6, sma_18 }) => {
  const options = {
    responsive: true,
    aspectRatio: 2.5,
    elements: {
      line: {
        tension: 0.35, // smooth lines
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: false,
        title: {
          display: true,
          text: "Quantity",
        },
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
    },

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: year() + " SKU Forecast",
        font: {
          size: "20px",
          family: "Inter",
          style: "normal",
          weight: 500,
        },
      },
    },
  };

  const data = {
    labels: label,
    datasets: [
      {
        type: "bar",
        label: "Actual",
        data: pastBought,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5) ",
      },
      {
        type: "bar",
        label: "Forecast",
        data: forecast,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        type: "line",
        label: "EMA-6",
        data: ema_6,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192,  0.5) ",
      },
      {
        type: "line",
        label: "SMA-18",
        data: sma_18,
        borderColor: "rgb(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86,  0.5)",
      },
    ],
  };
  return (
    <>
      <Chart type="bar" options={options} data={data} />
    </>
  );
};

export default Forecast;
