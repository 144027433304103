import { useState, createContext } from "react";

export const VendorDBContext = createContext();

export const VendorDBProvider = ({ children }) => {
  const [refresher, setRefresher] = useState(0);
  const [instance, setInstance] = useState();

  return (
    <VendorDBContext.Provider
      value={(instance, setInstance, refresher, setRefresher)}
    >
      {children}
    </VendorDBContext.Provider>
  );
};
