import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { AlertContext } from "../../App";
import ReactSelect from "react-select";
import { getVendorsFiltersFieldsData } from "../../Services/services";

const VendorFilterModal = ({ open, handleClose, setFilter, status }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  useEffect(() => {
    getVendorsFiltersFieldsData({ setVendorList, setAlertInfo, status });
  }, [status]);

  const vendorOptions = () => {
    return vendorList
      .map((vendor) => {
        return {
          value: vendor,
          label: vendor.vendorID + " - " + (vendor?.name ?? ""),
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };
  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label:
                    selectedVendor.vendorID +
                    " - " +
                    (selectedVendor?.name ?? ""),
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setSelectedVendor("");
              setFilter("vendorID", "");
              setFilter("name", "");
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilter("vendorID", selectedVendor?.vendorID);
              setFilter("name", selectedVendor?.name);
              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default VendorFilterModal;
