import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Card, Stack } from "@mui/material";
import ModalTable from "../Tables/TableInModal";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { storeSKUSetByVendorID } from "../../Services/services";
import { VendorSKUTableColumns } from "../Tables/columns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "70%",
  height: "90vh",
};

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

const VendorDetailModal = ({ open, handleClose, rowInfo }) => {
  const data = rowInfo[0];
  const skuCols = useMemo(() => VendorSKUTableColumns, []);
  const [sku, setSku] = useState([]);

  useEffect(() => {
    storeSKUSetByVendorID(setSku, data.vendorID);
  }, []);

  return (
    <Box sx={style}>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <h1>Vendor Information</h1>
        <OutlinedBox onClick={handleClose}>
          <ClearIcon sx={closeIconStyle} />
        </OutlinedBox>
      </Stack>

      <Card sx={{ my: 1, py: 1, px: 2, maxHeight: "25vh" }}>
        <div>
          <p>Short Code : {data.shortcode}</p>
        </div>
        <div>
          <p>Currency : {data.currency}</p>
        </div>
        <div>
          <p> Email: {data.email}</p>
        </div>
        <div>
          <p>Contact : {data.contactNo}</p>
        </div>
        <div>
          <p>Address : {data.address}</p>
        </div>
      </Card>
      <Card sx={{ my: 1, py: 2, px: 2 }}>
        <h2>SKUs</h2>
        <div
          style={{
            maxHeight: "35vh",
            overflowY: "auto",
          }}
        >
          <ModalTable data={sku} columns={skuCols} />
        </div>
      </Card>
    </Box>
  );
};

export default VendorDetailModal;
