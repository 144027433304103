import React, { useEffect, useContext, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { useNewOrderTable } from "../2.CommonChildrenHook/1.OrderTableHooks";
import { storeAllROs } from "../../Dashboard/actiondashboard.services";

//context
import { ActionDashboardContext } from "../../../App"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING
import { getOrderTableColumns } from "../4.TableColumns/1.OrderTableColumns";
import OrderTableWrapper from "./OrderTableWrapper";

const OrderTable = ({ actionDashboardData }) => {
  const [orderTableCols, setOrderTableCols] = useState([]);
  const { orderStatus } = useOutletContext();
  const { tableProps } = useNewOrderTable({
    orderStatus,
    data: actionDashboardData?.actions ?? [],
  });

  //Refresh Action dashboard.
  const { setActionDashboardData } = useContext(ActionDashboardContext);

  useEffect(() => {
    storeAllROs({ setter: setActionDashboardData });
  }, []);

  useEffect(() => {
    setOrderTableCols(getOrderTableColumns({ orderStatus }));
  }, [orderStatus]);

  return (
    <div>
      {tableProps?.tableInfo?.data == null && (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      )}
      {tableProps?.tableInfo?.data?.length > 0 && (
        <OrderTableWrapper
          tableProps={tableProps}
          orderTableColumns={orderTableCols}
        />
      )}
      {tableProps?.tableInfo?.data?.length == 0 && (
        <div>No Orders Available.</div>
      )}
    </div>
  );
};

export default OrderTable;
