import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useAsyncDebounce } from "react-table";
import {
  SearchBar,
  SubHeader,
  Field,
  SecondaryButton,
} from "../../../Styles/styles";

export const GlobalFilter = ({ filter, setFilter, label }) => {
  const [value, setValue] = useState(filter);
  const handleChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  return (
    <span>
      {label ? `${label}: ` : "Search: "}
      <SearchBar
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          handleChange(e.target.value);
        }}
      />
    </span>
  );
};

export const ExternalPOFilter = ({ setFilter }) => {
  const [form, setForm] = useState();

  useEffect(() => {
    form && handleSubmit(form);
  }, [form]);

  const handleSubmit = useAsyncDebounce((form) => {
    setFilter("PONUMBER", form.PONUMBER);
    setFilter("VendorID", form.VendorID);
    setFilter("editDate", form.editDate);
  }, 1000);

  const formik = useFormik({
    initialValues: {
      PONUMBER: "",
      VendorID: "",
      editDate: "",
    },

    onSubmit: (values) => {
      setForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SubHeader>Filter for RO</SubHeader>
      <table
        style={{
          border: "none",
          borderWidth: "0px",
          marginBottom: "10px",
        }}
      >
        <tr>
          <td style={{ border: "none" }}>
            <label htmlFor="PONUMBER">RO Number</label>
          </td>
          <td style={{ border: "none" }}>
            <Field
              id="PONUMBER"
              name="PONUMBER"
              type="text"
              placeholder="RO Number"
              onChange={formik.handleChange}
              value={formik.values.PONUMBER}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }}>
            <label htmlFor="VendorID">Vendor Name</label>
          </td>
          <td style={{ border: "none" }}>
            <Field
              id="VendorID"
              name="VendorID"
              type="text"
              placeholder="Vendor Name"
              onChange={formik.handleChange}
              value={formik.values.VendorID}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }}>
            <label htmlFor="editDate">Date</label>
          </td>
          <td style={{ border: "none" }}>
            <Field
              id="editDate"
              name="editDate"
              type="date"
              placeholder="Edit"
              onChange={formik.handleChange}
              value={formik.values.editDate}
            />
          </td>
        </tr>
      </table>
      <SecondaryButton type="submit">Submit</SecondaryButton>
    </form>
  );
};

export const ExternalSKUFilter = ({ setFilter }) => {
  const [form, setForm] = useState();

  useEffect(() => {
    form && handleSubmit(form);
  }, [form]);

  const handleSubmit = useAsyncDebounce((form) => {
    setFilter("skuID", form.skuID);
    setFilter("description", form.description);
    setFilter("category", form.category);
  }, 1000);

  const formik = useFormik({
    initialValues: {
      skuID: "",
      description: "",
      category: "",
    },

    onSubmit: (values) => {
      setForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SubHeader>Filter for SKU</SubHeader>
      <div style={{ marginBottom: "10px" }}>
        <table style={{ border: "none", borderWidth: "0px" }}>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="skuID">ID</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="skuID"
                name="skuID"
                type="text"
                placeholder="SKU ID"
                onChange={formik.handleChange}
                value={formik.values.skuID}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="description">Description</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="description"
                name="description"
                type="text"
                placeholder="Description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="category">Category</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="category"
                name="category"
                type="text"
                placeholder="Category"
                onChange={formik.handleChange}
                value={formik.values.category}
              />
            </td>
          </tr>
        </table>
      </div>
      <SecondaryButton type="submit">Filter</SecondaryButton>
    </form>
  );
};

export const ExternalVendorFilter = ({ setFilter }) => {
  const [form, setForm] = useState();

  useEffect(() => {
    form && handleSubmit(form);
  }, [form]);

  const handleSubmit = useAsyncDebounce((form) => {
    setFilter("vendorID", form.vendorID);
    setFilter("name", form.name);
    setFilter("contactNo", form.contactNo);
  }, 1000);

  const formik = useFormik({
    initialValues: {
      vendorID: "",
      name: "",
      contactNo: "",
    },

    onSubmit: (values) => {
      setForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SubHeader>Filter for SKU</SubHeader>
      <div style={{ marginBottom: "10px" }}>
        <table style={{ border: "none", borderWidth: "0px" }}>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="vendorID">Vendor Code</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="vendorID"
                name="vendorID"
                type="text"
                placeholder="Vendor ID"
                onChange={formik.handleChange}
                value={formik.values.vendorID}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="name">Vendor Name</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="name"
                name="name"
                type="text"
                placeholder="Vendor Name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="contactNo">Contact Number</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="contactNo"
                name="contactNo"
                type="text"
                placeholder="Vendor Contact Number"
                onChange={formik.handleChange}
                value={formik.values.contactNo}
              />
            </td>
          </tr>
        </table>
      </div>
      <SecondaryButton type="submit">Filter</SecondaryButton>
    </form>
  );
};

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // console.log("filterValue:", filterValue);
  // console.log("setFilter:", setFilter);
  // console.log("preFilteredRows:", preFilteredRows);
  // console.log("id:", id);

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()].sort();
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export const ExternalActionDashboardFilter = ({ setFilter }) => {
  const [form, setForm] = useState();

  useEffect(() => {
    form && handleSubmit(form);
  }, [form]);

  const handleSubmit = useAsyncDebounce((form) => {
    setFilter("ro_num", form.ro_num);
    setFilter("name", form.name);
    setFilter("contactNo", form.contactNo);
  }, 500);

  const formik = useFormik({
    initialValues: {
      vendorID: "",
      name: "",
      contactNo: "",
    },

    onSubmit: (values) => {
      setForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SubHeader>Filter for Action Dashboard</SubHeader>
      <div style={{ marginBottom: "10px" }}>
        <table style={{ border: "none", borderWidth: "0px" }}>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="name">RO no.</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="name"
                name="name"
                type="text"
                placeholder="Vendor Name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="contactNo">Order Value</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="contactNo"
                name="contactNo"
                type="text"
                placeholder="Vendor Contact Number"
                onChange={formik.handleChange}
                value={formik.values.contactNo}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="vendorID">Tag</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="vendorID"
                name="vendorID"
                type="text"
                placeholder="Vendor ID"
                onChange={formik.handleChange}
                value={formik.values.vendorID}
              />
            </td>
          </tr>
        </table>
      </div>
      <SecondaryButton type="submit">Filter</SecondaryButton>
    </form>
  );
};

export const ExternalOrderFilter = ({ setFilter }) => {
  const [form, setForm] = useState();

  useEffect(() => {
    form && handleSubmit(form);
  }, [form]);

  const handleSubmit = useAsyncDebounce((form) => {
    setFilter("SKU", form.SKU);
    setFilter("status", form.status);
  }, 500);

  const formik = useFormik({
    initialValues: {
      sku: "",
      status: "",
    },

    onSubmit: (values) => {
      setForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SubHeader>Filter for Order Table</SubHeader>
      <div style={{ marginBottom: "10px" }}>
        <table style={{ border: "none", borderWidth: "0px" }}>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="SKU">SKU</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="SKU"
                name="SKU"
                type="text"
                placeholder="SKU"
                onChange={formik.handleChange}
                value={formik.values.SKU}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="status">Status</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="status"
                name="status"
                type="text"
                placeholder="Status"
                onChange={formik.handleChange}
                value={formik.values.status}
              />
            </td>
          </tr>
        </table>
      </div>
      <SecondaryButton type="submit">Filter</SecondaryButton>
    </form>
  );
};

export const ExternalContainerFilter = ({ setFilter }) => {
  const [form, setForm] = useState();

  useEffect(() => {
    form && handleSubmit(form);
  }, [form]);

  const handleSubmit = useAsyncDebounce((form) => {
    setFilter("ro_num", form.ro_num);
    setFilter("con_id", form.con_id);
    setFilter("size", form.size);
    setFilter("capacity", form.capacity);
    setFilter("volume_left", form.volume_left);
  }, 500);

  const formik = useFormik({
    initialValues: {
      sku: "",
      status: "",
    },

    onSubmit: (values) => {
      setForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SubHeader>Filter for Order Table</SubHeader>
      <div style={{ marginBottom: "10px" }}>
        <table style={{ border: "none", borderWidth: "0px" }}>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="ro_num">RO No.</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="ro_num"
                name="ro_num"
                type="text"
                placeholder="RO No."
                onChange={formik.handleChange}
                value={formik.values.ro_num}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="con_id">Container ID</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="con_id"
                name="con_id"
                type="text"
                placeholder="Container ID"
                onChange={formik.handleChange}
                value={formik.values.con_id}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="size">Container Type</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="size"
                name="size"
                type="text"
                placeholder="Container Type"
                onChange={formik.handleChange}
                value={formik.values.size}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="capacity">Percent Filled</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="capacity"
                name="capacity"
                type="text"
                placeholder="Percent Filled"
                onChange={formik.handleChange}
                value={formik.values.capacity}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}>
              <label htmlFor="volume_left">Volume Left (m3)</label>
            </td>
            <td style={{ border: "none" }}>
              <Field
                id="volume_left"
                name="volume_left"
                type="text"
                placeholder="Volume Left (m3)"
                onChange={formik.handleChange}
                value={formik.values.volume_left}
              />
            </td>
          </tr>
        </table>
      </div>
      <SecondaryButton type="submit">Filter</SecondaryButton>
    </form>
  );
};
