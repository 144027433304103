import ReactSelect from "react-select";
import { AlertContext } from "../../App";
import { useState, useContext, useEffect } from "react";

import { Box, Button, Drawer, Slider, Stack, Typography } from "@mui/material";
import { getVendorLeadtimeFilterData } from "../../Services/UploadDataServices/leadtime.services";

export const VendorLeadtimeFilterModal = ({ open, handleClose, setFilter }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const [departureList, setDepartureList] = useState([]);
  const [selectedDeparture, setSelectedDeparture] = useState();

  const [arrivalList, setArrivalList] = useState([]);
  const [selectedArrival, setSelectedArrival] = useState();

  useEffect(() => {
    getVendorLeadtimeFilterData({
      setVendorList,
      setDepartureList,
      setArrivalList,
      setAlertInfo,
    });
  }, []);

  const vendorOptions = () => {
    return vendorList
      .map((vendor) => {
        return {
          value: vendor,
          label: vendor.vendorID + " - " + (vendor?.name ?? ""),
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };

  const arrivalOptions = () => {
    return arrivalList
      .map((arrival) => {
        return {
          value: arrival,
          label: arrival,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectArrival = (e) => {
    setSelectedArrival(e?.value);
  };

  const departureOptions = () => {
    return departureList
      .map((departure) => {
        return {
          value: departure,
          label: departure,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectDeparture = (e) => {
    setSelectedDeparture(e?.value);
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label:
                    selectedVendor.vendorID +
                    " - " +
                    (selectedVendor?.name ?? ""),
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Arrival
            </Typography>
            <ReactSelect
              value={
                selectedArrival && {
                  value: selectedArrival,
                  label: selectedArrival,
                }
              }
              options={arrivalOptions()}
              onChange={handleSelectArrival}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Departure
            </Typography>
            <ReactSelect
              value={
                selectedDeparture && {
                  value: selectedDeparture,
                  label: selectedDeparture,
                }
              }
              options={departureOptions()}
              onChange={handleSelectDeparture}
              isClearable
            />
          </Box>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setSelectedArrival("");
              setSelectedDeparture("");
              setSelectedVendor("");
              setFilter("vendor_id", "");
              setFilter("vendor_name", "");
              setFilter("arrival", "");
              setFilter("departure", "");
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilter("vendor_id", selectedVendor?.vendorID);
              setFilter("vendor_name", selectedVendor?.name);
              setFilter("arrival", selectedArrival);
              setFilter("departure", selectedDeparture);
              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};
