import React from "react";
import { Modal } from "@mui/material";
import VendorDetailModal from "./VendorTableModal";

const VendorTableModalWrapper = ({ open, handleClose, rowInfo }) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <div>
        <VendorDetailModal
          open={open}
          handleClose={handleClose}
          rowInfo={rowInfo}
        />
      </div>
    </Modal>
  );
};

export default VendorTableModalWrapper;
