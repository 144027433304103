import React from "react";
import { Chart as ChartJS, BarElement, Title, Tooltip, Legend } from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, Title, Tooltip, Legend);

const Revenue = ({ year, dataYear, labelsYear, type }) => {
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Sales Value",
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Store",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: type + " Stores Revenues For Year " + year,
      },
      autocolors: false,

      tooltip: {
        callbacks: {
          label: function (context) {
            //console.log(context)
            return `Sales Value: ${context.formattedValue}`;
          },
        },
      },
    },
  };

  const data = {
    labels: labelsYear,
    datasets: [
      {
        label: "Store Revenue",
        data: dataYear /* dataYear.x.map(x => x.data) */,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",
          ,
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
      // {
      //   label: "2021 Revenue",
      //   data: [100, 90, 87, 50, 60, 101],
      //   backgroundColor: [
      //     "rgba(54, 162, 235, 0.2)",
      //   ],
      //   borderColor: [
      //     "rgba(54, 162, 235, 1)",
      //   ],
      //   borderWidth: 1,
      // },
    ],
  };
  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
};

export default Revenue;
