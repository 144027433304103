import { useState, createContext } from "react";

export const ActionDashboardContext = createContext();

export const ActionDashboardProvider = ({ children }) => {
  const [refresher, setRefresher] = useState(0);
  const [instance, setInstance] = useState();

  return (
    <ActionDashboardContext.Provider
      value={(instance, setInstance, refresher, setRefresher)}
    >
      {children}
    </ActionDashboardContext.Provider>
  );
};
