import React, { useState } from "react";

const useModal = ({ setModalSKUDetail }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setModalSKUDetail({}); //reset
    };
    return { open, handleOpen, handleClose };
};

export default useModal;
