import React, { useMemo, useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import Stack from "@mui/material/Stack";
import { Dialog } from "@mui/material";

import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
  SVG,
  TertiaryButton,
} from "../../Styles/styles";

const Alerts = ({ buttonText, alertMessage, styling, addFunc, img }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const handleClick = () => {
    setOpenAlert(!openAlert);
  };
  const buttonInterface = ({ buttonText, styling }) => {
    switch (styling) {
      case "primary":
      case "Primary":
        return (
          <PrimaryButton
            className="hoverButton"
            onClick={() => {
              handleClick();
              addFunc();
            }}
          >
            {buttonText}
          </PrimaryButton>
        );
      case "secondary":
      case "Secondary":
        return (
          <SecondaryButton
            className="hoverButton"
            onClick={() => {
              handleClick();
              addFunc();
            }}
          >
            {buttonText}
          </SecondaryButton>
        );
      case "text":
      case "Text":
        return (
          <TextButton
            className="hoverButton"
            onClick={() => {
              handleClick();
              addFunc();
            }}
          >
            {buttonText}
          </TextButton>
        );
      case "refresh":
      case "Refresh":
        return (
          <TertiaryButton
            className="hoverSecWhiteButton"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              handleClick();
              addFunc();
            }}
          >
            <SVG src={img} style={{ marginRight: "5px" }} />
            {buttonText}
          </TertiaryButton>
        );
      default:
        return (
          <TextButton
            className="hoverSecWhiteButton"
            onClick={() => {
              handleClick();
              addFunc();
            }}
          >
            <SVG src={img} style={{ marginRight: "5px" }} />
            {buttonText}
          </TextButton>
        );
    }
  };
  return (
    <>
      {buttonInterface({ buttonText, styling })}
      {/* <SecondaryButton className="hoverSecWhiteButton" onClick={() => handleClick()}>
        {buttonText}
      </SecondaryButton> */}
      <Dialog open={openAlert} onClose={handleClick}>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {alertMessage}
          </Alert>
        </Stack>
      </Dialog>
    </>
  );
};

export default Alerts;
