import React from "react";
import { Typography, Box, Button, Modal, TextField } from "@mui/material";

//Date
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const OrderStatusTypeButtonDownloadRO = ({
    arrDate,
    handleArrDateChange,
    dateRequired,
    handleDateRequiredChange,
    ROComment,
    handleROCommentChange,
    handleDownloadRO,
    handleClose,
    buttonDefaultStyling,
    selectedContainer,
}) => {
    const gridContainerStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        rowGap: 2,
        columnGap: 2,
        gridTemplateAreas: `'arrDate dateRequired'
        'roComment roComment'
        'button button'
        `,
        width: "100%",
        height: "100%",
    };

    return (
        <Box sx={gridContainerStyle}>
            <Box gridArea="arrDate">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="MM/DD/YYYY"
                        value={arrDate}
                        onChange={handleArrDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box gridArea="dateRequired">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="MM/DD/YYYY"
                        value={dateRequired}
                        onChange={handleDateRequiredChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box gridArea="roComment">
                <TextField
                    sx={{ display: "flex" }}
                    required
                    id="outlined-required"
                    label="Please enter your comment here."
                    value={ROComment}
                    onChange={handleROCommentChange}
                />
            </Box>
            <Box
                gridArea="button"
                sx={{ display: "flex-end", justifySelf: "end" }}
            >
                <Button
                    {...buttonDefaultStyling}
                    color="success"
                    onClick={() => {
                        handleDownloadRO();
                    }}
                >
                    <CheckIcon />
                </Button>
                <Button
                    {...buttonDefaultStyling}
                    color="error"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <CloseIcon />
                </Button>
            </Box>
        </Box>
    );
};

export default OrderStatusTypeButtonDownloadRO;
