import React, { useState, useEffect } from "react";
import Select from "react-select";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { modalStyle, SecondaryButton } from "../../Styles/styles";

import ContainerSwapForm from "../Forms/Container.swap.form";

const SwapModal = ({ modalFunctions, containers, containersArrayIndex }) => {
  useEffect(() => {
    setCurrentSKU(undefined);
  }, [containers, containersArrayIndex]);

  // For handling Modal Open/Close
  const { open, handleClose } = modalFunctions;

  // For source container
  const currentContainer = containers[containersArrayIndex];
  const currentContainerContents = currentContainer?.Content;
  const currentContainerSKUs = currentContainerContents?.map((content) => ({
    label: content?.SKU,
    value: content,
  }));
  const [currentSKU, setCurrentSKU] = useState(undefined);
  const handleSKUChange = (selectedOption) => {
    setCurrentSKU(selectedOption);
  };
  // For source container

  // For target container
  const targetOptionsList = containers
    // All containers in this PO except currently selected container
    .filter((container, index) => index !== containersArrayIndex)
    .map((container, index) => ({
      value: containers[index],
      label: container.con_id,
    }));
  const [targetOption, setTargetOption] = useState(undefined);
  useEffect(() => {
    setTargetOption(undefined);
  }, [containersArrayIndex]);
  const handleTargetChange = (selectedOption) => {
    setTargetOption(selectedOption);
  };
  // For target container

  useEffect(() => {}, [currentContainer, targetOption]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <h1>Transfer SKU</h1>

        <h3>{`Current Container ID: ${currentContainer?.con_id}`}</h3>

        <h3>Transfer to container ID : </h3>
        <Select
          defaultValue={targetOption}
          onChange={handleTargetChange}
          options={targetOptionsList}
        />
        {targetOption && (
          <>
            <h4>SKU</h4>
            <Select
              defaultValue={currentSKU}
              onChange={handleSKUChange}
              options={currentContainerSKUs}
            />
            {/* <pre>{`Current SKU : ${JSON.stringify(currentSKU?.value)}`}</pre> */}
            {currentSKU && (
              <ContainerSwapForm
                currentSKU={currentSKU?.value}
                currentContainerID={currentContainer?.con_id}
                targetOptionID={targetOption?.label}
              />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default SwapModal;
