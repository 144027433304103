import React, { useMemo, useState, useEffect } from "react";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { IconSVG } from "../../../../Styles/styles";
import searchIcon from "../../../../images/Search.svg";
import { SKUs_COLUMNS } from "../marketing.table.columns";
import {
  DataGrid,
  GridLinkOperator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import SelectedSKUExpandTile from "./SelectedSKUExpandTile";
import { useFormikContext, useField } from "formik";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  paddingBottom: "24px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 32px rgba(61, 70, 112, 0.08)",
  display: "flex",
  borderRadius: "12px",
};

const Title = MUIStyled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const FooterText = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #130F26;
`;

const SelectedTitle = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #130F26;
`;

const OutlinedBorder = MUIStyled(Box)`
  width: 20px;
  height: 20px;
  border: 1px solid #bead7c;
  border-radius: 6px;
  dislpay: flex;
  position: relative;
`;

const Square = MUIStyled(Box)`
  width: 16px;
  height: 16px;
  background: #bead7c;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
`;

const datagridBoxStyle = {
  height: 400,
  width: "100%",
  marginTop: "22px",

  "& .MuiDataGrid-root": {
    border: "none",
    borderRadius: "0px",
  },
  "& .MuiDataGrid-cell": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#6E7079",
    border: "none",
  },
  "& .name-column--cell": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 500,
    fontSize: "16px",
    color: "#130F26",
    align: "center",
    justifyContent: "center",
  },

  "& .MuiDataGrid-footerContainer": {
    border: "none",
  },
  "& .MuiCheckbox-root": {},
  "& .MuiDataGrid-row": {},
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiCheckbox-root svg": {
    width: 24,
    height: 24,
    backgroundColor: "transparent",
    border: "1px solid #CFD3D5",
    borderRadius: "8px",
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#bead7c",
    borderColor: "#bead7c",
  },
};

const AddSKUModal = ({ handleClose, name, skuRows }) => {
  const { values, setFieldValue, handleChange, touched, errors } =
    useFormikContext();

  const [field] = useField(name);
  const columns = useMemo(() => SKUs_COLUMNS, []);

  const [notFoundSkus, setNotFoundSkus] = React.useState([]);

  const [selectedSKUsModel, setSelectedSKUsModel] = useState([]);

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) => {
            let list = searchInput
              .replaceAll(",", "\t ")
              .replaceAll("\t", "")
              .split(" ")
              .map((value) => value.trim());

            let result = list.filter((value) => value !== "");

            let notFound = [];
            result.map((id) => {
              let foundList = skuRows.filter((row) => {
                return row.skuID === id || row.Shortcode === id;
              });

              if (foundList.length === 0) {
                notFound.push(id);
              }
            });

            setNotFoundSkus(notFound);

            return result;
          }}
        />
      </Box>
    );
  }

  useEffect(() => {
    if (skuRows.length > 0) {
      let list =
        field.value === undefined || field.value === ""
          ? []
          : Array.isArray(field.value)
          ? field.value
          : [field.value];

      let selected = [];
      if (list.length > 0) {
        list.map((id) => {
          let a = skuRows.find((row) => {
            return row.skuID === id;
          });

          if (a !== undefined) {
            selected.push(a);
          }
        });

        setSelectedSKUsModel(selected);
      }
    }
  }, [skuRows]);

  return (
    <Box sx={modalContainerStyle}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "90px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "29px 24px",
          }}
        >
          <Title>Add SKU</Title>
          <OutlinedBox onClick={handleClose}>
            <ClearIcon
              sx={{
                width: "24px",
                height: "24px",
                position: "absolute",
                top: " 50%",
                left: "50%",
                margin: "-12px 0 0 -12px",
                color: "#1C1D22",
              }}
            />
          </OutlinedBox>
        </Box>
        <Divider sx={{ color: "#CFD3D5" }} />
        <Box sx={{ width: "100%", height: "470px" }}>
          <Grid container>
            <Grid item xs={8}>
              <Box sx={{ padding: "13px 30px" }}>
                <Box sx={datagridBoxStyle}>
                  <DataGrid
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterLogicOperator: GridLinkOperator.Or,
                        },
                      },
                    }}
                    getRowId={(row) => row.skuID}
                    checkboxSelection
                    rows={skuRows}
                    columns={columns}
                    disableSelectionOnClick
                    pagination={false}
                    hideFooter={true}
                    onSelectionModelChange={(ids) => {
                      const selectedRowsData = ids.map((id) =>
                        skuRows.find((row) => row?.skuID === id)
                      );

                      setSelectedSKUsModel(selectedRowsData);
                    }}
                    selectionModel={selectedSKUsModel.map((a) => a?.skuID)}
                    rowCount={skuRows.length}
                    components={{ Toolbar: QuickSearchToolbar }}
                    componentsProps={{
                      toolbar: { sx: {} },
                      searchIcon: <IconSVG src={searchIcon} />,
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sx={{ padding: "8px 36px" }}>
              <Box sx={{ overflow: "auto", height: "462px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ mr: "12px", padding: "8px 0px" }}
                >
                  <OutlinedBorder>
                    <Square />
                  </OutlinedBorder>
                  <Box sx={{ ml: "14px" }} />
                  <SelectedTitle>Selected IDs</SelectedTitle>
                </Stack>
                {selectedSKUsModel.map((sku) => (
                  <SelectedSKUExpandTile key={sku?.skuID} sku={sku} />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ color: "#CFD3D5" }} />
        <Grid container padding="0px 24px" my="16px">
          <Grid item xs={8}>
            <FooterText sx={{ color: "#6E7079" }}>
              {notFoundSkus.length > 0
                ? `Missing SKU${notFoundSkus.length > 1 ? "(s)" : ""} - ` +
                  notFoundSkus?.map((s) => s).join(", ")
                : ""}
            </FooterText>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 24px",
          }}
        >
          <FooterText>
            {selectedSKUsModel.length > 0
              ? selectedSKUsModel.length + " Selected SKU(s)"
              : ""}
          </FooterText>
          <Button
            variant="contained"
            height="35px"
            sx={{
              color: "#000",
              fontSize: "11px",
              fontWeight: 400,
              backgroundColor: "#bead7c",
              borderRadius: "12px",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "#bead7c",
              },
            }}
            onClick={() => {
              let selectedSKUs = selectedSKUsModel.map((a) => a?.skuID);
              setFieldValue(name, selectedSKUs);
              handleClose();
            }}
          >
            Confirm SKU(s)
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddSKUModal;
