export function dashboardOrderNavigationMapper(tags) {
  switch (tags) {
    case "Order Now":
      return "Procurement";

    case "Re-review":
      return "ReReview";

    case "Management":
      return "Management";

    case "Proforma Previous":
      return "Proforma";

    case "Proforma Current":
      return "Proforma";

    case "Proforma":
      return "Proforma";

    case "PO Generation":
      return "POGeneration";

    case "POs to Receive":
      return "POsToReceive";

    default:
      return "";
  }
}

/// To map tag with stage for dashboard filter Tags and Stage select fields
/// stage - User selected value from Stage Select Field
/// options - Tags options list ***(Backend not sending all the tags options)
export function dashboardFilterStageToTagMapper({ stage, options = [] }) {
  switch (stage) {
    case "Stage - 1":
      return options.filter((e) => e === "Order Now" || e === "Re-review");
    case "Stage - 2":
      return options.filter((e) => e === "Management");
    case "Stage - 3":
      return options.filter(
        (e) =>
          e === "Proforma Previous" ||
          e === "Proforma Current" ||
          e === "Proforma"
      );
    case "Stage - 4":
      return options.filter(
        (e) => e === "PO Generation" || e === "POs to Receive"
      );
    case "Stage - 5":
      return options.filter((e) => e === "Fulfilled");
    case "Stage - 6":
      return options.filter((e) => e === "Removed");
    case "Stage - 7":
      return options.filter((e) => e === "Backlog");
    default:
      return [];
  }
}

/// To map tag with stage for dashboard filter Tags and Stage select fields
/// tag - User selected value from Tags Select Field
/// options - Stage options list ***(Backend not sending all the stage options)
export function dashboardFilterTagToStageMapper({ tag, options = [] }) {
  let list = [];
  switch (tag) {
    case "Order Now":
    case "Re-review":
      list = options.filter((e) => e === "Stage - 1");
      break;
    case "Management":
      list = options.filter((e) => e === "Stage - 2");
      break;
    case "Proforma Previous":
    case "Proforma Current":
    case "Proforma":
      list = options.filter((e) => e === "Stage - 3");
      break;
    case "PO Generation":
    case "POs to Receive":
      list = options.filter((e) => e === "Stage - 4");
      break;
    case "Fulfilled":
      list = options.filter((e) => e === "Stage - 5");
      break;
    case "Removed":
      list = options.filter((e) => e === "Stage - 6");
      break;
    case "Backlog":
      list = options.filter((e) => e === "Stage - 7");
      break;
    default:
      list = [""];
      break;
  }

  return list[0];
}
