import React, { useContext, useEffect, useState } from "react";

import { Box, Drawer, Stack, Typography, Button } from "@mui/material";
import { AlertContext } from "../../App";
import ReactSelect from "react-select";
import { getOrderFiltersFieldsData } from "../../Services/services";

const OrderFilterModal = ({ open, handleClose, setFilter, status }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const [filterData, setFilterData] = useState();

  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [reviewedByList, setReviewedByList] = useState([]);
  const [selectedReviewedBy, setSelectedReviewedBy] = useState();

  const [rejectedByList, setRejectedByList] = useState([]);
  const [selectedRejectedBy, setSelectedRejectedBy] = useState();

  const [approvedByList, setApprovedByList] = useState([]);
  const [selectedApprovedBy, setSelectedApprovedBy] = useState();

  const [generatedByList, setGeneratedByList] = useState([]);
  const [selectedGeneratedBy, setSelectedGeneratedBy] = useState();

  const [submittedByList, setSubmittedByList] = useState([]);
  const [selectedSubmittedBy, setSelectedSubmittedBy] = useState();

  useEffect(() => {
    getOrderFiltersFieldsData({
      setFilterData,
      setAlertInfo,
    });
  }, []);

  useEffect(() => {
    // Reset the selected filter field
    setSelectedVendor("");
    setSelectedUser("");
    setSelectedReviewedBy("");
    setSelectedRejectedBy("");
    setSelectedApprovedBy("");
    setSelectedGeneratedBy("");
    setSelectedSubmittedBy("");

    // Reset the filter options based on the order status
    if (filterData) {
      let data;
      switch (status) {
        case "procurement":
          data = filterData?.procurement;
          if (data) {
            setVendorList(data?.vendor ?? []);
            setUserList(data?.updated_by ?? []);
            setReviewedByList(data?.reviewed_by ?? []);
          }
          break;
        case "rereview":
          data = filterData?.re_review;
          if (data) {
            setVendorList(data?.vendor ?? []);
            setRejectedByList(data?.rejected_by ?? []);
          }
          break;
        case "management":
          data = filterData?.management;
          if (data) {
            setVendorList(data?.vendor ?? []);
            setSubmittedByList(data?.submitted_by ?? []);
            setReviewedByList(data?.reviewed_by ?? []);
          }
          break;
        case "proforma":
          data = filterData?.proforma;
          if (data) {
            setVendorList(data?.vendor ?? []);
            setApprovedByList(data?.approved_by ?? []);
          }
          break;
        case "pogeneration":
          data = filterData?.po_generation;
          if (data) {
            setVendorList(data?.vendor ?? []);
            setGeneratedByList(data?.generated_by ?? []);
            setApprovedByList(data?.approved_by ?? []);
          }
          break;
        case "postoreceive":
          data = filterData?.pos_to_receive;
          if (data) {
            setVendorList(data?.vendor ?? []);
            setGeneratedByList(data?.generated_by ?? []);
            setApprovedByList(data?.approved_by ?? []);
          }
          break;
        default:
          break;
      }
    }
  }, [status]);

  const vendorOptions = () => {
    return vendorList.map((vendor) => {
      return {
        value: vendor,
        label: vendor,
      };
    });
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };

  const userOptions = () => {
    return userList.map((user) => {
      return {
        value: user,
        label: user,
      };
    });
  };

  const handleSelectUser = (e) => {
    setSelectedUser(e?.value);
  };

  const reviewedByOptions = () => {
    return reviewedByList.map((user) => {
      return {
        value: user,
        label: user,
      };
    });
  };

  const handleSelectReviewedBy = (e) => {
    setSelectedReviewedBy(e?.value);
  };

  const rejectedByOptions = () => {
    return rejectedByList.map((user) => {
      return {
        value: user,
        label: user,
      };
    });
  };

  const handleSelectRejectedBy = (e) => {
    setSelectedRejectedBy(e?.value);
  };

  const approvedByOptions = () => {
    return approvedByList.map((user) => {
      return {
        value: user,
        label: user,
      };
    });
  };

  const handleSelectApprovedBy = (e) => {
    setSelectedApprovedBy(e?.value);
  };

  const generatedByOptions = () => {
    return generatedByList.map((user) => {
      return {
        value: user,
        label: user,
      };
    });
  };

  const handleSelectGeneratedBy = (e) => {
    setSelectedGeneratedBy(e?.value);
  };

  const submittedByOptions = () => {
    return submittedByList.map((user) => {
      return {
        value: user,
        label: user,
      };
    });
  };

  const handleSelectSubmittedBy = (e) => {
    setSelectedSubmittedBy(e?.value);
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label: selectedVendor,
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>

          {status === "procurement" && (
            <Box marginBottom="36px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "135%",
                  color: "#1c1d22",
                }}
                marginBottom="8px"
              >
                Updated By
              </Typography>
              <ReactSelect
                value={
                  selectedUser && {
                    value: selectedUser,
                    label: selectedUser,
                  }
                }
                options={userOptions()}
                onChange={handleSelectUser}
                isClearable
              />
            </Box>
          )}

          {status === "management" && (
            <Box marginBottom="36px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "135%",
                  color: "#1c1d22",
                }}
                marginBottom="8px"
              >
                Submitted By
              </Typography>
              <ReactSelect
                value={
                  selectedSubmittedBy && {
                    value: selectedSubmittedBy,
                    label: selectedSubmittedBy,
                  }
                }
                options={submittedByOptions()}
                onChange={handleSelectSubmittedBy}
                isClearable
              />
            </Box>
          )}

          {(status === "procurement" || status === "management") && (
            <Box marginBottom="36px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "135%",
                  color: "#1c1d22",
                }}
                marginBottom="8px"
              >
                Reviewed By
              </Typography>
              <ReactSelect
                value={
                  selectedReviewedBy && {
                    value: selectedReviewedBy,
                    label: selectedReviewedBy,
                  }
                }
                options={reviewedByOptions()}
                onChange={handleSelectReviewedBy}
                isClearable
              />
            </Box>
          )}

          {status === "rereview" && (
            <Box marginBottom="36px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "135%",
                  color: "#1c1d22",
                }}
                marginBottom="8px"
              >
                Rejected By
              </Typography>
              <ReactSelect
                value={
                  selectedRejectedBy && {
                    value: selectedRejectedBy,
                    label: selectedRejectedBy,
                  }
                }
                options={rejectedByOptions()}
                onChange={handleSelectRejectedBy}
                isClearable
              />
            </Box>
          )}

          {(status === "pogeneration" || status === "postoreceive") && (
            <Box marginBottom="36px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "135%",
                  color: "#1c1d22",
                }}
                marginBottom="8px"
              >
                Generated By
              </Typography>
              <ReactSelect
                value={
                  selectedGeneratedBy && {
                    value: selectedGeneratedBy,
                    label: selectedGeneratedBy,
                  }
                }
                options={generatedByOptions()}
                onChange={handleSelectGeneratedBy}
                isClearable
              />
            </Box>
          )}

          {(status === "proforma" ||
            status === "pogeneration" ||
            status === "postoreceive") && (
            <Box marginBottom="36px">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "135%",
                  color: "#1c1d22",
                }}
                marginBottom="8px"
              >
                Approved By
              </Typography>
              <ReactSelect
                value={
                  selectedApprovedBy && {
                    value: selectedApprovedBy,
                    label: selectedApprovedBy,
                  }
                }
                options={approvedByOptions()}
                onChange={handleSelectApprovedBy}
                isClearable
              />
            </Box>
          )}
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setSelectedVendor("");
              setFilter("vendor", "");

              switch (status) {
                case "procurement":
                  setSelectedUser("");
                  setFilter("updated_by", "");
                  setSelectedReviewedBy("");
                  setFilter("reviewed_by", "");
                  break;
                case "rereview":
                  setSelectedRejectedBy("");
                  setFilter("rejected_by", "");
                  break;
                case "management":
                  setSelectedSubmittedBy("");
                  setFilter("submitted_by", "");
                  setSelectedReviewedBy("");
                  setFilter("reviewed_by", "");
                  break;
                case "proforma":
                  setSelectedApprovedBy("");
                  setFilter("approved_by", "");

                  break;
                case "pogeneration":
                  setSelectedGeneratedBy("");
                  setFilter("generated_by", "");
                  setSelectedApprovedBy("");
                  setFilter("approved_by", "");
                  break;
                case "postoreceive":
                  setSelectedGeneratedBy("");
                  setFilter("generated_by", "");
                  setSelectedApprovedBy("");
                  setFilter("approved_by", "");
                  break;
                default:
                  break;
              }
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilter("vendor", selectedVendor);
              switch (status) {
                case "procurement":
                  setFilter("updated_by", selectedUser);
                  setFilter("reviewed_by", selectedReviewedBy);
                  break;
                case "rereview":
                  setFilter("rejected_by", selectedRejectedBy);
                  break;
                case "management":
                  setFilter("submitted_by", selectedSubmittedBy);
                  setFilter("reviewed_by", selectedReviewedBy);
                  break;
                case "proforma":
                  setFilter("approved_by", selectedApprovedBy);

                  break;
                case "pogeneration":
                  setFilter("generated_by", selectedGeneratedBy);
                  setFilter("approved_by", selectedApprovedBy);
                  break;
                case "postoreceive":
                  setFilter("generated_by", selectedGeneratedBy);
                  setFilter("approved_by", selectedApprovedBy);
                  break;
                default:
                  break;
              }

              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default OrderFilterModal;
