import React from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import InvoiceForm from "../Forms/Invoice.form";
import { modalStyle, SubHeader } from "../../Styles/styles";

const InvoiceGeneration = ({
  containers,
  containersArrayIndex,
  modalFunctions,
}) => {
  const tableInfo = containers[containersArrayIndex];
  const { open, handleClose } = modalFunctions;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <SubHeader>Generate RO</SubHeader>
        <InvoiceForm tableInfo={tableInfo} />
      </Box>
    </Modal>
  );
};

export default InvoiceGeneration;
