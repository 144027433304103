import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import searchIcon from "../../../images/Search.svg";
import filterIcon from "../../../images/Filter.svg";

import { styled as MUIStyled } from "@mui/material/styles";
import { IconSVG } from "../../../Styles/styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { AlertContext } from "../../../App";
import { SAFETY_STOCK_TABLE_COLUMNS } from "./safetystock.table.columns";
import { DataGrid } from "@mui/x-data-grid";
import useModal from "../../../Hooks/useModal";
import SafetyStockFilterModal from "./SafetyStock.filter.modal";
import { getSafetyStockList } from "../../../Services/safetystock.service";
import { useOutletContext } from "react-router-dom";
import { timeout } from "../../../Services/util";

const Text = MUIStyled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
`;

const searchTextFieldStyle = {
  width: "300px",
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "& label.Mui-focused": {
    color: "#CFD3D5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CFD3D5",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CFD3D5",
    },
    "&:hover fieldset": {
      borderColor: "#CFD3D5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#bead7c",
    },
  },
};

const filterButtonStyle = {
  borderColor: "#53545C",
  color: "#53545C",
  margin: "0px 8px",
  fontSize: "11px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#53545C",
  },
};

const datagridStyle = {
  width: "100%",
  marginTop: "22px",
  "& .MuiDataGrid-root": {
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6E7079",
  },
  "& .name-column--cell": {},
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#2C2D33",
    align: "center",
    justifyContent: "center",
  },

  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
  "& .MuiCheckbox-root": {},
  "& .MuiDataGrid-row": {},
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiCheckbox-root svg": {
    width: 24,
    height: 24,
    backgroundColor: "transparent",
    border: "1px solid #CFD3D5",
    borderRadius: "8px",
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#bead7c",
    borderColor: "#bead7c",
  },
};

const itemsPerPageList = [25, 50, 100];

const SafetyStockTable = ({ props }) => {
  const {
    open: openFilter,
    handleOpen: handleOpenFilter,
    handleClose: handleCloseFilter,
  } = useModal();

  const { safetyStockStatus } = useOutletContext();

  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  // Filter and Search
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filterBySku, setFilterBySku] = useState();
  const [filterByVendor, setFilterByVendor] = useState();

  // Memoize the columns so that React doesn't keep rendering
  const columns = useMemo(() => SAFETY_STOCK_TABLE_COLUMNS, []);

  const [rows, setRows] = useState([]);

  const [stockCount, setStockCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(itemsPerPageList[0]);

  const [loading, setLoading] = useState(false);

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setPage(0);
  }, [safetyStockStatus]);

  useEffect(() => {
    getSafetyStockList({
      stockStatus: safetyStockStatus,
      setRows: setRows,
      setLoading: setLoading,
      setStockCount: setStockCount,
      page: page + 1,
      pageSize: pageSize,
      search: searchKeyword,
      skuID: filterBySku?.sku_id ?? "",
      vendorName: filterByVendor ?? "",
    });
  }, [
    safetyStockStatus,
    page,
    pageSize,
    alertInfo,
    // searchKeyword,
    filterBySku,
    filterByVendor,
  ]);

  // Add delay 0.5 sec in search case
  useEffect(() => {
    reloadRowsBySearch();
  }, [searchKeyword]);

  const reloadRowsBySearch = async () => {
    await timeout(500);
    getSafetyStockList({
      stockStatus: safetyStockStatus,
      setRows: setRows,
      setLoading: setLoading,
      setStockCount: setStockCount,
      page: page + 1,
      pageSize: pageSize,
      search: searchKeyword,
      skuID: filterBySku?.sku_id ?? "",
      vendorName: filterByVendor ?? "",
    });
  };

  return (
    <>
      <SafetyStockFilterModal
        open={openFilter}
        handleClose={handleCloseFilter}
        status={safetyStockStatus}
        setFilterBySku={setFilterBySku}
        setFilterByVendor={setFilterByVendor}
      />
      <div
        style={{
          background: "#ffffff",
          borderRadius: "12px",
          margin: "32px 20px",
          padding: "21px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text>
            {stockCount}{" "}
            {safetyStockStatus === "active" ? "Active" : "Inactive"} SKU
          </Text>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              sx={searchTextFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSVG src={searchIcon} />
                  </InputAdornment>
                ),
                style: { height: "35px", fontWeight: 400, fontSize: "12px" },
              }}
              placeholder="Search Safety Stock"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
            />

            <Button
              variant="outlined"
              height="35px"
              startIcon={<IconSVG src={filterIcon} />}
              sx={filterButtonStyle}
              onClick={() => {
                handleOpenFilter();
              }}
            >
              Filter
            </Button>
          </div>
        </div>
        <Box sx={datagridStyle}>
          <DataGrid
            autoHeight
            paginationMode="server"
            checkboxSelection={false}
            getRowId={(row) => row.skuID}
            rows={rows}
            columns={columns}
            rowHeight={36}
            rowCount={stockCount}
            rowsPerPageOptions={itemsPerPageList}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  "margin-top": "1em",
                  "margin-bottom": "1em",
                },
            }}
            onCellClick={handleCellClick}
            onRowClick={handleRowClick}
            loading={loading}
          />
        </Box>
      </div>
    </>
  );
};

export default SafetyStockTable;
