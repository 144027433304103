import { dateFormatter } from "../../../Services/util";

const isBoldHelper = (row) => {
  return row.cells[row.cells.length - 1].value !== null;
};

const textCell = ({ row, value }) => {
  const isBold = isBoldHelper(row);

  return (
    <div
      style={{
        textAlign: "left",
        maxWidth: 300,
        wordWrap: "break-word",
      }}
    >
      {isBold ? (
        <span style={{ fontWeight: "bold" }}>{value}</span>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};

const numberCell = ({ row, value }) => {
  const isBold = isBoldHelper(row);
  return isBold ? (
    <span
      style={{
        fontWeight: "bold",
      }}
    >
      {value}
    </span>
  ) : (
    <span>{value}</span>
  );
};

export const LEADTIME_TABLE_COLUMNS = [
  {
    Header: "Vendor",
    accessor: "vendor_id",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Vendor Name",
    accessor: "vendor_name",

    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 300 }}>{row.value}</div>
    ),
  },
  {
    Header: "Vendor Contact",
    accessor: "vendor_contact",

    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
    ),
  },
  {
    Header: "Vendor Email",
    accessor: "vendor_email",

    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
    ),
  },
  {
    Header: "Departure",
    accessor: "departure",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 180 }}>{row.value}</div>
    ),
  },
  {
    Header: "Arrival",
    accessor: "arrival",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 180 }}>{row.value}</div>
    ),
  },
  {
    Header: "System Avg leadtime",
    accessor: "calculated_1_year_lead_time",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 220 }}>{row.value}</div>
    ),
  },
  {
    Header: "User input Leadtime",
    accessor: "givenleadtime",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 220 }}>{row.value}</div>
    ),
  },
  {
    Header: "Last Updated",
    accessor: "last_updated_at",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {dateFormatter(row.value)}
      </div>
    ),
  },
];
