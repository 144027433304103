import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { rejectPO } from "../../Services/ordertable.service";
import * as Yup from "yup";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90vh",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "36px 28px",
  width: "35%",
};

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

const OrderManagementRejectModal = ({
  open,
  handleClose,
  ro_num,
  setIsLoading,
  setAlertInfo,
  setIsAbleToStage,
}) => {
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    // console.log("handleFormSubmit", values);
    await rejectPO(ro_num, setIsLoading, setAlertInfo, values);
    setIsAbleToStage(true);
    navigate("/orders/management");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <h3>PO Number : {ro_num}</h3>
          <OutlinedBox onClick={handleClose}>
            <ClearIcon sx={closeIconStyle} />
          </OutlinedBox>
        </Stack>

        <Box sx={{ marginTop: "16px" }}>
          <Formik
            initialValues={{
              reason: "",
            }}
            validationSchema={Yup.object({
              reason: Yup.string()
                .max(100, "* No more than 100 characters.")
                .required("* Please enter the reason."),
            })}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Field
                  id="reason"
                  name="reason"
                  validate={(value) => {
                    if (value === "") {
                      return "* Please enter the reason";
                    }

                    if (value.length > 100) {
                      return "* No more than 100 characters.";
                    }
                  }}
                >
                  {({ field, form, meta }) => (
                    <TextField
                      required
                      id="reason"
                      name="reason"
                      label="Enter your reason for this PO rejection"
                      value={values.reason}
                      onChange={handleChange}
                      sx={{ width: "100%" }}
                      rows={3}
                      multiline
                    />
                  )}
                </Field>
                {errors.reason && touched.reason && (
                  <Typography sx={{ color: "#CC5F5F", marginTop: "4px" }}>
                    {errors.reason}
                  </Typography>
                )}
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing="16px"
                  marginTop="36px"
                >
                  <Button
                    variant="text"
                    sx={{
                      width: "150px",
                      color: "#1c1d22",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "Inter",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      width: "150px",
                      // background: "#bead7c",
                      // color: "#1c1d22",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "Inter",
                    }}
                    startIcon={<ThumbDownIcon />}
                    type="submit"
                  >
                    Reject
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderManagementRejectModal;
