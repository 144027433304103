import { useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "./useAuth";

const useCommon = () => {
  const params = useParams();
  const auth = useAuth()[0];
  const stage = params.page;
  const option = params.poid;

  const [refresher, setRefresher] = useState(0);

  const handleRefresh = () => {
    console.log({ refresher });
    setRefresher((prev) => prev + 1);
  };

  return { stage, option, auth, refresher, handleRefresh };
};

export default useCommon;
