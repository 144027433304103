import React, { useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import ChartFilter from "./procurement.filter";
import { getRandomColor } from "./procurement.chart.services";
import produce from "immer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProcurementChart = () => {
  const [chartData, setChartData] = useState();

  const datasets = produce(chartData, (draft) => {
    draft?.datasets?.forEach((item) => {
      item.backgroundColor = getRandomColor();
    });
  })?.datasets;

  const data = {
    labels: chartData?.labels,
    datasets,
  };

  return (
    <>
      <ChartFilter dataSetter={setChartData} />
      {chartData?.datasets && <Bar options={chartOptions} data={data} />}
    </>
  );
};

export default ProcurementChart;

export const chartOptions = {
  plugins: {
    title: {
      display: true,
      text: "Procurement",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      position: "left",
      beginAtZero: true,
      stacked: true,
      title: {
        display: true,
        text: "Value (SGD)",
      },
    },
  },
};
