import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { IconButton } from "@mui/material";
import useModal from "../../../../Hooks/useModal";
import DeleteEventModalWrapper from "./DeleteEventModalWrapper";

const DeleteEventButton = ({ id }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <DeleteEventModalWrapper open={open} handleClose={handleClose} id={id} />
      <IconButton onClick={handleOpen}>
        <DeleteForeverSharpIcon sx={{ fontSize: "22px", color: "#CC5F5F" }} />
      </IconButton>
    </>
  );
};

export default DeleteEventButton;
