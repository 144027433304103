import React, { useState, useEffect, useContext } from "react";
import { IconSVG, Title } from "../../../../Styles/styles";
import HomeIcon from "../../../../images/HomeIcon.svg";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import * as Yup from "yup";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import AddPromoGroupCard from "./AddPromoGroupCard";
import NewEventCard from "./NewEventCard";
import AmountDiscountBulkCreateCard from "./AmountDiscountBulkCreateCard";
import { FieldArray, Form, Formik } from "formik";
import {
  getSKUList,
  getPromoGroupList,
  getPromoTypeList,
  createEventWithPromoGroups,
  getLocationList,
} from "../../../../Services/eventstable.service";
import { AlertContext } from "../../../../App";
import { eventPromoGroupListMapper } from "../mapper";
import { processTableData } from "../TableDataProcess";

const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
`;

const ExpandListtileTitle = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #45464E;
`;

const submitButtonStyle = {
  width: "160px",
  color: "#000",
  fontSize: "11px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};

const addTemplateButtonStyle = {
  width: "170px",
  height: "35px",
  borderColor: "#bead7c",
  color: "#000",
  margin: "0px 8px",
  fontSize: "11px",
  fontWeight: 400,
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#bead7c",
  },
};

const addPromoGroupButtonStyle = {
  color: "#000",
  fontSize: "11px",
  fontWeight: 400,
  backgroundColor: "#bead7c",
  borderRadius: "12px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#bead7c",
  },
};
const initialSetupRow = [{ from: null, to: null, discount: null }];
const initialRow = [
  {
    name: "Promo Group",
    description: "",
    promo_type: "",
    is_stackable: false,
    primary_sku: [],
    secondary_sku: [],
    location: [],
    add_min_spent: true,
    amount: null,
    discount: null,
    order_value: null,
    start_date: "",
    end_date: "",
    early_bird: null,
    cashback: null,
    purchase_value: null,
    amount_off: null,
    is_discount_percent: true,
    step_up: initialSetupRow,
    amount_discount: null,
    event: null,
  },
];

const AddEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const [expand, setExpand] = useState(true);
  const [bulkExpand, setBulkExpand] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [skuRows, setSKURows] = useState([]);
  const [skuLoaded, setSkuLoaded] = useState(false);
  const [promoGroups, setPromoGroups] = useState([]);
  const [promoTypes, setPromoTypes] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    getSKUList(setSKURows, setSkuLoaded);
    getPromoTypeList(setPromoTypes);
    getLocationList(setLocationList);
  }, []);

  useEffect(() => {
    getPromoGroupList(setPromoGroups);
  }, [alertInfo]);

  const handleExpandClick = () => {
    setExpand(!expand);
  };

  const handleBulkExpandClick = () => {
    setBulkExpand(!bulkExpand);
  };

  const handleBulkCreate = (data, push) => {
    console.log("Bulk Create");
    console.log(data);

    const {skuList, locationIdList, desc, isStackable, errorRows, groupedSKU} = processTableData(data, skuRows);

    console.log(skuList);
    console.log(locationIdList);
    console.log(desc);
    console.log(isStackable);
    console.log(errorRows);
    console.log(groupedSKU);

    // const pgs = skuList.map((row) => {
    //   return {
    //     name: "Promo Group",
    //     description: desc,
    //     promo_type: "AD",
    //     is_stackable: isStackable,
    //     primary_sku: [row[0]],
    //     secondary_sku: [],
    //     location: locationIdList.length > 0 ? locationIdList : [],
    //     add_min_spent: true,
    //     amount: null,
    //     discount: null,
    //     order_value: null,
    //     start_date: "",
    //     end_date: "",
    //     early_bird: null,
    //     cashback: null,
    //     purchase_value: null,
    //     amount_off: null,
    //     amount_off: null,
    //     is_discount_percent: false,
    //     step_up: null,
    //     amount_discount: row[3],
    //     event: null,
    //   }
    // });

    let pgs = []
    for (const [amount, skuList] of Object.entries(groupedSKU)) {
      console.log(amount);
      console.log(skuList);
      pgs.push({
        name: "Promo Group",
        description: desc,
        promo_type: "AD",
        is_stackable: isStackable,
        primary_sku: skuList,
        secondary_sku: [],
        location: locationIdList.length > 0 ? locationIdList : [],
        add_min_spent: true,
        amount: null,
        discount: null,
        order_value: null,
        start_date: "",
        end_date: "",
        early_bird: null,
        cashback: null,
        purchase_value: null,
        amount_off: null,
        amount_off: null,
        is_discount_percent: false,
        step_up: null,
        amount_discount: amount,
        event: null,
      })
    };

    // create a hardcoded promo group with Amount discount
    pgs.map((pg) => {
      push({...pg,});
    });

    // put status OK for the valid rows
    skuList.map((row) => {
      row[3] = "OK";
    });

    let finalRows = skuList.concat(errorRows);

    // if there are no errors, return an empty array
    if (finalRows.length == 0) {
      finalRows = [['','','']]
    }

    // handle snackbars
    if (errorRows.length > 0 && skuList.length > 0) {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "warning",
        msg: `Amount Discount (Bulk Create) Process Completed with errors!`,
      }));
    }
    else if (errorRows.length > 0 && skuList.length == 0) {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: `Amount Discount (Bulk Create) Process Failed!`,
      }));
    }
    else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `Amount Discount (Bulk Create) Process Completed!`,
      }));
    }
      
    return finalRows;

  };

  const handleFormSubmit = async (values) => {
    const list = eventPromoGroupListMapper(values);
    const status = getEventStatus();

    const map = {
      name: values.name,
      start_date: values.start_date,
      end_date: values.end_date,
      description: values.description,
      is_external: values.is_external,
      status: status,
      promo_groups: list,
    };

    // console.log("handle submit", map);

    await createEventWithPromoGroups(map, setAlertInfo, () => {
      navigate(
        location?.state?.from?.pathname ?? `/marketingCampaign/active-events`
      );
    });
  };

  function getEventStatus() {
    switch (true) {
      case location?.state?.from?.pathname?.includes("active-events"):
        return "ACTIVE";
        break;
      case location?.state?.from?.pathname?.includes("upcoming-events"):
        return "UPCOMING";
        break;
      case location?.state?.from?.pathname?.includes("completed-events"):
        return "COMPLETED";
        break;
      default:
        return "";
        break;
    }
  }

  return (
    <div
      style={{
        background: "#F4F5FA",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "3px",
          alignItems: "center",
          background: "#FFF",
        }}
      >
        <IconSVG src={HomeIcon} />
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title
          onClick={() => {
            navigate(
              location?.state?.from?.pathname ??
                `/marketingCampaign/active-events`
            );
          }}
          style={{ color: "#000", cursor: "pointer" }}
        >
          Events
        </Title>
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>New Event</Title>
      </div>
      <Formik
        initialValues={{
          is_draft: true,
          name: "",
          start_date: "",
          end_date: "",
          description: "",
          bulk_create_description: "",
          is_external: false,
          is_stackable: false,
          promo_groups: initialRow,
          location: [],
        }}
        onSubmit={handleFormSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        // validationSchema={Yup.object({
        //   name: Yup.string(),
        //   start_date: Yup.string(),
        //   end_date: Yup.string(),
        //   description: Yup.string(),
        //   is_external: Yup.boolean(),
        //   promo_groups: Yup.array().of(
        //     Yup.object().shape({
        //       name: Yup.string(),
        //       description: Yup.string(),
        //       promo_type: Yup.string(),
        //       is_stackable: Yup.boolean(),
        //       is_regular_item_applied: Yup.boolean(),
        //       location: Yup.array(),
        //       add_min_spent: Yup.boolean(),
        //       amount: Yup.number(),
        //       discount: Yup.number(),
        //       start_date: Yup.string(),
        //       end_date: Yup.string(),
        //       early_bird: Yup.number(),
        //       cashback: Yup.number(),
        //       purchase_value: Yup.number(),
        //       amount_off: Yup.number(),
        //       is_discount_percent: Yup.boolean(),
        //     })
        //   ),
        // })}
      >
        {({ values, handleSubmit }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "85%",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      padding: "24px 0px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={submitButtonStyle}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                  <Text>New Event</Text>
                  <NewEventCard locations={locationList} />
                  {/* Bulk create amount discount */}
                  <Text style={{ 
                    paddingTop: 40,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#45464E',
                    }}>Amount Discount (Bulk Create)
                    <IconButton onClick={handleBulkExpandClick}>
                      {bulkExpand ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Text>
                    
                  <FieldArray name="promo_groups">
                    {({ insert, remove, push }) => (
                      <>
                        <Collapse in={bulkExpand} timeout="auto" unmountOnExit>
                          <AmountDiscountBulkCreateCard skuRows={skuRows} locations={locationList} handleBulkCreate={(newItem) => handleBulkCreate(newItem, push)}/>
                        </Collapse>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "36px",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ExpandListtileTitle>
                              Promo Groups
                            </ExpandListtileTitle>
                            <IconButton onClick={handleExpandClick}>
                              {expand ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="outlined"
                              height="35px"
                              startIcon={
                                <AddSharpIcon sx={{ color: "#000" }} />
                              }
                              sx={addTemplateButtonStyle}
                              onClick={handleClick}
                            >
                              Add Template
                            </Button>
                            <Menu
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                                sx: { width: anchorEl && anchorEl.offsetWidth },
                              }}
                            >
                              {promoGroups.map((pg) => (
                                <MenuItem
                                  key={pg.id}
                                  value={pg}
                                  onClick={() => {
                                    let promoGroup = {
                                      ...pg,
                                    };
                                    // Need to convert location id to object
                                    if (promoGroup["location"]) {
                                      let locList = [];
                                      if (locationList.length > 0) {
                                        promoGroup["location"].map((lid) => {
                                          let a = locationList.find((row) => {
                                            return row.id === lid;
                                          });

                                          if (a !== undefined) {
                                            locList.push(a);
                                          }
                                        });
                                      }
                                      promoGroup["location"] = locList;
                                    } else {
                                      promoGroup["location"] = [];
                                    }

                                    push({
                                      ...promoGroup,
                                    });
                                    handleClose();
                                  }}
                                >
                                  {pg.name}
                                </MenuItem>
                              ))}
                            </Menu>
                            <Button
                              variant="contained"
                              height="35px"
                              startIcon={
                                <AddSharpIcon sx={{ color: "#000" }} />
                              }
                              sx={addPromoGroupButtonStyle}
                              onClick={() =>
                                push({
                                  ...initialRow[0],
                                })
                              }
                            >
                              Add Promo Group
                            </Button>
                          </div>
                        </div>
                        <Collapse in={expand} timeout="auto" unmountOnExit>
                          <>
                            {values.promo_groups.length > 0 &&
                              values.promo_groups.map((row, index) => (
                                <AddPromoGroupCard
                                  key={index}
                                  index={index}
                                  remove={remove}
                                  skuRows={skuRows}
                                  promoTypes={promoTypes}
                                  locations={locationList}
                                />
                              ))}
                          </>
                        </Collapse>
                      </>
                    )}
                  </FieldArray>
                </Box>
              </Box>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddEvent;
