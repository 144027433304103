import {
  colorableCell,
  dateCell,
  ToggleVenActiveCell,
  ToggleSKUActiveCell,
} from "./columns.cell";
import { SelectColumnFilter } from "./SortFilterTable/Filters";
import {
  currencyFormatter,
  dateFormatter,
  getPOStatusLabel,
  numToCurrency,
} from "../../Services/util";

import { Modals } from "../Modals/POModalTest";
import VendorDetailModal from "../Modals/VendorTableModal";
import useVendorTable from "../../Hooks/useVendorTable";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useEffect, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import VendorTableModalWrapper from "../Modals/VendorTableModalWrapper";

//
export const rejectedColoredColumns = () => [
  {
    Header: "SKU",
    accessor: "SKU",
    tipText: `SKU IDs`,
    Cell: colorableCell,
  },
  {
    Header: "Description",
    accessor: "description",
    tipText: "Description of SKU",
    Cell: colorableCell,
  },
  {
    Header: "Status",
    accessor: "status",
    tipText: "SKU Status",
    Cell: colorableCell,
  },
  {
    Header: "Total Qty",
    accessor: "total_qty",
    tipText: "Total SKUs already in warehouse",
    Cell: colorableCell,
  },
  {
    Header: "Safety Stock",
    accessor: "safety_qty",
    tipText: "Safety SKUs from previous cycle",
    Cell: colorableCell,
  },
  {
    Header: "JPN Qty",
    accessor: "jpn_qty",
    tipText: "SKUs from Japan",
    Cell: colorableCell,
  },
  {
    Header: "Qty for Promo",
    accessor: "promo_qty",
    tipText: "How many SKUs to be ordered for Promo",
    Cell: colorableCell,
  },
  {
    Header: "Replenishment",
    accessor: "demand_qty",
    tipText:
      "Forecasted demand for SKUs next month, indicated for you to amend if needed",
    Cell: colorableCell,
  },
  {
    Header: "Order Later",
    accessor: "frombacklog",
    tipText: "Backlog Quantity from previous cycle(s)",
    Cell: colorableCell,
  },
  {
    Header: "Total Qty to Order",
    accessor: "totalqtyordered",

    tipText:
      "Total Amount of SKU to order, calculated by summing Japan, Promo, Replenishment, Backlog QTY",
    Cell: colorableCell,
  },
  {
    Header: "Total Value (SGD)",
    // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
    accessor: "totalvalue",
    // (row) =>
    //   numToCurrency(
    //     (row.jpn_qty.value +
    //       row.promo_qty.value +
    //       row.demand_qty.value +
    //       row.frombacklog.value) *
    //       row.price.value
    //   ).toFixed(2),
    tipText: "Total value of this row",
    Cell: colorableCell,
  },
  {
    Header: "Remarks",
    accessor: "remark",
    tipText: "Remarks for this SKU",
    Cell: colorableCell,
  },
  {
    Header: "Flag",
    accessor: "flag",
    tipText: "Lorem ipsum 10",
  },
];
//

// Order Table
//______________________________________________________________________
export const orderTableImmutableColumns = () => [
  {
    Header: (props) => {
      return `SKU (${props.rows.length})`;
    },
    accessor: "SKU",
    tipText: `SKU IDs`,
  },
  {
    Header: "Description",
    accessor: "description",
    tipText: "Description of SKU",
  },
  {
    Header: "Status",
    accessor: "status",
    tipText: "SKU Status",
  },
  {
    Header: "Total Qty",
    accessor: "total_qty",
    tipText: "Total SKUs already in warehouse",
  },
  {
    Header: "Safety Stock",
    accessor: "safety_qty",
    tipText: "Safety SKUs from previous cycle",
  },
  {
    Header: "JPN Qty",
    accessor: "jpn_qty",
    tipText: "SKUs from Japan",
  },
  {
    Header: "Qty for Promo",
    accessor: "promo_qty",
    tipText: "How many SKUs to be ordered for Promo",
  },
  {
    Header: "Replenishment",
    accessor: "demand_qty",
    tipText:
      "Forecasted demand for SKUs next month, indicated for you to amend if needed",
  },
  {
    Header: "Backlog",
    accessor: "frombacklog",
    tipText: "Backlog Quantity from previous cycle(s)",
  },
  {
    Header: "Total Qty to Order",
    accessor: (row) =>
      row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog,
    tipText:
      "Total Amount of SKU to order, calculated by summing Japan, Promo, Replenishment, Backlog QTY",
  },
  {
    Header: "Total Value",
    // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
    accessor: (row) =>
      numToCurrency(
        (row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog) *
          row.price
      ).toFixed(2),
    tipText: "Total value of this row",
  },
  {
    Header: "Remarks",
    accessor: "remark",
    tipText: "Remarks for this SKU",
  },
];

export const orderTableExpandedColumns = () => [
  { Header: "SKU", accessor: "SKU", tipText: `SKU IDs` },
  { Header: "Status", accessor: "", tipText: "Description of SKU" },
  {
    Header: "Total Qty",
    accessor: "total_qty",
    tipText: "Total SKUs already in warehouse",
  },
  {
    Header: "Safety Stock",
    accessor: "safety_qty",
    tipText: "Safety SKUs from previous cycle",
  },
  {
    Header: "JPN Qty",
    accessor: "jpn_qty",
    tipText: "SKUs from Japan",
  },
  {
    Header: "Qty for Promo",
    accessor: "promo_qty",
    tipText: "How many SKUs to be ordered for Promo",
  },
  {
    Header: "Replenishment",
    accessor: "demand_qty",
    tipText:
      "Forecasted demand for SKUs next month, indicated for you to amend if needed",
  },
  {
    Header: "Backlog",
    accessor: "frombacklog",
    tipText: "Backlog Quantity from previous cycle(s)",
  },
  {
    Header: "Total Qty to Order",
    accessor: (row) =>
      row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog,
    tipText:
      "Total Amount of SKU to order, calculated by summing Japan, Promo, Replenishment, Backlog QTY",
  },
  {
    Header: "Total Value",
    // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
    accessor: (row) =>
      numToCurrency(
        (row.jpn_qty + row.promo_qty + row.demand_qty + row.frombacklog) *
          row.price
      ).toFixed(2),
    tipText: "Total value of this row",
  },
  {
    Header: "Remarks",
    accessor: "remark",
    tipText: "Remarks for this SKU",
  },
];
//______________________________________________________________________

// PODB
//______________________________________________________________________

export const PODBColumns = () => [
  {
    Header: "",
    accessor: "action",

    Cell: (props) => {
      return (
        <div style={{ textAlign: "center", minWidth: 60 }}>
          <Modals poInfo={[props?.row?.original]} />
        </div>
      );
    },
  },
  {
    Header: "PO Number",
    accessor: "PONUMBER",
    disableFilters: true,
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 200 }}>{row.value}</div>
    ),
  },
  {
    Header: "Vendor Name",
    accessor: "vendor_name",
    Filter: SelectColumnFilter,
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 380 }}>{row.value}</div>
    ),
  },
  {
    Header: "Currency",
    accessor: "currency",
    Filter: SelectColumnFilter,
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {row.value ?? ""}
      </div>
    ),
  },
  {
    Header: "Total Value",
    accessor: "total_value",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>
        {currencyFormatter(row.value, "$")}
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 250 }}>
        {getPOStatusLabel(row.value)}
      </div>
    ),
  },
  {
    Header: "Version",
    accessor: "Version",
    Filter: SelectColumnFilter,
    filter: "includes",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 100 }}>{row.value}</div>
    ),
  },
  {
    Header: "Updated By",
    accessor: "user",
    Filter: SelectColumnFilter,
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Updated At",
    accessor: "editDate",
    Filter: SelectColumnFilter,
    filter: "includes",
    Cell: dateCell,
  },
];
export const PODBModalColumns = () => [
  {
    Header: "SKU",
    accessor: "SKU",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
    ),
  },
  {
    Header: "Product Code",
    accessor: "shortcode",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 350 }}>{row.value}</div>
    ),
  },
  {
    Header: "Country",
    accessor: "country",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Total Qty",
    accessor: "total_qty",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Safety Stock",
    accessor: "safety_qty",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 170 }}>{row.value}</div>
    ),
  },
  {
    Header: "JPN Qty",
    accessor: "jpn_qty",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Qty for Promo",
    accessor: "promo_qty",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
    ),
  },
  {
    Header: "Replenishment",
    accessor: "demand_qty",
    Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
  },
  {
    Header: "Backlog",
    accessor: "frombacklog",
    Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
  },
  {
    Header: "Currency",
    accessor: "currency",
    Cell: (row) => (
      <div style={{ textAlign: "center", minWidth: 150 }}>
        {row.value ?? ""}
      </div>
    ),
  },
  {
    Header: "Cost/ Qty",
    accessor: "price",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>
        {currencyFormatter(row.value, "$")}
      </div>
    ),
  },
  {
    Header: "Total Value",
    accessor: "total_value",
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>
        {currencyFormatter(row.value, "$")}
      </div>
    ),
  },
  {
    Header: "Remarks",
    accessor: "remark",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 550 }}>{row.value}</div>
    ),
  },
];
//______________________________________________________________________

// SKUDB
//______________________________________________________________________
export const skuColumns = (setRefresher) => {
  return () => [
    {
      Header: "ID",
      accessor: "skuID",
      Cell: (row) => (
        <div
          style={{
            textAlign: "left",
            minWidth: 220,
          }}
        >
          {row.value}
        </div>
      ),
      sticky: "left",
      style: {
        position: "sticky",
        zIndex: 5,
        left: 0,
        cursor: "pointer",
        minWidth: 220,
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 600 }}>{row.value}</div>
      ),
      sticky: "left",
      style: {
        position: "sticky",
        zIndex: 5,
        left: 220,
        cursor: "pointer",
        minWidth: 600,
      },
    },
    {
      Header: "Product Code",
      accessor: "Shortcode",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Vendor ID",
      accessor: "vendor_id",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Vendor Name",
      accessor: "vendor_name",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 400 }}>{row.value}</div>
      ),
    },
    {
      Header: "Size",
      accessor: "size",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 100 }}>{row.value}</div>
      ),
    },
    {
      Header: "Currency",
      accessor: "currency",
      Cell: (row) => (
        <div style={{ textAlign: "center", minWidth: 150 }}>
          {row.value ?? ""}
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 150 }}>
          {currencyFormatter(row.value, "$")}
        </div>
      ),
      filter: "between",
    },
    {
      Header: "SG Qty On Hand",
      accessor: "SG_qty_on_hand",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 180 }}>{row.value}</div>
      ),
    },
    {
      Header: "SG Sales Order",
      accessor: "SG_sales_order",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 180 }}>{row.value}</div>
      ),
    },
    {
      Header: "SG On Order",
      accessor: "SG_on_order",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "SG Qty",
      accessor: "sg_qty",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "JPN Qty On Hand",
      accessor: "JPN_qty_on_hand",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 200 }}>{row.value}</div>
      ),
    },
    {
      Header: "JPN Sales Order",
      accessor: "JPN_sales_order",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 180 }}>{row.value}</div>
      ),
    },
    {
      Header: "JPN On Order",
      accessor: "JPN_on_order",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 180 }}>{row.value}</div>
      ),
    },
    {
      Header: "JPN Qty",
      accessor: "jpn_qty",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Total Qty",
      accessor: "total_qty",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Aggregated Demand",
      accessor: (row) =>
        row.proforecastsku.length > 0
          ? row.proforecastsku[0]?.aggregated_demand ?? ""
          : "",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 220 }}>{row.value}</div>
      ),
    },
    {
      Header: "Procurement Forecast",
      accessor: (row) =>
        row.proforecastsku.length > 0
          ? row.proforecastsku[0]?.procurement_forecast ?? ""
          : "",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 220 }}>{row.value}</div>
      ),
    },
    {
      Header: "Vendor Leadtime",
      accessor: (row) =>
        row.proforecastsku.length > 0
          ? row.proforecastsku[0]?.vendor_leadtime ?? ""
          : "",
      Cell: (row) => (
        <div style={{ textAlign: "right", minWidth: 180 }}>{row.value}</div>
      ),
    },
    {
      Header: "Updated At",
      accessor: "updated_at",
      Cell: (row) => (
        <div style={{ textAlign: "center", minWidth: 150 }}>
          {dateFormatter(row.value)}
        </div>
      ),
    },
    {
      Header: "Status",
      Cell: (props) => (
        <div
          style={{
            textAlign: "center",
            minWidth: 100,
          }}
        >
          {ToggleSKUActiveCell(props, setRefresher)}
        </div>
      ),
    },
  ];
};
//______________________________________________________________________

// Vendor
//______________________________________________________________________
export const VendorDBLargeTableColumn = (setRefresher) => {
  return () => [
    {
      Header: "",
      accessor: "action",

      Cell: (props) => {
        return (
          <div style={{ textAlign: "center", minWidth: 60 }}>
            <VendorDetailView rowInfo={[props?.row?.original]} />
          </div>
        );
      },
    },
    {
      Header: "Vendor ID",
      accessor: "vendorID",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Short Code",
      accessor: "shortcode",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 150 }}>{row.value}</div>
      ),
    },
    {
      Header: "Vendor Name",
      accessor: "name",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
      ),
    },
    {
      Header: "Currency",
      accessor: "currency",
      Cell: (row) => (
        <div style={{ textAlign: "center", minWidth: 150 }}>
          {row.value ?? ""}
        </div>
      ),
    },
    {
      Header: "Contact",
      accessor: "contactNo",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 200 }}>{row.value}</div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
      ),
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: (row) => (
        <div style={{ textAlign: "left", minWidth: 300 }}>{row.value}</div>
      ),
    },
    {
      Header: "Updated At",
      accessor: "updated_at",
      Cell: (row) => (
        <div style={{ textAlign: "center", minWidth: 150 }}>
          {dateFormatter(row.value)}
        </div>
      ),
    },
    {
      Header: "Status",
      Cell: (props) => (
        <div
          style={{
            textAlign: "center",
            minWidth: 100,
          }}
        >
          {ToggleVenActiveCell(props, setRefresher)}
        </div>
      ),
    },
  ];
};

export const VendorSKUTableColumns = [
  { Header: "SKU", accessor: "skuID" },
  { Header: "Product Code", accessor: "Shortcode" },
  { Header: "Description", accessor: "description" },
  // { Header: "Average Order Size" },
  // { Header: "Max. Order Size" },
];

export const VendorDetailView = ({ rowInfo }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {rowInfo && (
        <VendorTableModalWrapper
          open={open}
          handleClose={handleClose}
          rowInfo={rowInfo}
        />
      )}
      <IconButton onClick={handleOpen}>
        <RemoveRedEyeIcon sx={{ color: "#bead7c", fontSize: "32px" }} />
      </IconButton>
    </>
  );
};
//______________________________________________________________________

export const DashboardModalColumns = () => [
  { Header: "SKU", accessor: "SKU" },
  { Header: "Total Qty", accessor: "total_qty" },
  { Header: "Safety Stock", accessor: "safety_qty" },
  { Header: "Total Qty to Order", accessor: "total_qty_ordered" },
  { Header: "Price / Unit (S$) ", accessor: "price" },
  {
    Header: "Total Value",
    // Sums Total, Safety, Jpn and Promo QTY, multiplied by the price and rounded to 2 dec places
    accessor: (row) =>
      numToCurrency(row.total_qty_ordered * row.price).toFixed(2),
  },
  { Header: "Remarks", accessor: "remark" },
];
