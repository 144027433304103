import React, { useState, useEffect } from "react";
import { SelectColumnFilter } from "../../../Components/Tables/SortFilterTable/Filters";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Box, Select, MenuItem, Button } from "@mui/material";
import {
  SecondaryButton,
  DeleteButton,
  PrimaryPill,
} from "../../../Styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";
import { SKUHeader } from "./procurement_ordertable_columns";
import { currencyFormatter } from "../../../Services/util";

const ComparableCell = ({ value, column: { id } }) => {
  if (value === null) {
    //remark return null in ordertable, then no need to do anything
    return <></>;
  }

  const bothFlag = value.new === value.old;

  return (
    <>
      {!bothFlag ? (
        id === "remark" ? (
          <div style={{ backgroundColor: "#ede853" }}>
            <p>{value.new}</p>
            <p>
              <s>{value.old}</s>
            </p>
          </div>
        ) : (
          <div style={{ backgroundColor: "#ede853" }}>
            <span style={{ display: "block" }}>{value.new}</span>
            <span>
              <s>{value.old}</s>
            </span>
          </div>
        )
      ) : id === "remark" ? (
        <p>{value.new}</p>
      ) : (
        <span>{value.new}</span>
      )}
    </>
  );
};

export const orderStatusHistoryColumns = [
  // fields can be accessed via headergroups>headers> inside here
  SKUHeader,
  {
    Header: "Country",
    accessor: "country",
    sticky: "left",
    Filter: SelectColumnFilter,
    // Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    disableFilters: true,
    style: {
      position: "sticky",
      zIndex: "5",
      left: 225,
      cursor: "pointer",
    },
    // zIndex: 5,
  },
  {
    Header: "SKU Desc",
    // accessor: "sku",
    accessor: "description",
    Cell: (row) => (
      <div style={{ textAlign: "left", minWidth: 250 }}>{row.value}</div>
    ),
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Qty On Hand",
    accessor: "on_hand_qty",
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Safety Stock",
    accessor: "safety_qty",
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Replenish",
    accessor: "demand_qty",
    Cell: ComparableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Promotion",
    accessor: "promo_qty",
    Cell: ComparableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Total Quantity",
    accessor: "total_qty_ordered",
    Cell: ComparableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Price",
    // accessor: "promotion",
    accessor: "price",
    Filter: SelectColumnFilter,
    disableFilters: true,
    Cell: (row) => (
      <div style={{ textAlign: "right", minWidth: 150 }}>
        {currencyFormatter(row.value, "$")}
      </div>
    ),
  },
  {
    Header: "Remarks",
    accessor: "remark",
    Cell: ComparableCell,
    Filter: SelectColumnFilter,
    disableFilters: true,
  },
];

//_______________________________________________________________________________________________
