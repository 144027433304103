import React, { useEffect, useState, forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({ alertInfo, setAlertInfo }) {
    // function TransitionUp(props) {
    //     return <Slide {...props} direction="up" />;
    //   }
    // console.log(alertInfo);
    // console.log(alertInfo.open);

    const { vertical, horizontal, open, severity, msg } = alertInfo;

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertInfo((prev) => ({
            ...prev,
            open: false,
        }));
    };
    // console.log("MSG IN SNACK: ", msg, Boolean(msg));
    return (
        <>
            <Snackbar
                sx={{ color: "white" }}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={7000}
                onClose={handleClose}
                TransitionComponent={Slide}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: "100%", color: "white" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
            {/* <Alert severity="error">This is an error message!</Alert>
<Alert severity="warning">This is a warning message!</Alert>
<Alert severity="info">This is an information message!</Alert>
<Alert severity="success">This is a success message!</Alert> */}
        </>
    );
}
