import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "75vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const ChangelogModal = ({ open, handleClose, rowInfo }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h1>SKU Changelog</h1>
        <div
          style={{
            maxHeight: "75vh",
            maxWidth: "75vh",
            overflowY: "scroll",
          }}
        >
          {rowInfo[0].changes &&
            rowInfo[0].changes.map((chg) => <p key={chg}>{chg}</p>)}
        </div>
      </Box>
    </Modal>
  );
};

export default ChangelogModal;
