import { currencyFormatter, dateFormatter } from "../../Services/util";

export function orderTableMoreInfo({ POInfo, status }) {
  let list = [];

  list.push({ label: "Vendor Code", value: POInfo.VendorID });
  list.push({ label: "Vendor Name", value: POInfo.vendor_name });
  list.push({
    label: "Vendor Leadtime",
    value: POInfo?.vendor_leadtime ?? "",
  });
  list.push({
    label: "Total Value",
    value: currencyFormatter(POInfo.total_value, "$"),
  });

  switch (status) {
    case "procurement":
      list.push({
        label: "Updated By",
        value: POInfo?.user_actions?.updated_by,
      });
      list.push({
        label: "Updated At",
        value: dateFormatter(POInfo?.user_actions?.updated_on),
      });
      list.push({
        label: "Reviewed By",
        value: POInfo?.user_actions?.reviewed_by,
      });
      list.push({
        label: "Reviewed At",
        value: dateFormatter(POInfo?.user_actions?.reviewed_on),
      });
      break;
    case "rereview":
      list.push({
        label: "Reason",
        value: POInfo?.user_actions?.reason,
      });
      list.push({
        label: "Rejected By",
        value: POInfo?.user_actions?.rejected_by,
      });
      list.push({
        label: "Rejected At",
        value: dateFormatter(POInfo?.user_actions?.rejected_on),
      });
      break;
    case "management":
      list.push({
        label: "Submitted By",
        value: POInfo?.user_actions?.submitted_by,
      });
      list.push({
        label: "Submitted At",
        value: dateFormatter(POInfo?.user_actions?.submitted_on),
      });
      list.push({
        label: "Reviewed By",
        value: POInfo?.user_actions?.reviewed_by,
      });
      list.push({
        label: "Reviewed At",
        value: dateFormatter(POInfo?.user_actions?.reviewed_on),
      });
      break;
    case "proforma":
      list.push({
        label: "Approved By",
        value: POInfo?.user_actions?.approved_by,
      });
      list.push({
        label: "Approved At",
        value: dateFormatter(POInfo?.user_actions?.approved_on),
      });

      break;
    case "pogeneration":
      list.push({
        label: "Generated By",
        value: POInfo?.user_actions?.generated_by,
      });
      list.push({
        label: "Generated At",
        value: dateFormatter(POInfo?.user_actions?.generated_on),
      });
      list.push({
        label: "Approved By",
        value: POInfo?.user_actions?.approved_by,
      });
      list.push({
        label: "Approved At",
        value: dateFormatter(POInfo?.user_actions?.approved_on),
      });
      break;
    case "postoreceive":
      list.push({
        label: "Generated By",
        value: POInfo?.user_actions?.generated_by,
      });
      list.push({
        label: "Generated At",
        value: dateFormatter(POInfo?.user_actions?.generated_on),
      });
      list.push({
        label: "Approved By",
        value: POInfo?.user_actions?.approved_by,
      });
      list.push({
        label: "Approved At",
        value: dateFormatter(POInfo?.user_actions?.approved_on),
      });
      break;
    default:
      break;
  }

  list.push({
    label: "PO Last Updated At",
    value: dateFormatter(POInfo.editDate),
  });
  return list;
}
