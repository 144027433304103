import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import OrderStatusTypeContainerCard from "./OrderStatusTypeContainerCard";

import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Icon,
} from "@mui/material";

//MUI custome theme
import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";
import "../../../Styles/MUIcss.css";

import { SelectedContainerContext } from "./OrderStatusTypeRender"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING

export const OrderTableContainers = ({
  containersInfo,
  setContainersInfo,
  containerOptions,
  viewActive,
  setViewActive,
  setIsLoading,
}) => {
  const { orderStatus } = useParams();

  const { selectedContainer, setSelectedContainer } = useContext(
    SelectedContainerContext
  );

  const handleRadioChange = (event) => {
    setSelectedContainer(event.target.value);
  };

  return (
    <>
      {/* diff */}
      <Box sx={{ height: "60vh", width: "inherit", overflowY: "auto" }}>
        {orderStatus !== "proforma" && (
          <>
            {containersInfo.length > 0 ? (
              containersInfo.map((containerInfo) => {
                return (
                  <Box sx={{ boxShadow: 2, mb: 1 }} key={containerInfo.con_id}>
                    <OrderStatusTypeContainerCard
                      containerInfo={containerInfo}
                      setContainersInfo={setContainersInfo}
                      viewActive={viewActive}
                      setViewActive={setViewActive}
                      setIsLoading={setIsLoading}
                    />
                  </Box>
                );
              })
            ) : (
              <div>No Container.</div>
            )}
          </>
        )}
        {/* end diff */}

        {/* Diff */}
        {orderStatus === "proforma" && (
          <>
            {/* <h4> State: {selectedContainer}</h4> */}
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedContainer}
                onChange={handleRadioChange}
              >
                {containersInfo.length > 0 ? (
                  containersInfo.map((containerInfo) => {
                    return (
                      <Fragment key={`${containerInfo.con_id}-Form`}>
                        <ThemeProvider theme={customMUITheme}>
                          <FormControlLabel
                            disabled={containerInfo.generated}
                            sx={{ mr: 0 }}
                            key={containerInfo.con_id}
                            value={containerInfo.con_id}
                            control={<Radio />}
                            label={
                              <Box
                                sx={{
                                  boxShadow: 2,
                                  mb: 1,
                                }}
                              >
                                <OrderStatusTypeContainerCard
                                  containerInfo={containerInfo}
                                  setContainersInfo={setContainersInfo}
                                  viewActive={viewActive}
                                  setViewActive={setViewActive}
                                  setIsLoading={setIsLoading}
                                />
                              </Box>
                            }
                          />
                        </ThemeProvider>
                      </Fragment>
                    );
                  })
                ) : (
                  <div>No Container.</div>
                )}
              </RadioGroup>
            </FormControl>
            {/* end diff */}
          </>
        )}
      </Box>
    </>
  );
};

export default OrderTableContainers;
