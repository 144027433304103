import React from "react";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import SKUDesc from "../Charts/SKUDescChart";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SKUDescriptionModal = ({
  open,
  handleClose,
  year,
  dataYear,
  labelsYear,
  SKUID,

}) => {
  //const data = rowInfo[0];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h1>SKU Description</h1>
        <hr/>
        <div className="d-flex flex-inline justify-content-between">
        <Card
        sx={{
          width: 275,
          height: 325,
          }}>
            <SKUDesc year={year} dataYear={dataYear} labelsYear={labelsYear} SKUID={SKUID}/>
            <span> Description: Chair</span>
        </Card>
        <Card
          sx={{
            width: 275,
            height: 325,
            }}>
            <SKUDesc year={year} dataYear={dataYear} labelsYear={labelsYear} SKUID={SKUID}/>
        </Card>
        <Card
          sx={{
            width: 275,
            height: 325,
            }}>
            <SKUDesc year={year} dataYear={dataYear} labelsYear={labelsYear} SKUID={SKUID}/>
        </Card>
        <Card
          sx={{
            width: 275,
            height: 325,
            }}>
            <SKUDesc year={year} dataYear={dataYear} labelsYear={labelsYear} SKUID={SKUID}/>
        </Card>
        <Card
          sx={{
            width: 275,
            height: 325,
            }}>
            <SKUDesc year={year} dataYear={dataYear} labelsYear={labelsYear} SKUID={SKUID}/>
        </Card>
        </div>
      </Box>
    </Modal>
  );
};

export default SKUDescriptionModal;
