import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import ReactSelect from "react-select";
import Forecast from "../../../Components/Charts/ForecastChart";
import useForecast from "../../../Hooks/useForecast";
import {
  getVendorList,
  getSkuForecast,
} from "../../../Services/forecast.service";
import { Header } from "../../../Styles/styles";

import { Box, Stack, Typography } from "@mui/material";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";
import { AlertContext } from "../../../App";

const MachineLearningForecast = () => {
  /*
  1) Get SKUVendorDict and SKUList
  2) Inject SKUList into Dropdown
  3) Let User pick SKUID first. Then query vendor from SKUVendorDict
  4) Once the user picks the vendorID. Query forecast data
  5) Display the graph using dynamic table
  */

  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const [vendorList, setVendorList] = useState(["Not Loaded Yet"]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedSKU, setSelectedSKU] = useState();

  const [skuOptions, setSkuOptions] = useState([]);

  const { initialForecast, forecastedData, setForecastedData } = useForecast();

  useEffect(() => {
    // 4) Once the user picks the vendorID. Query forecast data
    let skuCode = selectedSKU?.code ?? "";
    getSkuForecast({
      selectedSKU: skuCode,
      setForecastedData,
      setAlertInfo,
    });
  }, [selectedSKU]);

  useEffect(() => {
    // 1) Get SKUVendorDict and SKUList
    getVendorList({ setVendorList, setAlertInfo });
  }, []);

  useEffect(() => {
    if (vendorList) {
      const list = vendorList.find((vendor) => vendor?.vendor === "");

      setSkuOptions(
        list?.sku_list.map((sku) => {
          return {
            value: sku,
            label:
              (sku.vendor ?? "") +
              " - " +
              (sku?.code ?? sku) +
              " - " +
              (sku?.sku_name ?? "") +
              (sku?.has_data === false ? " (No forecast)" : ""),
            // isDisabled: sku?.has_data === false,
          };
        })
      );
    }
  }, [vendorList]);

  const vendorOptions = () => {
    const list = vendorList
      .map((vendor) => {
        return {
          value: vendor.vendor,
          label: vendor.vendor + " - " + (vendor?.name ?? ""),
        };
      })
      .filter((e) => e.value !== "");
    return list;
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
    setSelectedSKU("");
    setForecastedData(initialForecast);

    for (const vendor in vendorList) {
      if (vendorList[vendor].vendor === (e?.value ?? "")) {
        setSkuOptions(
          vendorList[vendor]?.sku_list.map((sku) => {
            return {
              value: sku,
              label:
                (sku.vendor ?? "") +
                " - " +
                (sku?.code ?? sku) +
                " - " +
                (sku?.sku_name ?? "") +
                (sku?.has_data === false ? " (No forecast)" : ""),
              // isDisabled: sku?.has_data === false,
            };
          })
        );
      }
    }
  };

  const handleSelectSKU = (e) => {
    setSelectedSKU(e?.value);
  };

  return (
    <>
      <Header>Machine Learning Forecast</Header>

      {vendorList[0] !== "Not Loaded Yet" ? (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="16px"
            margin="16px 0px"
          >
            <Box width="100%">
              <label>Vendor</label>

              <ReactSelect
                options={vendorOptions()}
                onChange={handleSelectVendor}
                isClearable
              />
            </Box>
            <Box width="100%">
              <label>SKU</label>

              <ReactSelect
                value={
                  selectedSKU && {
                    value: selectedSKU,
                    label:
                      (selectedSKU.vendor ? `${selectedSKU.vendor} - ` : "") +
                      (selectedSKU?.code
                        ? `${selectedSKU?.code} - `
                        : selectedSKU) +
                      (selectedSKU?.sku_name ?? ""),
                    // isDisabled: selectedSKU?.has_data === false,
                  }
                }
                options={skuOptions}
                onChange={handleSelectSKU}
                isClearable
              />
            </Box>
          </Stack>
          {selectedSKU && <Forecast {...forecastedData} />}
          {/* Display forecasting reason */}
          {selectedSKU && forecastedData.reason && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop="36px"
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#53545C",
                }}
              >
                {forecastedData.reason ?? ""}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ height: 400 }}>
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        </Box>
      )}
    </>
  );
};

export default MachineLearningForecast;
