export function addPromoGroupCardMapper(values) {
  let map = {};

  if (values.id) {
    map["id"] = values.id;
  }

  map["event"] = values.event;

  //! Convert location object to id
  let location = [];

  values.location.forEach((l) => {
    location.push(l.id);
  });

  map["location"] = location;

  switch (values.promo_type) {
    case "FGWP":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["secondary_sku"] = values.secondary_sku;
      map["add_min_spent"] = values.add_min_spent;
      map["amount"] = values.amount;
      break;
    case "PWP":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["secondary_sku"] = values.secondary_sku;
      map["discount"] = values.discount;
      map["is_discount_percent"] = values.is_discount_percent;
      break;
    case "SU":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      let stepUp = [];
      if (values.step_up) {
        values.step_up.forEach((e) => {
          let row = {
            from: parseFloat(e.from),
            to: e.to && parseFloat(e.to),
            discount: parseFloat(e.discount),
          };
          stepUp.push(row);
        });
      }
      map["step_up"] = stepUp;
      break;
    case "PD":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["discount"] = values.discount;
      break;
    case "AD":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["amount_discount"] = values.amount_discount;
      break;
    case "EBV":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["start_date"] = values.start_date;
      map["end_date"] = values.end_date;
      map["early_bird"] = values.early_bird;
      break;
    case "CB":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["cashback"] = values.cashback;
      break;
    case "AOPP":
      map["name"] = values.name;
      map["description"] = values.description;
      map["is_stackable"] = values.is_stackable;
      map["promo_type"] = values.promo_type;
      map["primary_sku"] = values.primary_sku;
      map["purchase_value"] = values.purchase_value;
      map["amount_off"] = values.amount_off;
      break;
    default:
      break;
  }
  return map;
}

export function eventPromoGroupListMapper(values) {
  let list = [];

  values.promo_groups.forEach((p) => {
    list.push(p);
  });

  list.forEach((value, index) => {
    // Convert location object to id
    let location = [];

    value.location.forEach((l) => {
      location.push(l.id);
    });

    value["location"] = location;

    switch (value.promo_type) {
      case "FGWP":
        delete value["discount"];
        delete value["order_value"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["cashback"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["is_discount_percent"];
        delete value["step_up"];
        delete value["amount_discount"];
        break;
      case "PWP":
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["order_value"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["cashback"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["step_up"];
        delete value["amount_discount"];
        break;
      case "SU":
        let stepUp = [];
        if (value.step_up) {
          value.step_up.forEach((e) => {
            let row = {
              from: parseFloat(e.from),
              to: e.to && parseFloat(e.to),
              discount: parseFloat(e.discount),
            };
            stepUp.push(row);
          });
        }
        value["step_up"] = stepUp;
        delete value["order_value"];
        delete value["discount"];
        delete value["secondary_sku"];
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["cashback"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["is_discount_percent"];
        delete value["amount_discount"];
        break;
      case "PD":
        delete value["secondary_sku"];
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["order_value"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["cashback"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["is_discount_percent"];
        delete value["step_up"];
        delete value["amount_discount"];
        break;
      case "AD":
        delete value["secondary_sku"];
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["order_value"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["cashback"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["is_discount_percent"];
        delete value["step_up"];
        delete value["discount"];
        break;
      case "EBV":
        delete value["secondary_sku"];
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["order_value"];
        delete value["discount"];
        delete value["cashback"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["is_discount_percent"];
        delete value["step_up"];
        delete value["amount_discount"];
        break;
      case "CB":
        delete value["secondary_sku"];
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["order_value"];
        delete value["discount"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["purchase_value"];
        delete value["amount_off"];
        delete value["is_discount_percent"];
        delete value["step_up"];
        delete value["amount_discount"];
        break;
      case "AOPP":
        delete value["secondary_sku"];
        delete value["add_min_spent"];
        delete value["amount"];
        delete value["order_value"];
        delete value["discount"];
        delete value["start_date"];
        delete value["end_date"];
        delete value["early_bird"];
        delete value["cashback"];
        delete value["is_discount_percent"];
        delete value["step_up"];
        delete value["amount_discount"];
        break;
      default:
        break;
    }
  });
  return list;
}
