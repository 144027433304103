import React from "react";
import { Outlet, useParams } from "react-router-dom";

const OrderStatusType = () => {
    const { orderStatusType } = useParams();
    const possibleOrderTypeStatuses = ["order_table", "backlog", "history"];
    // console.log("OrderStatusType Console log");
    return possibleOrderTypeStatuses.includes(orderStatusType) ? (
        <div>
            <Outlet />
        </div>
    ) : (
        ""
    );
};

export default OrderStatusType;
