/*
After installing json-server, pull the json-server file from test-server branch and run the following code : 
json-server -p3001 --watch db.json
*/

//https://dev.scanteak-backend.reluvate.net/

// General API
export const allPOAPI = process.env.REACT_APP_API_URL + "getpodetailall/";
export const allLatestPOAPI = process.env.REACT_APP_API_URL + "getpodetail/";
export const piCycle = process.env.REACT_APP_API_URL + "getpovendordiff/";
export const latestStageAPI = process.env.REACT_APP_API_URL + "getstage/";
export const logInAPI = process.env.REACT_APP_API_URL + "auth/jwt/create";
export const getUserAPI = process.env.REACT_APP_API_URL + "getuserinfo";
export const getBacklog = process.env.REACT_APP_API_URL + "getbacklog/";
export const poHistory = process.env.REACT_APP_API_URL + "pohistory/";

// export const intraHistoryAPI = process.env.REACT_APP_API_URL + "comparepo/";
// /getbacklog/<ponumber> | get | get backlog based on POnumber |

// All Procurement API
// export const allProcurePODetailsVersionsAPI = process.env.REACT_APP_API_URL + "getpodetailallpro/";
export const sendManAPI = process.env.REACT_APP_API_URL + "sendtomanage/";
export const saveProcurementAPI =
  process.env.REACT_APP_API_URL + "updatepodetailpro/";
export const latestProcurementAPI =
  process.env.REACT_APP_API_URL + "updatepodetailpro/";
export const procurementContainerAPI =
  process.env.REACT_APP_API_URL + "getcontainerpro/";
export const toBacklogPROAPI =
  process.env.REACT_APP_API_URL + "sendtobacklogpro/";
export const fromBacklogPROAPI =
  process.env.REACT_APP_API_URL + "sendfrombacklogpro/";
export const optionProAPI = process.env.REACT_APP_API_URL + "polatestpro";
export const procurementHistoryAPI =
  process.env.REACT_APP_API_URL + "comparepopro/";
export const saveContainerPro =
  process.env.REACT_APP_API_URL + "updateconpro/";
export const swapContainerPro =
  process.env.REACT_APP_API_URL + "conswappro/"; 
  export const intraProHistoryAPI = process.env.REACT_APP_API_URL + "compareintrapopro/";

//All Management API
// export const allManagerPODetailsVersionsAPI = process.env.REACT_APP_API_URL + "getpodetailallman/";
export const sendProAPI = process.env.REACT_APP_API_URL + "sendtoprocurement/";
export const sendVenAPI = process.env.REACT_APP_API_URL + "sendtovendor/";
export const saveManagementAPI =
  process.env.REACT_APP_API_URL + "updatepodetailman/";
export const latestManagementAPI =
  process.env.REACT_APP_API_URL + "updatepodetailman/";
export const managementContainerAPI =
  process.env.REACT_APP_API_URL + "getcontainerman/";
export const toBacklogManAPI =
  process.env.REACT_APP_API_URL + "sendtobacklogman/";
export const fromBacklogManAPI =
  process.env.REACT_APP_API_URL + "sendfrombacklogman/";
export const optionManAPI = process.env.REACT_APP_API_URL + "polatestman";
export const managementHistoryAPI =
  process.env.REACT_APP_API_URL + "comparepoman/";
export const saveContainerMan =
  process.env.REACT_APP_API_URL + "updateconman/";  
export const swapContainerMan =
  process.env.REACT_APP_API_URL + "conswapman/";  
export const intraManHistoryAPI = process.env.REACT_APP_API_URL + "compareintrapoman/";

//All Vendor API
export const allVendorPODetailsVersionsAPI =
  process.env.REACT_APP_API_URL + "getpovendor/";
export const vendorContainerAPI =
  process.env.REACT_APP_API_URL + "getcontainerven/";
export const optionVenAPI = process.env.REACT_APP_API_URL + "polatestven";
export const saveVendorAPI = process.env.REACT_APP_API_URL + "updatepoven/";
export const latestVendorAPI = process.env.REACT_APP_API_URL + "updatepoven/";
export const vendorDetailAPI = "getvendor";
export const skuDetailAPI = "getsku";
export const saveContainerVen =
  process.env.REACT_APP_API_URL + "updateconven/"; 
export const swapContainerVen =
  process.env.REACT_APP_API_URL + "conswapven/"; 
  export const intraVenHistoryAPI = process.env.REACT_APP_API_URL + "compareintrapoven/";

  //PO Gen API
  export const currentCyclePOAPI =
  process.env.REACT_APP_API_URL + "pogeneratednow";
  export const intraGenHistoryAPI = process.env.REACT_APP_API_URL + "compareintrapogen/";
  export const poGenDetailAPI = process.env.REACT_APP_API_URL + "getpodetailgen/"; //ponumber
  export const poGenContainerAPI = process.env.REACT_APP_API_URL + "getcontainergen/"; //ponumber

  // POs To Receive API
  export const prevCyclePOAPI = process.env.REACT_APP_API_URL + "pogeneratedpre";

  // Management Dashboard
  export const performanceAPI = process.env.REACT_APP_API_URL + "performance";

// Useful links
export const marketingCampaignAPI =
  "hhttp://localhost:3001/marketingCampaignDetail";
export const skuAPI = "hhttp://localhost:3001/getsku";
