import React from "react";
import { Outlet, useParams } from "react-router-dom";

const CampaignStatus = () => {
  const { campaignStatus } = useParams();
  const possibleCampaignStatuses = [
    "active-events",
    "upcoming-events",
    "completed-events",
  ];

  return possibleCampaignStatuses.includes(campaignStatus) ? (
    <div style={{ marginTop: "20px" }}>
      <Outlet context={{ campaignStatus }} />
    </div>
  ) : (
    ""
  );
};

export default CampaignStatus;
