import React from "react";
import { ContainerProvider } from "../../../Contexts/container.context";
import ContainerRow from "./ContainerRow";
import ContainerTable from "./ContainerTable";

const Containers = () => {
  return (
    <ContainerProvider>
      <ContainerRow />
      <ContainerTable />
    </ContainerProvider>
  );
};

export default Containers;
