import React, { useState, useEffect } from "react";
import { IconSVG } from "../../../../Styles/styles";
import editIcon from "../../../../images/Edit.svg";
import { Box, Button, Grid, Typography } from "@mui/material";

import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

const Label = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8b8d97;
`;

const Value = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #45464e;
`;
const SubText = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #45464E;
`;

const ReviewPromoGroupCard = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        padding: "28px 32px",
        border: "1px solid #C1C1C1",
        margin: "15px 0px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SubText sx={{ marginRight: "14px" }}>1 for 1 Sale</SubText>
        </div>
        <Button
          variant="outlined"
          height="35px"
          startIcon={<IconSVG src={editIcon} />}
          sx={{
            height: "30px",
            borderColor: "#53545C",
            color: "#53545C",
            margin: "0px 8px",
            fontSize: "11px",
            fontWeight: 400,
            borderRadius: "4px",
            "&:hover": {
              borderColor: "#53545C",
            },
          }}
          onClick={() => {}}
        >
          Edit Promo
        </Button>
      </div>

      <Grid container columnSpacing={3} rowSpacing={6}>
        <Grid item xs={3}>
          <Box>
            <Label>Promo Type</Label>
            <Value>PWP</Value>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box>
            <Label>Limit Qty </Label>
            <Value>10</Value>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Label>Description</Label>
            <Value>Christmas Special 2022</Value>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Label>Group Type</Label>
            <Value>Stackable</Value>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box>
            <Label>Discount</Label>
            <Value>20%</Value>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Label>Format</Label>
            <Value>20%</Value>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Label>SKU(s)</Label>
            <Value>FS29I302I</Value>
            <Value>FS84UR94</Value>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Label>Applies to regular items</Label>
            <Value>Yes</Value>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ReviewPromoGroupCard;
