import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { getPOFiltersFieldsData } from "../../Services/services";
import ReactSelect from "react-select";
import { AlertContext } from "../../App";
import { getPOStatusLabel } from "../../Services/util";

const POFilterModal = ({ open, handleClose, setFilter, columns }) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => {
    // Need to call api here
    getPOFiltersFieldsData({
      setVendorList,
      setUserList,
      setStatusList,
      setAlertInfo,
    });
  }, []);

  const vendorOptions = () => {
    return vendorList
      .map((vendor) => {
        return {
          value: vendor,
          label: vendor,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };

  const userOptions = () => {
    return userList
      .map((user) => {
        return {
          value: user,
          label: user,
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectUser = (e) => {
    setSelectedUser(e?.value);
  };

  const statusOptions = () => {
    return statusList
      .map((status) => {
        return {
          value: status,
          label: getPOStatusLabel(status),
        };
      })
      .filter((e) => e.value !== "");
  };

  const handleSelectStatus = (e) => {
    setSelectedStatus(e?.value);
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label: selectedVendor,
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Status
            </Typography>
            <ReactSelect
              value={
                selectedStatus && {
                  value: selectedStatus,
                  label: getPOStatusLabel(selectedStatus),
                }
              }
              options={statusOptions()}
              onChange={handleSelectStatus}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Updated By
            </Typography>
            <ReactSelect
              value={
                selectedUser && {
                  value: selectedUser,
                  label: selectedUser,
                }
              }
              options={userOptions()}
              onChange={handleSelectUser}
              isClearable
            />
          </Box>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setSelectedVendor("");
              setSelectedUser("");
              setSelectedStatus("");
              setFilter("vendor_name", "");
              setFilter("user", "");
              setFilter("status", "");
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilter("vendor_name", selectedVendor);
              setFilter("user", selectedUser);
              setFilter("status", selectedStatus);
              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default POFilterModal;
