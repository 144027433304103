import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedPosition }) => {
  const location = useLocation();
  const userPosition = JSON.parse(localStorage.getItem("user"))?.position;
  return allowedPosition.includes(userPosition) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
