import React, { useState } from "react";
import BacklogModal from "../../Components/Modals/BacklogModal";
import styled from "styled-components";

const Div = styled.div`
  background: rgba(251, 249, 243, 0.25);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 30vh;
  padding: 10px;
  margin: 5px;
  margin-right: 10px;
  height: "50px";
  cursor: pointer;
`;

const Backlog = ({ backlogInfo, handleRefresh }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Div className="hoverSecWhiteButton" onClick={handleOpen}>
        <h4>PO Backlog</h4>
        <p>{backlogInfo.PONUMBER}</p>
      </Div>
      <BacklogModal
        open={open}
        handleClose={handleClose}
        poInfo={backlogInfo}
        handleRefresh={handleRefresh}
      />
    </>
  );
};

export default Backlog;
