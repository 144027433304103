import api from "./api";

const alertError = (err, setAlertInfo) => {
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err?.response?.data["message"] ?? err?.toString(),
  }));
};

export const getSafetyStockList = async ({
  stockStatus,
  setRows,
  setLoading,
  setStockCount,
  page,
  pageSize,
  search,
  skuID,
  vendorName,
}) => {
  try {
    setLoading(true);

    const url = `getadditionalsafetystock?active=${
      stockStatus === "active" ? "true" : "false"
    }&page=${page}&page_size=${pageSize}&search=${search}&sku_id=${skuID}&vendor_name=${vendorName}`;

    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const data = response.data["data"];
        setRows(data["results"]);
        setStockCount(data["count"]);

        setLoading(false);
      } else {
        setRows([]);
        setLoading(false);
      }
    } else {
      setRows([]);
      setLoading(false);
    }
  } catch (error) {
    setRows([]);
    setLoading(false);
  }
};

export const getSafetyStockFiltersFieldsData = async ({
  setSKUList,
  setVendorNameList,
  setAlertInfo,
  status,
}) => {
  const url = `getadditionalsafetystockfilterconditions?active=${
    status === "active" ? true : false
  }`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const data = response.data["data"];
        const skuList = data["sku_list"] ?? [];
        const vendorList = data["vendor_name"] ?? [];

        setSKUList(skuList);
        setVendorNameList(vendorList);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response?.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response?.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error?.response?.data["message"] ?? error?.toString(),
    }));
  }
};

export const updateSafetyStock = async ({
  payload,
  setAlertInfo,
  onSuccess,
}) => {
  try {
    const url = `updateadditionalsafetystock`;
    const response = await api.post(url, payload);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const data = response.data["data"];
        await setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "success",
          msg: `The safety stock of ${
            data["sku_id"] ?? ""
          } has been successfully updated to ${
            data["additional_safety_stock_quantity"]
          }!`,
        }));
        onSuccess();
      } else {
        alertError(response?.data["data"], setAlertInfo);
      }
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};
