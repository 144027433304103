import api from "./api";

const alertError = (err, setAlertInfo) => {
  setAlertInfo((prev) => ({
    ...prev,
    open: true,
    severity: "error",
    msg: err?.response?.data["message"] ?? err?.toString(),
  }));
};

export const getIncentivePlanList = async (
  setRows,
  setLoading,
  setIncentiveCount,
  page,
  pageSize,
  startDate,
  endDate,
  search,
  productCode,
  employeeID
) => {
  try {
    setLoading(true);

    const url = `incentive-plans/?page=${page}&page_size=${pageSize}&search=${search}&start_date=${startDate}&end_date=${endDate}&product_sku=${productCode}&employee=${employeeID}`;

    const response = await api.get(url);

    if (response.status === 200) {
      if (response.data["results"]) {
        setRows(response.data["results"]);
        setIncentiveCount(response.data["count"]);
      } else {
        setRows(response.data);
        setIncentiveCount(response.data.length);
      }

      setLoading(false);
    } else {
      setRows([]);
      setLoading(false);
    }
  } catch (error) {
    setRows([]);
    setLoading(false);
  }
};

export const getIncentiveDetailById = async (id, setDetail) => {
  try {
    const url = `incentive-plans/${id}/`;
    const response = await api.get(url);

    if (response.status === 200) {
      setDetail(response.data);
    }
  } catch (error) {
    // console.log(error);
  }
};

export const createIncentivePlan = async (payload, setAlertInfo, onSuccess) => {
  try {
    const url = `incentive-plans/`;
    const response = await api.post(url, payload);
    if (response.status === 201) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The new incentive plan has been created successfully!`,
      }));
      onSuccess();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const updateIncentivePlan = async (
  id,
  payload,
  setAlertInfo,
  onSuccess
) => {
  try {
    const url = `incentive-plans/${id}/`;
    const response = await api.put(url, payload);

    if (response.status === 200) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The incentive plan - ${id} - has been updated successfully!`,
      }));
      onSuccess();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const deleteIncentivePlan = async (id, setAlertInfo, onSuccess) => {
  try {
    const payload = {
      delete_id: [id],
    };
    const url = `incentive-plans/${id}/`;
    const response = await api.delete(url);

    if (response.status === 204) {
      await setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "success",
        msg: `The incentive plan - ${id} - has been deleted successfully!`,
      }));
      onSuccess();
    } else {
      alertError(response, setAlertInfo);
    }
  } catch (error) {
    alertError(error, setAlertInfo);
  }
};

export const getEmployeeList = async (setData) => {
  try {
    const url = `incentive-plans/employees/`;
    const response = await api.get(url);

    if (response.status === 200) {
      setData(response.data);
    } else {
      setData([]);
    }
  } catch (error) {
    // console.log(error);
    setData([]);
  }
};
