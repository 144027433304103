import React from "react";
import Select from "react-select";
import { Formik } from "formik";

import { SecondaryButton } from "../../../Styles/styles";
import { CATEGORY_OPTIONS, YEAR_OPTIONS } from "./chart.utils";

import {
  handleCategoryChange,
  handleYearChange,
  storeTurnOver,
} from "./turnover.services";

const ChartFilter = ({ dataSetter }) => {
  var today = new Date();
  var yearNow = today.getFullYear();
  return (
    <>
      <Formik
        initialValues={{ year: null, categories: [] }}
        validate={(values) => {
          const errors = {};

          if (values.categories.length === 0) {
            errors.categories = "Please select at least one category!";
          }
          if (!values.year) {
            errors.year = "Please select a year!";
          }

          return errors;
        }}
        onSubmit={(values) => {
          const formData = {
            year: values?.year?.value,
            categories: values?.categories?.map((item) => item?.value),
          };

          storeTurnOver({ filterFormData: formData, setter: dataSetter });
        }}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <label>Year</label>
            <Select
              defaultValue={{ label: yearNow, value: yearNow }}
              value={values.year}
              options={YEAR_OPTIONS({
                startYear: 2000,
                endYear: yearNow,
              })}
              onChange={(selectedYear) =>
                handleYearChange(selectedYear, setFieldValue)
              }
            />
            <pre>{JSON.stringify(errors?.year)}</pre>
            <label>Category</label>
            <Select
              isMulti
              value={values.categories}
              options={CATEGORY_OPTIONS}
              onChange={(selectedCategoryList) =>
                handleCategoryChange(selectedCategoryList, setFieldValue)
              }
            />
            <pre>{JSON.stringify(errors?.categories)}</pre>
            <SecondaryButton type="submit">Filter</SecondaryButton>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChartFilter;
