import React, {
    useEffect,
    createContext,
    useMemo,
    useState,
    Fragment,
} from "react";

import { FORECASTEXCEL_COLUMNS } from "./forecastexcel.table.columns";

import { Box } from "@mui/material";
import { LoadingSpinner, SpinnerContainer } from "../../../Styles/styles";

import { useParams } from "react-router-dom";

import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    useAbsoluteLayout,
} from "react-table";

import { SVG, TertiaryButton } from "../../../Styles/styles";
import up from "../../../images/Ascending.svg";
import down from "../../../images/Descending.svg";
import defaultSort from "../../../images/DefaultSort.svg";

//API
// import { getSKUModalDetail } from "../../../Services/ordertable.service";
// import { getSkuForecast } from "../../../Services/forecast.service";

const ForecastExcelTable = ({
    forecastExcelTableData,
    forecastExcelTableColumns,
    handleDownloadExcel,
    handleDeleteExcel,
}) => {
    const data = forecastExcelTableData;
    const columns = forecastExcelTableColumns;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        page, //this is basically row with pagination
        nextPage,
        previousPage,
        canPreviousPage, //condition to check whether can go to prev page or not
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        SelectColumnFilter,
        // setGlobalFilter,
        // setFilter,
    } = useTable(
        {
            //Values that can be access by the cell later
            data: data,
            columns: columns,

            initialState: {
                sortBy: [
                    {
                        id: "upload_time",
                        desc: true,
                    },
                ],
            },
            //Custom
            handleDownloadExcel: handleDownloadExcel,
            handleDeleteExcel: handleDeleteExcel,
        },
        //Hooks that called to apply these thing
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const { pageIndex, pageSize } = state;
    const excludeArrowSortingHeaders = ["Download", "Delete"];

    useEffect(() => {
        setPageSize(15);
    }, []);

    return (
        <div>
            {/* Table HTML */}
            <div style={{ height: "70vh", overflow: "scroll" }}>
                <table {...getTableProps()} className="forecastTable">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <Fragment key={column.Header}>
                                        <th
                                            {...column.getHeaderProps(
                                                !excludeArrowSortingHeaders.includes(
                                                    column.Header
                                                )
                                                    ? column.getSortByToggleProps() // to enable sorting feature
                                                    : undefined
                                            )}
                                            {...column.getHeaderProps(
                                                !excludeArrowSortingHeaders.includes(
                                                    column.Header
                                                )
                                                    ? {
                                                          //curly instead of open bracket, cause u return an object
                                                          style: {
                                                              ...column.style,
                                                              cursor: "pointer",
                                                          },
                                                      }
                                                    : {
                                                          style: {
                                                              ...column.style,
                                                          },
                                                      }
                                            )}
                                        >
                                            {column.render("Header")}

                                            {/* Add a sort direction indicator */}
                                            {!excludeArrowSortingHeaders.includes(
                                                column.Header
                                            ) &&
                                                (column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <SVG
                                                            style={{
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                            src={up}
                                                        />
                                                    ) : (
                                                        <SVG
                                                            style={{
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                            src={down}
                                                        />
                                                    )
                                                ) : (
                                                    <SVG
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                        src={defaultSort}
                                                    />
                                                ))}
                                        </th>
                                    </Fragment>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    {row.cells.map((cell) => {
                                        // console.log("CELL: ", cell);
                                        return (
                                            <Fragment
                                                key={`${cell.column.Header} - cell`}
                                            >
                                                <td
                                                    {...cell.getCellProps({
                                                        style: {
                                                            ...cell.getCellProps()
                                                                .style,
                                                            textAlign: "center",
                                                            backgroundColor:
                                                                "inherit",
                                                        },
                                                    })} //the args inside is like can className
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            </Fragment>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div
                style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >
                <div>
                    <TertiaryButton
                        style={{ margin: "5px" }}
                        className="hoverSecWhiteButton"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {"<<"}
                    </TertiaryButton>
                    <TertiaryButton
                        style={{ margin: "5px" }}
                        className="hoverSecWhiteButton"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        Previous
                    </TertiaryButton>
                    <TertiaryButton
                        style={{ margin: "5px" }}
                        className="hoverSecWhiteButton"
                        onClick={() => nextPage()}
                        disabled={false}
                    >
                        Next
                    </TertiaryButton>
                    <TertiaryButton
                        className="hoverSecWhiteButton"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </TertiaryButton>
                </div>
                <span style={{ marginTop: "10px" }}>
                    Page
                    <strong style={{ marginLeft: "5px", marginRight: "5px" }}>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <span style={{ marginTop: "10px" }}>
                    Go To Page:
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageNumber);
                        }}
                        style={{ width: "50px", marginLeft: "5px" }}
                    />
                </span>
                <span style={{ marginTop: "10px" }}>
                    Show
                    <select
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                    >
                        {[10, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    Items
                </span>
            </div>
        </div>
    );
};

export default ForecastExcelTable;
/*


SKU/PO/VenContext = createContext()
<TableContext.Provider>
<FilterObject> => useContext(TableContext)
<OrderStatusTypeTable tableSettings={{sortBy}} tableData={{data,col}} tableContext={TableContext}>
<TableContext.Provider>

*/
