import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";

import CalendarIcon from "../../images/Calendar.svg";
import { formatFilterDate } from "../../Pages/UsefulLinks/Marketing Campaign/FilterEvent/FilterEventModal";

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const Input = ({ onChange, placeholder, value, id, onClick }) => (
  <TextField
    margin="normal"
    fullWidth
    value={value}
    id={id}
    onChange={onChange}
    placeholder={placeholder}
    onClick={onClick}
    InputProps={{
      readOnly: true,
      startAdornment: (
        <InputAdornment position="start">
          <Icon src={CalendarIcon} />
        </InputAdornment>
      ),
    }}
    sx={{
      padding: "0px",
      margin: "0px",
      height: "52px",
      backgroundColor: "#eff1f999",
      borderRadius: "8px",
      "& legend": { display: "none" },
      "& fieldset": { border: "none" },
      "& label.Mui-focused": {
        border: "none",
      },
      "& .MuiInput-underline:after": {
        border: "none",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "none",
        },
        "&:hover fieldset": {
          border: "none",
        },
        "&.Mui-focused fieldset": {
          border: "none",
        },
      },
    }}
  />
);

const DatePickerField = ({ name, value, onChange, placeholder }) => {
  return (
    <ReactDatePicker
      selected={(value && new Date(value)) || null}
      customInput={<Input />}
      placeholderText={placeholder}
      onChange={(date) => onChange(name, formatFilterDate(date))}
      dateFormat="yyyy-MM-dd"
      format="yyyy-MM-dd"
    />
  );
};
export default DatePickerField;
