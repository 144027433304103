import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import { rejectedColoredColumns } from "../Components/Tables/columns";
import { getHistory, getIntraHistory, getNewHistory } from "../Services/services";

const useRejectedTable = ({ stage, option, refresher, intra }) => {
  const columns = useMemo(rejectedColoredColumns, []);
  const params = useParams();

  const [allHistory, setAllHistory] = useState();
  const [historyOptions, setHistoryOptions] = useState();
  const [historyValue, setHistoryValue] = useState();
  const [POdetails, setPOdetails] = useState();

  const handleHistoryChange = (e) => {
    setHistoryValue(e.target.value);
  };

  useEffect(() => {
    // if (intra === true) {
    //   getIntraHistory({
    //     stage,
    //     poNum: option,
    //     historyRecordSetter: setAllHistory,
    //     optionsSetter: setHistoryOptions,
    //   });
    // } else if (intra === false) {
    //   getHistory(stage, option, setAllHistory, setHistoryOptions);
    // } else {
      getNewHistory(option, setAllHistory, setHistoryOptions);
    // }
  }, [params.poid, params.stage, refresher]);

  useEffect(() => {
    historyOptions && setHistoryValue(historyOptions[0]);
    historyOptions && setPOdetails(allHistory[0]);
  }, [historyOptions]);

  useEffect(() => {
    const queryIndex = historyOptions && historyOptions.indexOf(historyValue);
    allHistory && setPOdetails(allHistory[queryIndex]);
  }, [historyValue]);

  return {
    columns,
    POdetails,
    historyValue,
    historyOptions,
    handleHistoryChange,
  };
};

export default useRejectedTable;
