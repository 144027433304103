import React from "react";
import { Outlet, useParams } from "react-router-dom";

const SafetyStockSkuStatus = () => {
  const { safetyStockStatus } = useParams();
  const possibleSafetyStockStatuses = ["active", "inactive"];

  return possibleSafetyStockStatuses.includes(safetyStockStatus) ? (
    <div style={{ marginTop: "20px" }}>
      <Outlet context={{ safetyStockStatus }} />
    </div>
  ) : (
    ""
  );
};

export default SafetyStockSkuStatus;
