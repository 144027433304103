import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextField, TableHead, IconButton, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EditableTable = forwardRef((props, ref) => {
  const data = props.data;
  const setData = props.setData;

  useImperativeHandle(ref, () => ({
    getData: () => data,
  }));

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text');
    // Filter out empty rows that might result from a final newline character
    const pasteRows = pasteData.split(/\r\n|\n|\r/)
                               .filter(row => row.length) // Filter out empty rows
                               .map(row => row.split('\t').slice(0, 3)); // Ensure only 3 columns for pasting

    let newData = pasteRows.map(row => {
        // Ensure each pasted row has exactly 3 items, filling missing cells with empty strings
        // The Status column remains unaffected
        return [...Array.from({ length: 3 }, (_, index) => row[index] || ''), '']; // Add empty status for new rows
    });

    // Combine new data with existing status, if applicable
    newData = newData.map((row, index) => {
        if (index < data.length) {
            // Preserve existing status if available
            return [...row.slice(0, 3), data[index][3] || ''];
        }
        return row;
    });

    // if no data in col status, remove the status column
    // if (newData.every(row => !row[3] || row[3].trim() === '')) {
    newData = newData.map(row => row.slice(0, 3));
    // }

    setData(newData);
    e.preventDefault();
  };
  const removeRow = (indexToRemove) => {
    if (data.length === 1) {
      setData([['', '', '']]);
    } else {
      const newData = data.filter((_, index) => index !== indexToRemove);
      setData(newData);
    }
  };

  const isStatusColumnEmpty = data.every(row => !row[3] || row[3].trim() === '');

  return (
    <TableContainer sx={{
      maxHeight: data.length > 10 ? 500 : 'auto', // Adjust 500px to your preference
      overflow: 'auto',
    }}>
    {/* // <TableContainer component={Paper}> */}
      {/* <Table aria-label="editable table"> */}
      <Table aria-label="editable table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '40%', zIndex:1 }}>
              <Typography variant='' sx={{color:"white"}}>SKU(s)</Typography>
            </TableCell>
            <TableCell sx={{ width: '10%', zIndex:1 }}>
              <Typography variant='' sx={{color:"white"}}>Original Price ($)</Typography>
            </TableCell>
            <TableCell sx={{ width: '10%', zIndex:1 }}>
              <Typography variant='' sx={{color:"white"}}>Selling Price ($)</Typography>
            </TableCell>
            {!isStatusColumnEmpty && (<TableCell sx={{ width: '40%', zIndex:1 }}>
              <Typography variant='' sx={{color:"white"}}>Status</Typography>
            </TableCell>)}
            <TableCell sx={{ width: '1%', zIndex:1 }}>
              <Typography variant='' sx={{color:"white"}}></Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.map((row, rowIndex) => (
          <TableRow key={rowIndex}
            sx = {{
              backgroundColor: row[3] != 'OK' ? (row[3] && row !== '' ? 'rgba(242, 38, 19, 0.1) !important' : 'inherit') : 'rgba(46, 204, 113, 0.2) !important',
            }}>
            {row.map((cell, columnIndex) => (
              <TableCell key={columnIndex} sx={{ py: '2px !important', fontSize: '0.875rem', textAlign: columnIndex >= 1 && columnIndex <= 2 ? 'right' : 'inherit' }}>
                {columnIndex === 0 ? (
                  // Specific case for SKU(s) column (columnIndex 0)
                  <TextField
                    value={cell}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[rowIndex][columnIndex] = e.target.value;
                      setData(newData);
                    }}
                    variant="standard"
                    InputProps={{ 
                      disableUnderline: true, 
                      style: { fontSize: 'inherit' } 
                    }}
                    fullWidth
                    onPaste={rowIndex === 0 ? handlePaste : undefined}
                  />
                ) : columnIndex >= 1 && columnIndex <= 2 ? (
                  // For the Original Price and Selling Price columns (columnIndex 1 and 2)
                  <TextField
                    value={cell}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[rowIndex][columnIndex] = e.target.value;
                      setData(newData);
                    }}
                    onBlur={(e) => {
                      // Format the number only when the input loses focus
                      const formattedValue = parseFloat(e.target.value).toFixed(2);
                      if (!isNaN(formattedValue)) {
                        const newData = [...data];
                        newData[rowIndex][columnIndex] = formattedValue;
                        setData(newData);
                      }
                    }}
                    variant="standard"
                    InputProps={{ 
                      disableUnderline: true, 
                      style: { fontSize: 'inherit', textAlign: 'right' } 
                    }}
                    fullWidth
                    onPaste={rowIndex === 0 ? handlePaste : undefined}
                  />
                //   <TextField
                //   value={cell}
                //   onChange={(e) => {
                //     const newData = [...data];
                //     newData[rowIndex][columnIndex] = e.target.value;
                //     setData(newData);
                //   }}
                //   variant="standard"
                //   InputProps={{ 
                //     disableUnderline: true, 
                //     style: { fontSize: 'inherit', textAlign: 'right' } 
                //   }}
                //   fullWidth
                //   onPaste={rowIndex === 0 ? handlePaste : undefined}
                // />
                ) : columnIndex === 3 ? (
                  // Specific case for Status column (columnIndex 3)
                  cell.trim() !== 'OK' ? (
                    <TextField
                      value={cell}
                      onChange={(e) => {
                        const newData = [...data];
                        newData[rowIndex][columnIndex] = e.target.value;
                        setData(newData);
                      }}
                      variant="standard"
                      InputProps={{ 
                        disableUnderline: true, 
                        style: { fontSize: 'inherit',
                        color:  'rgba(242, 38, 19, 1)',
                        fontWeight: 'bold'
                      } 
                      }}
                      fullWidth
                      onPaste={rowIndex === 0 ? handlePaste : undefined}
                    />
                  ) : (
                    <CheckCircleIcon sx={{ color: 'green' }} />
                  )
                ) : (
                  // Default case for any other columns
                  <TextField
                    value={cell}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[rowIndex][columnIndex] = e.target.value;
                      setData(newData);
                    }}
                    variant="standard"
                    InputProps={{ 
                      disableUnderline: true, 
                      style: { fontSize: 'inherit' } 
                    }}
                    fullWidth
                  />
                )}
              </TableCell>
            ))}
          {/* {(data.length == 1 && !row[3]) && <TableCell  sx={{ py: '2px !important', fontSize: '0.875rem' }}></TableCell>} */}
          <TableCell  sx={{ py: '2px !important', fontSize: '0.875rem' }}>
            {/* Button to remove the row */}
            <IconButton onClick={() => removeRow(rowIndex)} disabled={data.length === 1 && data[0].every(cell => cell === '')}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}

        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default EditableTable;