import React from "react";
import { Outlet, useParams } from "react-router-dom";

const OrderStatus = () => {
  const { orderStatus } = useParams();
  const possibleOrderStatuses = [
    "procurement",
    "rereview",
    "management",
    "proforma",
    "pogeneration",
    "postoreceive",
  ];

  return possibleOrderStatuses.includes(orderStatus) ? (
    <div style={{ marginTop: "20px" }}>
      <Outlet context={{ orderStatus }} />
    </div>
  ) : (
    ""
  );
};

export default OrderStatus;
