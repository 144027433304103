import React from "react";
import { Outlet } from "react-router-dom";

import useCycle from "../../Hooks/useCycle";
import { SubHeader } from "../../Styles/styles";

// Responsible for displaying the correct Options/Stages available to a particular user(Procurement/Management)
const Cycle = () => {
  const {
    option,
    options,
    stage,
    stages,
    handleOptionChange,
    handleStageChange,
  } = useCycle();

  return (
    <div style={{ maxHeight: "90vh" }}>
      <h1>Procurement Cycle</h1>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "inline-block",
            marginRight: "20px",
          }}
        >
          <SubHeader
            style={{ display: "inline", lineHeight: "1.5" }}
          >{`Stage - `}</SubHeader>

          <select
            style={{
              color: "inherit",
              border: "none",
              font: "inherit",
              fontSize: "20px",
            }}
            value={stage}
            onChange={handleStageChange}
          >
            {stages.map((stage) => (
              <option key={stage}>{stage}</option>
            ))}
          </select>
        </div>
        <div style={{ display: "inline_block" }}>
          <SubHeader
            style={{ display: "inline", lineHeight: "1.5" }}
          >{`RO# - `}</SubHeader>
          <select
            style={{
              color: "inherit",
              border: "none",
              font: "inherit",
              fontSize: "20px",
            }}
            value={option}
            onChange={handleOptionChange}
          >
            {options &&
              options.map((poNum) => (
                <option key={poNum} value={poNum}>
                  {poNum}
                </option>
              ))}
          </select>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Cycle;
