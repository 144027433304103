import styled, { keyframes } from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";

// General
//____________________________________________________________
export const ProductImage = styled.img`
  width: 55px;
  height: 55px;
  border: 1px solid #f1f3f9;
  border-radius: 8px;
  overflow: hidden;
`;

export const SVG = styled.img`
  height: 18px;
`;

export const IconSVG = styled.img`
  width: 12.67px;
  height: 13px;
`;

export const Logo = styled.img`
  height: 7vh;
`;

export const Card = styled.div`
  background: rgba(251, 249, 243, 0.25);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 10px;
`;

export const Header = styled.p`
  font-size: 40px;
  padding: 0px;
  margin: 0px;
`;

export const SubHeader = styled.p`
  font-size: 20px;
  padding: 0px;
  margin: 0px;
`;

export const Paragraph = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: black;
  display: block;
`;

export const ClickableText = styled.a`
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: #999076;
  display: block;
  text-decoration: none;
  :hover {
    color: #615534;
  }
`;

export const Field = styled.input`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  gap: 8px;

  width: 302px;
  height: 50px;

  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vh",
  height: "80vh",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const buttonDefaultStyling = {
  sx: {
    ml: 1,
  },
  size: "large",
  variant: "contained",
};

export const OrderTableModalStyle = {
  display: "block",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#ffffe5",
  boxShadow: 24,
  p: 4,
};

export const newModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vh",
  height: "30vh",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

//____________________________________________________________

// Buttons
//____________________________________________________________
export const CircleButton = styled.button`
  background: #ffffff;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  align-items: "center";
  justify-content: "center";
  width: 30px;
  height: 30px;
  border: 0px;
  border-radius: 30px;
  margin: 0px 6px;
`;

export const TextButton = styled.button`
  display: inline-block;
  height: 40px;
  border: 0px;
  color: grey;
  background-color: transparent;
`;

export const PrimaryPill = styled.button`
  height: 25px;
  background: ${(props) => props.theme.main};
  border-radius: 30px;
  color: white;
  border: 0px;
  padding: 0px 12px;
  margin: 0px 6px;
  min-width: 40px;
  justify-content: center;
  font-size: 13px;
`;

export const PrimaryButton = styled.button`
  height: 40px;
  background: #485202;
  border-radius: 10px;
  color: white;
  border: 0px;
  padding: 0px 12px;
  margin: 0px 6px;
  :disabled {
    background: black;
    opacity: 0.4;
  }
`;

export const PrimaryButton2 = styled.button`
  height: 40px;
  background: #7c8500;
  border-radius: 10px;
  color: white;
  border: 0px;
  padding: 0px 12px;
  margin: 0px 6px;
`;

export const SecondaryButton = styled.button`
  background: #ffffff;
  color: #485303;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 40px;
  border: 0px;
  padding: 0px 12px;
  margin: 0px 6px;
  :disabled {
    background: black;
    opacity: 0.4;
  }
`;

export const SecondaryLoadingButton = MUIStyled(LoadingButton)`
    background-color: #ffffff;
    color: #485303;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 40px;
    border: 0px;
    padding: 0px 12px;
    margin: 0px 6px;
    font-family: "Inter";
    font-weight: 500;
    :hover {
        background-color: #edede1;
    }
`;

export const TertiaryButton = styled.button`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 40px;
  padding: 0px 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  /* Grey */
  color: rgba(0, 0, 0, 0.5);
`;

export const DisabledButton = styled.button`
  background: #ffffff;
  opacity: 0.5;
  color: #485303;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 40px;
  border: 0px;
  padding: 0px 12px;
  margin: 0px 6px;
`;

export const DeleteButton = styled.button`
  background: #ffffff;
  color: red;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 40px;
  border: 0px;
  padding: 0px 12px;
  margin: 0px 6px;
`;

export const WhiteButton = styled.div`
  background: rgba(251, 249, 243, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 0px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
`;

export const DashboardButton = styled.button`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 0px;
  height: 35px;
  padding: 0px 12px;
  margin-left: 10px;
`;

export const ButtonRow = styled.div`
  margin-top: 10px;
  display: flex;
`;

//____________________________________________________________

//____________________________________________________________
export const SearchBar = styled.input`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: 2px solid #bead7c;
  height: 40px;
  &:focus {
    outline: none;
    border: 1px solid #91804e;
    transition: border-color 0.3s linear;
  }
`;

export const ProfilePic = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 40px;
`;

export const LoginLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 40px;
`;

export const NavStyled = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  gap: 10px;
  position: sticky;
`;

export const ProfileDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 5px;
`;
//____________________________________________________________

// Dashboard
//____________________________________________________________
export const DashboardTabContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  // width: 140%;
`;

export const DashboardCard = styled.div`
  background: rgba(251, 249, 243, 0.25);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 40vh;
  margin-right: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 20px;
`;
//____________________________________________________________

// Container
export const ContainerCard = styled.div`
  background: rgba(251, 249, 243, 0.25);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  min-width: 43vh;
  min-height: 25vh;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
`;
//____________________________________________________________

// Spinner
export const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(3600deg);
  }
`;

export const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: ${Spinner} 6s linear infinite;
`;
export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventActiveTab = styled.button`
  border-radius: 0px;
  color: #2b2f32;
  border: 0px;
  padding: 10px 43px;
  margin: 0px 0px;
  border-bottom: 4px solid #bead7c;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background: #f4f5fa;
`;

export const EventInactiveTab = styled.button`
  border-radius: 0px;
  color: #abafb1;
  border: 0px;
  padding: 10px 43px;
  margin: 0px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background: #f4f5fa;
`;

export const Title = MUIStyled(Typography)`
    fontWeight: 400;
    fontSize: 12px;
    textAlign: left;
    color: #8B8D97;
    lineHeight: 15px;
`;
