import api from "./api"; //api here is the axios instance.
import { getUser } from "./auth.service";

// Util function for converting some positions to their lowercase form
const toLowerCaseFirstLetter = (str) => {
  if (str.length === 0) return str;
  if (str.length === 1) return str.charAt[0].toLowerCase();
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const getForecast = async (setForecast) => {
  const url = "/charts/listingcomparison/";
  await api.get(url).then((response) => {
    setForecast(response.data);
  });
};

// For Databases
export const getPODB = async (setPOData) => {
  const url = "getpodetailall/";
  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        setPOData(response.data["data"]);
      }
    }
  } catch (err) {
    // console.log(err);
  }
};

export const getPOFiltersFieldsData = async ({
  setVendorList,
  setUserList,
  setStatusList,
  setAlertInfo,
}) => {
  const url = `getpofilterconditions`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const vendorList = response.data["data"]["vendor_name"] ?? [];
        const userList = response.data["data"]["updated_by"] ?? [];
        const statusList = response.data["data"]["status"] ?? [];

        setUserList(userList);
        setVendorList(vendorList);
        setStatusList(statusList);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error?.response?.data["message"] ?? error?.toString(),
    }));
  }
};

export const getDashboardFiltersFieldsData = async ({
  setVendorList,

  setStageList,
  setTagList,
  setAlertInfo,
}) => {
  const url = `getdashboardfilterconditions`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const vendorList = response.data["data"]["vendor"] ?? [];

        const stageList = response.data["data"]["stage"] ?? [];
        const tagList = response.data["data"]["tags"] ?? [];

        setVendorList(vendorList);
        setStageList(stageList);
        setTagList(tagList);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error?.response?.data["message"] ?? error?.toString(),
    }));
  }
};

export const getOrderFiltersFieldsData = async ({
  setFilterData,
  setAlertInfo,
}) => {
  const url = `getorderfilterconditions`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        setFilterData(response.data["data"]);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error?.response?.data["message"] ?? error?.toString(),
    }));
  }
};

export const getSKUFiltersFieldsData = async ({
  setVendorList,
  setCategoryList,
  setPriceRange,
  setAlertInfo,
  status,
}) => {
  const url = `getskufilterconditions?active=${
    status === "active" ? true : false
  }`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const vendorList = response.data["data"]["vendor"] ?? [];
        const categories = response.data["data"]["category"] ?? [];
        const priceRange = {
          price_start: response.data["data"]["price_start"],
          price_end: response.data["data"]["price_end"],
        };

        setCategoryList(categories);
        setVendorList(vendorList);
        setPriceRange(priceRange);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error?.response?.data["message"],
    }));
  }
};

export const storeSKUSet = async (setSKUSet, status) => {
  const url = `getsku?active=${
    status === "active" ? true : status === "inactive" ? false : ""
  }`;
  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        setSKUSet(response.data["data"]);
      } else {
        // Need to implement the error handling
        setSKUSet([]);
      }
    } else {
      // Need to implement the error handling
      setSKUSet([]);
    }
  } catch (err) {
    // console.log(err);
    setSKUSet([]);
  }
};

export const storeSKUSetByVendorID = async (setSKUSet, vendorId) => {
  const url = `getsku?vendor_id=${vendorId}`;
  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        setSKUSet(response.data["data"]);
      } else {
        // Need to implement the error handling
        setSKUSet([]);
      }
    } else {
      // Need to implement the error handling
      setSKUSet([]);
    }
  } catch (err) {
    // console.log(err);
    setSKUSet([]);
  }
};

export const storeSKUIDList = async (setSKUIDList) => {
  const url = "getsku";
  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const skuIDList = response.data["data"].map((obj) => obj.skuID);

        setSKUIDList(skuIDList);
      } else {
        // Need to implement the error handling
      }
    } else {
      // Need to implement the error handling
    }
  } catch (err) {
    // console.log(err);
  }
};

export const getSKUSet = async () => {
  const url = "getsku";
  try {
    const response = await api.get(url);
    //console.log({ data: response.data });

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        return response.data["data"];
      } else {
        // Need to implement the error handling
        return [];
      }
    } else {
      // Need to implement the error handling
      return [];
    }
  } catch (err) {
    // console.log(err);
  }
};

export const storeVendorSet = async (setVendorSet) => {
  const url = "getvendor";
  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        setVendorSet(response.data["data"]);
      } else {
        // Need to implement the error handling
      }
    } else {
      // Need to implement the error handling
    }
  } catch (err) {
    // console.log(err);
  }
};

export const getVendorsFiltersFieldsData = async ({
  setVendorList,

  setAlertInfo,
  status,
}) => {
  const url = `getvendorfilterconditions?active=${
    status === "active" ? true : false
  }`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      if (
        response.data["status_code"] === 200 &&
        response.data["status"] === "success"
      ) {
        const vendorList = response.data["data"]["vendor"] ?? [];

        setVendorList(vendorList);
      } else {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: response.data["message"] ?? "Failed to load data.",
        }));
      }
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        open: true,
        severity: "error",
        msg: response?.data["message"] ?? "Failed to load data.",
      }));
    }
  } catch (error) {
    setAlertInfo((prev) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: error?.response?.data["message"],
    }));
  }
};

// export const sendToBacklog = async (poID, SKU, VendorID) => {
//     const user = getUser();
//     const position = user?.position;
//     // Safety code. user.position should already be all lower cased
//     const lowerCasedPosition =
//         position.charAt(0).toLowerCase() + position.slice(1);
//     // Safety code. user.position should already be all lower cased
//     var url;
//     switch (lowerCasedPosition) {
//         case "procurement":
//             url = "sendtobacklogpro/";
//             break;
//         case "management":
//             url = "sendtobacklogpro/";
//             break;
//     }
//     await api.post(url + poID, { SKU, VendorID });
// };

// export const getAllPO = async (setPurchaseOrders) => {
//     const url = "getpodetailall/";
//     const response = await api.get(url);
//     setPurchaseOrders(response.data);
// };

export const toggleSKUActiveFunc = async (rowProp) => {
  const rowInfo = rowProp?.row?.original;
  const becomeInactive = "skuactivetoinactive";
  const becomeActive = "skuinactivetoactive";

  if (rowInfo.status === "Active" || rowInfo.status === "active") {
    const response = await api.put(becomeInactive, rowInfo);
  } else {
    const response = await api.put(becomeActive, rowInfo);
  }
};

export const toggleVenActiveFunc = async (rowProp) => {
  const rowInfo = rowProp?.row?.original;
  const becomeInactive = `venactivetoinactive/${rowInfo.vendorID}`;
  const becomeActive = `veninactivetoactive/${rowInfo.vendorID}`;

  if (rowInfo.active) {
    const response = await api.put(becomeInactive);
  } else {
    const response = await api.put(becomeActive);
  }
};

// Marketing Campaign
export const getMarketingCampaign = async (setter) => {
  const url = "marketingCampaignDetail";
  try {
    const response = await api.get(url);
    setter(response.data);
  } catch (err) {}
};

// Overview
export const yes = async (setter) => {
  const url = "marketingCampaignDetail";
  try {
    const response = await api.get(url);
    setter(response.data);
  } catch (err) {}
};

// New Overview
export const getAllLatestPO = async (setter) => {};

// Container
export const getContainers = async (stage, poNum, setter) => {
  var url;
  switch (stage) {
    case "Procurement":
      url = "getcontainerpro/";
      break;
    case "Management":
      url = "getcontainerman/";
      break;
    case "Proforma":
      url = "getcontainerven/";
      break;
    case "POGeneration":
      url = "getcontainergen/";
      break;
    case "POsToReceive":
      url = "getcontainergen/";
  }
  try {
    const response = await api.get(url + poNum);
    if (response.data.length !== 0) {
      setter(response.data);
    }
  } catch (err) {}
};

// Backlogs

export const getBacklog = async (poNum, setter) => {
  const url = "getbacklog/";
  try {
    const response = await api.get(url + poNum);
    if (response.data.length !== 0) {
      setter([response.data]);
    }
  } catch (err) {
    setter([]);
    // console.log(err);
  }
};

export const getCycleOptions = async (stage) => {
  var option;
  const currentStage = toLowerCaseFirstLetter(stage);
  switch (currentStage) {
    case "procurement":
      option = "polatestpro";
      break;
    case "management":
      option = "polatestman";
      break;
    case "vendor":
    case "proforma":
      option = "polatestven";
      break;
  }

  try {
    const response = await api.get(option);
    const opts = response.data.map((PO) => PO.PONUMBER);
    return opts;
  } catch (err) {
    return [];
  }
};

export const undoBacklog = async (SKU, VendorID, poID) => {
  var undoURL = "";
  const position = getUser().position;
  switch (position) {
    case "procurement":
      undoURL = "sendfrombacklogpro/";
      break;
    case "management":
      undoURL = "sendfrombacklogman/";
      break;
  }
  await api.post(undoURL + poID, { SKU, VendorID });
};

// Rejected
// ____________________________________________
export const getHistory = async (
  stage,
  poNum,
  historyRecordSetter,
  optionsSetter
) => {
  var url;
  switch (stage) {
    case "Procurement":
      url = "comparepopro/";
      break;
    case "Management":
      url = "comparepoman/";
      break;
  }

  try {
    const response = await api.get(url + poNum);
    const allHistory = response.data.procurementInterHistory;
    const histInfoList = allHistory.map((hist) => {
      const editDate =
        hist[0][0].slice(0, 10) + " at " + hist[0][0].slice(11, 19);
      const editor = hist[0][1];
      return editDate + " by " + editor;
    });

    const record = allHistory.map((hist) => hist[1]);
    optionsSetter(histInfoList);
    historyRecordSetter(record);

    // historyRecordSetter()
  } catch (err) {}
};
// Intra History
// ____________________________________________
export const getIntraHistory = async ({
  stage,
  poNum,
  historyRecordSetter,
  optionsSetter,
}) => {
  // console.log({ stage, poNum });
  var url = "";
  switch (stage) {
    case "Procurement":
      url = "compareintrapopro/";
      break;
    case "Management":
      url = "compareintrapoman/";
      break;
    case "Proforma":
      url = "compareintrapoven/";
      break;
    case "POGeneration":
      url = "compareintrapogen/";
      break;
    case "POsToReceive":
      url = "compareintrapogen/";
      break;
    default:
      url = "reachedgetIntraHistorythereIsABug";
      break;
  }

  try {
    const response = await api.get(url + poNum);
    const allHistory = response.data.procurementInterHistory;
    const histInfoList = allHistory.map((hist) => {
      const editDate =
        hist[0][0].slice(0, 10) + " at " + hist[0][0].slice(11, 19);
      const editor = hist[0][1];
      return editDate + " by " + editor;
    });

    const record = allHistory.map((hist) => hist[1]);
    optionsSetter(histInfoList);
    historyRecordSetter(record);

    // historyRecordSetter()
  } catch (err) {}
};

// History
// ____________________________________________
export const getNewHistory = async (
  poNum,
  historyRecordSetter,
  optionsSetter
) => {
  // console.log(poNum);
  var url = "pohistory/";

  const intToStage = (stage, version, record) => {
    switch (stage) {
      case 1:
        switch (version) {
          case 1:
            return "Procurement" + record;
          default:
            return "Re-Review" + record;
        }
      case 2:
        return "Management" + record;
      case 3:
        return "Proforma" + record;
      case 4:
        return "POGeneration" + record; //POsToReceive
    }
  };

  try {
    const response = await api.get(url + poNum);
    const allHistory = response.data.procurementInterHistory;
    // console.log(allHistory);
    const histInfoList = allHistory.map((hist) => {
      const editDate =
        hist[0][0].slice(0, 10) + " at " + hist[0][0].slice(11, 19);
      const editor = hist[0][1];
      // console.log(hist[0][2][0]);
      const from = intToStage(hist[0][2][0], hist[0][2][1], hist[0][2][2]);
      const to = intToStage(hist[0][3][0], hist[0][3][1], hist[0][3][2]);
      // return editDate + " by " + editor + stages;
      return "From " + from + " To " + to + " on " + editDate + " by " + editor;
    });

    const record = allHistory.map((hist) => hist[1]);
    optionsSetter(histInfoList);
    historyRecordSetter(record);

    // historyRecordSetter()
  } catch (err) {}
};

// Since store<Noun> is an often repeated pattern, abstract it out to reduce code
export const abstractStorer = async ({ setter, URL }) => {
  const response = await api.get(URL);

  if (response.status === 200) {
    if (
      response.data["status_code"] === 200 &&
      response.data["status"] === "success"
    ) {
      setter(response.data["data"]);
    }
  }
};
