import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import database from "../images/Database.svg";
import dashboard from "../images/Dashboard.svg";
import orderSVG from "../images/Order.svg";
import clockSVG from "../images/Clock.svg";
import forecast from "../images/Forecast.svg";
import marketing from "../images/Marketing.svg";
import management from "../images/Management.png";
import excelSVG from "../images/Excel.svg";

import styled from "styled-components";
import { SVG } from "../Styles/styles";
import useAuth from "../Hooks/useAuth";

import { storeAllROs } from "../Pages/Dashboard/actiondashboard.services";
import { getCurrentUser } from "../Services/auth.service";

import Box from "@mui/material/Box";

//context
import { ActionDashboardContext } from "../App"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING

const Background = styled.div`
  /* min-width: 250px; */
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  // max-height: 100vh;
  // height: 100%;
  background-color: #faf9f4;
  top: 0;
  position: sticky;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`;

const navLinkChild = ({ isActive }, text, img) => {
  return isActive ? (
    <div
      style={{
        backgroundColor: "#BEAD7C",
        marginBottom: "1px",
        padding: "4px",
        borderRadius: "5px",
      }}
    >
      <SVG src={img} style={{ paddingLeft: "10px", marginRight: "5px" }} />{" "}
      {text}
    </div>
  ) : (
    <div style={{ marginBottom: "1px", padding: "3px" }}>
      <SVG src={img} style={{ paddingLeft: "10px", marginRight: "5px" }} />{" "}
      {text}
    </div>
  );
};

const Sidebar = () => {
  var today = new Date();

  const {
    actionDashboardData,
    setActionDashboardData,
    actionDashboardFilteredData,
    setActionDashboardFilteredData,
  } = useContext(ActionDashboardContext);

  // TODO : Filter the action dashboard data by user position
  const filterDataByPosition = () => {
    // Retreive the user position
    const position = getCurrentUser().position;

    // Default filter list
    let filterList = [
      "Order Now",
      "Re-review",
      "Proforma Current",
      "Proforma Previous",
      "Management",
    ];

    // Check the user position and reset the filter list
    switch (position) {
      case "procurement":
        filterList = [
          "Order Now",
          "Re-review",
          "Proforma Current",
          "Proforma Previous",
        ];
        break;
      case "management":
        filterList = ["Management"];
        break;
    }

    // Filtering the data
    const filterTempData = (
      actionDashboardData?.actions ? actionDashboardData?.actions : []
    )?.filter((item) => {
      return filterList.includes(item.tags);
    });

    setActionDashboardFilteredData(filterTempData);
  };

  //call this will call setActionDashboardData > Change ActionDashboardData > Trigger filterDataByPosition > setActionDashboardFilteredData > Change ActionDashboardFilteredData
  useEffect(() => {
    storeAllROs({ setter: setActionDashboardData });
  }, []);

  useEffect(() => {
    if (actionDashboardData) {
      filterDataByPosition();
    }
  }, [actionDashboardData]);

  var actDashboard =
    actionDashboardFilteredData?.length > 0
      ? "PO Actions Dashboard (" + actionDashboardFilteredData?.length + ")"
      : "PO Actions Dashboard";

  const { pathname } = useLocation();
  const orderActive = pathname.includes("orders");
  const eventActive = pathname.includes("event");
  const safetyStockActive = pathname.includes("safety-stock");

  return (
    <Background>
      <div className="p-3">
        <h5>Procurement Cycle</h5>
        <NavLink
          style={{ textDecoration: "none" }}
          to="/"
          children={({ isActive }) =>
            navLinkChild({ isActive }, actDashboard, dashboard)
          }
        />
        <NavLink
          style={{ textDecoration: "none" }}
          to="/orders/procurement"
          children={() =>
            navLinkChild({ isActive: orderActive }, "Orders", orderSVG)
          }
        />
      </div>
      <div className="p-3">
        <h5>Database</h5>
        <NavLink
          style={{ textDecoration: "none" }}
          to="/poDB"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "PO", database)
          }
        />
        <NavLink
          style={{ textDecoration: "none" }}
          to="/skuDB"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "SKU", database)
          }
        />

        <NavLink
          style={{ textDecoration: "none" }}
          to="/vendorDB"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "Vendors", database)
          }
        />
      </div>
      <div className="p-3">
        <h5>Upload Data</h5>

        <NavLink
          style={{ textDecoration: "none" }}
          to="/marketingCampaign/active-events"
          children={() =>
            navLinkChild(
              { isActive: eventActive },
              "Marketing Campaign",
              marketing
            )
          }
        />

        <NavLink
          style={{ textDecoration: "none" }}
          to="/vendor-leadtime"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "Vendor Leadtime", clockSVG)
          }
        />
        {/* <NavLink
          style={{ textDecoration: "none" }}
          to="/forecast_excel"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "Forecast Excel", excelSVG)
          }
        /> */}
        <NavLink
          style={{ textDecoration: "none" }}
          to="/incentivePlan"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "Incentive Plan", management)
          }
        />
        <NavLink
          style={{ textDecoration: "none" }}
          to="/safety-stock/active"
          children={({ isActive }) =>
            navLinkChild(
              { isActive: safetyStockActive },
              "Safety Stock",
              orderSVG
            )
          }
        />
      </div>
      <div className="p-3">
        <h5>Analytics</h5>
        <NavLink
          style={{ textDecoration: "none" }}
          to="/mlforecast"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "Forecast", forecast)
          }
        />
        <NavLink
          style={{ textDecoration: "none" }}
          to="/ManagementDashboard/Performance"
          children={({ isActive }) =>
            navLinkChild({ isActive }, "Management", management)
          }
        />
      </div>

      <Box
        sx={{
          fontSize: "12px",
          px: 3,
          textAlign: "center",
          mt: "auto",
          bottom: 0,
        }}
      >
        <p style={{ marginBottom: "0px" }}>Powered by Reluvate Technologies.</p>
        <p style={{ marginBottom: "1rem" }}>Copyright {today.getFullYear()}</p>
      </Box>
    </Background>
  );
};

export default Sidebar;
