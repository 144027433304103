import React, { useContext, useEffect, useState } from "react";

import { Box, Drawer, Stack, Typography, Button } from "@mui/material";
import ReactSelect from "react-select";
import { AlertContext } from "../../../App";
import { getSafetyStockFiltersFieldsData } from "../../../Services/safetystock.service";

const SafetyStockFilterModal = ({
  open,
  handleClose,
  status,
  setFilterBySku,
  setFilterByVendor,
}) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const [skuList, setSkuList] = useState([]);
  const [selectedSku, setSelectedSku] = useState();

  useEffect(() => {
    getSafetyStockFiltersFieldsData({
      setSKUList: setSkuList,
      setVendorNameList: setVendorList,
      setAlertInfo,
      status,
    });
  }, []);

  const vendorOptions = () => {
    return vendorList.map((vendor) => {
      return {
        value: vendor,
        label: vendor,
      };
    });
  };

  const handleSelectVendor = (e) => {
    setSelectedVendor(e?.value);
  };

  const skuOptions = () => {
    return skuList.map((sku) => {
      return {
        value: sku,
        label: sku.sku_id + " - " + sku.sku_name,
      };
    });
  };

  const handleSelectSku = (e) => {
    setSelectedSku(e?.value);
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50vh",
          height: "100%",
          padding: "56px 24px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        role="presentation"
      >
        <Box width="100%">
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              SKU
            </Typography>
            <ReactSelect
              value={
                selectedSku && {
                  value: selectedSku,
                  label: selectedSku.sku_id + " - " + selectedSku.sku_name,
                }
              }
              options={skuOptions()}
              onChange={handleSelectSku}
              isClearable
            />
          </Box>
          <Box marginBottom="36px">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "135%",
                color: "#1c1d22",
              }}
              marginBottom="8px"
            >
              Vendor Name
            </Typography>
            <ReactSelect
              value={
                selectedVendor && {
                  value: selectedVendor,
                  label: selectedVendor,
                }
              }
              options={vendorOptions()}
              onChange={handleSelectVendor}
              isClearable
            />
          </Box>
        </Box>

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="16px"
          marginTop="16px"
        >
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              borderRadius: "5px",
              borderColor: "#53545C",
              color: "#53545C",
              ":hover": {
                borderColor: "#53545C",
                color: "#53545C",
              },
            }}
            onClick={() => {
              setFilterBySku();
              setFilterByVendor("");
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#bead7c",
              color: "#1c1d22",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Inter",
              ":hover": {
                background: "#bead7c",
                color: "#1c1d22",
              },
            }}
            onClick={() => {
              setFilterBySku(selectedSku);
              setFilterByVendor(selectedVendor);
              handleClose();
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default SafetyStockFilterModal;
