import React, { useContext } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
  Header,
  PrimaryButton,
  SecondaryButton,
  DisabledButton,
} from "../../Styles/styles";
import { getUser } from "../../Services/auth.service";
import { Box, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Orders = ({ lastForecasted }) => {
  const position = getUser()?.position; //procurement && management

  const location = useLocation();

  /// Popper for vendor info
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  const showLastTrigger =
    location.pathname === "/orders/procurement" ||
    location.pathname === "/orders/rereview" ||
    location.pathname === "/orders/management" ||
    location.pathname === "/orders/proforma" ||
    location.pathname === "/orders/pogeneration" ||
    location.pathname === "/orders/postoreceive";

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Header>Orders</Header>
        {showLastTrigger && (
          <Tooltip title="Click here">
            <IconButton
              aria-describedby={id}
              type="button"
              onClick={handlePopoverClick}
            >
              <InfoIcon sx={{ color: "#485202" }} />
            </IconButton>
          </Tooltip>
        )}
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, bgcolor: "background.paper" }}>
            <Typography>Last Forecast Run At : {lastForecasted}</Typography>
          </Box>
        </Popover>
      </div>
      <div>
        <NavLink
          to="procurement"
          children={({ isActive }) =>
            OrderButton({ isActive, text: "Procurement" })
          }
        />
        <NavLink
          to="rereview"
          children={({ isActive }) =>
            OrderButton({ isActive, text: "Re-review", position })
          }
        />

        <NavLink
          to="management"
          children={({ isActive }) =>
            OrderButton({ isActive, text: "Management", position })
          }
        />

        <NavLink
          to="proforma"
          children={({ isActive }) =>
            OrderButton({ isActive, text: "Proforma" })
          }
        />
        <NavLink
          to="pogeneration"
          children={({ isActive }) =>
            OrderButton({ isActive, text: "PO Generation" })
          }
        />
        <NavLink
          to="postoreceive"
          children={({ isActive }) =>
            OrderButton({ isActive, text: "POs to Receive" })
          }
        />
      </div>
      <hr />
      <Outlet />
    </>
  );
};

const OrderButton = ({ isActive, text, position }) => {
  if (text === "Re-review" && position === "management") {
    return <DisabledButton disabled={true}>{text}</DisabledButton>;
  } else if (text === "Management" && position === "procurement") {
    return <DisabledButton disabled={true}>{text}</DisabledButton>;
  }

  return isActive ? (
    <PrimaryButton>{text}</PrimaryButton>
  ) : (
    <SecondaryButton>{text}</SecondaryButton>
  );
};
export default Orders;
