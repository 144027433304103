import { useState } from "react";

const useRefresh = () => {
  const [refresher, setRefresher] = useState(0);

  const handleRefresh = () => {
    setRefresher((prev) => prev + 1);
  };
  return { refresher, handleRefresh };
};

export default useRefresh;
