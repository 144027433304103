import { Tooltip, Typography } from "@mui/material";
import EditButton from "./EditSafetyStock/EditButton";

export const SAFETY_STOCK_TABLE_COLUMNS = [
  {
    headerName: "SKU",
    field: "skuID",
    flex: 3,
    align: "left",
    headerAlign: "left",
  },

  {
    headerName: "Product Code",
    field: "shortcode",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },

  {
    headerName: "Description",
    field: "description",
    flex: 6,
    align: "left",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.description}>
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#6E7079" }}
          >
            {params.row.description}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    headerName: "Vendor Name",
    field: "vendor_name",
    flex: 4,
    align: "left",
    headerAlign: "center",
  },
  {
    headerName: "Additional Safety Stock Month", // Can Edit
    field: "additional_safety_stock_month",
    flex: 2,
    align: "right",
    headerAlign: "center",
  },

  {
    headerName: "",
    field: "edit",
    flex: 1,
    align: "center",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <EditButton id={params.row.skuID} data={params.row} />;
    },
  },
];

export const mockSafetyStockData = [
  {
    id: 1,
    sku: "PS-03-00003-03-0120-SQ",
    description: "3FT BEDSLAT (20PCS/BUNDLE) (91.6*7*1.5 CM)",
    last_calculated_total: 408,
    additional_per_month: 136,
    updated_by: "sage",
    updated_at: "2023-03-13T23:16:10.301427Z",
  },
];
