import React from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

import DatePickerField from "../../../../Components/DatePicker/DatePickerField";
import { useFormikContext } from "formik";

const Label = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
`;

const CheckboxLabel = MUIStyled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6E7079;
`;

const textFieldStyle = {
  padding: "0px",
  margin: "0px",
  marginBottom: "24px",
  height: "52px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const fullHeightTextFieldStyle = {
  padding: "0px",
  margin: "0px",
  height: "100%",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const NewEventCard = ({ locations = [] }) => {
  const { values, setFieldValue, handleChange } = useFormikContext();
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        padding: "28px 32px",
        border: "1px solid #C1C1C1",
      }}
    >
      <Label>Event Details</Label>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            fullWidth
            id="name"
            name="name"
            value={values.name}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Name of Event"
            InputProps={{}}
            sx={textFieldStyle}
          />
          {/* Start Date Section */}
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Label>Start Date</Label>
              <DatePickerField
                name="start_date"
                value={values.start_date}
                onChange={setFieldValue}
                placeholder="Start Date"
              />
            </Grid>
            <Grid item xs={6}>
              <Label>End Date</Label>
              <DatePickerField
                name="end_date"
                value={values.end_date}
                onChange={setFieldValue}
                placeholder="End Date"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="normal"
            fullWidth
            multiline
            id="description"
            name="description"
            value={values.description}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Additional Note"
            InputProps={{}}
            sx={fullHeightTextFieldStyle}
          />
        </Grid>
      </Grid>
      <FormControlLabel
        sx={{ marginTop: "26px" }}
        id="is_external"
        name="is_external"
        checked={values?.is_external}
        onChange={() => setFieldValue("is_external", !values?.is_external)}
        control={
          <Checkbox
            sx={{
              color: "#CFD3D4",
              "&.Mui-checked": {
                color: "#bead7c",
              },
            }}
          />
        }
        label={<CheckboxLabel>External Event</CheckboxLabel>}
      />
    </Box>
  );
};

export default NewEventCard;
