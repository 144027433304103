import React, { useEffect, useState, useMemo } from "react";
import { storeForecastOptions } from "../Services/forecast.service";
import { forecastArrayShifter } from "../Services/util";

// const useForecast = () => {
//     const [options, setOptions] = useState();
//     const [selectedVendor, setSelectedVendor] = useState();
//     const [selectedSKU, setSelectedSKU] = useState();

//     const [labels, setLabels] = useState();
//     const [forecast, setForecast] = useState();
//     const [pastBought, setPastBought] = useState();
//     const [intersection, setIntersection] = useState();

//     const handleSelectVendor = (selectedOption) => {
//         console.log("handleSelectVendor props:", selectedOption);
//         setSelectedVendor(selectedOption);
//         setSelectedSKU();
//         setForecast();
//         setPastBought();
//         setIntersection();
//     };
//     const handleSelectSKU = (selectedOption) => {
//         console.log("handleSelectSKU props: ", selectedOption);
//         setSelectedSKU(selectedOption);
//     };

//     // useMemo(() => {
//     //     storeForecastOptions({ setOptions });
//     // }, []);

//     useEffect(() => {
//         /*
//     selectedSKU :{
//       label:"SKU1",
//       value:[{"current month":"July"},{"forecast":[5,1,2,0]},{"past bought":[0,0,4,3,0,4,1,1]}]
//     }
//     */

//         // After both the Vendor and SKU have been selected:
//         if (selectedSKU) {
//             const currMonth = selectedSKU?.value[0]["current month"];
//             const rawForecastedData = selectedSKU?.value[1]["forecast"];
//             const rawPastBought = selectedSKU?.value[2]["past bought"];
//             const paddedForecast = Array(8)
//                 .fill(null, 0, 8)
//                 .concat(rawForecastedData);

//             const paddedPastBought = rawPastBought.concat(rawForecastedData[0]);
//             const monthArrays = forecastArrayShifter({ currMonth });
//             setIntersection(rawForecastedData[0]);
//             setLabels(monthArrays);
//             setForecast(paddedForecast);
//             setPastBought(paddedPastBought);
//         }
//     }, [selectedSKU]);

//     return {
//         options,
//         handleSelectVendor,
//         handleSelectSKU,
//         selectedVendor,
//         selectedSKU,

//         labels,
//         chartData: { forecast, pastBought, intersection },
//     };
// };

// export default useForecast;

const useForecast = () => {
  const initialForecast = {
    curMonth: "",
    label: [],
    forecast: [],
    pastBought: [],
    ema_6: [],
    sma_18: [],
    reason: "",
  };

  const [forecastedData, setForecastedData] = useState(initialForecast);

  return {
    initialForecast,
    forecastedData,
    setForecastedData,
  };
};

export default useForecast;
