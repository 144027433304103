import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Components/Sidebar";
import Navbars from "./Components/Navbar";

const Layout = () => {
  return (
    <>
      <div>
        <Navbars className="col-md-auto" />
      </div>

      <div style={{ display: "flex" }}>
        <div className="col-md-2 layout">
          <Sidebar />
        </div>
        <div className="px-5 py-3 col-md-10">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
