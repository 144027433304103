import React, { useState } from "react";

import SubmitDialog from "../../Components/SubmitDialog";

import useOrderTable from "../../Hooks/useOrderTable";
import RowExpandTable from "../../Components/Tables/RowExpandTable";
import useModal from "../../Hooks/useModal";

import PODetailModal from "../../Components/Modals/OrderTable.modal";
import { useEffect } from "react";
import Alerts from "../../Components/Modals/Alert";
import refresh from "../../images/Refresh.svg";

const OrderTable = ({ stage, option, refresher, handleRefresh }) => {
  // console.log(stage);
  const {
    columns,
    PO,
    modalData,
    isEditable,
    handleSave,
    handleSubmit,
    handleApprove,
  } = useOrderTable({
    stage,
    option,
    refresher,
    handleRefresh,
  });

  // _______________________________________________________
  // Code for handling expand table
  const { open, handleOpen, handleClose } = useModal();
  const [rowInfo, setRowInfo] = useState();
  const [similarItemFlag, setSimilarItemFlag] = useState(false);
  const expandTable = (row, similarFlag = false) => {
    if (similarFlag) {
      setSimilarItemFlag(true);
    } else {
      setSimilarItemFlag(false);
    }
    setRowInfo([row.original]);
    // row.original is an obj {}, wrap it in [] so that when handleClose() triggers.
    // rowInfo is considered as a different object
  };

  // Callback for expandTable()
  useEffect(() => {
    handleOpen();
  }, [rowInfo]);

  // _______________________________________________________

  return (
    <>
      <div className="d-flex flex-row-reverse">
        <Alerts
          buttonText={"Refresh"}
          alertMessage={"Page Refreshed"}
          styling={"Refresh"}
          addFunc={handleRefresh}
          img={refresh}
        />
        {rowInfo && modalData && (
          <PODetailModal
            open={open}
            handleClose={handleClose}
            rowInfo={rowInfo}
            similarFlag={similarItemFlag}
            data={modalData.filter((detail) => detail.sku === rowInfo[0].SKU)}
          />
        )}
      </div>
      <div>
        {isEditable ? (
          ""
        ) : (
          <p>This is the latest table. No changes can be made as of now.</p>
        )}

        {PO && (
          <>
            <RowExpandTable
              columns={columns}
              data={PO?.POdetail}
              rowFunc={expandTable}
            />
          </>
        )}

        {isEditable && (
          <div
            className="d-flex flex-row-reverse"
            style={{ alignItems: "flex-end", marginTop: "20px" }}
          >
            {stage === "Procurement" ? (
              <SubmitDialog
                header={"Send to Management"}
                buttonText={"Send to Management"}
                message={"send this PO to Management"}
                outcome={handleSubmit}
              />
            ) : stage === "Management" ? (
              <>
                <SubmitDialog
                  header={"Reject RO"}
                  buttonText={"Reject RO"}
                  message={
                    "reject this RO and send it back to the Procurement Team"
                  }
                  outcome={handleSubmit}
                />
                <SubmitDialog
                  header={"Approve RO"}
                  buttonText={"Approve RO"}
                  message={"send this RO to Proforma Invoice"}
                  outcome={handleApprove}
                />
              </>
            ) : (
              ""
            )}
            <Alerts
              buttonText={"Save"}
              alertMessage={"Draft Saved Successfully"}
              styling={"secondary"}
              addFunc={handleSave}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OrderTable;
