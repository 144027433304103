import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { modalStyle, SecondaryButton, SubHeader } from "../../Styles/styles";

import { createContainer } from "../../Services/containers.services";
import { useParams } from "react-router-dom";
import { ContainerContext } from "../../Contexts/container.context";

const CreateContainerModal = ({ modalFunctions }) => {
  const [optionState, setOptionState] = useState("");
  const { poid, page } = useParams();

  const { setRefresher } = useContext(ContainerContext);

  const handleCreateContainer = async (e) => {
    await createContainer({
      containerSize: optionState,
      poNum: poid,
      stage: page,
    });
    modalFunctions?.handleClose();
    setOptionState("");
    setRefresher((prev) => prev + 1);
  };

  const style = { ...modalStyle, height: "25vh", width: "40vh" };
  return (
    <Modal open={modalFunctions?.open} onClose={modalFunctions?.handleClose}>
      <Box sx={style}>
        {/* <pre>{JSON.stringify(optionState)}</pre> */}
        <SubHeader>Create Container</SubHeader>
        <form onSubmit={(e) => handleCreateContainer(e)}>
          <div>
            <label htmlFor="size">Size</label>
            <select
              value={optionState}
              onChange={(e) => setOptionState(e.target.value)}
              defaultValue=""
              required
            >
              <option value=""></option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="40hc">40hc</option>
            </select>
          </div>
          <div style={{ marginTop: "30px" }}>
            <SecondaryButton type="submit">Create Container</SecondaryButton>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateContainerModal;
