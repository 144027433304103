import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import produce from "immer";

import {
  saveContainers,
  storeContainers,
} from "../Services/containers.services";

import useColumns from "./useColumns";
import useModal from "./useModal";
import { getUser } from "../Services/auth.service";
import ContainerSwapForm from "../Components/Forms/Container.swap.form";

const useContainer = () => {
  const params = useParams();
  const { poid, page } = params;

  const modalFunctions = useModal();

  const [refresher, setRefresher] = useState(0);

  const [containers, setContainers] = useState();
  const [containerContent, setContainerContent] = useState();
  const [containersArrayIndex, setContainersArrayIndex] = useState(0);
  const [containersIndexClicked, setContainersIndexClicked] = useState(0);

  const updateRow = (rowIndex, columnID, value) => {
    const updatedContainerContent = produce(containerContent, (draft) => {
      draft.map((row, index) => {
        if (index === rowIndex) {
          row[columnID] = value;
        }
        return row;
      });
    });
    const updatedContainers = produce(containers, (draft) => {
      draft[containersArrayIndex].Content = updatedContainerContent;
    });
    setContainers(updatedContainers);
  };

  //________________________________________________________________________________________
  useEffect(() => {
    poid !== "undefined"
      ? storeContainers({ setContainers, poNum: poid, stage: page })
      : storeContainers([]);
  }, [poid, page, refresher]);

  useEffect(() => {
    containers && containers?.length !== 0
      ? setContainerContent(containers[0]?.Content)
      : setContainerContent([]);
  }, [containers]);

  //________________________________________________________________________________________
  const handleClick = ({ index }) => {
    //console.log({ index });
    setContainersArrayIndex(index);
    const currentContainer = containers && containers[index];
    setContainersIndexClicked(currentContainer?.con_id);
    // console.log(containersIndexClicked)
    // console.log(index)
  };

  useEffect(() => {
    const currentContainer = containers && containers[containersArrayIndex];
    setContainerContent(currentContainer?.Content);
  }, [containersArrayIndex]);

  //________________________________________________________________________________________

  const handleSave = () => {
    const currentContainer = containers[containersArrayIndex];

    saveContainers({ container: currentContainer, poNum: poid, stage: page });
  };

  //________________________________________________________________________________________

  const { containerCols } = useColumns({
    updateRow,
    cellFunction: modalFunctions.handleOpen,
  });

  let columns;

  switch (page) {
    case "POGeneration":
    case "POsToReceive":
      columns = containerCols?.containerImmuCol;
      break;
    default:
      // Management User has to be in Managaement stage to do anything.
      // If the user isn't a Management user, he/she has to be a Procurement user(that can do everything)
      // except edit management
      const position = getUser()?.position;
      if (position === "management" && page !== "Management") {
        columns = containerCols?.containerImmuCol;
      } else if (position !== "management" && page === "Management") {
        columns = containerCols?.containerImmuCol;
      } else {
        columns = containerCols?.containerEditableCol;
      }
  }

  //________________________________________________________________________________________

  return {
    containers,
    containerContent,
    handleClick,
    setContainersArrayIndex,
    containersArrayIndex,
    setContainersIndexClicked,
    containersIndexClicked,
    columns,
    handleSave,
    modalFunctions,
    page,
    setRefresher,
  };
};

export default useContainer;
