import React from "react";
import { IconButton } from "@mui/material";
import editIcon from "../../../../images/Edit.svg";
import { SVG } from "../../../../Styles/styles";
import useModal from "../../../../Hooks/useModal";
import EditSafetyStockModalWrapper from "./EditSafetyStockModalWrapper";

const EditButton = ({ id, data }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <EditSafetyStockModalWrapper
        open={open}
        handleClose={handleClose}
        id={id}
        detail={data}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={handleOpen}>
          <SVG src={editIcon} />
        </IconButton>
      </div>
    </>
  );
};

export default EditButton;
