import EditButton from "./EditButton";
import DeletePlanButton from "./DeletePlan/DeletePlanButton";

export const INCENTIVE_TABLE_COLUMNS = [
  {
    headerName: "Employee Type",
    field: "employee_type",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },

  {
    headerName: "SKU ID",
    field: "product_sku",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Product Code",
    field: "product_sku_shortcode",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "SKU Name",
    field: "product_sku_description",
    flex: 3,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Description",
    field: "description",
    flex: 3,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Gross Price",
    field: "gross_price",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Incentive",
    field: "incentive",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Start Date",
    field: "start_date",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "End Date",
    field: "end_date",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "",
    field: "edit",
    flex: 1,
    align: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <EditButton id={params.row.id} />;
    },
  },
  {
    headerName: "",
    field: "delete",
    flex: 1,
    align: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DeletePlanButton id={params.row.id} />;
    },
  },
];
