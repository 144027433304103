import React, { useState, useMemo, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { SetContainerNPOContext } from "./OrderStatusTypeRender"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING

import {
  Box,
  IconButton,
  Icon,
  SvgIcon,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
// import AddBoxIcon from "@mui/icons-material/AddBox";
// import AutoModeIcon from "@mui/icons-material/AutoMode";
import Tooltip from "@mui/material/Tooltip";

import {
  createContainer,
  optimizeContainer,
} from "../../../Services/ordertable.service";

//Context
import { AlertContext } from "../../../App";

// Add Container, Optimize container
export const OrderStatusTypeIconButton = ({
  setContainersInfo,
  containerOptions,
  setIsLoading,
}) => {
  const { orderStatus, ro_num, orderStatusType } = useParams();
  const { setPOInfo, setMasterPOInfo } = useContext(SetContainerNPOContext);
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  // const [containerOptions, setContainerOptions] = useState([]);
  // useEffect(() => {
  //     console.log("getcontainerrunning");
  //     getContainerOptions(setContainerOptions, setIsLoading);
  // }, []);

  //Add container:
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //Track added container size
  const [selectedContainerSize, setSelectedContainerSize] = useState("");

  const handleShowContainerOptions = (e) => {
    // console.log("EVENT HERE: ", e);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseContainerOptions = (size) => {
    setAnchorEl(null);
    // console.log("CONTAINER SIZE: ", size);
    if (typeof size === "string") {
      // call add api here.
      createContainer(
        orderStatus,
        ro_num,
        size, //size
        setContainersInfo,
        setIsLoading,
        setAlertInfo
      );
    }
  };

  const handleOptimizeContainer = () => {
    optimizeContainer(
      orderStatus,
      ro_num,
      setContainersInfo,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo
    );
    // containerOptions.map((option) => {
    //   // console.log(option);
    // });
  };

  return (
    <>
      <ThemeProvider theme={customMUITheme}>
        <Tooltip title="Add Container" placement="top">
          <IconButton
            size="large"
            variant="contained"
            color="addCon"
            id="add-container-button"
            aria-controls={open ? "add-container-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleShowContainerOptions}
          >
            <DashboardCustomizeRoundedIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="add-container-menu"
          aria-labelledby="add-container-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseContainerOptions}
          MenuListProps={{
            "aria-labelledby": "add-container-button",
          }}
        >
          {containerOptions.map((option) => {
            return (
              <MenuItem
                key={option.id}
                onClick={() => handleCloseContainerOptions(option.size)}
                // value={option.size}  << this cannot be pass in as string, so need use state.
              >
                Size: {option.size}, Volume: {option.volume}
              </MenuItem>
            );
          })}
        </Menu>
        <Tooltip title="Optimize Container" placement="top">
          <IconButton
            size="large"
            variant="contained"
            color="optCon"
            id="optimize-container-button"
            onClick={handleOptimizeContainer}
          >
            <CachedRoundedIcon />
          </IconButton>
        </Tooltip>
      </ThemeProvider>
    </>
  );
};

export default OrderStatusTypeIconButton;
