import React, { useCallback, useContext, useEffect, useState } from "react";
import { IconSVG } from "../../../../Styles/styles";
import editIcon from "../../../../images/Edit.svg";
import {
  Box,
  Button,
  FormControlLabel,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Switch,
  Stack,
  InputAdornment,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { styled as MUIStyled } from "@mui/material/styles";
import useModal from "../../../../Hooks/useModal";
import { Field, FieldArray, useFormikContext } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import DatePickerField from "../../../../Components/DatePicker/DatePickerField";
import AddSKUModalWrapper from "./AddSKUModalWrapper";
import {
  savePromoGroupTemplate,
  updatePromoGroupTemplate,
} from "../../../../Services/eventstable.service";
import { AlertContext } from "../../../../App";
import { addPromoGroupCardMapper } from "../mapper";
import AddIcon from "@mui/icons-material/Add";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LocationDisabledRounded } from "@mui/icons-material";

const actionButtonStyle = {
  height: "30px",
  borderColor: "#53545C",
  color: "#53545C",
  margin: "0px 8px",
  fontSize: "11px",
  fontWeight: 400,
  borderRadius: "4px",
  whiteSpace: "nowrap",
  "&:hover": {
    borderColor: "#53545C",
  },
};

const checkboxStyle = {
  color: "#CFD3D4",
  "&.Mui-checked": {
    color: "#bead7c",
  },
};

const textFieldStyle = {
  padding: "0px",
  margin: "0px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const selectFormControlStyle = {
  padding: "0px",
  margin: "0px",
  height: "52px",
  backgroundColor: "#eff1f999",
  borderRadius: "8px",
};

const selectStyle = {
  "& legend": { display: "none" },
  "& fieldset": { border: "none" },
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const SubText = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #45464E;
`;

const Label = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #53545C;
`;

const CheckboxLabel = MUIStyled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6E7079;
`;

const PlaceholderText = MUIStyled(Typography)`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #ABAFB1;
`;

const IOSSwitch = MUIStyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#bead7c" : "#bead7c",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddPromoGroupCard = ({
  index,
  remove,
  skuRows = [],
  promoTypes = [],
  locations = [],
}) => {
  const { alertInfo, setAlertInfo } = useContext(AlertContext);
  const [editPromoGroupName, setEditPromoGroupName] = useState(false);

  const [sgLocations, setSgLocations] = useState([]);

  useEffect(() => {
    let list = [];
    if (locations.length > 0) {
      list = locations.filter((e) => e.is_sg_store === true);
    }

    setSgLocations(list);
  }, [locations]);

  const { open, handleOpen, handleClose } = useModal();
  const {
    open: open2,
    handleOpen: handleOpen2,
    handleClose: handleClose2,
  } = useModal();

  const {
    values,
    setFieldValue,
    handleChange,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
  } = useFormikContext();

  const handleCreatePromoGroup = useCallback(
    async (values) => {
      if (values.promo_type === undefined || values.promo_type === "") {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: "Please select promo type.",
        }));
      } else {
        const map = addPromoGroupCardMapper(values);
        // console.log("handleCreatePromoGroup", map);
        //! Need to make sure that event id and promo group id must be null when calling create endpoint
        map.event = null;
        map.id = null;

        const resp = await savePromoGroupTemplate(map, setAlertInfo);

        //! Update the created promo group id to the form
        if (resp) {
          if (resp.id) {
            setFieldValue(`promo_groups.${index}.id`, resp.id);
          }
        }
      }
    },
    [addPromoGroupCardMapper, savePromoGroupTemplate, setAlertInfo]
  );

  const handleUpdatePromoGroup = useCallback(
    async (values) => {
      if (values.promo_type === undefined || values.promo_type === "") {
        setAlertInfo((prev) => ({
          ...prev,
          open: true,
          severity: "error",
          msg: "Please select promo type.",
        }));
      } else {
        const map = addPromoGroupCardMapper(values);

        // console.log("handleUpdatePromoGroup", map);

        await updatePromoGroupTemplate(values.id, map, setAlertInfo);
      }
    },
    [addPromoGroupCardMapper, updatePromoGroupTemplate, setAlertInfo]
  );

  const handleLocation = (e) => {
    const lvalue = e.target.value;

    if (lvalue[lvalue.length - 1] === "all") {
      // 1: "All"
      setFieldValue(
        `promo_groups.${index}.location`,
        values.promo_groups[index].location.length === locations.length
          ? []
          : locations
      );
      return;
    } else if (lvalue[lvalue.length - 2] === "all") {
      //  2: "All"
      setFieldValue(
        `promo_groups.${index}.location`,
        values.promo_groups[index].location.length === locations.length
          ? []
          : locations
      );
    } else if (lvalue[lvalue.length - 1] === "all-sg-store") {
      // 1: "All SG"

      setFieldValue(
        `promo_groups.${index}.location`,
        values.promo_groups[index].location.length === sgLocations.length
          ? []
          : sgLocations
      );
    } else if (lvalue[lvalue.length - 2] === "all-sg-store") {
      // 2: "All SG"
      setFieldValue(
        `promo_groups.${index}.location`,
        values.promo_groups[index].location.length === sgLocations.length
          ? []
          : sgLocations
      );
    } else {
      setFieldValue(`promo_groups.${index}.location`, e.target.value);
      return;
    }
  };

  return (
    <>
      {/* Primary SKU Modal */}
      <AddSKUModalWrapper
        open={open}
        handleClose={handleClose}
        name={`promo_groups.${index}.primary_sku`}
        skuRows={skuRows}
      />
      {/* Secondary SKU Modal */}
      <AddSKUModalWrapper
        open={open2}
        handleClose={handleClose2}
        name={`promo_groups.${index}.secondary_sku`}
        skuRows={skuRows}
      />

      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          padding: "28px 32px",
          border: "1px solid #C1C1C1",
          margin: "15px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <div style={{ width: "50%", marginBottom: "12px" }}>
            <FormControl fullWidth sx={{ ...selectFormControlStyle }}>
              <Field
                id={`promo_groups.${index}.promo_type`}
                name={`promo_groups.${index}.promo_type`}
                component={({ children }) => (
                  <Select
                    value={values.promo_groups[index].promo_type}
                    displayEmpty
                    onChange={(e) =>
                      setFieldValue(
                        `promo_groups.${index}.promo_type`,
                        e.target.value
                      )
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ ...selectStyle }}
                  >
                    {children}
                  </Select>
                )}
                validate={(value) => {
                  if (value?.length < 1 || value === "") {
                    return "Please select promo type.";
                  }
                }}
              >
                <MenuItem disabled value="">
                  <PlaceholderText>Promo Type</PlaceholderText>
                </MenuItem>
                {promoTypes.map((pt) => (
                  <MenuItem key={pt.id} value={pt.label}>
                    {pt.name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            {touched.promo_groups &&
            touched?.promo_groups[index]?.promo_type &&
            errors.promo_groups &&
            errors?.promo_groups[index]?.promo_type ? (
              <Box
                sx={{
                  color: "#CC5F5F",
                  fontSize: "12px",
                  margin: "8px 12px",
                  paddingBottom: "16px",
                }}
              >
                {errors?.promo_groups[index]?.promo_type}
              </Box>
            ) : (
              <Box sx={{ marginBottom: "24px" }}></Box>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "52px",
              }}
            >
              {editPromoGroupName ? (
                <Field
                  id={`promo_groups.${index}.name`}
                  name={`promo_groups.${index}.name`}
                  validate={(value) => {}}
                >
                  {({ field, form, meta }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id={`promo_groups.${index}.name`}
                      name={`promo_groups.${index}.name`}
                      value={values?.promo_groups[index]?.name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder="Promo Name"
                      InputProps={{}}
                      sx={{
                        ...textFieldStyle,
                        height: "52px",
                        marginRight: "14px",
                      }}
                    />
                  )}
                </Field>
              ) : (
                <SubText
                  sx={{
                    marginRight: "14px",
                  }}
                >
                  {values?.promo_groups[index]?.name === ""
                    ? "Promo Group"
                    : values?.promo_groups[index]?.name}
                </SubText>
              )}

              <IconButton
                onClick={() => {
                  setEditPromoGroupName(!editPromoGroupName);
                }}
              >
                <IconSVG src={editIcon} />
              </IconButton>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              height="35px"
              sx={actionButtonStyle}
              onClick={() => {
                if (values?.promo_groups[index]) {
                  let promoGroup = { ...values.promo_groups[index] };

                  if (promoGroup["id"]) {
                    if (promoGroup["event"]) {
                      handleCreatePromoGroup(promoGroup);
                    } else {
                      handleUpdatePromoGroup(promoGroup);
                    }
                  } else {
                    handleCreatePromoGroup(promoGroup);
                  }
                }
              }}
            >
              {values?.promo_groups[index] &&
              values?.promo_groups[index].id &&
              !values?.promo_groups[index].event
                ? "Update Template"
                : "Create Template"}
            </Button>
            <IconButton
              sx={{ ml: "12px" }}
              onClick={() => {
                if (values.promo_groups.length > 0) {
                  remove(index);
                }
              }}
            >
              <ClearIcon sx={{ color: "#CC5F5F", fontSize: "28px" }} />
            </IconButton>
          </div>
        </div>

        {promoTypes
          .map((a) => a.label)
          .includes(values.promo_groups[index].promo_type) && (
          <>
            <Grid container columnSpacing={2}>
              <>
                {/* START - Free gift with purchase COMPONENT */}
                {values.promo_groups[index].promo_type === "FGWP" && (
                  <>
                    <Grid item xs={6}>
                      <Grid
                        container
                        columnSpacing={2}
                        alignItems="center"
                        sx={{ marginBottom: "24px" }}
                      >
                        <FormControl
                          fullWidth
                          sx={{
                            ...selectFormControlStyle,
                            marginBottom: "24px",
                            marginLeft: "16px",
                          }}
                        >
                          <Field
                            id={`promo_groups.${index}.location`}
                            name={`promo_groups.${index}.location`}
                            component={({ children }) => (
                              <Select
                                value={values.promo_groups[index].location}
                                displayEmpty
                                onChange={handleLocation}
                                inputProps={{ "aria-label": "Without label" }}
                                multiple
                                sx={{ ...selectStyle }}
                                renderValue={(selected) => {
                                  if (
                                    selected === undefined ||
                                    selected?.length === 0
                                  ) {
                                    return (
                                      <PlaceholderText>
                                        Location
                                      </PlaceholderText>
                                    );
                                  } else {
                                    return selected
                                      ?.map((s) => s.name)
                                      .join(", ");
                                  }
                                }}
                              >
                                {children}
                              </Select>
                            )}
                          >
                            <MenuItem
                              value="all"
                              classes={{
                                root:
                                  locations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  locations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.length <=
                                    locations.length
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All
                              </Typography>
                            </MenuItem>
                            {sgLocations.length > 0 && (
                              <MenuItem
                                value="all-sg-store"
                                classes={{
                                  root:
                                    sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length
                                      ? {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.08)",
                                          },
                                        }
                                      : "",
                                }}
                              >
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={
                                    (sgLocations.length > 0 &&
                                      values.promo_groups[index].location
                                        .length === sgLocations.length) ||
                                    values.promo_groups[index].location
                                      .length === locations.length
                                  }
                                  indeterminate={
                                    values.promo_groups[index].location.length >
                                      0 &&
                                    values.promo_groups[index].location.filter(
                                      (e) => e.is_sg_store
                                    ).length > 0
                                  }
                                />
                                <Typography sx={{ fontWeight: "600" }}>
                                  Select All SG Stores
                                </Typography>
                              </MenuItem>
                            )}
                            {locations.map((pt) => {
                              return (
                                <MenuItem key={pt.id} value={pt}>
                                  <Checkbox
                                    sx={checkboxStyle}
                                    checked={values.promo_groups[
                                      index
                                    ].location.some((e) => e.id === pt.id)}
                                  />
                                  {pt.name}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>
                        <Grid item xs={6}>
                          <>
                            <Label sx={{ marginBottom: "8px" }}>
                              Primary SKU(s)
                            </Label>
                            <Field
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              validate={(value) => {}}
                            >
                              {({ field, form, meta }) => (
                                <TextField
                                  margin="normal"
                                  fullWidth
                                  id={`promo_groups.${index}.primary_sku`}
                                  name={`promo_groups.${index}.primary_sku`}
                                  value={
                                    values?.promo_groups[index]?.primary_sku
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  placeholder="Primary SKU(s)"
                                  sx={{
                                    ...textFieldStyle,
                                    height: "52px",
                                    marginRight: "14px",
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                />
                              )}
                            </Field>
                          </>
                        </Grid>
                        <Grid item xs={6}>
                          <>
                            <Label sx={{ marginBottom: "8px" }}>
                              Secondary SKU
                            </Label>
                            <Field
                              id={`promo_groups.${index}.secondary_sku`}
                              name={`promo_groups.${index}.secondary_sku`}
                              validate={(value) => {}}
                            >
                              {({ field, form, meta }) => (
                                <TextField
                                  margin="normal"
                                  fullWidth
                                  id={`promo_groups.${index}.secondary_sku`}
                                  name={`promo_groups.${index}.secondary_sku`}
                                  value={
                                    values?.promo_groups[index]?.secondary_sku
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  placeholder="Secondary SKU"
                                  sx={{
                                    ...textFieldStyle,
                                    height: "52px",
                                    marginRight: "14px",
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={() => {
                                    handleOpen2();
                                  }}
                                />
                              )}
                            </Field>
                          </>
                        </Grid>
                      </Grid>
                      <Grid container columnSpacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <SubText sx={{ color: "#8B8D97" }}>
                            Minimum Spent
                          </SubText>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <SubText sx={{ color: "#8B8D97" }}>
                              Add Min Spent
                            </SubText>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  checked={
                                    values.promo_groups[index]?.add_min_spent
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      `promo_groups.${index}.add_min_spent`,
                                      !values.promo_groups[index].add_min_spent
                                    );
                                  }}
                                />
                              }
                              label=""
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                      {values.promo_groups[index].add_min_spent && (
                        <Field
                          id={`promo_groups.${index}.amount`}
                          name={`promo_groups.${index}.amount`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.amount`}
                              name={`promo_groups.${index}.amount`}
                              value={values?.promo_groups[index]?.amount}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="Amount"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                            />
                          )}
                        </Field>
                      )}
                    </Grid>
                  </>
                )}

                {/* START - Purchase with purchase - COMPONENT */}
                {values.promo_groups[index].promo_type === "PWP" && (
                  <>
                    <Grid item xs={6}>
                      <Grid
                        container
                        columnSpacing={2}
                        alignItems="center"
                        sx={{ marginBottom: "24px" }}
                      >
                        <FormControl
                          fullWidth
                          sx={{
                            ...selectFormControlStyle,
                            marginBottom: "24px",
                            marginLeft: "16px",
                          }}
                        >
                          <Field
                            id={`promo_groups.${index}.location`}
                            name={`promo_groups.${index}.location`}
                            component={({ children }) => (
                              <Select
                                value={values.promo_groups[index].location}
                                displayEmpty
                                onChange={handleLocation}
                                inputProps={{ "aria-label": "Without label" }}
                                multiple
                                sx={{ ...selectStyle }}
                                renderValue={(selected) => {
                                  if (
                                    selected === undefined ||
                                    selected?.length === 0
                                  ) {
                                    return (
                                      <PlaceholderText>
                                        Location
                                      </PlaceholderText>
                                    );
                                  } else {
                                    return selected
                                      ?.map((s) => s.name)
                                      .join(", ");
                                  }
                                }}
                              >
                                {children}
                              </Select>
                            )}
                          >
                            <MenuItem
                              value="all"
                              classes={{
                                root:
                                  locations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  locations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.length <=
                                    locations.length
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All
                              </Typography>
                            </MenuItem>
                            {sgLocations.length > 0 && (
                              <MenuItem
                                value="all-sg-store"
                                classes={{
                                  root:
                                    sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length
                                      ? {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.08)",
                                          },
                                        }
                                      : "",
                                }}
                              >
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={
                                    (sgLocations.length > 0 &&
                                      values.promo_groups[index].location
                                        .length === sgLocations.length) ||
                                    values.promo_groups[index].location
                                      .length === locations.length
                                  }
                                  indeterminate={
                                    values.promo_groups[index].location.length >
                                      0 &&
                                    values.promo_groups[index].location.filter(
                                      (e) => e.is_sg_store
                                    ).length > 0
                                  }
                                />
                                <Typography sx={{ fontWeight: "600" }}>
                                  Select All SG Stores
                                </Typography>
                              </MenuItem>
                            )}
                            {locations.map((pt) => {
                              return (
                                <MenuItem key={pt.id} value={pt}>
                                  <Checkbox
                                    sx={checkboxStyle}
                                    checked={values.promo_groups[
                                      index
                                    ].location.some((e) => e.id === pt.id)}
                                  />
                                  {pt.name}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>
                        <Grid item xs={6}>
                          <>
                            <Label sx={{ marginBottom: "8px" }}>
                              Primary SKU(s)
                            </Label>
                            <Field
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              validate={(value) => {}}
                            >
                              {({ field, form, meta }) => (
                                <TextField
                                  margin="normal"
                                  fullWidth
                                  id={`promo_groups.${index}.primary_sku`}
                                  name={`promo_groups.${index}.primary_sku`}
                                  value={
                                    values?.promo_groups[index]?.primary_sku
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  placeholder="Primary SKU(s)"
                                  sx={{
                                    ...textFieldStyle,
                                    height: "52px",
                                    marginRight: "14px",
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                />
                              )}
                            </Field>
                          </>
                        </Grid>
                        <Grid item xs={6}>
                          <>
                            <Label sx={{ marginBottom: "8px" }}>
                              Secondary SKU
                            </Label>
                            <Field
                              id={`promo_groups.${index}.secondary_sku`}
                              name={`promo_groups.${index}.secondary_sku`}
                              validate={(value) => {}}
                            >
                              {({ field, form, meta }) => (
                                <TextField
                                  margin="normal"
                                  fullWidth
                                  id={`promo_groups.${index}.secondary_sku`}
                                  name={`promo_groups.${index}.secondary_sku`}
                                  value={
                                    values?.promo_groups[index]?.secondary_sku
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  placeholder="Secondary SKU"
                                  sx={{
                                    ...textFieldStyle,
                                    height: "52px",
                                    marginRight: "14px",
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={() => {
                                    handleOpen2();
                                  }}
                                />
                              )}
                            </Field>
                          </>
                        </Grid>
                      </Grid>
                      <div>
                        <Grid
                          container
                          columnSpacing={2}
                          alignItems="center"
                          marginBottom="12px"
                        >
                          <Grid item xs={6}>
                            <SubText sx={{ color: "#8B8D97" }}>
                              {"Discount"}
                            </SubText>
                          </Grid>
                          <Grid item xs={6}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <SubText sx={{ color: "#8B8D97" }}>
                                Percentage/Value
                              </SubText>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                      values.promo_groups[index]
                                        ?.is_discount_percent
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `promo_groups.${index}.is_discount_percent`,
                                        !values.promo_groups[index]
                                          .is_discount_percent
                                      );
                                    }}
                                  />
                                }
                                label=""
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                        {values.promo_groups[index].is_discount_percent ? (
                          <Field
                            id={`promo_groups.${index}.discount`}
                            name={`promo_groups.${index}.discount`}
                            validate={(value) => {}}
                          >
                            {({ field, form, meta }) => (
                              <TextField
                                margin="normal"
                                fullWidth
                                id={`promo_groups.${index}.discount`}
                                name={`promo_groups.${index}.discount`}
                                value={values?.promo_groups[index]?.discount}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                placeholder="Percentage Discount"
                                sx={{
                                  ...textFieldStyle,
                                  height: "52px",
                                  marginRight: "14px",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                type="number"
                              />
                            )}
                          </Field>
                        ) : (
                          <Field
                            id={`promo_groups.${index}.discount`}
                            name={`promo_groups.${index}.discount`}
                            validate={(value) => {}}
                          >
                            {({ field, form, meta }) => (
                              <TextField
                                margin="normal"
                                fullWidth
                                id={`promo_groups.${index}.discount`}
                                name={`promo_groups.${index}.discount`}
                                value={values?.promo_groups[index]?.discount}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                placeholder="Discount"
                                sx={{
                                  ...textFieldStyle,
                                  height: "52px",
                                  marginRight: "14px",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                type="number"
                              />
                            )}
                          </Field>
                        )}
                      </div>
                    </Grid>
                  </>
                )}
                {/* START - Step Up - COMPONENT */}
                {values.promo_groups[index].promo_type === "SU" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...selectFormControlStyle,
                          marginBottom: "24px",
                        }}
                      >
                        <Field
                          id={`promo_groups.${index}.location`}
                          name={`promo_groups.${index}.location`}
                          component={({ children }) => (
                            <Select
                              value={values.promo_groups[index].location}
                              displayEmpty
                              onChange={handleLocation}
                              inputProps={{ "aria-label": "Without label" }}
                              multiple
                              sx={{ ...selectStyle }}
                              renderValue={(selected) => {
                                if (
                                  selected === undefined ||
                                  selected?.length === 0
                                ) {
                                  return (
                                    <PlaceholderText>Location</PlaceholderText>
                                  );
                                } else {
                                  return selected
                                    ?.map((s) => s.name)
                                    .join(", ");
                                }
                              }}
                            >
                              {children}
                            </Select>
                          )}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root:
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                                  ? {
                                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      },
                                    }
                                  : "",
                            }}
                          >
                            <Checkbox
                              sx={checkboxStyle}
                              checked={
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                              }
                              indeterminate={
                                values.promo_groups[index].location.length >
                                  0 &&
                                values.promo_groups[index].location.length <=
                                  locations.length
                              }
                            />
                            <Typography sx={{ fontWeight: "600" }}>
                              Select All
                            </Typography>
                          </MenuItem>
                          {sgLocations.length > 0 && (
                            <MenuItem
                              value="all-sg-store"
                              classes={{
                                root:
                                  sgLocations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    sgLocations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  (sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length) ||
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.filter(
                                    (e) => e.is_sg_store
                                  ).length > 0
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All SG Stores
                              </Typography>
                            </MenuItem>
                          )}
                          {locations.map((pt) => {
                            return (
                              <MenuItem key={pt.id} value={pt}>
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={values.promo_groups[
                                    index
                                  ].location.some((e) => e.id === pt.id)}
                                />
                                {pt.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <div>
                        <SubText
                          sx={{ color: "#8B8D97", marginBottom: "14px" }}
                        >
                          SKU(s)
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.primary_sku`}
                          name={`promo_groups.${index}.primary_sku`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              value={values?.promo_groups[index]?.primary_sku}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="SKU(s)"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <Grid container columnSpacing={2} marginBottom="24px">
                        <Grid item xs={6}>
                          <FormControlLabel
                            sx={{ marginTop: "26px" }}
                            id={`promo_groups.${index}.is_stackable`}
                            name={`promo_groups.${index}.is_stackable`}
                            checked={values.promo_groups[index].is_stackable}
                            onChange={(e) => {
                              setFieldValue(
                                `promo_groups.${index}.is_stackable`,
                                !values.promo_groups[index].is_stackable
                              );
                            }}
                            control={<Checkbox sx={checkboxStyle} />}
                            label={
                              <CheckboxLabel>Stackable Promo</CheckboxLabel>
                            }
                          />{" "}
                        </Grid>
                      </Grid>
                      <FieldArray name={`promo_groups.${index}.step_up`}>
                        {({ insert, remove, push }) => (
                          <>
                            <Grid
                              container
                              columnSpacing={2}
                              alignItems="center"
                              justifyContent="center"
                              sx={{ marginTop: "0px", marginBottom: "8px" }}
                            >
                              <Grid item xs={3}>
                                <Label>{"Discount"}</Label>
                              </Grid>
                              <Grid item xs={4}>
                                <Label>{"From(>=)"}</Label>
                              </Grid>
                              <Grid item xs={5}>
                                <Label>{"Description"}</Label>
                              </Grid>
                            </Grid>
                            {values.promo_groups[index]?.step_up?.map(
                              (row, suIndex) => (
                                <Grid
                                  key={suIndex}
                                  container
                                  columnSpacing={2}
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{ marginTop: "0px" }}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <>
                                      <Field
                                        id={`promo_groups.${index}.step_up.${suIndex}.discount`}
                                        name={`promo_groups.${index}.step_up.${suIndex}.discount`}
                                        validate={(value) => {
                                          if (value === "") {
                                            return "Please fill this field first";
                                          }
                                          let dis = parseFloat(value);

                                          if (isNaN(dis)) {
                                            return "Please fill this field first";
                                          } else {
                                            if (dis > 100) {
                                              return "Invalid value";
                                            }
                                            if (suIndex > 0) {
                                              let prevSU =
                                                values?.promo_groups[index]
                                                  ?.step_up[suIndex - 1];

                                              if (dis < prevSU.discount) {
                                                return `Should be > ${prevSU.discount}`;
                                              } else {
                                                let nextSU =
                                                  values?.promo_groups[index]
                                                    ?.step_up[suIndex + 1];
                                                if (
                                                  !!nextSU &&
                                                  dis > nextSU.discount
                                                ) {
                                                  return `Should be < ${nextSU.discount}`;
                                                }
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        {({ field, form, meta }) => (
                                          <TextField
                                            margin="normal"
                                            fullWidth
                                            id={`promo_groups.${index}.step_up.${suIndex}.discount`}
                                            name={`promo_groups.${index}.step_up.${suIndex}.discount`}
                                            value={
                                              values?.promo_groups[index]
                                                ?.step_up[suIndex]?.discount
                                            }
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            placeholder="Discount"
                                            sx={{
                                              ...textFieldStyle,
                                              height: "52px",
                                              marginRight: "14px",
                                            }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  %
                                                </InputAdornment>
                                              ),
                                            }}
                                            type="number"
                                          />
                                        )}
                                      </Field>

                                      {touched.promo_groups &&
                                      touched?.promo_groups[index]?.step_up &&
                                      touched?.promo_groups[index]?.step_up[
                                        suIndex
                                      ]?.discount &&
                                      errors.promo_groups &&
                                      errors?.promo_groups[index]?.step_up &&
                                      errors?.promo_groups[index]?.step_up[
                                        suIndex
                                      ]?.discount ? (
                                        <Box
                                          sx={{
                                            color: "#CC5F5F",
                                            fontSize: "12px",
                                            height: "24px",
                                          }}
                                        >
                                          {
                                            errors?.promo_groups[index]
                                              ?.step_up[suIndex]?.discount
                                          }
                                        </Box>
                                      ) : (
                                        <Box sx={{ height: "24px" }}></Box>
                                      )}
                                    </>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <>
                                      <Field
                                        id={`promo_groups.${index}.step_up.${suIndex}.from`}
                                        name={`promo_groups.${index}.step_up.${suIndex}.from`}
                                        validate={(value) => {
                                          if (value === "") {
                                            return "Please fill this field first";
                                          }

                                          let from = parseFloat(value);

                                          if (isNaN(from)) {
                                            return "Please fill this field first";
                                          } else {
                                            if (suIndex > 0) {
                                              let prevSU =
                                                values?.promo_groups[index]
                                                  ?.step_up[suIndex - 1];

                                              if (from < prevSU.from) {
                                                return `Should be > ${prevSU.from}`;
                                              } else {
                                                let nextSU =
                                                  values?.promo_groups[index]
                                                    ?.step_up[suIndex + 1];
                                                if (
                                                  !!nextSU &&
                                                  from > nextSU.from
                                                ) {
                                                  return `Should be < ${nextSU.from}`;
                                                }
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        {({ field, form, meta }) => (
                                          <TextField
                                            margin="normal"
                                            fullWidth
                                            id={`promo_groups.${index}.step_up.${suIndex}.from`}
                                            name={`promo_groups.${index}.step_up.${suIndex}.from`}
                                            value={
                                              values?.promo_groups[index]
                                                ?.step_up[suIndex]?.from
                                            }
                                            onChange={(e) => {
                                              handleChange(e);
                                              if (suIndex > 0) {
                                                setFieldValue(
                                                  `promo_groups.${index}.step_up.${
                                                    suIndex - 1
                                                  }.to`,
                                                  e.target.value
                                                );
                                              }
                                              if (
                                                suIndex ===
                                                values?.promo_groups[index]
                                                  ?.step_up[suIndex]?.length -
                                                  1
                                              ) {
                                                setFieldValue(
                                                  `promo_groups.${index}.step_up.${suIndex}.to`,
                                                  null
                                                );
                                              }
                                            }}
                                            placeholder="From"
                                            sx={{
                                              ...textFieldStyle,
                                              height: "52px",
                                              marginRight: "14px",
                                            }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  $
                                                </InputAdornment>
                                              ),
                                            }}
                                            type="number"
                                          />
                                        )}
                                      </Field>

                                      {touched.promo_groups &&
                                      touched.promo_groups[index]?.step_up &&
                                      touched.promo_groups[index]?.step_up[
                                        suIndex
                                      ]?.from &&
                                      errors.promo_groups &&
                                      errors?.promo_groups[index]?.step_up &&
                                      errors?.promo_groups[index]?.step_up[
                                        suIndex
                                      ]?.from ? (
                                        <Box
                                          sx={{
                                            color: "#CC5F5F",
                                            fontSize: "12px",
                                            height: "24px",
                                          }}
                                        >
                                          {
                                            errors?.promo_groups[index]
                                              ?.step_up[suIndex]?.from
                                          }
                                        </Box>
                                      ) : (
                                        <Box sx={{ height: "24px" }}></Box>
                                      )}
                                    </>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    alignItems="center"
                                    justifyContent="center"
                                    marginBottom="24px"
                                  >
                                    <Typography>
                                      {
                                        values?.promo_groups[index]?.step_up[
                                          suIndex
                                        ]?.discount
                                      }
                                      % discount from $
                                      {
                                        values?.promo_groups[index]?.step_up[
                                          suIndex
                                        ]?.from
                                      }
                                      {!values?.promo_groups[index]?.step_up[
                                        suIndex + 1
                                      ]
                                        ? " and above"
                                        : " to $" +
                                          values?.promo_groups[index]?.step_up[
                                            suIndex + 1
                                          ]?.from}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={1}
                                    alignItems="center"
                                    justifyContent="center"
                                    marginBottom="24px"
                                  >
                                    {values.promo_groups[index].step_up.length >
                                      1 && (
                                      <IconButton
                                        onClick={() => {
                                          if (
                                            values.promo_groups[index].step_up
                                              .length > 0
                                          ) {
                                            remove(suIndex);
                                          }
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: "24px",
                                            height: "24px",
                                            background:
                                              "rgba(235, 87, 87, 0.2)",
                                            borderRadius: "24px",
                                          }}
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <CloseRoundedIcon
                                            sx={{
                                              color: "#CC5F5F",
                                              fontSize: "20px",
                                            }}
                                          />
                                        </Box>
                                      </IconButton>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                            <Grid
                              container
                              columnSpacing={2}
                              alignItems="center"
                              sx={{ marginTop: "24px" }}
                            >
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    direction: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    variant="text"
                                    sx={{
                                      minWidth: "34px",
                                      height: "26px",
                                      borderRadius: "7px",
                                      color: "#5570F1",
                                    }}
                                    onClick={() => {
                                      let lastIndex =
                                        values.promo_groups[index]?.step_up
                                          .length - 1;
                                      let lastRow =
                                        values.promo_groups[index]?.step_up[
                                          lastIndex
                                        ];

                                      if (lastRow) {
                                        if (lastRow.from === "") {
                                          setFieldTouched(
                                            `promo_groups.${index}.step_up.${lastIndex}.from`,
                                            true
                                          );
                                          setFieldError(
                                            `promo_groups.${index}.step_up.${lastIndex}.from`,
                                            "This field need to be filled."
                                          );
                                        } else if (lastRow.discount === "") {
                                          setFieldTouched(
                                            `promo_groups.${index}.step_up.${lastIndex}.discount`,
                                            true
                                          );
                                          setFieldError(
                                            `promo_groups.${index}.step_up.${lastIndex}.discount`,
                                            "This field need to be filled."
                                          );
                                        } else {
                                          push({
                                            from: parseFloat(lastRow.from) + 1,
                                            to: "",
                                            discount:
                                              parseFloat(lastRow.discount) + 1,
                                          });
                                        }
                                      } else {
                                        push({
                                          from: "",
                                          to: "",
                                          discount: "",
                                        });
                                      }
                                    }}
                                  >
                                    <AddIcon
                                      sx={{
                                        fontSize: "16px",
                                        color: "#5570F1",
                                      }}
                                    />
                                    <Box width="7.5px" />
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                      }}
                                    >
                                      Add Row
                                    </Typography>
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  </>
                )}

                {/* START - Percentage Discount - COMPONENT */}
                {values.promo_groups[index].promo_type === "PD" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...selectFormControlStyle,
                          marginBottom: "24px",
                        }}
                      >
                        <Field
                          id={`promo_groups.${index}.location`}
                          name={`promo_groups.${index}.location`}
                          component={({ children }) => (
                            <Select
                              value={values.promo_groups[index].location}
                              displayEmpty
                              onChange={handleLocation}
                              inputProps={{ "aria-label": "Without label" }}
                              multiple
                              sx={{ ...selectStyle }}
                              renderValue={(selected) => {
                                if (
                                  selected === undefined ||
                                  selected?.length === 0
                                ) {
                                  return (
                                    <PlaceholderText>Location</PlaceholderText>
                                  );
                                } else {
                                  return selected
                                    ?.map((s) => s.name)
                                    .join(", ");
                                }
                              }}
                            >
                              {children}
                            </Select>
                          )}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root:
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                                  ? {
                                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      },
                                    }
                                  : "",
                            }}
                          >
                            <Checkbox
                              sx={checkboxStyle}
                              checked={
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                              }
                              indeterminate={
                                values.promo_groups[index].location.length >
                                  0 &&
                                values.promo_groups[index].location.length <=
                                  locations.length
                              }
                            />
                            <Typography sx={{ fontWeight: "600" }}>
                              Select All
                            </Typography>
                          </MenuItem>
                          {sgLocations.length > 0 && (
                            <MenuItem
                              value="all-sg-store"
                              classes={{
                                root:
                                  sgLocations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    sgLocations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  (sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length) ||
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.filter(
                                    (e) => e.is_sg_store
                                  ).length > 0
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All SG Stores
                              </Typography>
                            </MenuItem>
                          )}
                          {locations.map((pt) => {
                            return (
                              <MenuItem key={pt.id} value={pt}>
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={values.promo_groups[
                                    index
                                  ].location.some((e) => e.id === pt.id)}
                                />
                                {pt.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <div>
                        <SubText
                          sx={{
                            color: "#8B8D97",
                            fontWeight: "400",
                            marginBottom: "8px",
                          }}
                        >
                          SKU(s)
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.primary_sku`}
                          name={`promo_groups.${index}.primary_sku`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              value={values?.promo_groups[index]?.primary_sku}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="SKU(s)"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div style={{ marginTop: "24px" }}>
                        <SubText
                          sx={{
                            color: "#8B8D97",
                            fontWeight: "400",
                            marginBottom: "8px",
                          }}
                        >
                          Percentage Discount
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.discount`}
                          name={`promo_groups.${index}.discount`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.discount`}
                              name={`promo_groups.${index}.discount`}
                              value={values?.promo_groups[index]?.discount}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="Percentage Discount"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                            />
                          )}
                        </Field>
                      </div>
                    </Grid>
                  </>
                )}

                {/* START - Amount Discount - COMPONENT */}
                {values.promo_groups[index].promo_type === "AD" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...selectFormControlStyle,
                          marginBottom: "24px",
                        }}
                      >
                        <Field
                          id={`promo_groups.${index}.location`}
                          name={`promo_groups.${index}.location`}
                          component={({ children }) => (
                            <Select
                              value={values.promo_groups[index].location}
                              displayEmpty
                              onChange={handleLocation}
                              inputProps={{ "aria-label": "Without label" }}
                              multiple
                              sx={{ ...selectStyle }}
                              renderValue={(selected) => {
                                if (
                                  selected === undefined ||
                                  selected?.length === 0
                                ) {
                                  return (
                                    <PlaceholderText>Location</PlaceholderText>
                                  );
                                } else {
                                  return selected
                                    ?.map((s) => s.name)
                                    .join(", ");
                                }
                              }}
                            >
                              {children}
                            </Select>
                          )}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root:
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                                  ? {
                                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      },
                                    }
                                  : "",
                            }}
                          >
                            <Checkbox
                              sx={checkboxStyle}
                              checked={
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                              }
                              indeterminate={
                                values.promo_groups[index].location.length >
                                  0 &&
                                values.promo_groups[index].location.length <=
                                  locations.length
                              }
                            />
                            <Typography sx={{ fontWeight: "600" }}>
                              Select All
                            </Typography>
                          </MenuItem>
                          {sgLocations.length > 0 && (
                            <MenuItem
                              value="all-sg-store"
                              classes={{
                                root:
                                  sgLocations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    sgLocations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  (sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length) ||
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.filter(
                                    (e) => e.is_sg_store
                                  ).length > 0
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All SG Stores
                              </Typography>
                            </MenuItem>
                          )}
                          {locations.map((pt) => {
                            return (
                              <MenuItem key={pt.id} value={pt}>
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={values.promo_groups[
                                    index
                                  ].location.some((e) => e.id === pt.id)}
                                />
                                {pt.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <div>
                        <SubText
                          sx={{
                            color: "#8B8D97",
                            fontWeight: "400",
                            marginBottom: "8px",
                          }}
                        >
                          SKU(s)
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.primary_sku`}
                          name={`promo_groups.${index}.primary_sku`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              value={values?.promo_groups[index]?.primary_sku}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="SKU(s)"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div style={{ marginTop: "24px" }}>
                        <SubText
                          sx={{
                            color: "#8B8D97",
                            fontWeight: "400",
                            marginBottom: "8px",
                          }}
                        >
                          Amount Discount
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.amount_discount`}
                          name={`promo_groups.${index}.amount_discount`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.amount_discount`}
                              name={`promo_groups.${index}.amount_discount`}
                              value={
                                values?.promo_groups[index]?.amount_discount
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="Amount Discount"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                            />
                          )}
                        </Field>
                      </div>
                    </Grid>
                  </>
                )}

                {/* START - Early Bird Value  - COMPONENT */}
                {values.promo_groups[index].promo_type === "EBV" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...selectFormControlStyle,
                          marginBottom: "24px",
                        }}
                      >
                        <Field
                          id={`promo_groups.${index}.location`}
                          name={`promo_groups.${index}.location`}
                          component={({ children }) => (
                            <Select
                              value={values.promo_groups[index].location}
                              displayEmpty
                              onChange={handleLocation}
                              inputProps={{ "aria-label": "Without label" }}
                              multiple
                              sx={{ ...selectStyle }}
                              renderValue={(selected) => {
                                if (
                                  selected === undefined ||
                                  selected?.length === 0
                                ) {
                                  return (
                                    <PlaceholderText>Location</PlaceholderText>
                                  );
                                } else {
                                  return selected
                                    ?.map((s) => s.name)
                                    .join(", ");
                                }
                              }}
                            >
                              {children}
                            </Select>
                          )}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root:
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                                  ? {
                                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      },
                                    }
                                  : "",
                            }}
                          >
                            <Checkbox
                              sx={checkboxStyle}
                              checked={
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                              }
                              indeterminate={
                                values.promo_groups[index].location.length >
                                  0 &&
                                values.promo_groups[index].location.length <=
                                  locations.length
                              }
                            />
                            <Typography sx={{ fontWeight: "600" }}>
                              Select All
                            </Typography>
                          </MenuItem>
                          {sgLocations.length > 0 && (
                            <MenuItem
                              value="all-sg-store"
                              classes={{
                                root:
                                  sgLocations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    sgLocations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  (sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length) ||
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.filter(
                                    (e) => e.is_sg_store
                                  ).length > 0
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All SG Stores
                              </Typography>
                            </MenuItem>
                          )}
                          {locations.map((pt) => {
                            return (
                              <MenuItem key={pt.id} value={pt}>
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={values.promo_groups[
                                    index
                                  ].location.some((e) => e.id === pt.id)}
                                />
                                {pt.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <div>
                        <SubText
                          sx={{ color: "#8B8D97", marginBottom: "14px" }}
                        >
                          SKU(s)
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.primary_sku`}
                          name={`promo_groups.${index}.primary_sku`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              value={values?.promo_groups[index]?.primary_sku}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="SKU(s)"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <Grid
                        container
                        columnSpacing={2}
                        sx={{ marginTop: "24px" }}
                      >
                        <Grid item xs={6}>
                          <Label sx={{ marginBottom: "8px" }}>Start Date</Label>
                          <DatePickerField
                            name={`promo_groups.${index}.start_date`}
                            value={values?.promo_groups[index]?.start_date}
                            onChange={setFieldValue}
                            placeholder="Start Date"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Label sx={{ marginBottom: "8px" }}>End Date</Label>
                          <DatePickerField
                            name={`promo_groups.${index}.end_date`}
                            value={values?.promo_groups[index]?.end_date}
                            onChange={setFieldValue}
                            placeholder="End Date"
                          />
                        </Grid>
                      </Grid>
                      <Field
                        id={`promo_groups.${index}.early_bird`}
                        name={`promo_groups.${index}.early_bird`}
                        validate={(value) => {}}
                      >
                        {({ field, form, meta }) => (
                          <TextField
                            margin="normal"
                            fullWidth
                            id={`promo_groups.${index}.early_bird`}
                            name={`promo_groups.${index}.early_bird`}
                            value={values?.promo_groups[index]?.early_bird}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Discount"
                            sx={{
                              ...textFieldStyle,
                              height: "52px",
                              marginRight: "14px",
                              marginTop: "24px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            type="number"
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}

                {/* START - Cashback  - COMPONENT */}
                {values.promo_groups[index].promo_type === "CB" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...selectFormControlStyle,
                          marginBottom: "24px",
                        }}
                      >
                        <Field
                          id={`promo_groups.${index}.location`}
                          name={`promo_groups.${index}.location`}
                          component={({ children }) => (
                            <Select
                              value={values.promo_groups[index].location}
                              displayEmpty
                              onChange={handleLocation}
                              inputProps={{ "aria-label": "Without label" }}
                              multiple
                              sx={{ ...selectStyle }}
                              renderValue={(selected) => {
                                if (
                                  selected === undefined ||
                                  selected?.length === 0
                                ) {
                                  return (
                                    <PlaceholderText>Location</PlaceholderText>
                                  );
                                } else {
                                  return selected
                                    ?.map((s) => s.name)
                                    .join(", ");
                                }
                              }}
                            >
                              {children}
                            </Select>
                          )}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root:
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                                  ? {
                                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      },
                                    }
                                  : "",
                            }}
                          >
                            <Checkbox
                              sx={checkboxStyle}
                              checked={
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                              }
                              indeterminate={
                                values.promo_groups[index].location.length >
                                  0 &&
                                values.promo_groups[index].location.length <=
                                  locations.length
                              }
                            />
                            <Typography sx={{ fontWeight: "600" }}>
                              Select All
                            </Typography>
                          </MenuItem>
                          {sgLocations.length > 0 && (
                            <MenuItem
                              value="all-sg-store"
                              classes={{
                                root:
                                  sgLocations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    sgLocations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  (sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length) ||
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.filter(
                                    (e) => e.is_sg_store
                                  ).length > 0
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All SG Stores
                              </Typography>
                            </MenuItem>
                          )}
                          {locations.map((pt) => {
                            return (
                              <MenuItem key={pt.id} value={pt}>
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={values.promo_groups[
                                    index
                                  ].location.some((e) => e.id === pt.id)}
                                />
                                {pt.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <div>
                        <SubText
                          sx={{
                            color: "#8B8D97",
                            fontWeight: "400",
                            marginBottom: "14px",
                          }}
                        >
                          SKU(s)
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.primary_sku`}
                          name={`promo_groups.${index}.primary_sku`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              value={values?.promo_groups[index]?.primary_sku}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="SKU(s)"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div style={{ marginTop: "24px" }}>
                        <SubText
                          sx={{
                            color: "#8B8D97",
                            fontWeight: "400",
                            marginBottom: "14px",
                          }}
                        >
                          Cashback
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.cashback`}
                          name={`promo_groups.${index}.cashback`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.cashback`}
                              name={`promo_groups.${index}.cashback`}
                              value={values?.promo_groups[index]?.cashback}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="Cashback"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                            />
                          )}
                        </Field>
                      </div>
                    </Grid>
                  </>
                )}
                {/* START - Amount off per purchase  - COMPONENT */}
                {values.promo_groups[index].promo_type === "AOPP" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        sx={{
                          ...selectFormControlStyle,
                          marginBottom: "24px",
                        }}
                      >
                        <Field
                          id={`promo_groups.${index}.location`}
                          name={`promo_groups.${index}.location`}
                          component={({ children }) => (
                            <Select
                              value={values.promo_groups[index].location}
                              displayEmpty
                              onChange={handleLocation}
                              inputProps={{ "aria-label": "Without label" }}
                              multiple
                              sx={{ ...selectStyle }}
                              renderValue={(selected) => {
                                if (
                                  selected === undefined ||
                                  selected?.length === 0
                                ) {
                                  return (
                                    <PlaceholderText>Location</PlaceholderText>
                                  );
                                } else {
                                  return selected
                                    ?.map((s) => s.name)
                                    .join(", ");
                                }
                              }}
                            >
                              {children}
                            </Select>
                          )}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root:
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                                  ? {
                                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                      },
                                    }
                                  : "",
                            }}
                          >
                            <Checkbox
                              sx={checkboxStyle}
                              checked={
                                locations.length > 0 &&
                                values.promo_groups[index].location.length ===
                                  locations.length
                              }
                              indeterminate={
                                values.promo_groups[index].location.length >
                                  0 &&
                                values.promo_groups[index].location.length <=
                                  locations.length
                              }
                            />
                            <Typography sx={{ fontWeight: "600" }}>
                              Select All
                            </Typography>
                          </MenuItem>
                          {sgLocations.length > 0 && (
                            <MenuItem
                              value="all-sg-store"
                              classes={{
                                root:
                                  sgLocations.length > 0 &&
                                  values.promo_groups[index].location.length ===
                                    sgLocations.length
                                    ? {
                                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.08)",
                                        },
                                      }
                                    : "",
                              }}
                            >
                              <Checkbox
                                sx={checkboxStyle}
                                checked={
                                  (sgLocations.length > 0 &&
                                    values.promo_groups[index].location
                                      .length === sgLocations.length) ||
                                  values.promo_groups[index].location.length ===
                                    locations.length
                                }
                                indeterminate={
                                  values.promo_groups[index].location.length >
                                    0 &&
                                  values.promo_groups[index].location.filter(
                                    (e) => e.is_sg_store
                                  ).length > 0
                                }
                              />
                              <Typography sx={{ fontWeight: "600" }}>
                                Select All SG Stores
                              </Typography>
                            </MenuItem>
                          )}
                          {locations.map((pt) => {
                            return (
                              <MenuItem key={pt.id} value={pt}>
                                <Checkbox
                                  sx={checkboxStyle}
                                  checked={values.promo_groups[
                                    index
                                  ].location.some((e) => e.id === pt.id)}
                                />
                                {pt.name}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <div>
                        <SubText
                          sx={{ color: "#8B8D97", marginBottom: "14px" }}
                        >
                          SKU(s)
                        </SubText>
                        <Field
                          id={`promo_groups.${index}.primary_sku`}
                          name={`promo_groups.${index}.primary_sku`}
                          validate={(value) => {}}
                        >
                          {({ field, form, meta }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id={`promo_groups.${index}.primary_sku`}
                              name={`promo_groups.${index}.primary_sku`}
                              value={values?.promo_groups[index]?.primary_sku}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              placeholder="SKU(s)"
                              sx={{
                                ...textFieldStyle,
                                height: "52px",
                                marginRight: "14px",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => {
                                handleOpen();
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <Grid
                        container
                        columnSpacing={2}
                        sx={{ marginTop: "24px" }}
                      >
                        <Grid item xs={6}>
                          <Label sx={{ marginBottom: "8px" }}>
                            Purchase Value
                          </Label>
                          <Field
                            id={`promo_groups.${index}.purchase_value`}
                            name={`promo_groups.${index}.purchase_value`}
                            validate={(value) => {}}
                          >
                            {({ field, form, meta }) => (
                              <TextField
                                margin="normal"
                                fullWidth
                                id={`promo_groups.${index}.purchase_value`}
                                name={`promo_groups.${index}.purchase_value`}
                                value={
                                  values?.promo_groups[index]?.purchase_value
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                placeholder="Purchase Value"
                                sx={{
                                  ...textFieldStyle,
                                  height: "52px",
                                  marginRight: "14px",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                type="number"
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Label sx={{ marginBottom: "8px" }}>Amount Off</Label>
                          <Field
                            id={`promo_groups.${index}.amount_off`}
                            name={`promo_groups.${index}.amount_off`}
                            validate={(value) => {}}
                          >
                            {({ field, form, meta }) => (
                              <TextField
                                margin="normal"
                                fullWidth
                                id={`promo_groups.${index}.amount_off`}
                                name={`promo_groups.${index}.amount_off`}
                                value={values?.promo_groups[index]?.amount_off}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                placeholder="Amount Off"
                                sx={{
                                  ...textFieldStyle,
                                  height: "52px",
                                  marginRight: "14px",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                type="number"
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>

              <Grid item xs={6}>
                <Field
                  id={`promo_groups.${index}.description`}
                  name={`promo_groups.${index}.description`}
                  validate={(value) => {}}
                >
                  {({ field, form, meta }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id={`promo_groups.${index}.description`}
                      name={`promo_groups.${index}.description`}
                      value={values.promo_groups[index].description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      multiline
                      placeholder="Short Description"
                      InputProps={{}}
                      sx={{
                        ...textFieldStyle,
                        height: "100%",
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            {values.promo_groups[index].promo_type !== "SU" && (
              <Grid container columnSpacing={2}>
                <Grid item xs={3}>
                  <FormControlLabel
                    sx={{ marginTop: "26px" }}
                    id={`promo_groups.${index}.is_stackable`}
                    name={`promo_groups.${index}.is_stackable`}
                    checked={values.promo_groups[index].is_stackable}
                    onChange={(e) => {
                      setFieldValue(
                        `promo_groups.${index}.is_stackable`,
                        !values.promo_groups[index].is_stackable
                      );
                    }}
                    control={<Checkbox sx={checkboxStyle} />}
                    label={<CheckboxLabel>Stackable Promo</CheckboxLabel>}
                  />{" "}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AddPromoGroupCard;
