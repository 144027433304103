import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import {
  EventActiveTab,
  EventInactiveTab,
  IconSVG,
  Title,
} from "../../../Styles/styles";
import HomeIcon from "../../../images/HomeIcon.svg";

const Campaign = () => {
  return (
    <div style={{ background: "#F4F5FA" }}>
      <div
        style={{
          height: "20%",
          display: "flex",
          padding: "3px",
          alignItems: "center",
          background: "#FFF",
        }}
      >
        <IconSVG
          src={HomeIcon}
          style={{
            alignSelf: "self-start",
            marginTop: "4px",
          }}
        />
        <Title style={{ margin: "0px 12px" }}>/</Title>
        <Title>Events</Title>
      </div>

      <div
        style={{
          marginTop: "3px",
          padding: "20px",
        }}
      >
        <NavLink
          to="active-events"
          children={({ isActive }) => EventButton({ isActive, text: "Active" })}
        />

        <NavLink
          to="upcoming-events"
          children={({ isActive }) =>
            EventButton({ isActive, text: "Upcoming" })
          }
        />
        <NavLink
          to="completed-events"
          children={({ isActive }) =>
            EventButton({ isActive, text: "Completed" })
          }
        />
      </div>
      <Outlet />
    </div>
  );
};

const EventButton = ({ isActive, text }) => {
  return isActive ? (
    <EventActiveTab>{text}</EventActiveTab>
  ) : (
    <EventInactiveTab>{text}</EventInactiveTab>
  );
};

export default Campaign;
