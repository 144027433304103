import React from "react";
import { Field, SecondaryButton } from "../../Styles/styles";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { generateInvoice } from "../../Services/invoice.services";
import { numToCurrency } from "../../Services/util";

const InvoiceForm = ({ tableInfo }) => {
  const { vendor, Content } = tableInfo;
  const { poid } = useParams();

  const totalPrice = numToCurrency(
    Content?.reduce(
      (a, b) =>
        a + (b.promo_qty + b.demand_qty + b.frombacklog + b.jpn_qty) * b.price,
      0
    )
  ).toFixed(2);

  return (
    <div>
      <Formik
        initialValues={{
          comments: "",
          arr_date: "",
          date_required: "",
        }}
        onSubmit={(values) => {
          const invoiceInfo = {
            vendor: tableInfo?.vendor,
            content: Content,
            arr_date: values?.arr_date,
            date_required: values?.date_required,
            comments: values?.comments,
          };
          generateInvoice({ invoiceInfo, poNum: poid });
        }}
      >
        {({ handleChange }) => (
          <Form>
            {/* <pre>{JSON.stringify(tableInfo)}</pre> */}
            {/* <label>RO No.</label>
            <Field
              type="text"
              name="invoice_no"
              onChange={handleChange}
              required
            /> */}
            {/* <label>Receipient Name</label>
            <Field
              type="text"
              name="receipient"
              onChange={handleChange}
              required
            />
            <label>Receipient Address</label>
            <Field
              type="text"
              name="receipient_address"
              onChange={handleChange}
              required
            /> */}

            <label>Arr Date.</label>
            <Field
              type="date"
              name="arr_date"
              onChange={handleChange}
              required
            />
            <label>Date Required.</label>
            <Field
              type="date"
              name="date_required"
              onChange={handleChange}
              required
            />
            <label>Comments</label>
            <Field
              type="text"
              name="comments"
              onChange={handleChange}
              required
            />

            <label>{`Total Price : $${totalPrice}`}</label>
            <div style={{ marginTop: "10px" }}>
              <SecondaryButton type="submit">Download Table</SecondaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InvoiceForm;
