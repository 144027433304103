import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import Modal from "@mui/material/Modal";

import Forecast from "../Charts/ForecastChart";
import styled from "styled-components";
import useForecast from "../../Hooks/useForecast";
import { currencyFormatter } from "../../Services/util";

const Background = styled.div`
  background-color: #faf9f4;
  position: sticky;
  // box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`;

const PODetailModalDetail = ({ data }) => {
  // const SKUInfo = rowInfo[0];

  const {
    options,
    selectedVendor,
    selectedSKU,
    handleSelectVendor,
    handleSelectSKU,

    labels,
    chartData,
  } = useForecast();
  // console.log(options)
  var vendorSelected;
  for (var i = 0; i < options?.length; i++) {
    if (options[i]?.label === data?.vendor) {
      vendorSelected = options[i];
    }
  }
  var skuSelected;
  for (var i = 0; i < vendorSelected?.value.length; i++) {
    if (vendorSelected?.value[i].label === data?.sku) {
      skuSelected = vendorSelected?.value[i];
    }
  }
  // const vendorSelected = options?.map((idx, value)=> value.filter((option)=> option.label === data?.vendor))

  useEffect(() => {
    handleSelectVendor(vendorSelected);
    handleSelectSKU(skuSelected);
  }, [vendorSelected, skuSelected]);

  return (
    <>
      {data && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card
            sx={{ my: 1, py: 2, px: 2, width: 1 / 2 }}
            style={{ boxShadow: "none" }}
          >
            <h3>SKU Information</h3>
            <Background>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                }}
              >
                <div>
                  <p>
                    <span style={{ fontWeight: "bold" }}>SKU : </span>
                    {data.sku}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Product Code : </span>
                    {data.shortcode ?? ""}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>SKU Name : </span>
                    {data.description ?? ""}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Price/Qty : </span>
                    {data.priceqty}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Category : </span>
                    {data.Category}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Similar Items : </span>
                    {data.similaritems.length !== 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th>SKU</th>
                            <th>Vendor</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.similaritems.map((item) => (
                            <tr>
                              <td>{item.skuID}</td>
                              <td>{item.vendorID}</td>
                              <td>{item.qty_ordered}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {data.similaritems.length === 0 && "N/A"}
                  </p>
                </div>
              </div>
            </Background>

            <h3 style={{ marginTop: "10px" }}>SKU Sales</h3>
            <Background>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                }}
              >
                <div>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Gross Sale / Month (6 Months) :{" "}
                    </span>
                    {data.grosssales && currencyFormatter(data.grosssales, "$")}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Discount / Month (6 Months) :{" "}
                    </span>
                    {data.discount && currencyFormatter(data.discount, "$")}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Net Sales / Month (6 Months) :{" "}
                    </span>
                    {data.netsales && currencyFormatter(data.netsales, "$")}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Total Sales : </span>
                    {data.totalsales && currencyFormatter(data.totalsales, "$")}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Avg Qty Sold / Month (YTD) :{" "}
                    </span>
                    {data.avgqtysoldytd}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Avg Qty Sold / Month (3 Mths) :{" "}
                    </span>
                    {data.avgqtysold3months}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Avg Qty on Promo :{" "}
                    </span>
                    {data.promo}
                  </p>
                </div>
              </div>
            </Background>

            <h3 style={{ marginTop: "10px" }}>Stock Information</h3>
            <Background>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                }}
              >
                <div>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Total Stock : </span>
                    {data.totalstock}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      No. of JPN Qty :{" "}
                    </span>
                    {data.jpnqty}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>No. of SG Qty : </span>
                    {data.sgqty}
                  </p>
                </div>
              </div>
            </Background>
          </Card>

          <Card
            sx={{ my: 1, py: 2, px: 2, width: 1 }}
            style={{ boxShadow: "none" }}
          >
            <h3 /*style={{textDecoration: "underline"}}*/>Forecast</h3>
            <div style={{ width: "800px" }}>
              {chartData?.forecast && (
                <Forecast labels={labels} chartData={chartData} />
              )}
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default PODetailModalDetail;
