import React, { useEffect, createContext, useMemo, Fragment } from "react";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { useSticky } from "react-table-sticky";

import { SVG, TertiaryButton } from "../../../Styles/styles";
import up from "../../../images/Ascending.svg";
import down from "../../../images/Descending.svg";
import defaultSort from "../../../images/DefaultSort.svg";

/*
SFPTable is an abstract table that supports Sorting, Filtering and Pagination and rowFunc(usually Modal expansion in this project) upon double click
You need to wrap SFPTable inside a TableContext along with a FilterObject

To do this, it needs to know 2 things:
1) TableSettingsContext({sortBy,filterObject})
where sortBy is an Object, consisting of the columns that supports sorting
and filterObject is a Component that contains FilterModal + FilterLogic
2) TableInfo({data,columns})


How it accomplishes this:
1) pass TableSettings to SFPTable. SFP table uses TableSettings to setInstance(from TableContext)
2) After instance is created, the properties are used in SFPTables. FilterObject(SFP's sibling wrapped in Context) will get the Filter from the context
3) Then, FilterObject will pass its child Modal to the Context, and will be passed to SFPTable

*/
export const SFPTable = ({
  tableSettings,
  tableInfo,
  tableContext,
  filterObject,
  rowFunc = console.log,
}) => {
  //rowFunc is temporary
  //   console.log({ tableSettings, tableInfo, tableContext, rowFunc })   ;
  const { setInstance } = createContext(tableContext);

  const columns = tableInfo?.columns;
  const data = tableInfo?.data;

  //The instance here == tableInstance
  const instance = useTable(
    {
      columns: columns,
      data: data,
      initialState: tableSettings?.sortBy // Might be able to change this to just {sortBy : tableSettings?.sortBy}
        ? { sortBy: tableSettings.sortBy, pageSize: 25 }
        : { pageSize: 25 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useSticky
  );

  useEffect(() => {
    setInstance && setInstance(instance);
  }, [instance, setInstance]);
  // console.log({ SFPTable: instance });

  //Destructuring of table instances
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, //this is basically row with pagination
    nextPage,
    previousPage,
    canPreviousPage, //condition to check whether can go to prev page or not
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    // setGlobalFilter,
    // setFilter,
  } = instance;
  const { pageIndex, pageSize } = state;

  return (
    <div>
      {filterObject && filterObject(instance)}
      <div
        style={{
          maxHeight: "60vh",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        {/* Table HTML */}
        <table {...getTableProps()} className="orderTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(
                      column.Header != ""
                        ? column.getSortByToggleProps() // to enable sorting feature
                        : undefined
                    )}
                    {...column.getHeaderProps(
                      column.Header === "Status"
                        ? {
                            style: {
                              ...column.style,
                              position: "sticky",
                              position: "-webkit-sticky",
                              top: 0,
                              zIndex: 1,
                              padding: "6px",
                            },
                          }
                        : {
                            style: {
                              ...column.style,
                              padding: "6px",
                            },
                          }
                    )}
                  >
                    {column.render("Header")}

                    {/* Add a sort direction indicator */}
                    {column.Header != "" &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <SVG
                            style={{
                              marginLeft: "10px",
                            }}
                            src={up}
                          />
                        ) : (
                          <SVG
                            style={{
                              marginLeft: "10px",
                            }}
                            src={down}
                          />
                        )
                      ) : (
                        <SVG
                          style={{
                            marginLeft: "10px",
                          }}
                          src={defaultSort}
                        />
                      ))}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onDoubleClick={() => rowFunc(row)}
                  style={{ cursor: "pointer", padding: 0 }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Fragment key={`${cell.column.Header} - cell`}>
                        {cell.column.Header === "ID" ? (
                          <td
                            {...cell.getCellProps({
                              style: {
                                ...cell.getCellProps().style,
                                textAlign: "center",
                                backgroundColor: "inherit",
                                top: 40,
                                left: 0,
                                sticky: "left",
                                padding: "0px 6px",
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : cell.column.Header === "Description" ? (
                          <td
                            {...cell.getCellProps({
                              style: {
                                ...cell.getCellProps().style,
                                textAlign: "center",
                                backgroundColor: "inherit",
                                top: 40,
                                left: 220,
                                sticky: "left",
                                padding: "0px 6px",
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : (
                          <td
                            {...cell.getCellProps()}
                            style={{ padding: "0px 6px" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        )}
                      </Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          marginTop: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </TertiaryButton>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </TertiaryButton>
          <TertiaryButton
            style={{ margin: "5px" }}
            className="hoverSecWhiteButton"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </TertiaryButton>
          <TertiaryButton
            className="hoverSecWhiteButton"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </TertiaryButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ marginTop: "10px" }}>
            Page
            <strong style={{ marginLeft: "5px", marginRight: "5px" }}>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <div style={{ width: "8px" }} />
          <span style={{ marginTop: "10px" }}>
            Go To Page:
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px", marginLeft: "5px" }}
            />
          </span>
        </div>
        <span style={{ marginTop: "10px" }}>
          Show
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            {[25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          Items
        </span>
      </div>
    </div>
  );
};

export default SFPTable;

/*


SKU/PO/VenContext = createContext()
<TableContext.Provider>
<FilterObject> => useContext(TableContext)
<SFPTable tableSettings={{sortBy}} tableInfo={{data,col}} tableContext={TableContext}>
<TableContext.Provider>

*/
