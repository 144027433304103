import { createTheme } from "@mui/material/styles";

export const customMUITheme = createTheme({
    main: "#387002",
    palette: {
        save: {
            main: "#387002",
            contrastText: "#fff",
        },
        send: {
            main: "#43a047",
            contrastText: "#fff",
        },
        approve: {
            main: "#00600f",
            contrastText: "#fff",
        },
        reject: {
            main: "#ba000d",
            contrastText: "#fff",
        },
        download: {
            main: "#485202",
            contrastText: "#fff",
        },
        generate: {
            main: "#7c8500",
            contrastText: "#fff",
        },
        addCon: {
            main: "#004c40",
            contrastText: "#fff",
        },
        optCon: {
            main: "#004c40",
            contrastText: "#fff",
        },
    },
});
