import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import editIcon from "../../../images/Edit.svg";
import { SVG } from "../../../Styles/styles";
import { Link } from "react-router-dom";
import DeleteEventButton from "./DeleteEvent/DeleteEventButton";

export const EVENT_TABLE_COLUMNS = [
  {
    headerName: "Event Name",
    field: "name",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  // {
  //   headerName: "Location",
  //   field: "location_name",
  //   flex: 2,
  //   align: "center",
  //   headerAlign: "center",
  // },
  {
    headerName: "Event Description",
    field: "description",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Promo Groups",
    field: "promo_groups",
    flex: 2,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const str = params.row.promo_groups.map((a) => a).join(", ");
      return (
        <Tooltip title={str}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ fontWeight: 400, fontSize: "14px", color: "#6E7079" }}
            >
              {str}
            </Typography>
          </Stack>
        </Tooltip>
      );
    },
  },
  {
    headerName: "Start Date",
    field: "start_date",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "End Date",
    field: "end_date",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },

  {
    headerName: "Created By",
    field: "created_by",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "",
    field: "edit",
    flex: 1,
    disableClickEventBubbling: true,
    align: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Link
          hrefLang={"/"}
          to={{
            pathname: `/edit-event/${params.row.id}`,
            state: { data: params.row },
          }}
        >
          <SVG src={editIcon} />
        </Link>
      );
    },
  },
  {
    headerName: "",
    field: "delete",
    flex: 1,
    disableClickEventBubbling: true,
    align: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DeleteEventButton id={params.row.id} />;
    },
  },
];

export const SKUs_COLUMNS = [
  {
    headerName: "SKU ID",
    field: "skuID",
    flex: 2,
    align: "left",
    headerAlign: "center",
  },
  {
    headerName: "Product Code",
    field: "Shortcode",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },

  {
    headerName: "Description",
    field: "description",
    flex: 2,
    align: "left",
    headerAlign: "center",
  },
  {
    headerName: "Category",
    field: "category",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    headerName: "Vendor ID",
    field: "vendor_id",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
];
