import { performanceAPI } from "../../Components/Services/apiURL";
import BusynessDashboard from "./BusynessDashboard";
import PerformanceDashboard from "./PerformanceDashboard";
import PopularityDashboard from "./PopularityDashboard";
import ProductivityDashboard from "./ProductivityDashboard";
import RevenueDashboard from "./RevenueDashboard";
import React, { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";

export const PerformanceData = () => {
  var alldate = new Date();
  var year = alldate.getFullYear();
  const auth = useAuth()[0];
  const [perYearData, setPerYearData] = useState([]);
  const [perPrevYearData, setPerPrevYearData] = useState([]);
  const [percentArray, setPercentArray] = useState([]);

  const [currYear, setCurrYear] = useState([]);
  const [prevYear, setPrevYear] = useState([]);
  const [label, setLabel] = useState([]);
  const [sma24, setSma24] = useState([]);

  useEffect(() => {
    fetch(performanceAPI, {
      headers: {
        Authorization: "Bearer " + auth.accessToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status_code === 200 && data.status === "success") {
          const performanceData = data.data;

          setCurrYear(performanceData.current_year);
          setPrevYear(performanceData.previous_year);
          setLabel(performanceData.label);
          setSma24(performanceData.sma_24);
        }
        // setPerYearData(data[1]);
        // setPerPrevYearData(data[0]);
        // setPercentArray(data[2]);
      });
  }, []);

  // var perYearData = [1409467.43, 668177.88, 992211.2, 1110248.1, 967026.47, 710168.59,];
  // var perPrevYearData = [1265912.09, 626844.97, 652656.21, 533627.86, 534084.35, 655669.45, 631870.89, 616856.81, 602229.51, 696126.68, 808283.5, 859126.99];
  // var percentArray = [11.34, 6.59 , 11.05, 58.28, 34.2, -20.09];

  return (
    <>
      <PerformanceDashboard
        year={year}
        perPrevYearData={perPrevYearData}
        perYearData={perYearData}
        percentArray={percentArray}
        currentYear={currYear}
        previousYear={prevYear}
        label={label}
        sma_24={sma24}
      />
    </>
  );
};

export const RevenueData = () => {
  var alldate = new Date();
  var year = alldate.getFullYear();

  const revYearTopData = [
    579674.37, 473043.53, 328798.35, 326166.56, 288983.23,
  ];
  const revTopLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "TOH GUAN"];
  const revYearBotData = [1, 10, 8, 10, 5];
  const revPrevLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "OESTOR"];

  const revPrevYearTopData = [
    579674.37, 473043.53, 328798.35, 326166.56, 288983.23,
  ];
  const revPrevTopLabels = [
    "SUNTEC",
    "HQ",
    "HENDERSON",
    "WESTGATE",
    "TOH GUAN",
  ];
  const revPrevYearBotData = [10, 10, 10, 10, 10];
  const revPrevBotLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "OESTOR"];

  return (
    <>
      <RevenueDashboard
        year={year}
        revYearTopData={revYearTopData}
        revTopLabels={revTopLabels}
        revYearBotData={revYearBotData}
        revBotLabels={revPrevLabels}
        revPrevYearTopData={revPrevYearTopData}
        revPrevTopLabels={revPrevTopLabels}
        revPrevYearBotData={revPrevYearBotData}
        revPrevBotLabels={revPrevBotLabels}
      />
    </>
  );
};

export const ProductivityData = () => {
  var alldate = new Date();
  var year = alldate.getFullYear();

  const proYearTopData = [
    579674.37, 473043.53, 328798.35, 326166.56, 288983.23,
  ];
  const proTopLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "TOH GUAN"];
  const proYearBotData = [1, 10, 8, 10, 5];
  const proBotLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "OESTOR"];

  const proPrevYearTopData = [
    579674.37, 473043.53, 328798.35, 326166.56, 288983.23,
  ];
  const proPrevTopLabels = [
    "SUNTEC",
    "HQ",
    "HENDERSON",
    "WESTGATE",
    "TOH GUAN",
  ];
  const proPrevYearBotData = [10, 10, 10, 10, 10];
  const revPrevBotLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "OESTOR"];

  return (
    <>
      <ProductivityDashboard
        year={year}
        proYearTopData={proYearTopData}
        proTopLabels={proTopLabels}
        proYearBotData={proYearBotData}
        proBotLabels={proBotLabels}
        proPrevYearTopData={proPrevYearTopData}
        proPrevTopLabels={proPrevTopLabels}
        proPrevYearBotData={proPrevYearBotData}
        proPrevBotLabels={revPrevBotLabels}
      />
    </>
  );
};

export const BusynessData = () => {
  var alldate = new Date();
  var year = alldate.getFullYear();

  const busYearTopData = [2218, 1726, 1198, 1188, 1158];
  const busTopLabels = ["SUNTEC", "HQ", "WESTGATE", "OESTOR", "HENDERSON"];
  const busYearBotData = [1, 10, 8, 10, 5];
  const busBotLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "OESTOR"];

  const busPrevYearTopData = [2218, 1726, 1198, 1188, 1158];
  const busPrevTopLabels = [
    "SUNTEC",
    "HQ",
    "HENDERSON",
    "WESTGATE",
    "TOH GUAN",
  ];
  const busPrevYearBotData = [10, 10, 10, 10, 10];
  const busPrevBotLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "OESTOR"];

  return (
    <>
      <BusynessDashboard
        year={year}
        busYearTopData={busYearTopData}
        busTopLabels={busTopLabels}
        busYearBotData={busYearBotData}
        busBotLabels={busBotLabels}
        busPrevYearTopData={busPrevYearTopData}
        busPrevTopLabels={busPrevTopLabels}
        busPrevYearBotData={busPrevYearBotData}
        busPrevBotLabels={busPrevBotLabels}
      />
    </>
  );
};

export const PopularityData = () => {
  var alldate = new Date();
  var year = alldate.getFullYear();

  const popYearTopData = [
    818872.76, 421208.02, 351438.38, 328252.14, 257848.45,
  ];
  const popTopLabels = [
    "FS0201823020000CF",
    "DL0000000",
    "FS0500119050001RS",
    "FS0308197030300FA",
    "FS0301517031201JA",
  ];
  const popYearBotData = [0, 0, 0, 0, 0];
  const popBotLabels = [
    "PS0101818010103MF",
    "PS0102320011505MF",
    "PS0301768030401MA",
    "PS0302778030101MA",
    "PS0101818010115MF",
  ];

  const popPrevYearTopData = [
    818872.76, 421208.02, 351438.38, 328252.14, 257848.45,
  ];
  const popPrevTopLabels = [
    "FS0201823020000CF",
    "DL0000000",
    "FS0500119050001RS",
    "FS0308197030300FA",
    "FS0301517031201JA",
  ];
  const popPrevYearBotData = [0, 0, 0, 0, 0];
  const popPrevBotLabels = [
    "PS0101818010103MF",
    "PS0102320011505MF",
    "PS0301768030401MA",
    "PS0302778030101MA",
    "PS0101818010115MF",
  ];

  // SKU

  const skuYearData = [818872.76, 421208.02, 351438.38, 328252.14, 257848.45];
  const skuLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE", "TOH GUAN"];

  const skuPrevYearData = [2218, 1726, 1198, 1188];
  const skuPrevLabels = ["SUNTEC", "HQ", "HENDERSON", "WESTGATE"];
  const SKUID = "FS0500012050000LM";

  return (
    <>
      <PopularityDashboard
        year={year}
        popYearTopData={popYearTopData}
        popTopLabels={popTopLabels}
        popYearBotData={popYearBotData}
        popBotLabels={popBotLabels}
        popPrevYearTopData={popPrevYearTopData}
        popPrevTopLabels={popPrevTopLabels}
        popPrevYearBotData={popPrevYearBotData}
        popPrevBotLabels={popPrevBotLabels}
        skuYearData={skuYearData}
        skuLabels={skuLabels}
        skuPrevYearData={skuPrevYearData}
        skuPrevLabels={skuPrevLabels}
        SKUID={SKUID}
      />
    </>
  );
};

// to pass in apis
