import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Box, Button, Modal, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

//Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import DownloadIcon from "@mui/icons-material/Download";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

// save send approve reject download generate
import {
  putPOInfo,
  sendToManagement,
  approvePO,
  rejectPO,
  generatePO,
  downloadRO,
  backlogUpdate,
} from "../../../Services/ordertable.service";

//RO
import dayjs from "dayjs";

//MUI custome theme
import { ThemeProvider } from "@mui/material/styles";
import { customMUITheme } from "../../../Styles/MUIStyles";

//Styles
import { OrderTableModalStyle } from "../../../Styles/styles";

import OrderStatusTypeButtonDownloadRO from "./OrderStatusTypeButtonDownloadRO";

import { SetContainerNPOContext } from "./OrderStatusTypeRender"; //IF NOT EXPORT DEFAULT MUST USE DESTRUCTRING
import { SelectedContainerContext } from "./OrderStatusTypeRender";
import { AlertContext } from "../../../App";

import { getUser } from "../../../Services/auth.service";
import useModal from "../../../Hooks/useModal";
import OrderManagementRejectModal from "../../../Components/Modals/OrderManagement.reject.modal";

export const OrderStatusTypeButton = ({
  POInfo,
  setPOInfo,
  masterPOInfo,
  setMasterPOInfo,
  setSelectedContainer,
  backlogPOInfo,
  setBacklogPOInfo,
  isLoading,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const position = getUser()?.position; //procurement && management

  const { orderStatus, ro_num, orderStatusType } = useParams();
  const viewOnlyTab =
    orderStatus === "pogeneration" || orderStatus === "postoreceive";

  const { setContainersInfo, isAbleToStage, setIsAbleToStage } = useContext(
    SetContainerNPOContext
  );
  const { selectedContainer } = useContext(SelectedContainerContext);
  const { alertInfo, setAlertInfo } = useContext(AlertContext);

  const {
    tableLoading,
    cardLoading,
    poUpdating,
    containerOptionsLoading,
    creatingContainer,
    deletingContainer,
    optimizingContainer,
    sendingToManagement,
    approvingPO,
    rejectingPO,
    generatingPO,
    downloadingRO,
  } = isLoading;

  const {
    open: rejectOpen,
    handleOpen: handleRejectOpen,
    handleClose: handleRejectClose,
  } = useModal();

  const handleSave = async () => {
    if (orderStatusType === "backlog") {
      await backlogUpdate({
        orderStatus,
        orderStatusType,
        ro_num,
        backlogPOInfo,
        setBacklogPOInfo,
        setIsLoading,
        setAlertInfo,
      });
      setIsAbleToStage(true);
    } else {
      await putPOInfo({
        orderStatus,
        ro_num,
        masterPOInfo,
        setPOInfo,
        setMasterPOInfo,
        setContainersInfo,
        setIsLoading,
        setAlertInfo,
      });
      setIsAbleToStage(true);
    }
  };

  //Send, Approve, Reject need to refresh dashboard
  const handleSend = async () => {
    await sendToManagement(
      orderStatus,
      ro_num,
      setContainersInfo,
      setPOInfo,
      setMasterPOInfo,
      setIsLoading,
      setAlertInfo
    );
    navigate(`/orders/${orderStatus}`);
  };

  const handleApprove = async () => {
    await approvePO(ro_num, setIsLoading, setAlertInfo);
    setIsAbleToStage(true);
    navigate("/orders/management");
  };

  const handleReject = async () => {
    handleRejectOpen();
    // await rejectPO(ro_num, setIsLoading, setAlertInfo);
    // setIsAbleToStage(true);
    // navigate("/orders/management");
  };

  //handle open and close of downloadro and generatepo button
  const [open, setOpen] = useState({
    openDownloadRO: false,
    openGeneratePO: false,
  });

  const handleOpen = (action) => {
    {
      action == "downloadRO" &&
        setOpen({
          ...open,
          openDownloadRO: true,
        });
    }
    {
      action == "generatePO" &&
        setOpen({
          ...open,
          openGeneratePO: true,
        });
    }
  };

  const handleClose = (action) => {
    setOpen({
      openDownloadRO: false,
      openGeneratePO: false,
    });
  };

  //RO
  const [downloadROParams, setDownloadROParams] = useState({
    arrDate: dayjs(),
    dateRequired: dayjs(),
    ROComment: "",
  });

  const handleArrDateChange = (e) => {
    setDownloadROParams((prev) => ({
      ...prev,
      arrDate: e,
    }));
  };
  const handleDateRequiredChange = (e) => {
    setDownloadROParams((prev) => ({
      ...prev,
      dateRequired: e,
    }));
  };
  const handleROCommentChange = (e) => {
    setDownloadROParams((prev) => ({
      ...prev,
      ROComment: e.target.value,
    }));
  };

  const handleDownloadRO = async () => {
    handleClose();
    await downloadRO(
      selectedContainer,
      downloadROParams,
      setIsLoading,
      setAlertInfo
    );
  };

  //PO
  const [POComment, setPOComment] = useState("");
  const handlePOCommentChange = (e) => {
    setPOComment(e.target.value);
  };

  const handleGeneratePO = async () => {
    handleClose();
    const isAllGenerated = await generatePO(
      orderStatus,
      ro_num,
      POComment,
      selectedContainer,
      setPOInfo,
      setMasterPOInfo,
      setContainersInfo,
      setIsLoading,
      setAlertInfo
    );
    setSelectedContainer(-1);
    if (isAllGenerated) {
      navigate("/orders/proforma");
    }
  };

  //Procurement > OrderTable > Save + Send
  //Re-review > OrderTable > Save + Send
  //Management > OrderTable > Approve + Reject
  //Proforma > OrderTable > Save + Download RO and Generate PO
  const buttonDefaultStyling = {
    sx: {
      ml: 1,
    },
    size: "large",
    variant: "contained",
  };

  const disable = selectedContainer === -1;

  return (
    <>
      <OrderManagementRejectModal
        open={rejectOpen}
        handleClose={handleRejectClose}
        ro_num={ro_num}
        setAlertInfo={setAlertInfo}
        setIsLoading={setIsLoading}
        setIsAbleToStage={setIsAbleToStage}
      />
      <Box>
        <ThemeProvider theme={customMUITheme}>
          <LoadingButton
            {...buttonDefaultStyling}
            color="save"
            onClick={() => {
              handleSave();
            }}
            loading={poUpdating}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            hidden={viewOnlyTab}
            disabled={
              position === "management" && orderStatus === "procurement"
            }
          >
            Save
          </LoadingButton>

          <LoadingButton
            {...buttonDefaultStyling}
            color="send"
            hidden={
              !(orderStatus === "procurement" || orderStatus === "rereview") ||
              viewOnlyTab ||
              orderStatusType == "backlog"
            }
            onClick={() => {
              handleSend();
            }}
            loading={sendingToManagement}
            loadingPosition="start"
            disabled={
              !isAbleToStage ||
              (position === "management" && orderStatus === "procurement")
            }
            startIcon={<SendIcon />}
          >
            Send
          </LoadingButton>

          <LoadingButton
            {...buttonDefaultStyling}
            color="approve"
            onClick={() => {
              handleApprove();
            }}
            hidden={
              !(orderStatus === "management") ||
              viewOnlyTab ||
              orderStatusType == "backlog"
            }
            loading={approvingPO}
            loadingPosition="start"
            disabled={!isAbleToStage}
            startIcon={<ThumbUpIcon />}
          >
            Approve
          </LoadingButton>
          <LoadingButton
            {...buttonDefaultStyling}
            color="reject"
            hidden={
              !(orderStatus === "management") ||
              viewOnlyTab ||
              orderStatusType == "backlog"
            }
            onClick={() => {
              handleReject();
            }}
            loading={rejectingPO}
            loadingPosition="start"
            disabled={!isAbleToStage}
            startIcon={<ThumbDownIcon />}
          >
            Reject
          </LoadingButton>
          <LoadingButton
            {...buttonDefaultStyling}
            color="download"
            hidden={
              !(orderStatus === "proforma") ||
              viewOnlyTab ||
              orderStatusType === "backlog"
            }
            onClick={() => {
              handleOpen("downloadRO");
            }}
            loading={downloadingRO}
            loadingPosition="start"
            startIcon={<DownloadIcon />}
            disabled={disable}
          >
            Download RO
          </LoadingButton>
          <LoadingButton
            {...buttonDefaultStyling}
            color="generate"
            hidden={
              !(orderStatus === "proforma") ||
              viewOnlyTab ||
              orderStatusType === "backlog"
            }
            disabled={disable}
            onClick={() => {
              handleOpen("generatePO");
            }}
            loading={generatingPO}
            loadingPosition="start"
            startIcon={<TipsAndUpdatesIcon />}
          >
            Generate PO
          </LoadingButton>
        </ThemeProvider>
        {/* Modal for Download + Generate */}
        <Modal
          open={open.openDownloadRO || open.openGeneratePO}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={OrderTableModalStyle}>
            {/* Download RO - require invoice number */}
            <p>Container Selected: {selectedContainer}</p>

            <Box sx={{ display: "block" }}>
              {open.openDownloadRO && (
                <OrderStatusTypeButtonDownloadRO
                  {...downloadROParams}
                  handleArrDateChange={handleArrDateChange}
                  handleDateRequiredChange={handleDateRequiredChange}
                  handleROCommentChange={handleROCommentChange}
                  handleDownloadRO={handleDownloadRO}
                  handleClose={handleClose}
                  buttonDefaultStyling={buttonDefaultStyling}
                  selectedContainer={selectedContainer}
                />
              )}

              {open.openGeneratePO && (
                <>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Enter your comment"
                      value={POComment}
                      onChange={handlePOCommentChange}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      my: 1,
                      flexGrow: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      {...buttonDefaultStyling}
                      color="success"
                      onClick={() => {
                        handleGeneratePO();
                      }}
                    >
                      <CheckIcon />
                    </Button>
                    <Button
                      {...buttonDefaultStyling}
                      color="error"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default OrderStatusTypeButton;
