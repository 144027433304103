import React from "react";
import Select from "react-select";
import { Formik } from "formik";

import { SecondaryButton } from "../../../Styles/styles";

import { YEAR_OPTIONS, MONTH_OPTIONS } from "../TurnoverChart/chart.utils";
import {
  handleYearChange,
  handleMonthChange,
} from "../TurnoverChart/turnover.services";
import { storeOutstandingRO } from "./outstanding.services";

const ChartFilter = ({ dataSetter }) => {
  var today = new Date();
  var yearNow = today.getFullYear();
  return (
    <>
      <Formik
        initialValues={{ year: null, month: null }}
        validate={(values) => {
          const errors = {};

          if (!values.month) {
            errors.month = "Please select a month!";
          }
          if (!values.year) {
            errors.year = "Please select a year!";
          }

          return errors;
        }}
        onSubmit={(values) => {
          const formData = {
            year: values?.year?.value,
            month: values?.month?.value,
          };

          storeOutstandingRO({
            filteredFormData: formData,
            setter: dataSetter,
          });
        }}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {/* <pre>{JSON.stringify(values)}</pre> */}
            <label>Year</label>
            <Select
              defaultValue={{ label: yearNow, value: yearNow }}
              value={values.year}
              options={YEAR_OPTIONS({ startYear: 2000, endYear: yearNow })}
              onChange={(selectedYear) =>
                handleYearChange(selectedYear, setFieldValue)
              }
            />
            <pre>{JSON.stringify(errors?.year)}</pre>

            <label>Month</label>
            <Select
              value={values.month}
              options={MONTH_OPTIONS}
              onChange={(selectedMonth) =>
                handleMonthChange(selectedMonth, setFieldValue)
              }
            />
            <pre>{JSON.stringify(errors?.month)}</pre>
            <SecondaryButton type="submit">Filter</SecondaryButton>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChartFilter;
