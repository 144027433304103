import React, { useState, useEffect, useRef } from "react";
import { Modal } from "@mui/material";
import EditSafetyStockModal from "./EditSafetyStockModal";

const EditSafetyStockModalWrapper = ({ open, handleClose, id, detail }) => {
  const modalRef = useRef();

  return (
    <Modal open={open} closeOnOverlayClick={false}>
      <div>
        <EditSafetyStockModal
          id={id}
          handleClose={handleClose}
          detail={detail}
        />
      </div>
    </Modal>
  );
};

export default EditSafetyStockModalWrapper;
