import React, { useMemo } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import Table from "../Tables/Table";
import { PODBModalColumns } from "../Tables/columns";
import { Stack } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90vh",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const OutlinedBox = MUIStyled(Box)`
  width: 32px;
  height: 32px;
  background:#FFF2E2;
  border-radius: 8px;
  dislpay: flex;
  position: relative;
  cursor: pointer;
`;

const closeIconStyle = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: " 50%",
  left: "50%",
  margin: "-12px 0 0 -12px",
  color: "#1C1D22",
};

export const POModal = ({ open, handleClose, poInfo }) => {
  const columns = useMemo(PODBModalColumns, []);
  const data = useMemo(() => poInfo[0]?.POdetails, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <h1>Purchase Order</h1>
          <OutlinedBox onClick={handleClose}>
            <ClearIcon sx={closeIconStyle} />
          </OutlinedBox>
        </Stack>
        <Table data={data} columns={columns} />
      </Box>
    </Modal>
  );
};

export default POModal;
