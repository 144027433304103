import React from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { NavLinkChild } from "../../../Components/NavLinkChild";

const OrderTypeNav = () => {
    const { orderStatus } = useParams();

    const isPoGenRec = !(
        orderStatus === "pogeneration" || orderStatus === "postoreceive"
    );

    return (
        <>
            <NavLink
                to="order_table"
                children={({ isActive }) =>
                    NavLinkChild({
                        isActive,
                        text: "Order Table",
                        disabled: false,
                    })
                }
            />
            {isPoGenRec && (
                <NavLink
                    to="backlog"
                    children={({ isActive }) =>
                        NavLinkChild({
                            isActive,
                            text: "Backlog",
                            disabled: false,
                        })
                    }
                />
            )}

            <NavLink
                to="history"
                children={({ isActive }) =>
                    NavLinkChild({ isActive, text: "History", disabled: false })
                }
            />
            <Outlet />
        </>
    );
};

export default OrderTypeNav;
