import React from "react";
import { Modal } from "@mui/material";
import AddSKUModal from "./AddSKUModal";

const AddSKUModalWrapper = ({ open, handleClose, name, skuRows = [] }) => {
  return (
    <Modal open={open} closeOnOverlayClick={false}>
      <AddSKUModal handleClose={handleClose} name={name} skuRows={skuRows} />
    </Modal>
  );
};

export default AddSKUModalWrapper;
