import React from "react";
import { Field } from "../../../Styles/styles";

const EditableNumberCell = ({ updateRow, editableKeyToFocus }) => {
  return ({ cell: { value }, row: { index }, column: { id } }) => {
    const key = index + id;

    const onChange = (e) => {
      e.preventDefault();
      editableKeyToFocus.current = key;
      const currValue = e.target.value;
      updateRow(index, id, parseInt(currValue === "" ? 0 : currValue, 10));
    };

    return (
      <Field
        key={key}
        style={{ width: "100px", textAlign: "right" }}
        value={value}
        min="0"
        onChange={onChange}
        type="number"
        autoFocus={key === editableKeyToFocus.current}
      />
    );
  };
};

export default EditableNumberCell;
