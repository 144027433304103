import React from "react";

import { ActionDashboardFilterRow } from "../../Components/Tables/SortFilterTable/FilterRows";
import useActionDashboardColumns from "./actiondashboard.columns";
import { ActionDashboardContext } from "./actiondashboard.context";

const useActionDashboard = () => {
  const { columns } = useActionDashboardColumns();
  const tableContext = ActionDashboardContext;
  const filterObject = (instance) => (
    <ActionDashboardFilterRow instance={instance} />
  );

  return { columns, tableContext, filterObject };
};

export default useActionDashboard;
